import { AdjustmentStatusValue, OrderStatus, StockAllocationStatus } from './status';

export const AdjStatusArray = [
  { label: 'Convert to pending', value: 1 },
  { label: 'Convert to adjusted', value: 2 },
  { label: 'Convert to abandoned', value: 3 },
];

export const getAdjustmentKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case AdjustmentStatusValue.Pending:
      statusActionByRow = AdjStatusArray?.filter((adj) => adj.value !== AdjustmentStatusValue.Pending);
      break;
    case AdjustmentStatusValue.Adjusted:
      statusActionByRow = [];
      break;
    case AdjustmentStatusValue.Abandoned:
      statusActionByRow = AdjStatusArray?.filter((adj) => adj.value === AdjustmentStatusValue.Pending);
      break;
  }
  return statusActionByRow;
};

export const LocationTypes = [
  { label: 'Warehouse', value: '1' },
  { label: 'Store', value: '2' },
  { label: 'Shop', value: '3' },
  { label: 'Office', value: '4' },
  { label: 'Factory', value: '5' },
  { label: 'Home', value: '6' },
];

export const TransactionTypes = [
  // { label: 'Opening Balance', value: "1" },
  { label: 'Purchase', value: '2' },
  { label: 'Sales', value: '3' },
  { label: 'Return', value: '5' },
  { label: 'Adjustment', value: '6' },
  { label: 'Transfer', value: '7' },
];
export const IndustryTypes = [
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Electronics', value: 'Electronics' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Banking', value: 'Banking' },
  { label: 'Biotechnology', value: 'Biotechnology' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consulting', value: 'Consulting' },
  { label: 'Education', value: 'Education' },
  { label: 'Energy', value: 'Energy' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Media', value: 'Media' },
  { label: 'Nonprofit', value: 'Nonprofit' },
  { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Software', value: 'Software' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: 'Other' },
];

export const AccountTypes = [
  { value: 1, label: 'Customer' },
  { value: 2, label: 'Vendor' },
  { value: 3, label: 'Partner' },
  { value: 4, label: 'Investor' },
  { value: 5, label: 'Affiliate' },
  { value: 6, label: 'Supplier' },
  { value: 7, label: 'Distributor' },
  { value: 8, label: 'Manufacturer' },
  { value: 9, label: 'Retailer' },
  { value: 10, label: 'Wholesaler' },
  { value: 11, label: 'Broker' },
  { value: 12, label: 'Agent' },
  { value: 13, label: 'Contractor' },
  { value: 14, label: 'Subcontractor' },
  { value: 15, label: 'ServiceProvider' },
  { value: 16, label: 'Consultant' },
  { value: 17, label: 'Freelancer' },
];

export const EntityTypes = [
  { value: 1, label: 'Individual' },
  { value: 2, label: 'Business' },
];

export const AccountCategories = [
  { value: 1, label: 'High Value' },
  { value: 2, label: 'Regular' },
  { value: 3, label: 'Occasional' },
];

export const AccountStatuses = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
  { value: 3, label: 'Suspended' },
];

export const PaymentTermsTypes = [
  { value: 'fulladvance', label: 'Full advance' },
  { value: 'partialadvance', label: 'Partial advance' },
  { value: 'net15', label: 'Net 15' },
  { value: 'net30', label: 'Net 30' },
  { value: 'net45', label: 'Net 45' },
  { value: 'net60', label: 'Net 60' },
  { value: 'other', label: 'Other' },
];

export const PaymentMethods = [
  { value: 'cash', label: 'Cash' },
  { value: 'bankTransfer', label: 'Bank Transfer' },
  { value: 'cheque', label: 'Cheque' },
  { value: 'upi', label: 'UPI' },
  { value: 'other', label: 'Other' },
];

export const DeliveryMethods = [
  { value: 'Air', label: 'Air' },
  { value: 'Sea Container', label: 'Sea Container' },
  { value: 'Ground', label: 'Ground' },
  { value: 'Rail', label: 'Rail' },
  { value: 'Courier', label: 'Courier' },
  { value: 'Self Pickup', label: 'Self Pickup' },
  { value: 'Express', label: 'Express' },
  { value: 'Standard Shipping', label: 'Standard Shipping' },
  { value: 'Overnight Shipping', label: 'Overnight Shipping' },
  { value: 'Two-Day Shipping', label: 'Two-Day Shipping' },
  { value: 'Freight', label: 'Freight' },
  { value: 'Drone Delivery', label: 'Drone Delivery' },
];

export const OperationsChips = [
  {
    label: 'Work Order',
    value: 'workOrder',
    bindValue: 'isworkOrder',
    shouldDisplay: false,
  },
  { label: 'Quality Control', value: 'qualityControl', bindValue: 'isQualityControl', shouldDisplay: false },
  { label: 'Packaging', value: 'packaging', bindValue: 'isPackaging', shouldDisplay: true },
  { label: 'Logistics & Delivery', value: 'logisticDelivery', bindValue: 'isLogisticsDelivery', shouldDisplay: true },
];

export const SaleOrderStatusArray = [
  { label: 'Submit Order', value: 1 },
  { label: 'Complete Order', value: 4 },
  { label: 'Cancel Order', value: 5 },
];

export const getSalesOrderKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case OrderStatus.Draft:
      statusActionByRow = SaleOrderStatusArray?.filter((adj) => adj.value !== 4);
      break;
    case OrderStatus.Pending:
      statusActionByRow = SaleOrderStatusArray?.filter((adj) => adj.value !== 1);
      break;
  }
  return statusActionByRow;
};

export const ManageAllocationChips = [
  {
    label: 'Procure',
    value: 'procure',
  },
  { label: 'Produce', value: 'produce' },
  { label: 'Pick', value: 'pick' },
];

export const Granularity = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];

export const getAllocationStatus = (status) => {
  let allocationStatus = '';
  switch (status) {
    case StockAllocationStatus.InProgress:
      allocationStatus = 'In Progress';
      break;
    default:
      allocationStatus = status;
      break;
  }
  return allocationStatus;
};

export const AllocationKebabOptions = [
  { label: 'Go to Sales Order', value: 2 },
  { label: 'Cancel Stock Allocation', value: 3 },
  { label: 'Convert to Pending', value: 1 },
];

export const getAllocationKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case StockAllocationStatus.Pending:
      statusActionByRow = AllocationKebabOptions?.filter((adj) => adj.value !== 1);
      break;
    case StockAllocationStatus.Cancelled:
      statusActionByRow = AllocationKebabOptions?.filter((adj) => adj.value !== 3);
      break;
    case StockAllocationStatus.InProgress:
    case 'In Progress':
    case StockAllocationStatus.Allocated:
      statusActionByRow = AllocationKebabOptions?.filter((adj) => adj.value === 2);
      break;
  }
  return statusActionByRow;
};

export const MfgChips = [
  {
    label: 'Materials',
    value: 'materials',
    bindValue: 'materials',
    shouldDisplay: true,
  },
];

export const saKebabKeys = {
  workOrderId: 'workOrderId',
  packagingOrderId: 'packagingOrderId',
  qualityOrderId: 'qualityOrderId',
};

// Branding
export const BRAND_NAME = 'Raya AI';

// URIs
export const WEBSITE_URI = process.env.REACT_APP_WEBSITE_URL;
export const DASHBOARD_APP_URI = process.env.REACT_APP_DASHBOARD_URL;
export const BI_APP_URI = process.env.REACT_APP_BI_URL;

// domain
export const DOMAIN = process.env.DOMAIN;

// IDS API Endpoints
export const API_IDS_BASE_URL = process.env.REACT_APP_IDS_API_BASE_URL;
export const API_AUTH_RENEW_TOKEN = API_IDS_BASE_URL + '/RenewSession';
export const API_USERS_ENDPOINT = API_IDS_BASE_URL + '/users';
export const API_ACCOUNT_ENDPOINT = API_IDS_BASE_URL + '/AuthRayaAccount';
export const API_API_KEYS_ENDPOINT = API_IDS_BASE_URL + '/apikeys';

// Workflow API Endpoints
export const API_WORKFLOW_BASE_URL = process.env.REACT_APP_WORKFLOW_API_BASE_URL;
export const API_DOCUMENTS_ENDPOINT = '/documents';

// Inventory API Endpoints
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_PRODUCTS_ENDPOINT = '/products';
export const API_PRODUCTS_STOCKHISTORY_ENDPOINT = '/stockhistory';
export const API_SALES_TRANSACTIONS_ENDPOINT = '/salestransactions';
export const API_SALES_LEADS_ENDPOINT = '/salesleads';
export const API_TENANTS_ENDPOINT = '/tenants';
export const API_ORDER_ITEMS_ENDPOINT = '/transactionorderitems';
export const API_ORDERITEM_PREPDETAILS_ENDPOINT = '/prepitems';
export const API_SALES_DELIVERY_NOTES_ENDPOINT = '/deliverynotes';
export const API_ITEM_TRANSACTIONS_ENDPOINT = API_ORDER_ITEMS_ENDPOINT + '/transactions';
export const API_ADJUSTMENTS_ENDPOINT = '/adjustments';
export const API_ADJUSTMENTS_REASON_ENDPOINT = '/adjustmentreason';
export const API_PRODUCT_LOCATION_ENDPOINT = '/productlocation';
export const API_SKU_ENDPOINT = '/sku';
export const API_PRODUCT_CATEGORY_ENDPOINT = '/productcategory';
export const API_PRODUCT_TRANSACTIONS_ENDPOINT = '/producttransactions';
export const API_STOCK_ALLOCATION_ENDPOINT = '/operations/stock-allocations';
export const API_OPERATIONS_ENDPOINT = '/operations';
export const API_LOCATION_ENDPOINT = '/locations';

// Claims
export const TOKEN_EMAIL_CLAIMS = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
export const TOKEN_ACCOUNT_NAME_CLAIMS = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsaccountname';
export const TOKEN_USER_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const TOKEN_USER_GIVEN_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
export const TOKEN_USER_PRIMARY_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid';
export const TOKEN_COMPANY_LOGO_CLAIMS = 'CompanyLogo';

// Colors
export const PRIMARY_COLOR = '#007bff';
export const SECONDARY_COLOR = '#6c757d';

// Configuration
export const MAX_ITEMS_PER_PAGE = 10;

// Messages
export const ERROR_MESSAGE = 'An error occurred. Please try again later.';

// User Roles
export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const UNIT_OPTIONS = [
  { value: '1', label: 'Kg' },
  { value: '2', label: 'Gram' },
  { value: '3', label: 'Litre' },
  { value: '4', label: 'Millilitre' },
  { value: '5', label: 'Piece' },
  { value: '6', label: 'Box' },
  { value: '7', label: 'Bottle' },
  { value: '8', label: 'Can' },
  { value: '9', label: 'Packet' },
  { value: '10', label: 'Bag' },
  { value: '11', label: 'Tin' },
  { value: '12', label: 'Jar' },
  { value: '13', label: 'Dozen' },
  { value: '14', label: 'Pack' },
  { value: '15', label: 'Set' },
  { value: '16', label: 'Pair' },
  { value: '17', label: 'Unit' },
  { value: '18', label: 'Other' },
];

export const ITEM_TYPE_OPTIONS = [
  { value: '1', label: 'Raw Material' },
  { value: '2', label: 'Finished Goods' },
  { value: '3', label: 'Consumables' },
  { value: '4', label: 'Services' },
  { value: '7', label: 'Packaging Material' },
];

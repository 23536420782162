import { Delete } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import React from 'react';
import CustomAutocomplete from '../../../../common/autocomplete';
import { BootstrapTooltip } from '../../../../common/commonUtility';

const NewItemLocation = ({ locations = [], handleLocationChange, handleLocationFieldChange, handleRemoveLocationField, locationOptions, itemForm }) => {
  const disabledValues = locations.map((item) => item.selectedItem?.value);
  return (
    <>
      {locations?.length
        ? locations?.map((location, index) => (
            <Grid container key={index} spacing={2} className="mt-1">
              <Grid item xs={12} md={5}>
                <CustomAutocomplete
                  value={location.selectedItem || ''}
                  onChange={(e, newValue) => handleLocationChange(newValue, index)}
                  options={locationOptions}
                  disabledValues={disabledValues?.filter((dis) => dis !== location?.selectedItem?.value)}
                  inputLabel="Location"
                  placeholder="Select location..."
                  getOptionLabel={(option) => option.label || ''}
                  isError={!location?.selectedItem?.value && itemForm.submitted}
                  helperText={!location?.selectedItem?.value && itemForm.submitted ? 'Location is required.' : ''}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  type="number"
                  label="Opening Balance"
                  id="openingBalance"
                  value={location.openingBalance}
                  name="openingBalance"
                  variant="outlined"
                  onChange={(e) => handleLocationFieldChange(e, index)}
                  error={!location?.openingBalance && itemForm.submitted}
                  helperText={!location?.openingBalance && itemForm.submitted ? 'Opening balance is required.' : ''}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                {locations?.length > 1 ? (
                  <BootstrapTooltip title="Delete">
                    <IconButton color="info" onClick={() => handleRemoveLocationField(index)}>
                      <Delete />
                    </IconButton>
                  </BootstrapTooltip>
                ) : null}
              </Grid>
            </Grid>
          ))
        : null}
    </>
  );
};

export default NewItemLocation;

import { useUser } from '../../auth/useUser';

export const useAccessControl = (requiredAccess) => {
  const user = useUser(); // Use custom hook to get user claims
  const accessibleComponents = user['AccessibleComponents'] || [];

  return (
    accessibleComponents.includes(RayaComponent.All) ||
    accessibleComponents.includes(requiredAccess)
  );
};

// Enum for components access
export const RayaComponent = {
    All: 'All',
    AccountsPII: 'AccountsPII',
    FinancialData: 'FinancialData',
    Dashboard: 'Dashboard',
    Inventory: 'Inventory',
    Operations: 'Operations',
    Sales: 'Sales',
    Purchase: 'Purchase',
    Accounting: 'Accounting',
    Reports: 'Reports',
    Finances: 'Finances',
    Documents: 'Documents',
    Analytics: 'Analytics',
  };

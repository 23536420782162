import { Dialog } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { NewAccount } from '../inventory-mgmt-app/accounts/accounts/new-account';

export const CreateCustomerModal = React.memo(() => {
  const { isAccountModalOpen } = useSelector((state) => state?.account?.data);
  return (
    <Dialog
      open={isAccountModalOpen}
      PaperProps={{
        style: {
          height: '90vh', // Set the maximum height
          minWidth: '90vw', // Set the width
        },
      }}
    >
      <NewAccount />
    </Dialog>
  );
});

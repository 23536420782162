import { Checkbox, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import CustomAutocomplete from '../../../../common/autocomplete';
import { setAccountAddressData } from '../../../../redux/actions/account-action';
import { useDispatch, useSelector } from 'react-redux';

countries.registerLocale(enLocale);
export const AccountAddress = () => {
  const { stateBillingAddressForm, stateFormSubmitted } = useSelector((state) => state?.account?.data);
  const dispatch = useDispatch();
  const emptyAddress = {
    address: '',
    city: '',
    countryCode: '',
    lvCountryCode: '',
    postalCode: '',
    isAddressDifferent: false,
  };
  const [billingAddressForm, setBillingAddressForm] = useState({
    billingAddress: emptyAddress,
    shippingAddress: emptyAddress,
  });

  useEffect(() => {
    if (stateBillingAddressForm) {
      setBillingAddressForm(stateBillingAddressForm);
    }
  }, [stateBillingAddressForm]);

  const handleControlBillingChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBillingAddressForm({
      ...billingAddressForm,
      billingAddress: {
        ...billingAddressForm?.billingAddress,
        [name]: type === 'checkbox' ? checked : value,
      },
      shippingAddress: type === 'checkbox' && checked ? billingAddressForm?.shippingAddress : emptyAddress,
    });
    dispatch(
      setAccountAddressData({
        ...billingAddressForm,
        billingAddress: {
          ...billingAddressForm?.billingAddress,
          [name]: type === 'checkbox' ? checked : value,
        },
        shippingAddress: type === 'checkbox' && checked ? billingAddressForm?.shippingAddress : emptyAddress,
      })
    );
  };

  const handleControlShippingChange = (e) => {
    const { name, value } = e.target;
    setBillingAddressForm({
      ...billingAddressForm,
      shippingAddress: {
        ...billingAddressForm?.shippingAddress,
        [name]: value,
      },
    });
    dispatch(
      setAccountAddressData({
        ...billingAddressForm,
        shippingAddress: {
          ...billingAddressForm?.shippingAddress,
          [name]: value,
        },
      })
    );
  };

  return (
    <div className="mx-2">
      <Typography className="fs-14 fw-500 d-flex" gutterBottom>
        Billing Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} className="mt-2">
          <TextField
            name="address"
            label="Address *"
            multiline
            rows={2}
            variant="outlined"
            value={billingAddressForm?.billingAddress?.address}
            onChange={handleControlBillingChange}
            error={!billingAddressForm?.billingAddress?.address?.trim() && stateFormSubmitted}
            helperText={!billingAddressForm?.billingAddress?.address?.trim() && stateFormSubmitted ? 'Billing Address is required.' : ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="city"
            name="city"
            label="City *"
            variant="outlined"
            value={billingAddressForm?.billingAddress?.city}
            onChange={handleControlBillingChange}
            error={!billingAddressForm?.billingAddress?.city?.trim() && stateFormSubmitted}
            helperText={!billingAddressForm?.billingAddress?.city?.trim() && stateFormSubmitted ? 'Billing City is required.' : ''}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="state"
            name="state"
            label="State *"
            variant="outlined"
            value={billingAddressForm?.billingAddress?.state}
            onChange={handleControlBillingChange}
            error={!billingAddressForm?.billingAddress?.state?.trim() && stateFormSubmitted}
            helperText={!billingAddressForm?.billingAddress?.state?.trim() && stateFormSubmitted ? 'Billing State is required.' : ''}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            id="postalCode"
            name="postalCode"
            label="Zip Code *"
            variant="outlined"
            value={billingAddressForm?.billingAddress?.postalCode}
            onChange={handleControlBillingChange}
            error={!billingAddressForm?.billingAddress?.postalCode?.trim() && stateFormSubmitted}
            helperText={!billingAddressForm?.billingAddress?.postalCode?.trim() && stateFormSubmitted ? 'Billing Zip Code is required.' : ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={billingAddressForm?.billingAddress?.lvCountryCode}
            onChange={(event, newValue) => {
              setBillingAddressForm({
                ...billingAddressForm,
                billingAddress: {
                  ...billingAddressForm.billingAddress,
                  countryCode: newValue?.value,
                  lvCountryCode: { value: newValue?.value, label: newValue?.label },
                },
              });
              dispatch(
                setAccountAddressData({
                  ...billingAddressForm,
                  billingAddress: {
                    ...billingAddressForm.billingAddress,
                    countryCode: newValue?.value,
                    lvCountryCode: { value: newValue?.value, label: newValue?.label },
                  },
                })
              );
            }}
            options={Object.entries(countries.getNames('en', { select: 'official' })).map(([code, name]) => ({ value: code, label: name }))}
            inputLabel="Country *"
            disableAddNew={true}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
            isError={!billingAddressForm?.billingAddress?.countryCode && stateFormSubmitted}
            helperText={!billingAddressForm?.billingAddress?.countryCode && stateFormSubmitted ? 'Billing Country is required.' : ''}
          />
        </Grid>
        <Grid item xs={12} md={12} className="d-flex">
          <Typography className="label mt-2">Shipping address is different</Typography>
          <Checkbox
            size="small"
            name="isAddressDifferent"
            checked={billingAddressForm?.billingAddress?.isAddressDifferent || false}
            onChange={(e) => {
              handleControlBillingChange(e);
            }}
          />
        </Grid>

        {billingAddressForm?.billingAddress?.isAddressDifferent ? (
          <>
            <Grid className="mx-4">
              <Typography variant="h6" className="d-flex fs-14 fw-500" gutterBottom>
                Shipping Address
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} className=" mt-2">
              <TextField
                name="address"
                label="Address"
                multiline
                rows={2}
                variant="outlined"
                value={billingAddressForm?.shippingAddress?.address}
                onChange={handleControlShippingChange}
                error={!billingAddressForm?.shippingAddress?.address?.trim() && stateFormSubmitted}
                helperText={!billingAddressForm?.shippingAddress?.address?.trim() && stateFormSubmitted ? 'Shipping Address is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="city"
                name="city"
                label="City"
                variant="outlined"
                value={billingAddressForm?.shippingAddress?.city}
                onChange={handleControlShippingChange}
                error={!billingAddressForm?.shippingAddress?.city?.trim() && stateFormSubmitted}
                helperText={!billingAddressForm?.shippingAddress?.city?.trim() && stateFormSubmitted ? 'Shipping City is required.' : ''}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <TextField id="state" name="state" label="State" variant="outlined" value={billingAddressForm?.shippingAddress?.state} onChange={handleControlShippingChange} />
              </Grid> */}
            <Grid item xs={12} md={6}>
              <TextField
                id="postalCode"
                name="postalCode"
                label="Zip Code"
                variant="outlined"
                value={billingAddressForm?.shippingAddress?.postalCode}
                onChange={handleControlShippingChange}
                error={!billingAddressForm?.billingAddress?.postalCode?.trim() && stateFormSubmitted}
                helperText={!billingAddressForm?.billingAddress?.postalCode?.trim() && stateFormSubmitted ? 'Shipping Zip Code is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAutocomplete
                value={billingAddressForm?.shippingAddress?.lvCountryCode}
                onChange={(event, newValue) => {
                  setBillingAddressForm({
                    ...billingAddressForm,
                    shippingAddress: {
                      ...billingAddressForm.shippingAddress,
                      countryCode: newValue?.value,
                      lvCountryCode: { value: newValue?.value, label: newValue?.label },
                    },
                  });
                  dispatch(
                    setAccountAddressData({
                      ...billingAddressForm,
                      shippingAddress: {
                        ...billingAddressForm.shippingAddress,
                        countryCode: newValue?.value,
                        lvCountryCode: { value: newValue?.value, label: newValue?.label },
                      },
                    })
                  );
                }}
                options={Object.entries(countries.getNames('en', { select: 'official' })).map(([code, name]) => ({ value: code, label: name }))}
                inputLabel="Country"
                disableAddNew={true}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
                isError={!billingAddressForm?.shippingAddress?.countryCode && stateFormSubmitted}
                helperText={!billingAddressForm?.shippingAddress?.countryCode && stateFormSubmitted ? 'Shipping Country is required.' : ''}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { API_SALES_DELIVERY_NOTES_ENDPOINT } from '../../constants/constants';
import { DeliveryMethods } from '../common-constants/static-values';
import api from '../common/api';
import CustomAutocomplete from '../common/autocomplete';
import CloseIcon from '@mui/icons-material/Close';

export const DeliveryMethodModal = ({ showModal, handleCloseDeliveryNote }) => {
  const deliveryNotesEndpoint = API_SALES_DELIVERY_NOTES_ENDPOINT;
  const emptyDeliveryObj = {
    name: '',
    deliveryMethod: '',
    lvDeliveryMethod: '',
    notes: '',
    submitted: false,
  };
  const [deliveryNoteForm, setDeliveryNoteForm] = useState(emptyDeliveryObj);
  const [loading, setLoading] = useState(false);
  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setDeliveryNoteForm({
      ...deliveryNoteForm,
      [name]: value,
    });
  };

  const getValidatedFormResult = () => {
    if (!deliveryNoteForm.name?.trim() || !deliveryNoteForm?.deliveryMethod) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDeliveryNoteForm({
      ...deliveryNoteForm,
      submitted: true,
    });
    if (getValidatedFormResult()) {
      try {
        setLoading(true);
        const response = await api.post(deliveryNotesEndpoint, { ...deliveryNoteForm });
        handleCloseDeliveryNote(response.data);
        setDeliveryNoteForm(emptyDeliveryObj);
        setLoading(false);
      } catch (error) {
        console.error('Error creating delivery note:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleCloseDeliveryNote}
      PaperProps={{
        style: {
          maxHeight: '60vh', // Set the maximum height
          maxWidth: '60vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            Delivery Method
          </Typography>
          <CloseIcon
            className="cursor-pointer ml-2"
            onClick={() => {
              setDeliveryNoteForm(emptyDeliveryObj);
              handleCloseDeliveryNote('closed');
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="my-2">
          <Grid item xs={6} md={6}>
            <TextField
              id="name"
              name="name"
              label="Name *"
              variant="outlined"
              error={!deliveryNoteForm.name?.trim() && deliveryNoteForm?.submitted}
              helperText={!deliveryNoteForm.name?.trim() && deliveryNoteForm?.submitted ? 'Delivery Name is required.' : ''}
              value={deliveryNoteForm.name || ''}
              onChange={handleControlChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <CustomAutocomplete
              value={deliveryNoteForm?.lvDeliveryMethod || ''}
              onChange={(event, newValue) => {
                console.log('newValue', newValue);
                setDeliveryNoteForm({
                  ...deliveryNoteForm,
                  deliveryMethod: newValue?.value,
                  lvDeliveryMethod: { value: newValue?.value, label: newValue?.label },
                });
              }}
              options={DeliveryMethods}
              inputLabel="Delivery Method*"
              placeholder="Select..."
              disableAddNew={true}
              isError={!deliveryNoteForm?.deliveryMethod?.trim() && deliveryNoteForm?.submitted}
              helperText={!deliveryNoteForm.deliveryMethod?.trim() && deliveryNoteForm?.submitted ? 'Delivery Method is required.' : ''}
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField name="notes" label="Note" multiline rows={2} variant="outlined" value={deliveryNoteForm?.notes || ''} onChange={handleControlChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mx-3 my-2">
        <Button size="small" type="submit" variant="contained" disabled={loading} onClick={handleSubmit}>
          Submit
        </Button>
        {loading && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

import { Search } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_USERS_ENDPOINT } from '../../../constants/constants';
import { Messages } from '../../common-constants/messages';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomTable from '../../common/table';
import CustomPagination from '../../common/pagination';
import { getCheckboxResult } from '../../common/commonUtility';
import CustomBreadCrumb from '../../common/breadcrumb';
import CustomTableActions from '../../common/table-actions';
import ConfirmDialog from '../../common/confirm-dialog';

export const UserLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const itemsPerPage = 6;
  const userEndpoint = API_USERS_ENDPOINT;
  
  const userColumns = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'First Name',
      field: 'firstName',
      link: true,
    },
    {
      label: 'Last Name',
      field: 'lastName',
    },
    {
      label: 'Email',
      field: 'emailAddress',
    },
    {
      label: 'Phone',
      field: 'phoneNumber',
    },
    {
      label: 'Role',
      field: 'role',
      badge: true,
    },
    {
      label: 'Status',
      field: 'isBlocked',
      badge: true,
    }
  ];

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Users',
      active: true,
    },
  ];

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [users, setUsers] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    fetchUsers();
    stateHandlerOnLoad();
  }, []);

  const fetchUsers = async (currentPage = 1, search) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    try {
      const response = await api.get(`${userEndpoint}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}`);
      const usersData = response.data?.users?.map(user => ({
        ...user,
        role: user.role === 1 ? 'Admin' : 'Staff',
        isBlocked: user.isBlocked ? 'Blocked' : 'Active'
      }));
      setEntityData(response.data);
      setUsers(usersData);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const stateHandlerOnLoad = () => {
    if (state?.mode === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.userCreated,
      });
    }
    if (state?.mode === 'update') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.userUpdated,
      });
    }
    navigate('.', { replace: true });
  };

  const handleRefreshData = () => {
    fetchUsers();
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleAddEntity = () => {
    navigate('/users/new');
  };

  const handleEditEntity = (item) => {
    navigate(`/users/${item.id}`);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchUsers(1, searchText);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchUsers(page);
  };

  const handleDeleteConfirmYes = () => {
    deleteUsers();
  };

  const deleteUsers = async () => {
    const selectedItems = users.filter((item) => item.checked)?.map((user) => user.id);
    setDeleteDialog(false);
    try {
      await api.delete(userEndpoint, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.userDeleted,
      });
      fetchUsers();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setUsers(getCheckboxResult(users, checkedItem, 'id'));
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Users
            </Typography>
          </Grid>
          <Grid container className="d-flex justify-content-end">
            <Grid item className="border rounded p-2 bg-light-green">
              <Typography className="fs-18 success-green">
                <AdminPanelSettingsIcon />
                &nbsp; Administrators
              </Typography>
              <Typography className="fs-13">
                Total Users: <b>{users.filter(user => user.role === 'Administrator').length}</b>
              </Typography>
              <Typography className="fs-13">
                Active: <b>{users.filter(user => user.role === 'Administrator' && user.isBlocked === 'Active').length}</b>
              </Typography>
            </Grid>
            <Grid item className="border rounded p-2 ms-3 bg-light-blue">
              <Typography className="fs-18 primary-blue">
                <PersonIcon /> Staff
              </Typography>
              <Typography className="fs-13">
                Total Users: <b>{users.filter(user => user.role === 'Staff').length}</b>
              </Typography>
              <Typography className="fs-13">
                Active: <b>{users.filter(user => user.role === 'Staff' && user.isBlocked === 'Active').length}</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="d-flex mt-2">
          <Grid container>
            <Grid item xs={10}>
              <CustomTableActions
                disableDelete={!users.filter((user) => user.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={handleAddEntity}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable 
          tableColumn={userColumns} 
          loading={tableLoader} 
          tableData={users} 
          toggleSelectedCheckbox={toggleSelectedCheckbox} 
          handleEditEntity={handleEditEntity} 
        />
        <CustomPagination 
          totalCount={entityData?.totalCount} 
          totalPages={entityData?.totalPages} 
          currentPage={currentPage} 
          handlePageChange={handlePageChange} 
        />
        <ConfirmDialog 
          showModal={deleteDialog} 
          handleConfirmYes={handleDeleteConfirmYes} 
          handleConfirmNo={handleDeleteConfirmNo} 
        />
        <CustomAlert 
          type={alertData?.type} 
          message={alertData.message} 
          open={alertData?.open} 
          onClose={alertClose} 
        />
      </div>
    </>
  );
};
import { TYPES } from '../type-constants/type';

export const setCustomerData = (data) => ({
  type: TYPES.SET_CUSTOMER_DATA,
  payload: { ddlCustomer: data },
});

export const setSalesPersonData = (data) => ({
  type: TYPES.SET_SALES_PERSON_DATA,
  payload: { ddlSalesPerson: data },
});

export const setDeliveryNoteData = (data) => ({
  type: TYPES.SET_DELIVERY_NOTE_DATA,
  payload: { ddlDeliveryNote: data },
});

export const setProductsData = (data) => ({
  type: TYPES.SET_PRODUCTS_DATA,
  payload: { ddlProducts: data },
});

export const setSalesByIdData = (data) => ({
  type: TYPES.SET_SALES_BY_ID_DATA,
  payload: { salesData: data },
});

export const setEditProductData = (data) => ({
  type: TYPES.SET_EDIT_PRODUCTS_DATA,
  payload: { editProductData: data },
});

export const setEditOperationsData = (data) => ({
  type: TYPES.SET_EDIT_OPERATIONS_DATA,
  payload: { editOperationData: data },
});

export const setDeltedItemsData = (data) => ({
  type: TYPES.SET_DELETED_ITEMS_DATA,
  payload: { deletedItems: data || [] },
});

export const openItemModal = (index) => ({
  type: TYPES.OPEN_ITEM_MODAL,
  payload: { newItemModalData: { isItemModalOpen: true, index: index } || {} },
});

export const  closeItemModal = (data, index) => ({
  type: TYPES.CLOSE_ITEM_MODAL,
  payload: { newItemModalData: { isItemModalOpen: false, index: index }, itemData: data },
});

export const resetSalesReducer = () => ({
  type: TYPES.RESET_SALES_REDUCER,
});

import { TYPES } from '../type-constants/type';

export const setDdlTenantsData = (data) => ({
  type: TYPES.SET_DDL_TENANTS_DATA,
  payload: { ddlTenants: data },
});

export const setDdlContactsData = (data) => ({
  type: TYPES.SET_DDL_CONTACTS_DATA,
  payload: { ddlContacts: data },
});

export const setContactList = (data) => ({
  type: TYPES.SET_CONTACTS_LIST,
  payload: { contactList: data },
});

export const setTenantList = (data) => ({
  type: TYPES.SET_TENANTS_LIST_LIST,
  payload: { tenantList: data },
});

export const setDdlUsersData = (data) => ({
  type: TYPES.SET_DDL_USERS_DATA,
  payload: { ddlUsers: data },
});

export const setLeadsData = (data) => ({
  type: TYPES.SET_LEADS_DATA,
  payload: { leadsData: data },
});

export const setProductsData = (data) => ({
  type: TYPES.SET_PRODUCTS_DATA,
  payload: { ddlProducts: data },
});

export const setEditProductData = (data) => ({
  type: TYPES.SET_EDIT_PRODUCTS_DATA,
  payload: { editProductData: data },
});

export const setEditOperationsData = (data) => ({
  type: TYPES.SET_EDIT_OPERATIONS_DATA,
  payload: { editOperationData: data },
});

export const setDeltedItemsData = (data) => ({
  type: TYPES.SET_DELETED_ITEMS_DATA,
  payload: { deletedItems: data || [] },
});

export const openItemModal = (index) => ({
  type: TYPES.OPEN_ITEM_MODAL,
  payload: { newItemModalData: { isItemModalOpen: true, index: index } || {} },
});

export const closeItemModal = (data, index) => ({
  type: TYPES.CLOSE_ITEM_MODAL,
  payload: { newItemModalData: { isItemModalOpen: false, index: index }, itemData: data },
});

export const setLeadsQuotationData = (data) => ({
  type: TYPES.SET_LEADS_QUOTATION_DATA,
  payload: { leadsQuotationData: data },
});

export const resetSalesReducer = () => ({
  type: TYPES.RESET_LEADS_REDUCER,
});

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './SessionRenewalModal.css'; // Import the CSS file

const SessionRenewalModal = ({ show, sessionExpired, onRenewSession, onLogout }) => {
  const timeout = 300; // 5 minutes
  const [timeLeft, setTimeLeft] = useState(timeout); // 5 minutes
  const timerRef = useRef(null);

  useEffect(() => {
    if (show && !timerRef.current && !sessionExpired) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            timerRef.current = null;
            onLogout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [show, sessionExpired, onLogout]);

  useEffect(() => {
    if (!show) {
      setTimeLeft(timeout);
    }
  }, [show]);

  const handleRenewSession = () => {
    onRenewSession();
    setTimeLeft(timeout);
  };

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Modal show={show} dialogClassName="rounded-modal timeout-modal">
      <Modal.Header>
        <Modal.Title>{sessionExpired ? 'Session Expired' : 'Are you still there?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sessionExpired ? (
          <p>Your session has expired. Please click the button below to go back to the login page.</p>
        ) : (
          <>
            <p>Your session will expire in {minutes}:{seconds < 10 ? `0${seconds}` : seconds} minutes.</p>
            <p>Would you like to renew your session?</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {sessionExpired ? (
          <Button variant="primary" onClick={onLogout}>Go to Login Page</Button>
        ) : (
          <>
            <Button variant="danger" onClick={onLogout}>Logout</Button>
            <Button variant="primary" onClick={handleRenewSession}>Renew Session</Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SessionRenewalModal;

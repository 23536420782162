import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { getBadgeVariant } from './commonUtility';
import { ConfirmationStatus } from '../common-constants/status';

const ConfirmDialog = ({ showModal, confirmType = ConfirmationStatus.Warning, message = 'Are you sure you want to delete the selected items?', handleConfirmYes, handleConfirmNo }) => {
  return (
    <Dialog open={showModal} onClose={handleConfirmNo}>
      <DialogTitle>
        {getBadgeVariant(confirmType)}
        <span className="fs-6 mx-1"> Confirmation</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className="fs-14">{message}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmNo} className="fs-12" variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmYes} className="fs-12" variant="contained" color="primary" autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

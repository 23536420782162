import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, FormControl, Grid, InputLabel, TextField, Select, MenuItem, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_OPERATIONS_ENDPOINT, API_PRODUCT_LOCATION_ENDPOINT, API_USERS_ENDPOINT } from '../../../constants/constants';
import { useToken } from '../../auth/useToken';
import { Messages } from '../../common-constants/messages';
import { AlertStatus, QualityControlStatus, WorkOrderStatusValue } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomAutocomplete from '../../common/autocomplete';
import CustomBreadCrumb from '../../common/breadcrumb';
import { formatEntityLabelValue, generateEntityNumber, getBadgeVariant, getQCKebabMenuActions, renderLastModifiedData } from '../../common/commonUtility';
import MultiFileUpload from '../../common/multi-upload';
import BasicMenu from '../../common/menu';

export const ManageQualityControl = () => {
  const { qualityCheckId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  //   const customerEndpoint = API_TENANTS_ENDPOINT;
  const productLocationEndpoint = API_PRODUCT_LOCATION_ENDPOINT;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [qcFiles, setQcFiles] = useState();

  const [qcForm, setQcForm] = useState({
    qualityControlOrderReferenceNumber: '',
    checkedBy: '',
    lvCheckedBy: '',
    passedCheck: '',
    lvPassedCheck: '',
    notes: '',
    checkLocation: '',
    checkDate: null,
  });
  const qualityCheckOptions = [
    {
      label: 'Pass',
      value: true,
    },
    {
      label: 'Fail',
      value: false,
    },
  ];
  const [userOptions, setUserOptions] = useState();

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let userData = [];

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const breadcrumbs = [
    {
      name: 'Quality Control',
      href: '/qualitycontrol',
    },
    {
      name: 'Manage Quality Control Order',
      active: true,
    },
  ];

  useEffect(() => {
    fetchQCById();
  }, []);

  const fetchUsers = async () => {
    try {
      const uri = `${API_USERS_ENDPOINT}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchQCById = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${operationsEndpoint}/quality-orders/${qualityCheckId}`);
      await fetchUsers();
      setQcFiles(response?.data?.files || []);
      const finalQCObj = {
        ...response.data,
        qualityControlOrderReferenceNumber: response?.data?.qualityControlOrderReferenceNumber || generateEntityNumber('QC'),
        checkDate: response?.data?.checkDate ? dayjs(response?.data?.checkDate) : null,
        lvCheckedBy: response?.data?.checkedBy ? userData?.find((ud) => ud?.value === response?.data?.checkedBy) : '',
        lvPassedCheck: qualityCheckOptions?.find((qc) => qc.value == response?.data?.passedCheck),
      };
      setQcForm(finalQCObj);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleUserChange = async (newValue) => {
    if (newValue?.value) {
      setQcForm({
        ...qcForm,
        checkedBy: newValue?.value,
        lvCheckedBy: { value: newValue?.value, label: newValue?.label },
      });
    }
  };

  const handleAddFile = (event) => {
    if (event) {
      qcFiles.push(event);
      setQcForm({
        ...qcForm,
        files: qcFiles,
      });
    }
  };

  const handleRemoveFile = (index) => {
    if (index) {
      const filteredFile = qcFiles.filter((fi, i) => i !== index);
      setQcForm({
        ...qcForm,
        files: filteredFile,
      });
    }
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (!qcForm?.qualityControlOrderReferenceNumber?.trim() || !qcForm?.lvCheckedBy?.value || !qcForm?.checkDate?.isValid()) {
      return false;
    }
    if (!valid) {
      return false;
    }
    return true;
  };

  const handleSaveQualityControl = async (isSubmit) => {
    const reqObj = {
      ...qcForm,
      files: qcFiles,
    };
    if (!isSubmit) {
      setSaveLoader(true);
    }
    const response = await api.put(`${operationsEndpoint}/quality-orders/${qualityCheckId}`, reqObj);
    if (!isSubmit) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.qcSaved}`,
      });
    }
    if (isSubmit) {
      const submitObj = {
        locationId: qcForm?.locationIdForProduce,
        status: 1,
      };
      setSubmitLoader(true);
      const response = await api.put(`${operationsEndpoint}/quality-orders/${qualityCheckId}/status`, submitObj);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.qcSubmitted}`,
      });
      setSubmitLoader(false);
    }
    fetchQCById(qualityCheckId);
    setSaveLoader(false);
  };

  const handleQCControlChange = (e) => {
    const { name, value } = e.target;
    setQcForm({
      ...qcForm,
      [name]: value,
    });
  };

  // const handleDropdownChange = (event) => {
  //   const { name, value } = event.target;
  //   setQcForm((prevForm) => ({
  //     ...prevForm,
  //     [name]: value === '' ? null : value === true ? true : false,
  //   }));
  // };

  const handleMenuItemClicked = async (event) => {
    if (event > 0) {
      try {
        const reqObj = {
          status: Number(event),
        };
        await api.put(`${operationsEndpoint}/quality-orders/${qualityCheckId}/status`, reqObj);
        fetchQCById();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.qcCancelled} `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      {/* </Popover> */}
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <div className="d-flex">
              {qualityCheckId ? <BasicMenu menuItems={getQCKebabMenuActions(qcForm?.status)} itemClicked={(e, item) => handleMenuItemClicked(e, item)} /> : ''}
              <CloseIcon
                className="cursor-pointer mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress className="mx-2" size={15} />
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex ">
                    <div className="d-flex">
                      <Typography variant="h6" className="d-flex mb-0" gutterBottom>
                        {qcForm?.item?.productName} ({qcForm?.quantity} {qcForm?.item?.unit})
                      </Typography>
                      <span className="mx-4">{getBadgeVariant(qcForm?.status)}</span>
                    </div>
                    <div className="divider">
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (qcForm?.order?.transactionId) {
                              navigate(`/sales/${qcForm?.order?.transactionId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {qcForm?.order?.orderNumber}
                        </Typography>
                      </div>
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (qcForm?.tenant?.tenantId) {
                              navigate(`/accounts/${qcForm?.tenant?.tenantId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {qcForm?.tenant?.referenceNumber}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <span className="error-red ">*</span> Required Fields
            </div>
          </div>
        </div>
        <Grid container spacing={2} className="mt-1">
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="qualityControlOrderReferenceNumber"
              name="qualityControlOrderReferenceNumber"
              label="Reference Number *"
              variant="outlined"
              error={!qcForm.qualityControlOrderReferenceNumber?.trim() && qcForm.submitted}
              value={qcForm.qualityControlOrderReferenceNumber || ''}
              onChange={handleQCControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={qcForm?.lvCheckedBy || ''}
              onChange={(event, newValue) => handleUserChange(newValue)}
              options={userOptions || []}
              inputLabel="Assigned To *"
              isError={!qcForm?.lvCheckedBy?.value && qcForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Quality Check Date*</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={qcForm.checkDate}
                format="DD/MM/YYYY"
                name="checkDate"
                onChange={(e) =>
                  setQcForm({
                    ...qcForm,
                    checkDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12} className="lbl-margin-top">
            <TextField
              id="checkLocation"
              name="checkLocation"
              label="Quality Check Location"
              variant="outlined"
              // error={!packagingForm.packagingLocation?.trim() && packagingForm.submitted}
              value={qcForm.checkLocation || ''}
              onChange={handleQCControlChange}
            />
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Typography className="label m-0">Files</Typography>
            <MultiFileUpload listOfFiles={qcForm?.files?.length > 0 ? qcForm?.files : ''} addFile={handleAddFile} removeFile={handleRemoveFile} />
          </Grid>

          <Grid item sm={12} md={3} xs={12} className="mt-1">
            {/* <FormControl className="ms-3" style={{ minWidth: 120 }}>
              <InputLabel>Quality Check</InputLabel>
              <Select
                name="passedCheck"
                value={qcForm?.passedCheck ?? ''} // Handle nullable boolean
                onChange={handleDropdownChange}
                displayEmpty
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value={true}>Pass</MenuItem>
                <MenuItem value={false}>Fail</MenuItem>
              </Select>
            </FormControl> */}
            <CustomAutocomplete
              value={qcForm?.lvPassedCheck || ''}
              onChange={(event, newValue) => {
                setQcForm({
                  ...qcForm,
                  passedCheck: newValue?.value,
                  lvPassedCheck: { value: newValue?.value, label: newValue?.label },
                });
              }}
              options={qualityCheckOptions || []}
              inputLabel="Quality Check"
              disabled={[QualityControlStatus.Completed, QualityControlStatus.Cancelled].includes(qcForm?.status) && qcForm.passedCheck}
              //   isError={!qcForm?.lvPassedCheck?.value && qcForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField name="notes" label="Notes" multiline rows={2} variant="outlined" value={qcForm?.notes || ''} onChange={handleQCControlChange} />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-start mt-3">
          <Button
            size="small"
            variant="contained"
            onClick={() => handleSaveQualityControl(false)}
            disabled={saveLoader || [QualityControlStatus.Completed, QualityControlStatus.Cancelled].includes(qcForm?.status)}
          >
            Save
          </Button>
          {saveLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          <Button
            size="small"
            variant="contained"
            className="ms-3"
            onClick={() => handleSaveQualityControl(true)}
            disabled={!getValidatedFormResult() || ([QualityControlStatus.Completed, QualityControlStatus.Cancelled].includes(qcForm?.status) && qcForm.passedCheck !== false) || submitLoader}
          >
            Submit
          </Button>
          {submitLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress className="mx-2" size={15} />
            </div>
          ) : (
            renderLastModifiedData(qcForm?.lastModifiedByUser?.firstName, qcForm?.lastModifiedByUser?.lastName, qcForm?.lastModifiedTime)
          )}
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './UnauthenticatedView.css';
import brandImage from './Brand.png'; 

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0 20px',
      backgroundColor: 'white', // Light grey background
    },
    brandImage: {
      maxHeight: '70px', // Adjust as needed
      maxWidth: '170px', // Adjust as needed
      objectFit: 'contain', // Ensure the image scales properly
    }
  };


const UnauthenticatedView = ({ onLogin }) => {
  return (
    <div className="unauthenticated-container">
      <Row className="justify-content-center align-items-center text-center">
        <Col md={6}>
          <div className="unauthenticated-content">
            <h1>Welcome to 
                <img
                    src={brandImage}
                    alt="Brand"
                    style={styles.brandImage}
                />
            </h1>
            <br />
            <p>Please login to access your dashboard and streamline your business operations.</p>
            <Button variant="primary" onClick={onLogin}>Go to Login</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UnauthenticatedView;

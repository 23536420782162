import { Grid, IconButton } from '@mui/material';
import { BootstrapTooltip } from './commonUtility';
import { Add, Delete, Refresh } from '@mui/icons-material';

const CustomTableActions = ({ shouldHideAddBtn = false, shouldHideDeleteBtn = false, handleAddEntity, handleRefresh, handleDelete, disableDelete }) => {
  return (
    <>
      <div className="d-flex">
        {!shouldHideAddBtn && (
          <BootstrapTooltip title="Add">
            <IconButton color="primary" onClick={handleAddEntity}>
              <Add />
            </IconButton>
          </BootstrapTooltip>
        )}
        <BootstrapTooltip title="Refresh">
          <IconButton color="primary" onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        </BootstrapTooltip>
        {!shouldHideDeleteBtn && (
          <BootstrapTooltip title="Delete">
            <IconButton color="info" onClick={handleDelete} disabled={disableDelete}>
              <Delete />
            </IconButton>
          </BootstrapTooltip>
        )}
      </div>
    </>
  );
};

export default CustomTableActions;

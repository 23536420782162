import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Tab, Tabs, Typography, debounce } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, API_PRODUCTS_ENDPOINT } from '../../../../../constants/constants';
import api from '../../../../common/api';
import { InventoryItemActivity } from './inventory-item-activity';
import { InventoryItemLocations } from './inventory-item-locations';
import { InventoryOverview } from './inventory-overview';
import { InventoryTransactions } from './inventory-transactions';
import { useLocation } from 'react-router-dom';

const InventoryInfo = ({ itemId, onClose, refreshItemGrid, preSelectedTab }) => {
  const inventoryEndpoint = `${API_BASE_URL}${API_PRODUCTS_ENDPOINT}/${itemId}`;
  const [activeTab, setActiveTab] = useState('');
  const [inventoryData, setInventoryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [locationOptions, setLocationOptions] = useState([]);

  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (preSelectedTab) {
      setActiveTab(preSelectedTab);
    } else {
      setActiveTab('itemOverview');
    }
  }, [preSelectedTab]);

  useEffect(() => {
    setLoading(true);
    searchLocations();

    api
      .get(inventoryEndpoint)
      .then((response) => {
        setInventoryData(response.data);
        setLoading(false);
        if (preSelectedTab) {
          setActiveTab(preSelectedTab);
        } else {
          setActiveTab('itemOverview');
        }
      })
      .catch((error) => {
        console.error('Error fetching inventory data:', error);
      });
  }, [itemId]);

  // api.defaults.baseURL = usersEndpoint;

  const searchLocations = debounce(() => {
    const url = `/locations?pageNumber=1&pageSize=100&productId=${itemId}`;
    api
      .get(url)
      .then((response) => {
        const options = response?.data?.locations?.map((location) => ({
          value: location.locationId,
          label: location.locationName,
        }));
        setLocationOptions(options);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  return (
    <>
      <div className="d-flex justify-content-between">
        <Tabs value={activeTab} onChange={handleTabSelect} className="mb-2">
          <Tab value="itemOverview" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Overview</Typography>}></Tab>
          <Tab value="itemLocations" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Locations</Typography>}></Tab>
          <Tab value="itemActivity" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Activity</Typography>}></Tab>
          {/* <Tab value="itemTransactions" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Transactions</Typography>}></Tab> */}
        </Tabs>
        <CloseIcon className="cursor-pointer ml-2" onClick={onClose} />
      </div>

      {loading ? (
        <span className="text-center">
          <CircularProgress size={20} />
        </span>
      ) : (
        <>
          {activeTab === 'itemOverview' && <InventoryOverview initialLoader={loading} id={itemId} inventoryData={inventoryData} refreshItemGrid={refreshItemGrid} />}
          {activeTab === 'itemLocations' && <InventoryItemLocations id={itemId} inventoryData={inventoryData} locationOptions={locationOptions} />}
          {activeTab === 'itemActivity' && <InventoryItemActivity id={itemId} inventoryData={inventoryData} />}
          {activeTab === 'itemTransactions' && <InventoryTransactions id={itemId} inventoryData={inventoryData} />}
        </>
      )}
    </>
    //   )}
    // </>
  );
};

export default InventoryInfo;

import { Search } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_TENANTS_ENDPOINT } from '../../../../constants/constants';
import { Messages } from '../../../common-constants/messages';
import { AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { getCheckboxResult } from '../../../common/commonUtility';
import ConfirmDialog from '../../../common/confirm-dialog';
import CustomPagination from '../../../common/pagination';
import CustomTable from '../../../common/table';
import CustomTableActions from '../../../common/table-actions';
import { resetAccountReducer } from '../../../redux/actions/account-action';
import { resetDocumentReducer } from '../../../redux/actions/document-action';

const AccountLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const itemsPerPage = 6;
  const accountEndpoint = API_TENANTS_ENDPOINT;
  const accountColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Account Name',
      field: 'name',
      link: true,
    },
    {
      label: 'Industry',
      field: 'industry',
      badge: true,
      shorthandFirst: 'tenantType',
      icon: 'entityType',
    },
    {
      label: 'Receivables',
      field: '',
    },
    {
      label: 'Payables',
      field: '',
    },
    {
      label: 'Account Owner',
      field: 'user',
      avatar: true,
    },
    {
      label: 'Remarks',
      field: 'remarks',
    },
    {
      label: 'Created Date',
      field: 'createdDate',
      date: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Accounts',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [accounts, setAccounts] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    fetchAccounts();
    stateHandlerOnLoad();
    dispatch(resetDocumentReducer());
    dispatch(resetAccountReducer());
  }, []);

  const fetchAccounts = async (currentPage = 1, search, activeCard = 0) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    //  const adjustmentType = activeCard > 0 ? `&adjustmentType=${activeCard}` : '';
    try {
      const response = await api.get(`${accountEndpoint}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}`);
      response.data.tenants = response.data.tenants?.map((item) => ({
        ...item,
        user: `${item?.accountOwner?.firstName} ${item?.accountOwner?.lastName}`,
      }));
      const accountsData = response.data?.tenants;
      setEntityData(response.data);
      setAccounts(accountsData);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const stateHandlerOnLoad = () => {
    if (state?.mode === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.accountCreated}`,
      });
    }
    if (state?.mode === 'update') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.accountUpdated}`,
      });
    }
    navigate('.', { replace: true });
  };

  const handleRefreshData = () => {
    fetchAccounts();
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleAddEntity = () => {
    dispatch(resetAccountReducer());
    navigate('/accounts/newAccount');
  };

  const handleEditEntity = (item) => {
    navigate(`/accounts/${item.id}`);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchAccounts(1, searchText);
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    fetchAccounts(page);
  };

  const handleDeleteConfirmYes = () => {
    deleteAccounts();
  };

  const deleteAccounts = async () => {
    const selectedItems = accounts.filter((item) => item.checked)?.map((adj) => adj.id);
    setDeleteDialog(false);
    try {
      await api.delete('/accounts', {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.accountDeleted,
      });
      fetchAccounts();
      // fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setAccounts(getCheckboxResult(accounts, checkedItem, 'id'));
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Accounts
            </Typography>
          </Grid>
          <Grid container className="d-flex justify-content-end">
            <Grid item className="border rounded p-2 bg-light-green">
              <Typography className="fs-18 success-green">
                <AccountBalanceWalletIcon />
                &nbsp; Receivables
              </Typography>
              <Typography className="fs-13">
                Total Amount: <b> $10,000 </b>
              </Typography>
              <Typography className="fs-13">
                Pending Payments: <b> 3</b>
              </Typography>
            </Grid>
            <Grid item className="border rounded p-2 ms-3 bg-light-red">
              <Typography className="fs-18" color="error">
                <ShoppingCartCheckoutIcon /> Payables
              </Typography>
              <Typography className="fs-13">
                Total Amount: <b>$5,000</b>{' '}
              </Typography>
              <Typography className="fs-13">
                Outstanding Invoices: <b>2</b>{' '}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="d-flex mt-2">
          <Grid container>
            <Grid item xs={10}>
              <CustomTableActions
                disableDelete={!accounts.filter((cus) => cus.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={handleAddEntity}
              ></CustomTableActions>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={accountColumn} loading={tableLoader} tableData={accounts} toggleSelectedCheckbox={toggleSelectedCheckbox} handleEditEntity={handleEditEntity} />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default AccountLanding;

import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Grid, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_LOCATION_ENDPOINT, API_OPERATIONS_ENDPOINT, API_PRODUCT_LOCATION_ENDPOINT, API_PRODUCTS_ENDPOINT, API_USERS_ENDPOINT } from '../../../constants/constants';
import { useToken } from '../../auth/useToken';
import { Messages } from '../../common-constants/messages';
import { AlertStatus, WorkOrderStatus, WorkOrderStatusValue } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomAutocomplete from '../../common/autocomplete';
import CustomBreadCrumb from '../../common/breadcrumb';
import { BootstrapTooltip, formatEntityLabelValue, formatProductLocation, generateEntityNumber, getBadgeVariant, getPCKebabMenuActions, renderLastModifiedData } from '../../common/commonUtility';
import MultiFileUpload from '../../common/multi-upload';
import { openItemModal } from '../../redux/actions/sales-action';
import BasicMenu from '../../common/menu';

export const ManagePackagingOrder = () => {
  const { packagingId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  //   const customerEndpoint = API_TENANTS_ENDPOINT;
  const productLocationEndpoint = API_PRODUCT_LOCATION_ENDPOINT;
  const locationEndpoint = API_LOCATION_ENDPOINT;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [poFiles, setPoFiles] = useState();

  const defaulPackagingMaterial = {
    packagingMaterialId: '',
    materialId: '',
    lvMaterialId: '',
    locationId: '',
    lvLocationId: '',
    quantity: 0,
  };
  const [pkgMaterialForm, setPkgMaterialForm] = useState([defaulPackagingMaterial]);
  const [productItemOptions, setProductItemOptions] = useState([]);
  const [productLocationOptions, setProductLocationOptions] = useState([]);

  const [packagingForm, setPackagingForm] = useState({
    packagingOrderReferenceNumber: '',
    packagedBy: '',
    lvPackagedBy: '',
    packagingType: '',
    weight: 0,
    dimensions: '',
    specialInstructions: '',
    estimatedCompletionDate: null,
    actualCompletionDate: null,
    packagingLocation: '',
    packagingRemarks: '',
  });
  const [userOptions, setUserOptions] = useState();

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [locationByItemOptions, setLocationByItemOptions] = useState([]);
  let formatedProductLocationOptions = [];
  let userData = [];
  let formatLocationOptions = [];

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const breadcrumbs = [
    {
      name: 'Packaging Orders',
      href: '/packaging',
    },
    {
      name: 'Manage Packaging Order',
      active: true,
    },
  ];

  useEffect(() => {
    // fetchProducts();
    // fetchProductsLocations();
    // fetchUsers();
    // fetchLocationByItem();
    // fetchBOM();
    //fetchRouting();

    fetchPOById();
  }, []);

  // const fetchProducts = async () => {
  //   try {
  //     const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
  //     const formatedReasonsOptions = formatProductForSales(response?.data?.products) || [];
  //     setProductItemOptions(formatedReasonsOptions);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const fetchProductsLocations = async () => {
    try {
      const response = await api.get(`${productLocationEndpoint}?pageNumber=1&pageSize=100&itemType=7`);
      formatedProductLocationOptions = formatProductLocation(response?.data?.productLocations) || [];
      setProductLocationOptions(formatedProductLocationOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //   const fetchBOM = async () => {
  //     const response = await api.get(`${operationsEndpoint}/bom?pageNumber=1&pageSize=100`);
  //     const formatBOMOptions = formatEntityLabelValue(response?.data?.boms, 'name', 'id') || [];
  //     setBomOptions(formatBOMOptions);
  //   };

  //   const fetchRouting = async () => {
  //     const response = await api.get(`${operationsEndpoint}/routing?pageNumber=1&pageSize=100`);
  //     const formatRoutingOptions = formatEntityLabelValue(response?.data?.routings, 'routingName', 'routingId') || [];
  //     setRoutingOptions(formatRoutingOptions);
  //   };
  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchUsers = async () => {
    try {
      const uri = `${API_USERS_ENDPOINT}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchPOById = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${operationsEndpoint}/packaging-orders/${packagingId}`);
      await fetchProductsLocations();
      await fetchUsers();
      setPoFiles(response?.data?.files || []);
      const finalWorkFormObj = {
        ...response.data,
        packagingOrderReferenceNumber: response?.data?.packagingOrderReferenceNumber || generateEntityNumber('PO'),
        estimatedCompletionDate: response?.data?.estimatedCompletionDate ? dayjs(response?.data?.estimatedCompletionDate) : null,
        actualCompletionDate: response?.data?.actualCompletionDate ? dayjs(response?.data?.actualCompletionDate) : null,
        lvPackagedBy: response?.data?.packagedBy ? userData?.find((ud) => ud?.value === response?.data?.packagedBy) : '',
        lvLocationIdForProduce: formatLocationOptions?.find((lit) => lit.value === response?.data?.manufacturingDetails?.workOrder?.locationIdForProduce),
        locationIdForProduce: response?.data?.manufacturingDetails?.workOrder?.locationIdForProduce,
      };
      setPackagingForm(finalWorkFormObj);
      const finalObjForProcess = response?.data?.packagingMaterial?.map((md, mdIndex) => ({
        ...md,
        materialId: md?.material?.productId,
        lvMaterialId: {
          label: formatedProductLocationOptions?.length
            ? formatedProductLocationOptions?.find((foPro) => foPro?.value === `${md?.material?.productId}/${md?.locationId}`)?.formattedObject?.selectedLabel
            : '',
          value: `${md?.material?.productId}/${md?.locationId}`,
        },
        locationId: md?.locationId,
        quantity: md?.quantity,
      }));
      setPkgMaterialForm(finalObjForProcess || [defaulPackagingMaterial]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchLocationByItem = async () => {
    const response = await api.get(`${locationEndpoint}?pageNumber=1&pageSize=100`);
    formatLocationOptions = formatEntityLabelValue(response?.data?.locations, 'locationName', 'locationId') || [];
    setLocationByItemOptions(formatLocationOptions);
  };

  const handlePOControlChange = (e) => {
    const { name, value } = e.target;
    setPackagingForm({
      ...packagingForm,
      [name]: value,
    });
  };

  const handleUserChange = async (newValue) => {
    if (newValue?.value) {
      setPackagingForm({
        ...packagingForm,
        packagedBy: newValue?.value,
        lvPackagedBy: { value: newValue?.value, label: newValue?.label },
      });
    }
  };

  const handleAddFile = (event) => {
    if (event) {
      poFiles.push(event);
      setPackagingForm({
        ...packagingForm,
        files: poFiles,
      });
    }
  };

  const handleRemoveFile = (index) => {
    if (index) {
      const filteredFile = poFiles.filter((fi, i) => i !== index);
      setPackagingForm({
        ...packagingForm,
        files: filteredFile,
      });
    }
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (!packagingForm?.packagingOrderReferenceNumber?.trim() || !packagingForm?.packagingType || !packagingForm?.lvPackagedBy?.value) {
      return false;
    }
    pkgMaterialForm?.map((mfgForm) => {
      if (!mfgForm?.lvMaterialId?.value) {
        valid = false;
        return false;
      }
    });
    if (!valid) {
      return false;
    }
    return true;
  };

  const handleSavePackagingOrder = async (isSubmit) => {
    const reqObj = {
      ...packagingForm,
      files: poFiles,
      packagingMaterial: pkgMaterialForm?.map((pm) => ({
        ...pm,
        packagingMaterialId: pm?.packagingMaterialId || crypto.randomUUID(),
      })),
    };
    if (!isSubmit) {
      setSaveLoader(true);
    }
    const response = await api.put(`${operationsEndpoint}/packaging-orders/${packagingId}`, reqObj);
    if (!isSubmit) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.pkgOrderSaved}`,
      });
    }
    if (isSubmit) {
      const submitObj = {
        locationId: packagingForm?.locationIdForProduce,
        status: WorkOrderStatusValue?.Completed,
      };
      setSubmitLoader(true);
      const response = await api.put(`${operationsEndpoint}/packaging-orders/${packagingId}/status`, submitObj);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.pkgOrderSubmitted}`,
      });
      setSubmitLoader(false);
    }
    fetchPOById(packagingId);
    setSaveLoader(false);
  };

  const handleMaterialItemChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openItemModal(index));
    } else if (newValue?.formattedObject) {
      const formattedObject = newValue?.formattedObject;
      let updatedProducts = pkgMaterialForm.map((product, i) => {
        if (i !== index) return product;
        return {
          ...product,
          materialId: formattedObject?.productId || '',
          lvMaterialId: {
            //  label: formattedObject?.selectedLabelForSales || '',
            label: formattedObject?.selectedLabel || '',
            value: formattedObject?.productId || '',
          },
          unit: formattedObject?.unit,
          locationId: formattedObject?.locationId,
        };
      });
      setPkgMaterialForm(updatedProducts);
      //   dispatch(setBOMItemsData(updatedProducts));
    } else {
      pkgMaterialForm[index] = defaulPackagingMaterial;
    }
  };

  const handleLocationChange = (newValue, index) => {
    if (newValue?.value) {
      let updatedProducts = pkgMaterialForm.map((lc, i) => {
        if (i !== index) return lc;
        return {
          ...lc,
          locationId: newValue?.value || '',
          lvLocationId: {
            label: newValue?.label || '',
            value: newValue?.value || '',
          },
        };
      });
      setPkgMaterialForm(updatedProducts);
    } else {
      pkgMaterialForm[index] = defaulPackagingMaterial;
    }
  };

  const handleMaterialPkgChange = async (e, index) => {
    const { name, value } = e.target;
    let updatedProducts = pkgMaterialForm.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: value };
      return product;
    });
    setPkgMaterialForm(updatedProducts);
  };

  const handleMaterialAddProduct = () => {
    const addedProduct = [...pkgMaterialForm, defaulPackagingMaterial];
    setPkgMaterialForm(addedProduct);
  };

  const handleMaterialRemoveProduct = (index) => {
    const removedProduct = pkgMaterialForm?.filter((item, i) => i !== index);
    setPkgMaterialForm(removedProduct);
  };

  const handleMenuItemClicked = async (event) => {
    if (event > 0) {
      try {
        const reqObj = {
          status: Number(event),
        };
        await api.put(`${operationsEndpoint}/packaging-orders/${packagingId}/status`, reqObj);
        fetchPOById();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.pcCancelled} `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      {/* </Popover> */}
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <div className="d-flex">
              {packagingId ? <BasicMenu menuItems={getPCKebabMenuActions(packagingForm?.status)} itemClicked={(e, item) => handleMenuItemClicked(e, item)} /> : ''}
              <CloseIcon
                className="cursor-pointer mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress className="mx-2" size={15} />
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex ">
                    <div className="d-flex">
                      <Typography variant="h6" className="d-flex mb-0" gutterBottom>
                        {packagingForm?.item?.productName} ({packagingForm?.quantity} {packagingForm?.item?.unit})
                      </Typography>
                      <span className="mx-4">{getBadgeVariant(packagingForm?.status)}</span>
                    </div>
                    <div className="divider">
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (packagingForm?.order?.transactionId) {
                              navigate(`/sales/${packagingForm?.order?.transactionId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {packagingForm?.order?.orderNumber}
                        </Typography>
                      </div>
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (packagingForm?.tenant?.tenantId) {
                              navigate(`/accounts/${packagingForm?.tenant?.tenantId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {packagingForm?.tenant?.referenceNumber}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <span className="error-red ">*</span> Required Fields
            </div>
          </div>
        </div>
        <Grid container spacing={2} className="mt-1">
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="packagingOrderReferenceNumber"
              name="packagingOrderReferenceNumber"
              label="Reference Number *"
              variant="outlined"
              error={!packagingForm.packagingOrderReferenceNumber?.trim() && packagingForm.submitted}
              value={packagingForm.packagingOrderReferenceNumber || ''}
              onChange={handlePOControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="packagingType"
              name="packagingType"
              label="Packaging Type *"
              variant="outlined"
              error={!packagingForm.packagingType?.trim() && packagingForm.submitted}
              value={packagingForm.packagingType || ''}
              onChange={handlePOControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={packagingForm?.lvPackagedBy || ''}
              onChange={(event, newValue) => handleUserChange(newValue)}
              options={userOptions || []}
              inputLabel="Assigned To *"
              isError={!packagingForm?.lvPackagedBy?.value && packagingForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField id="weight" name="weight" label="Weight Per Package (Kg)" variant="outlined" value={packagingForm.weight || 0} onChange={handlePOControlChange} />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField id="dimensions" name="dimensions" label="Dimensions Per Package" variant="outlined" value={packagingForm.dimensions} onChange={handlePOControlChange} />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="packagingLocation"
              name="packagingLocation"
              label="Packaging Location"
              variant="outlined"
              // error={!packagingForm.packagingLocation?.trim() && packagingForm.submitted}
              value={packagingForm.packagingLocation || ''}
              onChange={handlePOControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Estimated Completion Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={packagingForm.estimatedCompletionDate}
                format="DD/MM/YYYY"
                name="estimatedCompletionDate"
                onChange={(e) =>
                  setPackagingForm({
                    ...packagingForm,
                    estimatedCompletionDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Typography className="label m-0">Files</Typography>
            <MultiFileUpload listOfFiles={packagingForm?.files?.length > 0 ? packagingForm?.files : ''} addFile={handleAddFile} removeFile={handleRemoveFile} />
          </Grid>
          <Grid item sm={12} md={12} xs={12}>
            <TextField
              name="specialInstructions"
              label="Special Instructions"
              multiline
              rows={2}
              variant="outlined"
              value={packagingForm?.specialInstructions || ''}
              onChange={handlePOControlChange}
            />
          </Grid>
          {/* <Grid item sm={12} md={6} xs={12}>
            <TextField name="packagingRemarks" label="Reamrks" multiline rows={2} variant="outlined" value={packagingForm?.packagingRemarks || ''} onChange={handlePOControlChange} />
          </Grid> */}
        </Grid>
        <div className="d-flex justify-content-between mt-3">
          <Typography variant="h6" className="d-flex mb-0" gutterBottom>
            Packaging Material
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="ps-2" width="60%">
                      Material*
                    </TableCell>
                    {/* <TableCell className="ps-2" width="40%">
                      Location*
                    </TableCell> */}
                    <TableCell className="ps-2" width="20%">
                      Quantity
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pkgMaterialForm?.length ? (
                    pkgMaterialForm?.map((row, mfgProcessIndex) => (
                      <TableRow key={mfgProcessIndex}>
                        <TableCell>
                          <CustomAutocomplete
                            value={row?.lvMaterialId || ''}
                            onChange={(e, newValue) => handleMaterialItemChange(newValue, mfgProcessIndex)}
                            disabledValues={pkgMaterialForm?.map((pitem) => pitem?.lvMaterialId?.value)}
                            options={productLocationOptions || []}
                            placeholder="Search..."
                            getOptionLabel={(option) => option.label || ''}
                            isError={!row?.lvMaterialId?.value && packagingForm.submitted}
                            // helperText={!row?.lvProductId?.value && bomForm.submitted ? 'Item is required.' : ''}
                          />
                        </TableCell>
                        {/* <TableCell>
                          <CustomAutocomplete
                            value={row?.lvLocationId || ''}
                            onChange={(e, newValue) => handleLocationChange(newValue, mfgProcessIndex)}
                            disableAddNew={true}
                            options={locationByItemOptions || []}
                            placeholder="Search..."
                            getOptionLabel={(option) => option.label || ''}
                            isError={!row?.lvLocationId?.value && packagingForm?.submitted}
                          />
                        </TableCell> */}
                        <TableCell>
                          <div className="d-flex">
                            <TextField
                              type="number"
                              id="quantity"
                              value={row?.quantity}
                              name="quantity"
                              variant="outlined"
                              onChange={(e) => {
                                handleMaterialPkgChange(e, mfgProcessIndex);
                              }}
                              error={!row?.quantity && packagingForm.submitted}
                            />
                            <span className="m-1"> {row?.unit}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          {pkgMaterialForm?.length > 1 ? (
                            <BootstrapTooltip title="Delete">
                              <IconButton className="pt-3 pb-1" color="info" onClick={() => handleMaterialRemoveProduct(mfgProcessIndex)}>
                                <Delete />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <div className="justify-center m-2 fs-14 error-red">Packaging Materials are mandatory to complete the Packaging order.</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <BootstrapTooltip title="Add Material">
                  <Button size="small" className="my-2 ms-2" variant="outlined" onClick={handleMaterialAddProduct}>
                    Add Material
                  </Button>
                </BootstrapTooltip>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Actual Completion Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={packagingForm.actualCompletionDate}
                format="DD/MM/YYYY"
                name="actualCompletionDate"
                onChange={(e) =>
                  setPackagingForm({
                    ...packagingForm,
                    actualCompletionDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: !packagingForm?.actualCompletionDate?.isValid() && packagingForm?.submitted,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={12} md={6} xs={12}>
            <TextField name="packagingRemarks" label="Remarks" multiline rows={2} variant="outlined" value={packagingForm?.packagingRemarks || ''} onChange={handlePOControlChange} />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-start mt-3">
          <Button
            size="small"
            variant="contained"
            onClick={() => handleSavePackagingOrder(false)}
            disabled={saveLoader || [WorkOrderStatus?.Completed, WorkOrderStatus?.Cancelled].includes(packagingForm?.status)}
          >
            Save
          </Button>
          {saveLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          <Button
            size="small"
            variant="contained"
            className="ms-3"
            onClick={() => handleSavePackagingOrder(true)}
            disabled={!getValidatedFormResult() || [WorkOrderStatus?.Completed, WorkOrderStatus?.Cancelled].includes(packagingForm?.status) || submitLoader}
          >
            Submit
          </Button>
          {submitLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress className="mx-2" size={15} />
            </div>
          ) : (
            renderLastModifiedData(packagingForm?.lastModifiedByUser?.firstName, packagingForm?.lastModifiedByUser?.lastName, packagingForm?.lastModifiedTime)
          )}
        </div>
      </div>
    </>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import api from '../common/api';
import CustomAutocomplete from '../common/autocomplete';

const ProductLocation = ({ id, ddlProductLocation, showModal, handleClose }) => {
  const [locationForm, setLocationForm] = useState({
    locationId: '',
    openingBalance: '',
    submitted: false,
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const [productLocation, setProductLocation] = useState('');
  const locationChange = (event, newValue) => {
    setProductLocation({
      value: newValue?.value,
      label: newValue?.label,
    });
    setLocationForm({
      ...locationForm,
      locationId: newValue?.value,
    });
  };
  const getValidatedFormResult = () => {
    if (!productLocation?.value || !locationForm?.openingBalance) {
      return false;
    }
    return true;
  };

  const handleAddLocationField = async () => {
    setLocationForm({
      ...locationForm,
      submitted: true,
    });

    if (getValidatedFormResult()) {
      setLocationLoader(true);
      const reqObj = {
        productId: id,
        ...locationForm,
      };
      try {
        const response = await api.post('/ProductLocation', reqObj);
        if (response) {
          setLocationForm({ locationId: '', openingBalance: '', submitted: false });
          setProductLocation('');
          setLocationLoader(false);
          handleClose('added');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: '60vh', // Set the maximum height
          width: '80vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            Add Item Location
          </Typography>
          <CloseIcon className="cursor-pointer ml-2" onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="my-2">
          <Grid item xs={6} md={6}>
            <CustomAutocomplete
              value={productLocation || ''}
              onChange={locationChange}
              options={ddlProductLocation}
              inputLabel="Location *"
              placeholder="Select location..."
              disableAddNew={true}
              getOptionLabel={(option) => option.label || ''}
              isError={!productLocation?.value && locationForm.submitted}
              helperText={!productLocation?.value && locationForm.submitted ? 'Location is required.' : ''}
            />
          </Grid>
          <Grid item xs={6} md={6} className="px-2">
            <TextField
              type="number"
              label="Opening Balance *"
              id="openingBalance"
              value={locationForm.openingBalance}
              name="openingBalance"
              variant="outlined"
              onChange={(e) => {
                setLocationForm({
                  ...locationForm,
                  openingBalance: e?.target?.value,
                });
              }}
              error={!locationForm?.openingBalance && locationForm.submitted}
              helperText={!locationForm?.openingBalance && locationForm.submitted ? 'Opening balance is required.' : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mx-4 my-2">
        <Button size="small" type="submit" variant="contained" disabled={locationLoader} onClick={handleAddLocationField}>
          Submit
        </Button>
        {locationLoader && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

export default ProductLocation;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'mdbreact/dist/css/mdb.css';
import 'mdbreact/dist/css/style.css';
import './index.css';
import { Provider } from 'react-redux';
import store from './pages/redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <head>
      <script src="https://www.google.com/recaptcha/enterprise.js?render=6LfmbRkTAAAAAFe9WOdrSVqwWyZdqqjQ7CpVK5J3"></script>
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    </head>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const TYPES = {
  SET_ITEM_LOCATION_DATA: 'GET_ITEM_LOCATION',
  SET_ITEM_ACTIVITY_DATA: 'GET_ITEM_ACTIVITY',
  SET_ITEM_TRANSACTION_DATA: 'SET_ITEM_TRANSACTION_DATA',
  SET_ITEM_OVERVIEW_ID: 'SET_ITEM_OVERVIEW_ID',
  SET_ITEM_LOCATION_ID: 'SET_ITEM_LOCATION_ID',
  SET_ITEM_TRANSACTION_ID: 'SET_ITEM_TRANSACTION_ID',
  SET_ITEM_ACTIVTY_ID: 'SET_ITEM_ACTIVTY_ID',
  LOCATION_TEXT_FILTER: 'LOCATION_TEXT_FILTER',
  LOCATION_API_FILTER: 'LOCATION_API_FILTER',
  LOCATION_SEARCH: 'LOCATION_SEARCH',
  ACTIVITY_SEARCH: 'ACTIVITY_SEARCH',
  TRANSACTION_SEARCH: 'TRANSACTION_SEARCH',
  RESET_ITEM_REDUCER: 'RESET_ITEM_REDUCER',
  // ACCOUNTS
  SET_ACCOUNT_USERS_DATA: 'SET_ACCOUNT_USERS_DATA',
  SET_PRIMARY_CONTACTS_DATA: 'SET_PRIMARY_CONTACTS_DATA',
  SET_ACCOUNT_DETAILS_FORM: 'SET_ACCOUNT_DETAILS_FORM',
  SET_ACCOUNT_SINCE_FIELD: 'SET_ACCOUNT_SINCE_FIELD',
  SET_FINANCE_DETAILS_FORM: 'SET_FINANCE_DETAILS_FORM',
  SET_ACCOUNT_ADDRESS_FORM: 'SET_ACCOUNT_ADDRESS_FORM',
  SET_ACCOUNT_ADDITIONAL_INFO_FORM: 'SET_ACCOUNT_ADDITIONAL_INFO_FORM',
  SET_ACCOUNT_FORM_SUBMITTED: 'SET_ACCOUNT_FORM_SUBMITTED',
  SET_ACCOUNT_TENANT_ID: 'SET_ACCOUNT_TENANT_ID',
  OPEN_ACCOUNT_MODAL: 'OPEN_ACCOUNT_MODAL',
  CLOSE_ACCOUNT_MODAL: 'CLOSE_ACCOUNT_MODAL',
  RESET_ACCOUNT_REDUCER: 'RESET_ACCOUNT_REDUCER',
  // DOCUMENTS
  SET_DOCUMENT_FILE_DATA: 'SET_DOCUMENT_FILE_DATA',
  RESET_DOCUMENT_REDUCER: 'RESET_DOCUMENT_REDUCER',
  // SALES
  SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
  SET_SALES_PERSON_DATA: 'SET_SALES_PERSON_DATA',
  SET_DELIVERY_NOTE_DATA: 'SET_DELIVERY_NOTE_DATA',
  SET_PRODUCTS_DATA: 'SET_PRODUCTS_DATA',
  SET_SALES_BY_ID_DATA: 'SET_SALES_BY_ID_DATA',
  SET_EDIT_PRODUCTS_DATA: 'SET_ITEM_PRODUCTS_DATA',
  SET_EDIT_OPERATIONS_DATA: 'SET_OPERATIONS_DATA',
  SET_DELETED_ITEMS_DATA: 'SET_DELETED_ITEMS_DATA',
  OPEN_ITEM_MODAL: 'OPEN_ITEM_MODAL',
  CLOSE_ITEM_MODAL: 'CLOSE_ITEM_MODAL',
  RESET_SALES_REDUCER: 'RESET_SALES_REDUCER',
  //WorkOrder
  SET_BOM_FORM: 'SET_BOM_FORM',
  SET_BOM_ITEMS_DATA: 'SET_BOM_ITEMS_DATA',
  SET_ROUTING_FORM: 'SET_ROUTING_FORM',
  SET_ROUTING_STEPS_DATA: 'SET_ROUTING_STEPS_DATA',
  RESET_WORKORDER_DATA: 'RESET_WORKORDER_DATA',

  //Leads
  SET_DDL_TENANTS_DATA: 'SET_DDL_TENANTS_DATA',
  SET_DDL_CONTACTS_DATA: 'SET_DDL_CONTACTS_DATA',
  SET_CONTACTS_LIST: 'SET_CONTACTS_LIST',
  SET_TENANTS_LIST: 'SET_TENANTS_LIST',
  SET_DDL_USERS_DATA: 'SET_DDL_USERS_DATA',
  SET_LEADS_DATA: 'SET_LEADS_DATA',
  SET_LEADS_QUOTATION_DATA: 'SET_LEADS_QUOTATION_DATA',
  RESET_LEADS_REDUCER: 'RESET_LEADS_REDUCER',
};

import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Avatar, Box, Card, CardContent, CardHeader, Chip, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { History } from 'lucide-react';
import React from 'react';
import { BootstrapTooltip, formatDate } from './commonUtility';

const stringAvatar = (name) => {
  if (!name) return { children: '?' };
  const nameParts = name.split(' ');
  return {
    sx: {
      bgcolor: '#1976d2',
    },
    children: `${nameParts[0][0]}${nameParts[1] ? nameParts[1][0] : ''}`,
  };
};

const UserModification = ({ firstName, lastName, lastModifiedTime }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Tooltip title={`Last modified by: ${firstName} ${lastName}`} arrow>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar {...stringAvatar(`${firstName} ${lastName}`)} sx={{ width: 25, height: 25, fontSize: 10, background: '#1976d2' }} />
        <Box>
          <Typography variant="subtitle2">
            {firstName} {lastName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {formatDate(lastModifiedTime)}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  </Box>
);

const ActivityLog = ({ leadsData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BootstrapTooltip title="Activities">
        <IconButton color="info" onClick={handleFilterClick}>
          <HistoryToggleOffRoundedIcon />
        </IconButton>
      </BootstrapTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            minWidth: '40vw', // Increased popover width
            minHeight: '80vh', // Maximum height relative to viewport
            overflowY: 'auto',
          },
        }}
      >
        <Card sx={{ width: '100%' }}>
          <CardHeader
            sx={{ py: 1, px: 2 }} // Reduced padding for header
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h6">Activities</Typography>
                <Chip label={leadsData?.entityActivities?.length || 0} color="primary" size="small" />
              </Box>
            }
            avatar={<History size={24} />}
          />
          <CardContent sx={{ py: 1, px: 2 }} className="adj-container">
            {' '}
            {/* Reduced padding for content */}
            <Timeline
              sx={{
                p: 0, // Remove default Timeline padding
                '& .MuiTimelineItem-root': {
                  minHeight: 'auto',
                  '&:before': {
                    display: 'none', // Remove default timeline spacing
                  },
                },
              }}
            >
              {leadsData?.entityActivities?.map((activity, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" sx={{ my: 0.5 }} /> {/* Reduced margin */}
                    {index < leadsData.entityActivities.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 0.5, px: 1 }}>
                    {' '}
                    {/* Reduced padding */}
                    <Card variant="outlined" sx={{ bgcolor: 'background.paper' }}>
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        {' '}
                        {/* Reduced padding and override Material-UI's last-child padding */}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            gap: 1,
                          }}
                        >
                          <UserModification firstName={activity?.changedByUser?.firstName} lastName={activity?.changedByUser?.lastName} lastModifiedTime={activity?.changedByUser?.lastModifiedAt} />
                          <Chip label={activity?.propertiesChanged[0]} variant="outlined" color="primary" size="small" sx={{ alignSelf: 'center' }} />
                        </Box>
                      </CardContent>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};

export default ActivityLog;

import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_PRODUCT_TRANSACTIONS_ENDPOINT } from '../../../../../constants/constants';
import CustomPagination from '../../../../common/pagination';
import CustomTable from '../../../../common/table';
import { setItemTransactionData, setItemTransactionId, setTransactionSearch } from '../../../../redux/actions/item-action';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { TransactionTypes } from '../../../../common-constants/static-values';
import { useNavigate } from 'react-router-dom';

export const InventoryTransactions = React.memo(({ id, inventoryData }) => {
  //   const transactionsByItemIdEndpoint = `${API_BASE_URL}${API_ITEM_TRANSACTIONS_ENDPOINT}/${itemId}`;
  const { itemTransactions, itemTransactionId, transactionSearch } = useSelector((state) => state?.item?.data, true);
  const transactionColumn = [
    {
      label: 'Date',
      field: 'transactionDate',
      className: 'px-2',
      date: true,
    },
    {
      label: 'Account Name',
      field: 'accountName',
    },
    {
      label: 'Transaction',
      field: 'transactionNumber',
      link: true,
    },
    {
      label: 'Type',
      field: 'transactionType',
    },
    {
      label: 'Quantity',
      field: 'quantity',
      align: 'center',
    },
  ];
  const transactionsByItemIdEndpoint = `${API_PRODUCT_TRANSACTIONS_ENDPOINT}?itemId=${id}`;
  const dispatch = useDispatch();
  const [tableLoader, setTableLoader] = useState(false);
  const itemPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionFilter, setTransactionFilter] = useState({
    type: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (itemTransactionId !== id) {
      dispatch(fetchTransactions());
      dispatch(setItemTransactionId(id));
    }
    setTransactionFilter({ type: transactionSearch || '' });
    setCurrentPage(itemTransactions?.pageNumber || 1);
  }, []);

  const changeFilter = (name, value) => {
    setTransactionFilter({
      ...transactionFilter,
      [name]: value,
    });
    setCurrentPage(1);
    dispatch(fetchTransactions(1, value?.value));
  };

  const fetchTransactions = (page = 1, filter = '') => {
    return async (dispatch) => {
      try {
        setTableLoader(true);
        const pageNumber = page ? `&pageNumber=${page}&pageSize=${itemPerPage}` : '';
        const search = filter ? `&transactionType=${filter}` : '';
        const finalUrl = `${transactionsByItemIdEndpoint}${pageNumber}${search}`;
        const response = await api.get(finalUrl);
        response.data.productTransactions = response.data?.productTransactions?.map((item) => ({
          ...item,
          quantity: `${item.quantity} ${inventoryData?.unit}`,
        }));
        dispatch(setItemTransactionData(response?.data));
        const filterSearch = TransactionTypes?.find((trc) => trc.value === filter);
        dispatch(setTransactionSearch(filterSearch));
        setTableLoader(false);
      } catch (error) {
        setTableLoader(false);
        console.error('Error fetching stock history data:', error);
      }
    };
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    dispatch(fetchTransactions(page, transactionFilter?.type?.value));
  };

  const handleItemClick = (item) => {
    switch (item.transactionType) {
      case 'Adjustment':
        navigate(`/adjustments/${item?.transactionId}`);
        break;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <CustomAutocomplete
          value={transactionFilter.type}
          onChange={(event, selectedOption) => {
            return changeFilter('type', { label: selectedOption?.label, value: selectedOption?.value });
          }}
          options={TransactionTypes}
          disableAddNew={true}
          placeholder="Filter data by transacation type..."
          getOptionLabel={(option) => option.label || ''}
          className="itemTransaction"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable tableColumn={transactionColumn} loading={tableLoader} tableData={itemTransactions?.productTransactions} handleEditEntity={handleItemClick} />
        <CustomPagination totalCount={itemTransactions?.totalCount} rowsPerPage={itemPerPage} totalPages={itemTransactions?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </Grid>
    </Grid>
  );
});

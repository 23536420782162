import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/constants';
import { LocationTypes } from '../common-constants/static-values';
import api from '../common/api';
import CustomAutocomplete from '../common/autocomplete';

const LocationModal = ({ location, locationIndex, showModal, handleClose }) => {
  const [locationType, setLocationType] = useState('');
  const [locationTypeOptions] = useState(LocationTypes);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locationForm, setLocationForm] = useState({
    locationName: '',
    locationType: '',
    addressLocation: {
      address: '',
      city: '',
      countryCode: '',
      postalCode: '',
    },
    submitted: false,
  });
  useEffect(() => {
    setLocationForm({
      locationName: location,
    });
    setLocationType('');
  }, [location]);

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setLocationForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setLocationForm((prevState) => ({
      ...prevState,
      addressLocation: {
        ...prevState.addressLocation,
        [name]: value,
      },
    }));
  };

  const handleLocationTypeChange = (event, newValue) => {
    setLocationType({
      value: newValue?.value,
      label: newValue?.label,
    });
    setLocationForm({
      ...locationForm,
      locationType: newValue?.value,
    });
  };

  const getValidatedFormResult = () => {
    if (!locationForm.locationName?.trim() || !locationForm.locationType) {
      return false;
    }
    return true;
  };

  const handleAddNewLocation = () => {
    setLocationForm({ ...locationForm, submitted: true });
    if (getValidatedFormResult()) {
      setLocationLoading(true);
      try {
        api.post(`${API_BASE_URL}/locations`, locationForm).then((response) => {
          handleClose(response?.data, locationIndex);
          setLocationLoading(false);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLocationLoading(false);
      }
    }
  };
  return (
    <Dialog open={showModal} onClose={handleClose}>
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            Add Location
          </Typography>
          <CloseIcon className="cursor-pointer ml-2" onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid className="mt-1" item xs={12} md={12}>
            <TextField
              name="locationName"
              label="Location name*"
              variant="outlined"
              error={!locationForm.locationName?.trim() && locationForm.submitted}
              helperText={!locationForm.locationName?.trim() && locationForm.submitted ? 'Location name is required.' : ''}
              value={locationForm.locationName}
              onChange={handleModalInputChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomAutocomplete
              name="locationType"
              value={locationType}
              disableAddNew={true}
              onChange={handleLocationTypeChange}
              options={locationTypeOptions}
              inputLabel="Location type *"
              isError={!locationType?.value && locationForm.submitted}
              helperText={!locationType?.value && locationForm.submitted ? 'Location type is required.' : ''}
              placeholder="Select location type...*"
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField variant="outlined" label="Address" name="address" value={locationForm?.addressLocation?.address || ''} onChange={handleAddressInputChange} multiline rows={4} />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField name="city" label="City" variant="outlined" value={locationForm?.addressLocation?.city || ''} onChange={handleAddressInputChange} />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField name="countryCode" label="country" variant="outlined" value={locationForm?.addressLocation?.countryCode || ''} onChange={handleAddressInputChange} />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField name="postalCode" label="Postal Code" variant="outlined" value={locationForm?.addressLocation?.postalCode || ''} onChange={handleAddressInputChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" className="mt-2 mx-3 my-2" variant="contained" disabled={locationLoading} onClick={handleAddNewLocation}>
          Submit
        </Button>
        {locationLoading && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

export default LocationModal;

import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import api from '../common/api';
import CustomAutocomplete from '../common/autocomplete';
import { useSelector } from 'react-redux';

countries.registerLocale(enLocale);
const PrimaryContactModal = ({ contactId, showModal, handleClose }) => {
  const { statePrimaryContactsOptions } = useSelector((state) => state?.account?.data);
  const countriesData = Object.entries(countries.getNames('en', { select: 'official' })).map(([code, name]) => ({ value: code, label: name }));
  const emptyObj = {
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    reportsToContact: null,
    lvReportsToContact: '',
    department: '',
    mailingAddress: {
      address: '',
      city: '',
      countryCode: '',
      lvCountryCode: '',
      postalCode: '',
    },
    submitted: false,
  };
  const [primaryContactForm, setPrimaryContactForm] = useState(emptyObj);
  const [reportsToContactOptions, setReportsToContactOptions] = useState([]);
  useEffect(() => {
    if (statePrimaryContactsOptions) {
      setReportsToContactOptions(statePrimaryContactsOptions);
    }
  }, [statePrimaryContactsOptions]);

  useEffect(() => {
    if (contactId) {
      fetchContactsById();
    }
  }, [contactId, showModal]);

  const [contactLoader, setContactLoader] = useState(false);
  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setPrimaryContactForm({
      ...primaryContactForm,
      [name]: value,
    });
  };
  const getValidatedFormResult = () => {
    if (!primaryContactForm?.firstName?.trim() || !primaryContactForm?.lastName?.trim() || !primaryContactForm?.mobile) {
      return false;
    }
    return true;
  };

  const fetchContactsById = async () => {
    try {
      const response = await api.get(`/contacts/${contactId}`);
      const contactsData = response.data;
      setPrimaryContactForm({
        ...contactsData,
        lvReportsToContact: contactsData?.reportsTo?.firstName
          ? { label: `${contactsData?.reportsTo?.firstName} ${contactsData?.reportsTo?.lastName}`, value: contactsData?.reportsTo?.contactId }
          : '',
        mailingAddress: {
          ...contactsData.mailingAddress,
          lvCountryCode: contactsData.mailingAddress?.countryCode
            ? { label: countriesData?.find((co) => co.value === contactsData?.mailingAddress?.countryCode)?.label, value: contactsData?.mailingAddress?.countryCode }
            : '',
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMailingAddressChange = (e) => {
    const { name, value } = e.target;
    setPrimaryContactForm({
      ...primaryContactForm,
      mailingAddress: {
        ...primaryContactForm?.mailingAddress,
        [name]: value,
      },
    });
  };

  const handleAddPrimaryContact = async () => {
    setPrimaryContactForm({
      ...primaryContactForm,
      submitted: true,
    });

    if (getValidatedFormResult()) {
      setContactLoader(true);
      const reqObj = {
        ...primaryContactForm,
      };
      try {
        let response;
        if (contactId) {
          response = await api.put(`/contacts/${contactId}`, reqObj);
        } else {
          response = await api.post('/contacts', reqObj);
        }
        if (response) {
          setPrimaryContactForm({ submitted: false });
          setContactLoader(false);
          handleClose(response?.data?.contactId, `${primaryContactForm.firstName} ${primaryContactForm.lastName}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: '90vh', // Set the maximum height
          maxWidth: '70vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            {contactId ? 'Edit' : 'Add'} Primary Contact
          </Typography>
          <CloseIcon
            className="cursor-pointer ml-2"
            onClick={() => {
              setPrimaryContactForm(emptyObj);
              handleClose();
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="my-2">
          <Grid item xs={6} md={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name *"
              variant="outlined"
              error={!primaryContactForm.firstName?.trim() && primaryContactForm?.submitted}
              helperText={!primaryContactForm.firstName?.trim() && primaryContactForm?.submitted ? 'First Name is required.' : ''}
              value={primaryContactForm.firstName || ''}
              onChange={handleControlChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name *"
              variant="outlined"
              error={!primaryContactForm.lastName?.trim() && primaryContactForm?.submitted}
              helperText={!primaryContactForm.lastName?.trim() && primaryContactForm?.submitted ? 'Last Name is required.' : ''}
              value={primaryContactForm.lastName || ''}
              onChange={handleControlChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="mobile"
              name="mobile"
              label="Phone *"
              variant="outlined"
              error={!primaryContactForm.mobile && primaryContactForm?.submitted}
              inputProps={{ maxLength: 10 }}
              helperText={!primaryContactForm.mobile && primaryContactForm?.submitted ? 'Phone Number is required.' : ''}
              value={primaryContactForm.mobile || ''}
              onChange={handleControlChange}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField id="email" name="email" type="email" label="Email" variant="outlined" value={primaryContactForm.email || ''} onChange={handleControlChange} />
          </Grid>
          <Grid item xs={6} md={6}>
            <CustomAutocomplete
              value={primaryContactForm?.lvReportsToContact || ''}
              onChange={(event, newValue) => {
                setPrimaryContactForm({
                  ...primaryContactForm,
                  reportsToContact: newValue?.value,
                  lvReportsToContact: { value: newValue?.value, label: newValue?.label },
                });
              }}
              options={contactId ? reportsToContactOptions?.filter((co) => co.value !== contactId) : reportsToContactOptions}
              inputLabel="Reports to contact"
              placeholder="Select..."
              disableAddNew={true}
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField id="department" name="department" label="Department" variant="outlined" value={primaryContactForm.department} onChange={handleControlChange} />
          </Grid>
          <Typography className="fs-14 fw-500 d-flex ms-3 mt-3" gutterBottom>
            Mailing Address
          </Typography>
          <Grid item xs={12} md={12}>
            <TextField name="address" label="Address" multiline rows={2} variant="outlined" value={primaryContactForm?.mailingAddress?.address || ''} onChange={handleMailingAddressChange} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="city" name="city" label="City" variant="outlined" value={primaryContactForm?.mailingAddress?.city || ''} onChange={handleMailingAddressChange} />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              id="state"
              name="state"
              label="State *"
              variant="outlined"
              value={primaryContactForm?.mailingAddress?.state}
              onChange={handleMailingAddressChange}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField id="postalCode" name="postalCode" label="Zip Code" variant="outlined" value={primaryContactForm?.mailingAddress?.postalCode || ''} onChange={handleMailingAddressChange} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete
              value={primaryContactForm?.mailingAddress?.lvCountryCode || ''}
              onChange={(event, newValue) => {
                setPrimaryContactForm({
                  ...primaryContactForm,
                  mailingAddress: {
                    ...primaryContactForm.mailingAddress,
                    countryCode: newValue?.value,
                    lvCountryCode: { value: newValue?.value, label: newValue?.label },
                  },
                });
              }}
              options={countriesData}
              inputLabel="Country"
              disableAddNew={true}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mx-3 my-2">
        <Button size="small" type="submit" variant="contained" disabled={contactLoader} onClick={handleAddPrimaryContact}>
          Submit
        </Button>
        {contactLoader && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

export default PrimaryContactModal;

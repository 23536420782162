import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_TENANTS_ENDPOINT } from '../../../../constants/constants';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { closeAccountModal, setAccountFormSubmitted } from '../../../redux/actions/account-action';
import { AccountAdditionalInfo } from './account-overview-tabs/account-additional-info';
import { AccountAddress } from './account-overview-tabs/account-address';
import { AccountDetail } from './account-overview-tabs/account-detail';
import { FinanceDetail } from './account-overview-tabs/finance-detail';

export const NewAccount = React.memo(() => {
  const { stateFinanceForm, stateAccountDetailForm, stateBillingAddressForm, stateAddtionalInfoForm, stateAccountSinceField, isAccountModalOpen } = useSelector((state) => state?.account?.data);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountEndpoint = API_TENANTS_ENDPOINT;
  const [activeTab, setActiveTab] = useState(0);
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Accounts',
      href: '/accounts',
    },
    {
      name: id ? 'Account Info' : 'New Account',
      active: true,
    },
  ];
  const tenantTabs = [
    {
      value: 0,
      label: 'Account Details',
      mandatory: true,
    },
    {
      value: 1,
      label: 'Financial Details',
      mandatory: true,
    },
    {
      value: 2,
      label: 'Address',
      mandatory: true,
    },
    {
      value: 3,
      label: 'Additional Info',
      mandatory: false,
    },
  ];
  const [accountTabs, setAccountTabs] = useState([...tenantTabs]);
  const [tabs] = useState(tenantTabs?.map((tb) => tb.value));

  const [loading, setLoading] = useState(false);
  const [submitCustomerLoader, setSubmitCustomerLoader] = useState(false);

  useEffect(() => {
    getValidatedFormResult();
  }, [stateFinanceForm, stateAccountDetailForm, stateBillingAddressForm, stateAddtionalInfoForm, stateAccountSinceField]);

  useEffect(() => {}, [isAccountModalOpen]);

  const getValidatedFormResult = () => {
    let isValid = true;
    if (
      !stateAccountDetailForm?.name?.trim() ||
      !stateAccountDetailForm?.referenceNumber?.trim() ||
      !stateAccountDetailForm.accountOwner ||
      !stateAccountDetailForm?.tenantType ||
      !stateAccountDetailForm.entityType ||
      !stateAccountDetailForm.accountStatus
    ) {
      isValid = false;
      tenantTabs[0].mandatory = true;
      setAccountTabs([...tenantTabs]);
    } else {
      tenantTabs[0].mandatory = false;
      setAccountTabs([...tenantTabs]);
    }

    if (!stateFinanceForm?.currency) {
      isValid = false;
      tenantTabs[1].mandatory = true;
      setAccountTabs([...tenantTabs]);
    } else {
      tenantTabs[1].mandatory = false;
      setAccountTabs([...tenantTabs]);
    }

    if (
      !stateBillingAddressForm?.billingAddress?.address?.trim() ||
      !stateBillingAddressForm?.billingAddress?.city?.trim() ||
      //  !stateBillingAddressForm?.billingAddress?.state?.trim() ||
      !stateBillingAddressForm?.billingAddress?.postalCode?.trim() ||
      !stateBillingAddressForm?.billingAddress?.countryCode
    ) {
      isValid = false;
      tenantTabs[2].mandatory = true;
      setAccountTabs([...tenantTabs]);
    } else if (stateBillingAddressForm?.billingAddress?.isAddressDifferent) {
      if (
        !stateBillingAddressForm?.shippingAddress?.address?.trim() ||
        !stateBillingAddressForm?.shippingAddress?.city?.trim() ||
        //  !stateBillingAddressForm?.billingAddress?.state?.trim() ||
        !stateBillingAddressForm?.shippingAddress?.postalCode?.trim() ||
        !stateBillingAddressForm?.shippingAddress?.countryCode
      ) {
        isValid = false;
        tenantTabs[2].mandatory = true;
        setAccountTabs([...tenantTabs]);
      }
    } else {
      tenantTabs[2].mandatory = false;
      setAccountTabs([...tenantTabs]);
    }
    return isValid;
  };

  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };

  const handleSubmit = async () => {
    try {
      dispatch(setAccountFormSubmitted(true));
      if (getValidatedFormResult()) {
        const reqObj = {
          tenantFinancials: { ...stateFinanceForm },
          ...stateAccountDetailForm,
          ...stateBillingAddressForm,
          ...stateAddtionalInfoForm,
          accountSince: stateAccountSinceField,
        };

        setSubmitCustomerLoader(true);
        const response = await api.post(accountEndpoint, reqObj);
        setSubmitCustomerLoader(false);
        dispatch(setAccountFormSubmitted(false));
        if (!isAccountModalOpen) {
          navigate('/accounts', { state: { mode: 'add' } });
        } else {
          dispatch(closeAccountModal(response?.data));
        }
      } else {
        const errorTab = accountTabs?.find((item) => item.mandatory);
        if (errorTab) setActiveTab(errorTab?.value);
      }
    } catch (error) {
      setSubmitCustomerLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        {!isAccountModalOpen ? (
          <div className="d-flex justify-content-between align-items-center">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <div className="d-flex flex-end">
              <CloseIcon
                className="cursor-pointer ml-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        ) : null}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <Typography variant="h6" className="d-flex" gutterBottom>
                {isAccountModalOpen ? 'Create Customer' : 'Add Account'}
              </Typography>
              <div>
                {isAccountModalOpen ? (
                  <div className="d-flex flex-grow-1 justify-content-end ">
                    <CloseIcon
                      className="cursor-pointer ml-2"
                      onClick={() => {
                        dispatch(closeAccountModal());
                      }}
                    />
                  </div>
                ) : null}
                <span className="error-red ">*</span> Required Fields
              </div>
            </div>
          </>
        )}
        <div className="pe-3 ps-3 flex-grow-1 border rounded">
          <Tabs value={activeTab} onChange={handleTabSelect} className="mx-2">
            {accountTabs?.length &&
              accountTabs?.map((item, index) => (
                <Tab
                  key={index}
                  value={item.value}
                  label={
                    <Typography className="text-capitalize fs-13">
                      {item.label} {item?.mandatory ? <span style={{ color: 'red' }}>*</span> : null}
                    </Typography>
                  }
                ></Tab>
              ))}
          </Tabs>
          {activeTab === 0 && <AccountDetail />}
          {activeTab === 1 && <FinanceDetail />}
          {activeTab === 2 && <AccountAddress />}
          {activeTab === 3 && <AccountAdditionalInfo />}
          <hr className="mt-4 mb-4" />
          <div className="d-flex align-items-center mt-3 mb-2 mx-2">
            {activeTab !== tabs[0] && (
              <Button size="small" variant="outlined" className="me-2" onClick={handlePrevious}>
                Previous
              </Button>
              // <Button size='sm' variant="outline-primary" onClick={handlePrevious}>Previous</Button>
            )}
            {activeTab !== tabs[tabs.length - 1] ? (
              <Button size="small" variant="outlined" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <div className="d-flex align-items-center mx-2">
                <Button size="small" type="submit" variant="contained" disabled={submitCustomerLoader} onClick={handleSubmit}>
                  Submit
                </Button>
                {submitCustomerLoader && <CircularProgress className="mx-2" size={15} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

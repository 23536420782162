import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { EditAccountOverview } from './edit-account-tabs/edit-account-overview';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
export const EditAccount = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Accounts',
      href: '/accounts',
    },
    {
      name: 'Account Info',
      active: true,
    },
  ];

  const editTenantTabs = [
    {
      value: 0,
      label: 'Overview',
    },
    {
      value: 1,
      label: 'Activity',
    },
    {
      value: 2,
      label: 'Communication',
      mandatory: true,
    },
    {
      value: 3,
      label: 'Files',
    },
    {
      value: 4,
      label: 'Contacts',
    },
  ];
  const [accountTabs, setAccountTabs] = useState([...editTenantTabs]);
  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-content-between align-items-center">
          <CustomBreadCrumb breadcrumbList={breadcrumbs} />
          <CloseIcon
            className="cursor-pointer ml-2"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <Grid container>
          <Grid item xs={9} md={9}>
            <Tabs value={activeTab} onChange={handleTabSelect} className="mx-2 mr-4">
              {accountTabs?.length && accountTabs?.map((item, index) => <Tab key={index} value={item.value} label={<Typography className="text-capitalize fs-13">{item.label}</Typography>}></Tab>)}
            </Tabs>
          </Grid>
        </Grid>

        {activeTab === 0 && <EditAccountOverview />}
        {activeTab === 1 && <Typography className="pt-4">Coming Soon...</Typography>}
        {activeTab === 2 && <Typography className="pt-4">Coming Soon...</Typography>}
        {activeTab === 3 && <Typography className="pt-4">Coming Soon...</Typography>}
        {activeTab === 4 && <Typography className="pt-4">Coming Soon...</Typography>}
      </div>
    </>
  );
};

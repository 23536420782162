import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Typography, TextField } from '@mui/material';
import { BootstrapTooltip } from '../../../../common/commonUtility';
import { Delete } from '@mui/icons-material';
import CustomAutocomplete from '../../../../common/autocomplete';

const AdjustmentsOrderItems = ({ ddlProduct = [], orderItems, handleCellEdit, handleCellDelete, handleItemEdit, adjForm }) => {
  // Ensure ddlProduct is always an array
  const productOptions = Array.isArray(ddlProduct) ? ddlProduct : [];
  const disabledValues = orderItems.map((item) => item.selectedItem?.value)
  return (
    <TableContainer className="adjustment-order-grid">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align="right">Available Quantity</TableCell>
            <TableCell align="right">Delta</TableCell>
            <TableCell align="right">New Quantity</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(orderItems) && orderItems.length > 0 && orderItems.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell width="30%">
                      <CustomAutocomplete
                        value={row?.selectedItem || ''}
                        onChange={(e, newValue) => handleItemEdit(newValue, index)}
                        disabledValues={disabledValues?.filter(dis=> dis !== row?.selectedItem?.value)}
                        options={productOptions}
                        inputLabel="Item"
                        placeholder="Search..."
                        disableAddNew={true}
                        getOptionLabel={(option) => option.label || ''}
                        isError={!row?.selectedItem?.value && adjForm.submitted}
                        helperText={!row?.selectedItem?.value && adjForm.submitted ? 'Item is required.' : ''}
                      />
                    </TableCell>
                    <TableCell width="7%" align="right">
                      <Typography className="label">{!isNaN(row.oldAvailableQuantity) ? row.oldAvailableQuantity : 'N/A'} </Typography>
                    </TableCell>
                    <TableCell align="right" width="10%">
                      <TextField
                        type="number"
                        id="adjustedQuantity"
                        value={row?.adjustedQuantity}
                        name={`adjustmentOrderItems-adjustedQuantity-${index}`}
                        variant="outlined"
                        onChange={(e) => handleCellEdit(e, index, 'adjustedQuantity')}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: 'right' },
                          },
                        }}
                        error={!row?.adjustedQuantity && adjForm.submitted}
                        helperText={!row?.adjustedQuantity && adjForm.submitted ? 'Delta is required.' : ''}
                      />
                    </TableCell>
                    <TableCell align="right" width="5%">
                      {!isNaN(row.oldAvailableQuantity) ? (
                        <span className={row.oldAvailableQuantity <= row.oldAvailableQuantity + Number(row.adjustedQuantity) ? 'delta-success' : 'delta-error'}>
                          {isNaN(row.oldAvailableQuantity <= row.oldAvailableQuantity + Number(row.adjustedQuantity)) ? 0 : row.oldAvailableQuantity + Number(row.adjustedQuantity)}
                        </span>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell align="right" width="5%">
                      {index !== 0 ? (
                        <BootstrapTooltip title="Delete">
                          <IconButton color="info" onClick={() => handleCellDelete(index)}>
                            <Delete />
                          </IconButton>
                        </BootstrapTooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdjustmentsOrderItems;

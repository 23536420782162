import React from 'react';

const UserIcon = ({ firstName, lastName }) => {
  // Compute the initials
  const initials = `${firstName[0]}${lastName[0]}`;

  // Inline styles for the circle
  const circleStyle = {
    width: '25px', // Circle size
    height: '25px',
    borderRadius: '50%', // Make it round
    backgroundColor: '#007bff', // Bootstrap primary color
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px', // Adjust as needed
    fontWeight: 'bold',
  };

  return (
    <>
    <div style={circleStyle}>
      {initials.toUpperCase()}
    </div>
    </>
  );
};

export const UserIconFullName = ({ fullName }) => {
  // Compute the initials
  const splitName = fullName.split(' ');
  const initials = `${splitName[0][0]}${splitName[1][0]}`;

  // Inline styles for the circle
  const circleStyle = {
    width: '25px', // Circle size
    height: '25px',
    borderRadius: '50%', // Make it round
    backgroundColor: '#007bff', // Bootstrap primary color
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px', // Adjust as needed
    fontWeight: 'bold',
  };

  return (
    <>
      <div className="text-muted mb-1" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={circleStyle}>
          {initials.toUpperCase()}
        </div>
        &nbsp;
        {fullName}
      </div>
    </>
  );
};

export default UserIcon;

import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const StockAllocationForProcure = ({ showAllocationModal, handleCloseAllocationModal, allocationData, currentKey, allocationStatus }) => {
  const emptyDeliveryObj = {
    quantity: '',
    notes: '',
    submitted: false,
  };
  const [SAProcureProduceForm, setSAProcureProduceForm] = useState(emptyDeliveryObj);
  const [loading, setLoading] = useState(false);
  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setSAProcureProduceForm({
      ...SAProcureProduceForm,
      [name]: value,
    });
  };

  useEffect(() => {
    if (allocationData && currentKey) {
      let allocationByLocationData = { ...allocationData[currentKey] };
      setSAProcureProduceForm(allocationByLocationData);
    }
  }, [showAllocationModal, allocationData, currentKey]);

  const getValidatedFormResult = () => {
    if (!SAProcureProduceForm.quantity) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSAProcureProduceForm({
      ...SAProcureProduceForm,
      submitted: true,
    });
    //  if (getValidatedFormResult()) {
    handleCloseAllocationModal({ ...SAProcureProduceForm });
    //  }
  };

  return (
    <Dialog
      open={showAllocationModal}
      PaperProps={{
        style: {
          maxHeight: '60vh', // Set the maximum height
          maxWidth: '60vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom className="text-capitalize">
            {currentKey}
          </Typography>
          <CloseIcon className="cursor-pointer ml-2" onClick={() => handleCloseAllocationModal(SAProcureProduceForm, 'close')} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={`my-2 ${allocationStatus === 'Allocated' ? 'item-disabled' : ''}`}>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              label="Quantity"
              id="quantity"
              value={SAProcureProduceForm.quantity}
              name="quantity"
              variant="outlined"
              onChange={handleControlChange}
              InputProps={{
                endAdornment: <Typography className="ml-2">{allocationData?.product?.unit}</Typography>,
              }}
              // error={!SAProcureProduceForm?.quantity && SAProcureProduceForm.submitted}
              // helperText={!SAProcureProduceForm?.quantity && SAProcureProduceForm.submitted ? 'Quantity is required.' : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField name="notes" label="Note" multiline rows={2} variant="outlined" value={SAProcureProduceForm?.notes || ''} onChange={handleControlChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mx-3 my-2">
        <Button size="small" type="submit" variant="contained" disabled={loading || allocationStatus === 'Allocated'} onClick={handleSubmit}>
          Ok
        </Button>
        {loading && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
const SparkLineChartForSales = ({ chartData = {}, granularity = 'daily' }) => {
  const localArrayData = chartData?.data?.sort((a, b) => new Date(a.date) - new Date(b.date));
  return (
    <>
      {chartData?.data?.length > 1 ? (
        <div className="d-flex flex-column text-center mt-chart">
          <Stack direction="row" sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <SparkLineChart
                data={localArrayData?.map((ot) => ot.count)}
                valueFormatter={(value, object) => `Order Count: ${value} , Order Amount: (${chartData?.currency}) ${localArrayData[object?.dataIndex]?.amount?.toFixed(2)} `}
                xAxis={{
                  scaleType: 'time',
                  data: localArrayData?.map((ot) => new Date(ot.date)),
                  // valueFormatter: (value) => value.toISOString().slice(0, 10),
                }}
                height={100}
                showTooltip
                showHighlight
                tooltip={{
                  valueFormatter: (value) => `${value} units`,
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <SparkLineChart
                plotType="bar"
                data={localArrayData?.map((ot) => ot.count)}
                valueFormatter={(value, object) => `Order Count: ${value} , Order Amount: (${chartData?.currency}) ${localArrayData[object?.dataIndex]?.amount?.toFixed(2)} `}
                height={100}
                showTooltip
                showHighlight
                xAxis={{
                  scaleType: 'band',
                  data: localArrayData?.map((ot) => new Date(ot.date)),
                  //valueFormatter: (value) => value.getFullYear(),
                }}
              />
            </Box>
          </Stack>
          <span className="fs-12">
            {' '}
            Order Count By Granularity (<span className="text-capitalize">{granularity}</span>)
          </span>
        </div>
      ) : null}
    </>
  );
};

export default SparkLineChartForSales;

import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_ADJUSTMENTS_ENDPOINT } from '../../../../constants/constants';
import { Messages } from '../../../common-constants/messages';
import { getAdjustmentKebabMenuActions } from '../../../common-constants/static-values';
import { AdjustmentStatusValue, AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import CardVisualization from '../../../common/card-visualization';
import { getCheckboxResult } from '../../../common/commonUtility';
import ConfirmDialog from '../../../common/confirm-dialog';
import CustomPagination from '../../../common/pagination';
import CustomTable from '../../../common/table';
import CustomTableActions from '../../../common/table-actions';
import { resetDocumentReducer } from '../../../redux/actions/document-action';
import { useDispatch } from 'react-redux';

const InventoryAdjustments = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const adjustmentColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Reference Number',
      field: 'referenceNumber',
      link: true,
    },
    {
      label: 'Status',
      field: 'adjustmentStatus',
      badge: true,
    },
    {
      label: 'Modified By',
      field: 'user',
      avatar: true,
    },
    {
      label: 'Remarks',
      field: 'remarks',
    },
    {
      label: 'Adjustment Date',
      field: 'adjustmentDate',
      date: true,
    },
    {
      label: 'Action',
      field: 'action',
      menu: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Adjustments',
      active: true,
    },
  ];
  const INURL = API_ADJUSTMENTS_ENDPOINT;
  const itemsPerPage = 6;
  const [activeCard, setActiveCard] = useState(0);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [tableColumn] = useState(adjustmentColumn);
  const [breadcrumbList] = useState(breadcrumbs);
  const [title] = useState('Adjustments');
  const [tableLoader, setTableLoader] = useState(false);
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [aggregateData, setAggregateData] = useState([]);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdjustments();
    fetchAggregateData();
    stateHandlerOnLoad();
    dispatch(resetDocumentReducer());
  }, [search]);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const handleDeleteConfirmYes = () => {
    deleteAdjustments();
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const deleteAdjustments = async () => {
    const selectedItems = items.filter((item) => item.checked)?.map((adj) => adj.adjustmentId);
    setDeleteDialog(false);
    try {
      await api.delete(INURL, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.adjustmentDeleted,
      });
      fetchAdjustments();
      fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const stateHandlerOnLoad = () => {
    if (state?.referenceNumber && state?.mode === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.adjustmentAdded?.replace('{reference}', state?.referenceNumber)}`,
      });
    } else if (state?.id) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.adjustmentUpdated?.replace('{reference}', state?.referenceNumber)}`,
      });
    }
    navigate('.', { replace: true });
  };

  const handleEditEntity = (item) => {
    navigate(`/adjustments/${item.adjustmentId}`);
  };

  const handleRefreshData = () => {
    fetchAdjustments();
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setItems(getCheckboxResult(items, checkedItem, 'adjustmentId'));
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    fetchAdjustments(page);
  };

  const handleAddEntity = () => {
    navigate('/adjustments/newAdjustment');
  };

  const prepareStatusMenuForAdjustments = (data) => {
    data.adjustments = data?.adjustments?.map((adj) => ({
      ...adj,
      disabledCheckbox: adj.adjustmentStatus === AdjustmentStatusValue.Adjusted,
      user: `${adj?.associatedUser?.firstName} ${adj?.associatedUser?.lastName}`,
      action: getAdjustmentKebabMenuActions(adj?.adjustmentStatus),
    }));
    return data;
  };

  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const response = await api.get(`${INURL}/overview`);
      const data = response.data;
      const aggregateData = [
        { title: 'Adjustments', value: data.totalAdjustments, bindValue: 0 },
        { title: 'Pending', value: data.totalPendingAdjustments, bindValue: 1 },
        { title: 'Adjusted', value: data.totalAdjustedAdjustments, bindValue: 2 },
        { title: 'Abandoned', value: data.totalAbandonedAdjustments, bindValue: 3 },
      ].filter((item) => item.value !== 0);
      setAggregateData(aggregateData);
      setAggregateLoader(false);
    } catch (error) {
      setAggregateLoader(false);
      console.error('Error fetching aggregate data:', error);
    }
  };

  const handleCardActive = (activeCard) => {
    setActiveCard(activeCard);
    fetchAdjustments(1, activeCard);
  };

  const fetchAdjustments = async (currentPage = 1, activeCard = 0) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    const adjustmentType = activeCard > 0 ? `&adjustmentType=${activeCard}` : '';
    try {
      const response = await api.get(`${INURL}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}${adjustmentType}`);
      response.data = prepareStatusMenuForAdjustments(response.data);
      const { adjustments } = response.data;
      setEntityData(response.data);
      setCurrentPage(currentPage);
      setItems(adjustments);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const handleMenuItemClicked = async (event, item) => {
    if (event > 0) {
      try {
        const reqObj = {
          adjustmentStatus: Number(event),
        };
        await api.post(`${INURL}/${item?.adjustmentId}/status`, reqObj);
        fetchAdjustments();
        fetchAggregateData();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.adjustmentStatusChange} '${Object.keys(AdjustmentStatusValue)[event]}' `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <CustomBreadCrumb breadcrumbList={breadcrumbList} />
        <Typography className="mt-2" variant="h5" gutterBottom>
          {title}
        </Typography>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={2}>
              <CustomTableActions
                disableDelete={!items.filter((item) => item.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={handleAddEntity}
              ></CustomTableActions>
            </Grid>
            <Grid className="d-flex align-items-center" item xs={8}>
              <CardVisualization loading={aggregateLoader} activeCard={activeCard} data={aggregateData} handleCardActive={handleCardActive} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable
          tableColumn={tableColumn}
          loading={tableLoader}
          tableData={items}
          toggleSelectedCheckbox={toggleSelectedCheckbox}
          handleEditEntity={handleEditEntity}
          menuItemClicked={handleMenuItemClicked}
        />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default InventoryAdjustments;

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_PRODUCTS_ENDPOINT, API_PRODUCTS_STOCKHISTORY_ENDPOINT } from '../../../../../constants/constants';
import api from '../../../../common/api';
import { BootstrapTooltip, convertToUTCWithEndTime, formatDate } from '../../../../common/commonUtility';
import { setActivitySearch, setItemActivityData, setItemActivityId } from '../../../../redux/actions/item-action';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomPagination from '../../../../common/pagination';
import { useLocation, useNavigate } from 'react-router-dom';

export const InventoryItemActivity = React.memo(({ id, inventoryData }) => {
  const stockHistoryEndpoint = `${API_PRODUCTS_ENDPOINT}/${id}${API_PRODUCTS_STOCKHISTORY_ENDPOINT}`;
  const { itemActivity, itemActivityId, activitySearch } = useSelector((state) => state?.item?.data);
  const dispatch = useDispatch();
  const itemPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activityFilter, setActivityFilter] = useState({
    fromDate: dayjs(new Date())?.subtract(1, 'year'),
    toDate: dayjs(new Date()),
  });
  useEffect(() => {
    if (itemActivityId !== id) {
      dispatch(fetchStockHistory(1, activityFilter.fromDate, activityFilter.toDate));
      dispatch(setItemActivityId(id));
    }
    if (activitySearch) {
      setActivityFilter({
        fromDate: dayjs(activitySearch?.fromDate),
        toDate: dayjs(activitySearch?.toDate),
      });
    }
    setCurrentPage(itemActivity?.pageNumber);
  }, []);

  const fetchStockHistory = (page = 1, fromDate = '', toDate = '') => {
    return async (dispatch) => {
      try {
        setLoading(true);
        const pageNumber = page ? `?pageNumber=${page}&pageSize=${itemPerPage}` : '';
        const dateFilter = fromDate && toDate && page ? `&startDate=${encodeURIComponent(convertToUTCWithEndTime(fromDate))}&endDate=${encodeURIComponent(convertToUTCWithEndTime(toDate))}` : '';
        const finalUrl = `${stockHistoryEndpoint}${pageNumber}${dateFilter}`;
        const response = await api.get(finalUrl);
        dispatch(setItemActivityData(response?.data));
        dispatch(
          setActivitySearch({
            fromDate: fromDate?.toISOString(),
            toDate: toDate?.toISOString(),
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching stock history data:', error);
      }
    };
  };

  const getStockChangeTypeLabel = (type) => {
    switch (type) {
      case 1:
        return 'Opening Balance';
      case 2:
        return 'Purchase';
      case 3:
        return 'Sales';
      case 4:
        return 'Sales';
      case 5:
        return 'Return';
      case 6:
        return 'Adjustment';
      case 7:
        return 'Transfer';
      case 8:
        return 'Work Order';
      default:
        return 'Unknown';
    }
  };
  const navigationURLForItem = (pathName, queryParam) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('itemTab', queryParam);
    searchParams.set('itemId', id);
    navigate({
      pathname: pathName,
      search: searchParams.toString(),
    });
  };

  const navigateTransaction = (event, transactionId) => {
    event.preventDefault();
    navigationURLForItem(`/adjustments/${transactionId}`, 'itemActivity');
  };

  const getTransactionLink = (history) => {
    if (history?.workOrder && history?.workOrder?.workOrderNumber && history?.stockChangeType == 8) {
      const workOrderNumber = history?.workOrder?.workOrderNumber || 'N/A';
      const workOrderId = history?.workOrder?.workOrderId;

      return workOrderId ? (
        <Typography
          variant="body2"
          component="a"
          href="#"
          onClick={(event) => {
            event.preventDefault();
            navigationURLForItem(`/workorders/${workOrderId}`, 'itemActivity');
          }}
          className="link"
        >
          {workOrderNumber}
        </Typography>
      ) : (
        workOrderNumber
      );
    } else if (history.salesTransaction) {
      const orderNumber = history.salesTransaction.orderNumber || 'N/A';
      const transactionId = history.salesTransactionId;

      return transactionId ? (
        <Typography
          variant="body2"
          component="a"
          href="#"
          onClick={(event) => {
            event.preventDefault();
            navigationURLForItem(`/sales/${transactionId}`, 'itemActivity');
          }}
          className="link"
        >
          {orderNumber}
        </Typography>
      ) : (
        orderNumber
      );
    } else if (history.adjustmentTransaction) {
      const referenceNumber = history.adjustmentTransaction.referenceNumber || 'N/A';
      const transactionId = history.adjustmentTransactionId;
      return transactionId ? (
        <Typography
          variant="body2"
          component="a"
          href="#"
          className="link"
          onClick={(event) => navigateTransaction(event, transactionId)}
          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
        >
          {referenceNumber}
        </Typography>
      ) : (
        referenceNumber
      );
    } else {
      return 'N/A';
    }
  };

  const changeFilter = (name, value) => {
    setActivityFilter({
      ...activityFilter,
      [name]: dayjs(value),
    });
    setCurrentPage(1);
    if (name === 'fromDate' && value && activityFilter.toDate && value?.isAfter(activityFilter?.toDate)) {
      setActivityFilter({
        ...activityFilter,
        [name]: dayjs(value),
        toDate: dayjs(value),
      });
      dispatch(fetchStockHistory(1, value, value));
    } else if (name === 'toDate' && value && activityFilter.fromDate && activityFilter.fromDate?.isAfter(value)) {
      setActivityFilter({
        ...activityFilter,
        [name]: dayjs(value),
        fromDate: dayjs(value),
      });
      dispatch(fetchStockHistory(1, value, value));
    } else if (name === 'fromDate' && value && activityFilter.toDate && activityFilter.toDate.isAfter(value)) {
      dispatch(fetchStockHistory(1, value, activityFilter.toDate));
    } else if (name === 'toDate' && value && activityFilter.fromDate && value.isAfter(activityFilter?.fromDate)) {
      dispatch(fetchStockHistory(1, activityFilter.fromDate, value));
    }
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    dispatch(fetchStockHistory(page, activityFilter.fromDate, activityFilter.toDate));
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography className="label">From Date </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker format="DD/MM/YYYY" value={activityFilter?.fromDate} onChange={(e) => changeFilter('fromDate', e)} />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={4} className="mx-4">
        <Typography className="label">To Date </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker format="DD/MM/YYYY" value={activityFilter?.toDate} onChange={(e) => changeFilter('toDate', e)} />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} className="d-flex pt-3 pb-1">
        <div className="bl-opening">
          <Typography className="label ms-1">Opening Balance </Typography>
          <Typography className="ms-2 fs-20">
            {itemActivity?.openingBalance} {inventoryData.unit}
          </Typography>
        </div>
        <div className="bl-closing ms-3">
          <Typography className="label ms-1">Closing Balance </Typography>
          <Typography className="ms-2 fs-20">
            {itemActivity?.closingBalance} {inventoryData.unit}
          </Typography>
        </div>
        <div className="bl-inward ms-3">
          <Typography className="label  ms-1">Total Inward </Typography>
          <Typography className="ms-2 fs-20">
            {itemActivity?.totalQuantityAdded} {inventoryData.unit}
          </Typography>
        </div>
        <div className="bl-outward ms-3">
          <Typography className="label ms-1">Total Outward </Typography>
          <Typography className="ms-2 fs-20">
            {itemActivity?.totalQuantityDeducted} {inventoryData.unit}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <TableContainer size="small" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '4px 8px' }} className="px-2">
                  <Typography variant="subtitle2">Date</Typography>
                </TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                  <Typography variant="subtitle2">Transaction</Typography>
                </TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                  <Typography variant="subtitle2">Stock Change</Typography>
                </TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                  <Typography variant="subtitle2">Available Stock</Typography>
                </TableCell>
                <TableCell sx={{ padding: '4px 8px' }}>
                  <Typography variant="subtitle2">Balance</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                itemActivity?.stockHistory?.length ? (
                  itemActivity?.stockHistory?.map((history, index) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell sx={{ padding: '4px 8px' }} className="px-2">
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                          {formatDate(history.asOnDate)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                          {getTransactionLink(history)}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                          <i> ({getStockChangeTypeLabel(history.stockChangeType)}) </i>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                          <BootstrapTooltip sx={{ cursor: 'pointer' }} title={history.change === 1 ? 'Inward Transaction' : 'Outward Transaction'}>
                            {history.stockChangeType !== 1 ? history.change === 1 ? <AddCircleIcon sx={{ color: 'green' }} /> : <RemoveCircleIcon sx={{ color: 'red' }} /> : null}
                            <span className="mx-2">
                              {history.stockChange} {inventoryData.unit}
                            </span>
                          </BootstrapTooltip>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                          {history.availableStock} {inventoryData.unit}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="mt-1">
                          <i>
                            Location : <b> {history.productLocation?.location?.locationName || 'N/A'} </b>
                          </i>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: '4px 8px' }}>
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="mt-1">
                          {history.totalAvailableStock} {inventoryData.unit}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center', fontSize: '13px', fontWeight: 500, padding: '20px' }}>
                      <span>No Data</span>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: 'center', fontSize: '13px', fontWeight: 500, padding: '20px' }}>
                    <span>
                      <CircularProgress size={20} />
                    </span>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <CustomPagination totalCount={itemActivity?.totalCount} rowsPerPage={itemPerPage} totalPages={itemActivity?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </Grid>
  );
});

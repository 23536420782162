import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoute } from './pages/auth/PrivateRoute';
import { useUser } from './pages/auth/useUser';
import AccountLanding from './pages/inventory-mgmt-app/accounts/accounts/account-landing';
import { EditAccount } from './pages/inventory-mgmt-app/accounts/accounts/edit-account';
import { NewAccount } from './pages/inventory-mgmt-app/accounts/accounts/new-account';
import ContactLanding from './pages/inventory-mgmt-app/accounts/contacts/contact-landing';
import { Dashboard } from './pages/inventory-mgmt-app/dashboard/Dashboard';
import InventoryAdjustments from './pages/inventory-mgmt-app/inventory/adjustments/inventory-adjustments';
import UpsertAdjustment from './pages/inventory-mgmt-app/inventory/adjustments/upsert-adjustment/upsert-adjustment';
import { NewProduct } from './pages/inventory-mgmt-app/inventory/items/new-product/new-product';
import { NewOrder } from './pages/inventory-mgmt-app/sales/new-order/new-order';
import InventoryManagement from './pages/inventory-mgmt-app/inventory/items/inventory-management';
import ApiKeyManagement from './pages/inventory-mgmt-app/settings/api-keys/ApiKeys';
import { NotFound } from './pages/notfound/NotFound';
import SalesLanding from './pages/inventory-mgmt-app/sales/sales-landing';
import { EditOrder } from './pages/inventory-mgmt-app/sales/edit-order/edit-order';
import LocationLanding from './pages/inventory-mgmt-app/inventory/locations/location-landing';
import AddLocation from './pages/inventory-mgmt-app/inventory/locations/AddLocation';
import StockAllocationLanding from './pages/operations/stock-allocation/stock-allocation-landing';
import { ManageStockAllocation } from './pages/operations/stock-allocation/manage-stock-allocation';
import { ManageWorkOrder } from './pages/operations/work-orders/manage-work-orders/manage-work-orders';
import { WorkOrdersLanding } from './pages/operations/work-orders/work-orders-landing';
import { QualityControlLanding } from './pages/operations/quality-control/quality-control-landing';
import { PackagingLanding } from './pages/operations/packaging/packaging-landing';
import { ManagePackagingOrder } from './pages/operations/packaging/manage-packaging-orders';
import { LogisticsDeliveryLanding } from './pages/operations/logistics-delivery/logistics-delivery-landing';
import { ManageQualityControl } from './pages/operations/quality-control/manage-quality-control';
import { ManageLogisticsDelivery } from './pages/operations/logistics-delivery/manage-logistics-delivery';
import { UserLanding } from './pages/inventory-mgmt-app/user-mgmt/UserManagement';
import { NewUser } from './pages/inventory-mgmt-app/user-mgmt/new-user/NewUser';
import { UserInfo } from './pages/inventory-mgmt-app/user-mgmt/user-info/UserInfo';
import { UpsertLeads } from './pages/inventory-mgmt-app/leads/upsert-leads/upsert-leads';
import LeadsLanding from './pages/inventory-mgmt-app/leads/leads-landing';

export const RayaRoutes = () => {
  const user = useUser();

  useEffect(() => {
    const currentTime = new Date().getTime() / 1000; // Get current time in seconds
    if (user && user.exp > currentTime) {
    } else {
    }
  }, [user]);

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/items" element={<InventoryManagement />} />
          <Route path="/items/newItem" element={<NewProduct />} />
          <Route path="/sales" element={<SalesLanding />} />
          <Route path="/sales/neworder" element={<NewOrder />} />
          <Route path="/sales/:salesId" element={<EditOrder />} />
          <Route path="/users" element={<UserLanding />} />
          <Route path="/users/new" element={<NewUser />} />
          <Route path="/users/:id" element={<UserInfo />} />
          <Route path="/adjustments" element={<InventoryAdjustments />} />
          <Route path="/adjustments/newAdjustment" element={<UpsertAdjustment />} />
          <Route path="/adjustments/:id" element={<UpsertAdjustment />} />
          <Route path="/accounts" element={<AccountLanding />} />
          <Route path="/accounts/newAccount" element={<NewAccount />} />
          <Route path="/accounts/:tenantId" element={<EditAccount />} />
          <Route path="/contacts" element={<ContactLanding />} />
          <Route path="/stockAllocation" element={<StockAllocationLanding />} />
          <Route path="/workOrders" element={<WorkOrdersLanding />} />
          <Route path="/workorders/:workorderId" element={<ManageWorkOrder />} />
          <Route path="/stockAllocation/:allocationId" element={<ManageStockAllocation />} />
          <Route path="/qualitycontrol" element={<QualityControlLanding />} />
          <Route path="/qualitycontrol/:qualityCheckId" element={<ManageQualityControl />} />
          <Route path="/packaging" element={<PackagingLanding />} />
          <Route path="/packaging/:packagingId" element={<ManagePackagingOrder />} />
          <Route path="/shipping" element={<LogisticsDeliveryLanding />} />
          <Route path="/shipping/:deliveryOrderId" element={<ManageLogisticsDelivery />} />
          <Route path="settings/api-keys" element={<ApiKeyManagement />} />
          <Route path="/locations" element={<LocationLanding />} /> {/* New route */}
          <Route path="/locations/new" element={<AddLocation />} />
          <Route path="/leads" element={<LeadsLanding />} />
          <Route path="/leads/newLead" element={<UpsertLeads />} />
          <Route path="/leads/:leadId" element={<UpsertLeads />} />
          <Route path="/leads/:leadId/:quotationId" element={<UpsertLeads />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

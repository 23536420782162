import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Avatar, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import currencyCodes from 'currency-codes';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_TENANTS_ENDPOINT } from '../../../../../constants/constants';
import { AccountCategories, AccountStatuses, AccountTypes, EntityTypes, PaymentTermsTypes } from '../../../../common-constants/static-values';
import api from '../../../../common/api';
import { BootstrapTooltip, formatDate, formatEntityLabelValue, renderLastModifiedData, stringAvatar } from '../../../../common/commonUtility';
import {
  setAccountAdditionalInfoData,
  setAccountAddressData,
  setAccountDetailsData,
  setAccountFormSubmitted,
  setAccountSinceFieldData,
  setFinanceDetailsData,
  setTenantId,
} from '../../../../redux/actions/account-action';
import { AccountAdditionalInfo } from '../account-overview-tabs/account-additional-info';
import { AccountAddress } from '../account-overview-tabs/account-address';
import { AccountDetail } from '../account-overview-tabs/account-detail';
import { FinanceDetail } from '../account-overview-tabs/finance-detail';
import CustomAlert from '../../../../common/alert';
import { AlertStatus } from '../../../../common-constants/status';
import { Messages } from '../../../../common-constants/messages';
countries.registerLocale(enLocale);
export const EditAccountOverview = () => {
  const { stateTenantId, stateFinanceForm, stateAccountDetailForm, stateBillingAddressForm, stateAddtionalInfoForm, stateAccountSinceField } = useSelector((state) => state?.account?.data);
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const [accountDetailForm, setAccountDetailForm] = useState({});
  const accountEndpoint = API_TENANTS_ENDPOINT;
  const baseEndpoint = API_BASE_URL;
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };
  useEffect(() => {
    if (stateTenantId !== tenantId) {
      fetchAccountDetailsById();
    } else {
      setAccountDetailForm(stateAccountDetailForm);
    }
  }, []);

  useEffect(() => {
    getValidatedFormResult();
  }, [stateFinanceForm, stateAccountDetailForm, stateBillingAddressForm, stateAddtionalInfoForm, stateAccountSinceField]);

  const currencyOptions = currencyCodes.codes().map((code) => {
    const currency = currencyCodes.code(code);
    return { value: code, label: `${currency.code} - ${currency.currency}` };
  });

  const fetchAccountDetailsById = async () => {
    try {
      api.defaults.baseURL = baseEndpoint;
      setInitialLoading(true);
      const response = await api.get(`${accountEndpoint}/${tenantId}`);
      const data = prepareAccountForm(response.data);
      setAccountDetailForm(data);
      dispatch(setAccountDetailsData(data));
      dispatch(setAccountSinceFieldData(data?.accountSince));
      dispatch(setAccountAddressData(data));
      dispatch(setTenantId(tenantId));
      dispatch(setAccountAdditionalInfoData(data));
      dispatch(setFinanceDetailsData(data?.tenantFinancials));
      setInitialLoading(false);
    } catch (error) {
      api.defaults.baseURL = baseEndpoint;
      console.error('Error fetching data:', error);
      setInitialLoading(false);
    }
  };

  const prepareAccountForm = (response) => {
    const countriesData = Object.entries(countries.getNames('en', { select: 'official' })).map(([code, name]) => ({ value: code, label: name }));
    const accountOwnerLabel = `${response?.accountOwner?.firstName} ${response?.accountOwner?.lastName}`;
    const primaryAccountLabel = `${response?.primaryContact?.firstName} ${response?.primaryContact?.lastName}`;
    const accountDetailForm = {
      ...response,
      accountOwner: response?.accountOwner?.id,
      lvAccountOwner: { label: accountOwnerLabel, value: response?.accountOwner?.id },
      primaryContact: response?.primaryContact?.contactId,
      lvPrimaryContact: { label: primaryAccountLabel, value: response?.primaryContact?.contactId },
      entityType: EntityTypes?.find((en) => en?.label === response?.entityType)?.value,
      lvEntityType: { label: response?.entityType, value: EntityTypes?.find((en) => en?.label === response?.entityType)?.value },
      lvIndustry: formatEntityLabelValue(response, 'industry', 'industry'),
      accountCategory: AccountCategories?.find((en) => en?.label?.replace(' ', '') === response?.accountCategory)?.value,
      lvAccountCategory: { label: response?.accountCategory, value: AccountCategories?.find((en) => en?.label?.replace(' ', '') === response?.accountCategory)?.value },
      accountStatus: AccountStatuses?.find((en) => en?.label?.replace(' ', '') === response?.accountStatus)?.value,
      lvAccountStatus: { label: response?.accountStatus, value: AccountStatuses?.find((en) => en?.label?.replace(' ', '') === response?.accountStatus)?.value },
      tenantType: AccountTypes?.find((en) => en?.label?.replace(' ', '') === response?.tenantType)?.value,
      lvTenantType: { label: response?.tenantType, value: AccountTypes?.find((en) => en?.label?.replace(' ', '') === response?.tenantType)?.value },
      billingAddress: {
        ...response.billingAddress,
        lvCountryCode: { label: countriesData?.find((co) => co.value === response?.billingAddress?.countryCode)?.label, value: response?.billingAddress?.countryCode },
        isAddressDifferent: response?.shippingAddress?.address ? true : false,
      },
      shippingAddress: {
        ...response.shippingAddress,
        lvCountryCode: { label: countriesData?.find((co) => co.value === response?.shippingAddress?.countryCode)?.label, value: response?.shippingAddress?.countryCode },
      },
      tenantFinancials: {
        ...response?.tenantFinancials,
        lvPaymentTerms: { label: response?.tenantFinancials?.paymentTerms, value: PaymentTermsTypes?.find((en) => en?.value === response?.tenantFinancials)?.paymentTerms },
        lvCurrency: { label: currencyOptions?.find((co) => co.value === response?.tenantFinancials?.currency)?.label, value: response?.tenantFinancials?.currency },
        lvPaymentMethod: { label: response?.tenantFinancials?.paymentMethod, value: response?.tenantFinancials?.paymentMethod },
      },
    };
    return accountDetailForm;
  };

  const getValidatedFormResult = () => {
    let isValid = true;
    if (
      !stateAccountDetailForm?.name?.trim() ||
      !stateAccountDetailForm?.referenceNumber?.trim() ||
      !stateAccountDetailForm.accountOwner ||
      !stateAccountDetailForm?.tenantType ||
      !stateAccountDetailForm.entityType ||
      !stateAccountDetailForm.accountStatus
    ) {
      isValid = false;
    }

    if (!stateFinanceForm?.currency) {
      isValid = false;
    }

    if (
      !stateBillingAddressForm?.billingAddress?.address?.trim() ||
      !stateBillingAddressForm?.billingAddress?.city?.trim() ||
      //    !stateBillingAddressForm?.billingAddress?.state?.trim() ||
      !stateBillingAddressForm?.billingAddress?.postalCode?.trim() ||
      !stateBillingAddressForm?.billingAddress?.countryCode
    ) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    dispatch(setAccountFormSubmitted(true));
    if (getValidatedFormResult()) {
      const reqObj = {
        ...stateAccountDetailForm,
        tenantFinancials: { ...stateFinanceForm },
        billingAddress: stateBillingAddressForm?.billingAddress,
        shippingAddress: stateBillingAddressForm?.shippingAddress,
        website: stateAddtionalInfoForm?.website,
        remarks: stateAddtionalInfoForm?.remarks,
        accountSince: stateAccountSinceField,
      };
      setLoading(true);
      try {
        const response = await api.put(`${accountEndpoint}/${reqObj?.id}`, reqObj);
        setLoading(false);
        dispatch(setAccountFormSubmitted(false));
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.accountUpdated}`,
        });
        // navigate('/accounts', { state: { mode: 'update' } });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      {initialLoading ? (
        <>
          <span className="d-flex justify-content-center mt-2">
            <CircularProgress size={20} />
          </span>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-end align-btn-tabs">
            <Button size="small" type="submit" variant="contained" disabled={loading} onClick={handleSubmit}>
              Submit
            </Button>
            {loading && <CircularProgress className="mx-2" size={15} />}
            {accountDetailForm?.id ? (
              <>
                {accountDetailForm?.modifiedBy &&
                  renderLastModifiedData(accountDetailForm?.modifiedBy?.firstName, accountDetailForm?.modifiedBy?.lastName, accountDetailForm?.modifiedBy?.lastModifiedAt)}
              </>
            ) : null}
          </div>
          <div className="adj-container">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper>
                  <div className="account-card">
                    <AccountCircleIcon className="fs-18" />
                    <Typography className="fs-14 fw-500 mx-2">Account Details</Typography>
                  </div>
                  <div className="px-2 pb-4">{accountDetailForm?.name && <AccountDetail />}</div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  <div className="account-card">
                    <LocationOnIcon className="fs-18" />
                    <Typography className="fs-14 fw-500 mx-2">Address</Typography>
                  </div>
                  <div className="px-2 pb-4 pt-2  ">
                    <AccountAddress />
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper>
                  <div className="account-card">
                    <CreditCardIcon className="fs-18" />
                    <Typography className="fs-14 fw-500 mx-2">Financial Details</Typography>
                  </div>
                  <div className="px-2 pb-4">
                    <FinanceDetail />
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper>
                  <div className="account-card">
                    <InfoIcon className="fs-18" />
                    <Typography className="fs-14 fw-500 mx-2">Additional Info</Typography>
                  </div>
                  <div className="px-2 pb-4">
                    <AccountAdditionalInfo />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

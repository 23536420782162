import { TYPES } from '../type-constants/type';

const initialState = {
  data: {},
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_ITEM_LOCATION_DATA:
    case TYPES.SET_ITEM_ACTIVITY_DATA:
    case TYPES.SET_ITEM_TRANSACTION_DATA:
    case TYPES.SET_ITEM_OVERVIEW_ID:
    case TYPES.SET_ITEM_LOCATION_ID:
    case TYPES.SET_ITEM_ACTIVTY_ID:
    case TYPES.SET_ITEM_TRANSACTION_ID:
    case TYPES.LOCATION_SEARCH:
    case TYPES.ACTIVITY_SEARCH:
    case TYPES.TRANSACTION_SEARCH:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case TYPES.RESET_ITEM_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default itemReducer;

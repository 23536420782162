import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {

  const styles = {
      container: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
      }
    };

  return (
    <main id="content" style={styles.container}>
      <div className="not-found-container container main-content">
        <div className="not-found-content">
          <h1 className="not-found-title">404 - Page Not Found</h1>
          <p className="not-found-description">
            Oops! It looks like you've ventured into uncharted territory.
          </p>
          <div className="not-found-image">
            {/* Add an image or illustration here */}
            {/* Example: <img src="/images/404.png" alt="Page Not Found" /> */}
          </div>
          <p className="not-found-suggestion">
            Maybe you can find your way back to the{' '}
            <Link to="/">homepage</Link>.
          </p>
        </div>
      </div>
    </main>
  );
};


import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../common/autocomplete';

export const StockAllocationForProduce = ({
  showAllocationModal,
  handleCloseAllocationModal,
  allocationData,
  currentKey,
  bomOptions,
  routingOptions,
  allocationStatus,
  locationByItemOptions,
  userOptions,
}) => {
  const emptyDeliveryObj = {
    quantity: '',
    bomId: '',
    lvBomId: '',
    notes: '',
    plannedStartDate: null,
    plannedEndDate: null,
    assignedTo: '',
    lvAssignedTo: '',
    locationId: '',
    lvLocationId: '',
    submitted: false,
  };

  const [SAProcureProduceForm, setSAProcureProduceForm] = useState(emptyDeliveryObj);
  const [loading, setLoading] = useState(false);
  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setSAProcureProduceForm({
      ...SAProcureProduceForm,
      [name]: value,
    });
  };

  useEffect(() => {
    if (allocationData && currentKey) {
      let allocationByLocationData = { ...allocationData[currentKey] };

      setSAProcureProduceForm(allocationByLocationData);
    }
  }, [showAllocationModal, allocationData, currentKey]);

  const getValidatedFormResult = () => {
    if (!SAProcureProduceForm.quantity) {
      return false;
    }
    return true;
  };

  // const fetchLocationByItem = async (itemId) => {
  //   const response = await api.get(`${locationEndpoint}?pageNumber=1&pageSize=100`);
  //   formatLocationOptions = formatEntityLabelValue(response?.data?.locations, 'locationName', 'locationId') || [];
  //   setLocationByItemOptions(formatLocationOptions);
  // };

  // const [token, setToken] = useToken();
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` },
  // };
  // const fetchUsers = async () => {
  //   try {
  //     const uri = `${API_USERS_ENDPOINT}`;
  //     const response = await axios.get(`${uri}`, config);
  //     response.data = response?.data?.users?.map((item) => ({
  //       ...item,
  //       fullName: `${item.firstName} ${item.lastName}`,
  //     }));
  //     const userData = formatEntityLabelValue(response.data, 'fullName', 'id');
  //     setUserOptions(userData);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const handleBOMChange = async (event, newValue) => {
    if (newValue?.value) {
      const updateItem = {
        ...SAProcureProduceForm,
        bomId: newValue?.value,
        lvBomId: { value: newValue?.value, label: newValue?.label },
      };
      setSAProcureProduceForm(updateItem);
    } else {
      const updateItem = {
        ...SAProcureProduceForm,
        bomId: '',
        lvBomId: { value: '', label: '' },
      };
      setSAProcureProduceForm(updateItem);
    }
  };

  const handleRouteChange = async (event, newValue) => {
    if (newValue?.value) {
      const updateItem = {
        ...SAProcureProduceForm,
        routingId: newValue?.value,
        lvRoutingId: { value: newValue?.value, label: newValue?.label },
      };
      setSAProcureProduceForm(updateItem);
    } else {
      const updateItem = {
        ...SAProcureProduceForm,
        routingId: '',
        lvRoutingId: { value: '', label: '' },
      };
      setSAProcureProduceForm(updateItem);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSAProcureProduceForm({
      ...SAProcureProduceForm,
      submitted: true,
    });
    //  if (getValidatedFormResult()) {
    handleCloseAllocationModal({ ...SAProcureProduceForm });
    //  }
  };

  const handleUserChange = async (newValue) => {
    if (newValue?.value) {
      setSAProcureProduceForm({
        ...SAProcureProduceForm,
        assignedTo: newValue?.value,
        lvAssignedTo: newValue,
      });
    }
  };

  const handleLocationChange = (newValue) => {
    if (newValue) {
      const locationObj = {
        locationId: newValue?.value,
        lvLocationId: newValue,
      };
      setSAProcureProduceForm({ ...SAProcureProduceForm, ...locationObj });
    }
  };

  return (
    <Dialog
      open={showAllocationModal}
      PaperProps={{
        style: {
          maxHeight: '60vh', // Set the maximum height
          maxWidth: '60vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom className="text-capitalize">
            {currentKey}
          </Typography>
          <CloseIcon className="cursor-pointer ml-2" onClick={() => handleCloseAllocationModal(SAProcureProduceForm, 'close')} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={`my-2 ${allocationStatus === 'Allocated' ? 'item-disabled' : ''}`}>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete
              className="d-flex flex-grow-1"
              value={SAProcureProduceForm?.lvBomId || ''}
              inputLabel="Bill Of Materials Name"
              onChange={(e, newValue) => handleBOMChange(e, newValue)}
              options={bomOptions || []}
              placeholder="Search..."
              disableAddNew={true}
              getOptionLabel={(option) => option.label || ''}
              // isError={!SAProcureProduceForm.lvBomId?.value && SAProcureProduceForm.submitted}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomAutocomplete
              className="d-flex flex-grow-1"
              value={SAProcureProduceForm?.lvRoutingId || ''}
              inputLabel="Route Name"
              disableAddNew={true}
              onChange={(e, newValue) => handleRouteChange(e, newValue)}
              options={routingOptions || []}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
              //   isError={!SAProcureProduceForm.lvRoutingId?.value && SAProcureProduceForm.submitted}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              label="Quantity"
              id="quantity"
              value={SAProcureProduceForm.quantity}
              name="quantity"
              variant="outlined"
              onChange={handleControlChange}
              InputProps={{
                endAdornment: <Typography className="ml-2">{allocationData?.product?.unit}</Typography>,
              }}
              // error={!SAProcureProduceForm?.quantity && SAProcureProduceForm.submitted}
              // helperText={!SAProcureProduceForm?.quantity && SAProcureProduceForm.submitted ? 'Quantity is required.' : ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Planned Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={SAProcureProduceForm.plannedStartDate}
                format="DD/MM/YYYY"
                name="plannedStartDate"
                onChange={(e) =>
                  setSAProcureProduceForm({
                    ...SAProcureProduceForm,
                    plannedStartDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Planned End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={SAProcureProduceForm.plannedEndDate}
                format="DD/MM/YYYY"
                name="plannedEndDate"
                onChange={(e) =>
                  setSAProcureProduceForm({
                    ...SAProcureProduceForm,
                    plannedEndDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={SAProcureProduceForm?.lvAssignedTo || ''}
              onChange={(event, newValue) => handleUserChange(newValue)}
              options={userOptions || []}
              inputLabel="Assigned User *"
              //isError={!SAProcureProduceForm?.assignedTo && SAProcureProduceForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={SAProcureProduceForm?.lvLocationId || ''}
              onChange={(e, newValue) => handleLocationChange(newValue)}
              disableAddNew={true}
              inputLabel="Location *"
              options={locationByItemOptions || []}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
              // isError={!SAProcureProduceForm?.lvLocationIdForProduce && SAProcureProduceForm?.submitted}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField name="notes" label="Note" multiline rows={2} variant="outlined" value={SAProcureProduceForm?.notes || ''} onChange={handleControlChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="mx-3 my-2">
        <Button size="small" type="submit" variant="contained" disabled={loading || allocationStatus === 'Allocated'} onClick={handleSubmit}>
          Ok
        </Button>
        {loading && <CircularProgress className="mx-2" size={15} />}
      </DialogActions>
    </Dialog>
  );
};

import { Avatar, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_SALES_DELIVERY_NOTES_ENDPOINT, API_SALES_TRANSACTIONS_ENDPOINT, API_TENANTS_ENDPOINT, API_USERS_ENDPOINT } from '../../../../../constants/constants';
import { useToken } from '../../../../auth/useToken';
import { Messages } from '../../../../common-constants/messages';
import { PaymentTermsTypes } from '../../../../common-constants/static-values';
import { AlertStatus, OrderStatusValue } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { BootstrapTooltip, formatDate, formatEntityLabelValue, getCurrencyOptions, renderLastModifiedData, stringAvatar } from '../../../../common/commonUtility';
import { CreateCustomerModal } from '../../../../modals/create-customer';
import { DeliveryMethodModal } from '../../../../modals/delivery-method';
import { openAccountModal, resetAccountReducer } from '../../../../redux/actions/account-action';
import { setCustomerData, setDeliveryNoteData, setSalesByIdData, setSalesPersonData } from '../../../../redux/actions/sales-action';

export const EditOrderOverview = () => {
  const { salesId } = useParams();
  const { ddlCustomer, ddlSalesPerson, ddlDeliveryNote, salesData } = useSelector((state) => state?.sales?.data);
  const { isAccountModalOpen, accountData } = useSelector((state) => state?.account?.data);
  const customerEndpoint = API_TENANTS_ENDPOINT;
  const usersEndpoint = API_USERS_ENDPOINT;
  const deliveryNoteEndpoint = API_SALES_DELIVERY_NOTES_ENDPOINT;
  const salesTransactionEndpoint = API_SALES_TRANSACTIONS_ENDPOINT;
  const dispatch = useDispatch();

  const salesDefaultValue = {
    customerId: '',
    lvCustomerId: '',
    currency: '',
    lvCurrency: '',
    salesPerson: '',
    lvSalesPerson: '',
    orderNumber: '',
    orderDate: null,
    shipmentDate: null,
    paymentTerms: '',
    lvPaymentTerms: '',
    paymentRemarks: '',
    deliveryNoteId: '',
    lvDeliveryNote: '',
    remarks: '',
  };
  const [salesOrderForm, setSalesOrderForm] = useState(salesDefaultValue);
  const [customerOptions, setCustomerOptions] = useState();
  const [salesPersonOptions, setSalesPersonOptions] = useState();
  const [deliveryNotesOptions, setDeliveryNotesOptions] = useState();
  const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const currencyOptions = getCurrencyOptions();
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };
  const [token, setToken] = useToken();
  const navigate = useNavigate();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);

  useEffect(() => {
    if (!ddlCustomer) {
      fetchCustomers();
    } else {
      setCustomerOptions(ddlCustomer);
    }
    if (!ddlSalesPerson) {
      fetchSalesPerson();
    } else {
      setSalesPersonOptions(ddlSalesPerson);
    }
    if (!ddlDeliveryNote) {
      fetchDeliveryNote();
    } else {
      setDeliveryNotesOptions(ddlDeliveryNote);
    }
  }, []);

  useEffect(() => {
    if (customerOptions && salesPersonOptions && deliveryNotesOptions && !salesData) {
      fetchSalesByIdData();
    } else {
      setSalesOrderForm({
        ...salesOrderForm,
        ...salesData,
        orderDate: salesData?.orderDate ? dayjs(salesData?.orderDate) : null,
        shipmentDate: salesData?.shipmentDate ? dayjs(salesData?.shipmentDate) : null,
      });
    }
  }, [customerOptions, salesPersonOptions, deliveryNotesOptions, !salesData]);

  useEffect(() => {
    if (accountData?.tenantId) {
      fetchCustomers();
      const customer = { label: accountData?.name, value: accountData?.tenantId };
      handleCustomerChange(customer);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${accountData?.name}' ${Messages.customerCreated}`,
      });
    }
    if (!isAccountModalOpen) {
      dispatch(resetAccountReducer());
    }
  }, [isAccountModalOpen, accountData]);

  const fetchCustomers = async () => {
    try {
      const response = await api.get(`${customerEndpoint}?pageNumber=1&pageSize=100`);
      response.data.tenants = response.data.tenants?.map((item) => ({
        ...item,
        user: `${item?.accountOwner?.firstName} ${item?.accountOwner?.lastName}`,
      }));
      const accountsData = response.data?.tenants?.filter((to) => to?.tenantType?.toLowerCase() === 'customer');
      const customerOptions = formatEntityLabelValue(accountsData, 'name', 'id');
      setCustomerOptions(customerOptions);
      dispatch(setCustomerData(customerOptions));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSalesPerson = async () => {
    try {
      const uri = `${usersEndpoint}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      const userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setSalesPersonOptions(userData);
      dispatch(setSalesPersonData(userData));
    } catch (error) {
      console.error('Error fetching salesperson suggestions:', error);
    }
  };

  const fetchDeliveryNote = async () => {
    try {
      const response = await api.get(`${deliveryNoteEndpoint}?pageNumber=1&pageSize=100`);
      const deliveryNotesOptions = formatEntityLabelValue(response.data, 'name', 'id');
      setDeliveryNotesOptions(deliveryNotesOptions);
      dispatch(setDeliveryNoteData(deliveryNotesOptions));
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
    }
  };

  const fetchSalesByIdData = async () => {
    try {
      const { data } = await api.get(`${salesTransactionEndpoint}/${salesId}`);
      const paymentTermsById = data?.deliveryNote?.salesTransactions?.length ? data?.deliveryNote?.salesTransactions[0].paymentTerms : '';
      const salesData = {
        ...salesOrderForm,
        ...data,
        orderDate: data?.orderDate ? data?.orderDate : null,
        shipmentDate: data?.shipmentDate ? data?.shipmentDate : null,
        lvCustomerId: customerOptions?.find((co) => co.value === data?.customerId),
        lvCurrency: currencyOptions?.find((co) => co.value === data?.currency),
        lvSalesPerson: salesPersonOptions?.find((co) => co.value === data?.salesPerson),
        paymentTerms: paymentTermsById,
        lvPaymentTerms: PaymentTermsTypes?.find((co) => co.value === paymentTermsById),
        lvDeliveryNote: deliveryNotesOptions?.find((co) => co.value === data?.deliveryNoteId),
        orderStatus: data?.orderStatus,
      };
      setSalesOrderForm(salesData);
      dispatch(setSalesByIdData(salesData));
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleSalesControlChange = (e) => {
    const { name, value } = e.target;
    setSalesOrderForm({
      ...salesOrderForm,
      [name]: value,
    });
    dispatch(
      setSalesByIdData({
        ...salesOrderForm,
        [name]: value,
      })
    );
  };

  const handleCloseDeliveryNote = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event?.id) {
      const updateDeliveryNote = {
        ...salesOrderForm,
        deliveryNoteId: event?.id,
        lvDeliveryNote: { label: event?.name, value: event?.id },
      };
      setSalesOrderForm(updateDeliveryNote);
      dispatch(setSalesByIdData(updateDeliveryNote));
      fetchDeliveryNote();
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${event?.name}' ${Messages.deliveryMethodAdded}`,
      });
    }
    setShowDeliveryNoteModal(false);
  };

  const handleCustomerChange = async (newValue) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openAccountModal());
    } else if (newValue?.value) {
      try {
        const response = await api.get(`${customerEndpoint}/${newValue?.value}`);
        updateCustomerRelatedData(response?.data, newValue);
      } catch (error) {
        console.log('error', error);
      }
    } else {
      const updateCustomer = {
        ...salesOrderForm,
        customerId: newValue?.value,
        lvCustomerId: { value: newValue?.value, label: newValue?.label },
      };
      setSalesOrderForm(updateCustomer);
      dispatch(setSalesByIdData(updateCustomer));
    }
  };

  const updateCustomerRelatedData = (cusByIdData, selectedCustomer) => {
    const needsUpdate = {
      customerId: selectedCustomer?.value,
      lvCustomerId: { value: selectedCustomer?.value, label: selectedCustomer?.label },
      paymentTerms: cusByIdData?.tenantFinancials?.paymentTerms || '',
      lvPaymentTerms: cusByIdData?.tenantFinancials?.paymentTerms ? PaymentTermsTypes?.find((pt) => pt.value === cusByIdData?.tenantFinancials?.paymentTerms) : '',
      salesPerson: cusByIdData?.accountOwner?.id,
      lvSalesPerson: { label: `${cusByIdData?.accountOwner?.firstName} ${cusByIdData?.accountOwner?.lastName}`, value: cusByIdData?.accountOwner?.id },
      currency: cusByIdData?.tenantFinancials?.currency,
      lvCurrency: currencyOptions?.find((co) => co?.value === cusByIdData?.tenantFinancials?.currency),
    };
    setSalesOrderForm({
      ...salesOrderForm,
      ...needsUpdate,
    });
    dispatch(
      setSalesByIdData({
        ...salesOrderForm,
        ...needsUpdate,
      })
    );
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (
      !salesOrderForm.lvCustomerId?.value ||
      !salesOrderForm?.orderDate?.isValid() ||
      !salesOrderForm.orderNumber ||
      (salesOrderForm.shipmentDate && !salesOrderForm.shipmentDate.isValid()) ||
      !salesOrderForm?.lvCurrency?.value ||
      !salesOrderForm?.lvSalesPerson?.value
    ) {
      return false;
    }
    return valid;
  };

  const handleSaveOrder = async () => {
    try {
      const reqObj = {
        ...salesOrderForm,
      };
      setSalesOrderForm({
        ...salesOrderForm,
        submitted: true,
      });
      setOrderSubmitLoading(true);
      if (getValidatedFormResult()) {
        const response = await api.put(`${salesTransactionEndpoint}/${salesId}`, reqObj);
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.salesOrderUpdated}`,
        });
        setOrderSubmitLoading(false);
      }
    } catch (error) {
      setOrderSubmitLoading(false);
      console.log('Error Occured', error);
    }
  };

  return (
    // <div className={salesData?.orderStatus !== OrderStatusValue.Draft ? 'item-disabled' : ''}>
    <>
      {salesOrderForm.orderNumber ? (
        <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
          <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
          <DeliveryMethodModal showModal={showDeliveryNoteModal} handleCloseDeliveryNote={handleCloseDeliveryNote} />
          <CreateCustomerModal />
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <CustomAutocomplete
                value={salesOrderForm.lvCustomerId}
                onChange={(event, newValue) => handleCustomerChange(newValue)}
                options={customerOptions}
                inputLabel="Customer Name *"
                isError={!salesOrderForm?.lvCustomerId?.value && salesOrderForm?.submitted}
                // helperText={!salesOrderForm?.lvCustomerId?.value && salesOrderForm?.submitted ? 'Customer Name is required.' : ''}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomAutocomplete
                value={salesOrderForm.lvCurrency}
                onChange={(event, newValue) => {
                  const updateCurrency = {
                    ...salesOrderForm,
                    currency: newValue?.value,
                    lvCurrency: { value: newValue?.value, label: newValue?.label },
                  };
                  setSalesOrderForm(updateCurrency);
                  dispatch(setSalesByIdData(updateCurrency));
                }}
                options={currencyOptions}
                inputLabel="Currency *"
                disableAddNew={true}
                isError={!salesOrderForm?.lvCurrency?.value && salesOrderForm?.submitted}
                // helperText={!salesOrderForm?.lvCurrency?.value && salesOrderForm?.submitted ? 'Currency is required.' : ''}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="position-relative">
              <div className="d-flex flex-grow-1">
                {/* {salesOrderForm.lvSalesPerson?.label ? (
              <BootstrapTooltip title={salesOrderForm.lvSalesPerson?.label}>
                <Avatar {...stringAvatar(salesOrderForm.lvSalesPerson?.label)} />
              </BootstrapTooltip>
            ) : null} */}
                <CustomAutocomplete
                  value={salesOrderForm.lvSalesPerson}
                  onChange={(event, newValue) => {
                    const updateSalesPerson = {
                      ...salesOrderForm,
                      salesPerson: newValue?.value,
                      lvSalesPerson: { value: newValue?.value, label: newValue?.label },
                    };
                    setSalesOrderForm(updateSalesPerson);
                    dispatch(setSalesByIdData(updateSalesPerson));
                  }}
                  className="d-flex flex-grow-1"
                  disableAddNew={true}
                  options={salesPersonOptions}
                  inputLabel="Sales Person *"
                  placeholder="Search..."
                  isError={!salesOrderForm?.lvSalesPerson?.value && salesOrderForm?.submitted}
                  getOptionLabel={(option) => option.label || ''}
                />
              </div>

              {salesOrderForm.lvSalesPerson?.label ? (
                <BootstrapTooltip title={salesOrderForm.lvSalesPerson?.label}>
                  <Avatar className="sales-person-avatar" {...stringAvatar(salesOrderForm.lvSalesPerson?.label)} />
                </BootstrapTooltip>
              ) : null}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                id="orderNumber"
                name="orderNumber"
                label="Order Number *"
                variant="outlined"
                disabled={!!salesId}
                error={!salesOrderForm.orderNumber?.trim() && salesOrderForm?.submitted}
                // helperText={!salesOrderForm.orderNumber?.trim() && salesOrderForm?.submitted ? 'Order Number is required.' : ''}
                value={salesOrderForm.orderNumber}
                onChange={handleSalesControlChange}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Order Date *</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={salesOrderForm?.orderDate}
                  format="DD/MM/YYYY"
                  name="orderDate"
                  onChange={(e) => {
                    setSalesOrderForm({
                      ...salesOrderForm,
                      orderDate: dayjs(e),
                    });
                    dispatch(
                      setSalesByIdData({
                        ...salesOrderForm,
                        orderDate: e?.toISOString(),
                      })
                    );
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      error: !salesOrderForm?.orderDate?.isValid() && salesOrderForm?.submitted,
                      // helperText: !salesOrderForm?.orderDate?.isValid() && salesOrderForm?.submitted ? 'Order Date is required' : '',
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} md={6} xs={12} className="lbl-margin-top">
              <CustomAutocomplete
                value={salesOrderForm.lvPaymentTerms}
                onChange={(event, newValue) => {
                  const updatePaymentTerms = {
                    ...salesOrderForm,
                    paymentTerms: newValue?.value,
                    lvPaymentTerms: { value: newValue?.value, label: newValue?.label },
                  };
                  setSalesOrderForm(updatePaymentTerms);
                  dispatch(setSalesByIdData(updatePaymentTerms));
                }}
                options={PaymentTermsTypes}
                inputLabel="Payment Terms"
                disableAddNew={true}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="paymentRemarks"
                multiline
                rows={2}
                name="paymentRemarks"
                label="Payment Remarks"
                variant="outlined"
                value={salesOrderForm.paymentRemarks}
                onChange={handleSalesControlChange}
              />
            </Grid>
          </Grid>
          <hr className="mt-4" />
          <Grid container spacing={2} className="mt-1">
            <Grid item md={6} sm={12} xs={12}>
              <Typography className="label">Shipment Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={salesOrderForm.shipmentDate}
                  format="DD/MM/YYYY"
                  name="orderDate"
                  onChange={(e) => {
                    setSalesOrderForm({
                      ...salesOrderForm,
                      shipmentDate: dayjs(e),
                    });
                    dispatch(
                      setSalesByIdData({
                        ...salesOrderForm,
                        shipmentDate: e?.toISOString(),
                      })
                    );
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      //error: !salesOrderForm?.shipmentDate?.isValid() && salesOrderForm?.submitted,
                      // helperText: !salesOrderForm?.shipmentDate?.isValid() && salesOrderForm?.submitted ? 'Shipment Date is required' : '',
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={12} md={6} xs={12} className="lbl-margin-top">
              <CustomAutocomplete
                value={salesOrderForm.lvDeliveryNote}
                onChange={(event, newValue) => {
                  if (newValue?.label?.includes('Add')) {
                    setShowDeliveryNoteModal(true);
                  } else {
                    const updateDeliveryNotes = {
                      ...salesOrderForm,
                      deliveryNoteId: newValue?.value,
                      lvDeliveryNote: { value: newValue?.value, label: newValue?.label },
                    };
                    setSalesOrderForm(updateDeliveryNotes);
                    dispatch(setSalesByIdData(updateDeliveryNotes));
                  }
                }}
                options={deliveryNotesOptions}
                inputLabel="Delivery Method"
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
              />
            </Grid>
          </Grid>
          <hr className="mt-4" />
          <Grid container spacing={2} className="mt-2">
            <Grid item md={12} sm={12} xs={12}>
              <TextField id="remarks" name="remarks" label="Remarks" variant="outlined" multiline rows={2} value={salesOrderForm.remarks} onChange={handleSalesControlChange} />
            </Grid>
          </Grid>
          <div className="mt-4 d-flex">
            <Button size="small" variant="contained" onClick={handleSaveOrder} disabled={orderSubmitLoading || [OrderStatusValue.Completed, OrderStatusValue.Cancelled].includes(salesData?.orderStatus)}>
              Submit
            </Button>
            {orderSubmitLoading && <CircularProgress size={24} className="m-2" />}
            {salesData?.orderNumber ? (
              renderLastModifiedData(salesData?.lastModifiedByUser?.firstName, salesData?.lastModifiedByUser?.lastName, salesData?.lastModifiedAt)
            ) : (
              <div className="d-flex justify-content-center">
                <CircularProgress className="mx-2" size={15} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-grow-1 justify-content-center">
          <CircularProgress className="mx-2" size={15} />
        </div>
      )}
    </>
  );
};

import { Box, Chip, LinearProgress } from '@mui/material';
import React from 'react';
import { BootstrapTooltip } from './commonUtility';

const CardVisualization = ({ data, handleCardActive, activeCard, loading }) => {
  return (
    <>
      {loading ? (
        <Box sx={{ width: '80%' }}>
          <LinearProgress />
        </Box>
      ) : data?.length ? (
        data.map((item, index) => (
          <BootstrapTooltip key={index} title={`${item?.tooltip || item?.title}`}>
            <Chip
              className="mx-2 mt-2"
              onClick={() => {
                handleCardActive(item?.bindValue, item);
              }}
              label={`${item.title}  (${item.value})`}
              variant={item?.bindValue === activeCard ? '' : 'outlined'}
              color={item?.color ? item.color : 'primary'}
              clickable
            />
          </BootstrapTooltip>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default CardVisualization;

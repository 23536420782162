import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function BasicMenu({ menuItems, itemClicked }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const showMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="three-dot-menu" aria-haspopup="true" onClick={showMenuClick}>
        <MoreVertIcon />
      </IconButton>
      {menuItems?.length ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {menuItems?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                itemClicked(item.value);
              }}
            >
              <Typography className="label" style={{ color: 'black'}}> {item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </div>
  );
}

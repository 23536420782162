import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomAutocomplete from '../common/autocomplete';

const ShipmentDetailDialog = ({ shipmentDetailArray, itemIndex = 0, productItemOptions, isShippingItemModalOpen, handleCloseShipmentModal }) => {
  const defaultShippingItem = {
    disabledCheckbox: false,
    checkedItemIndex: '',
    productId: '',
    productId: '',
    quantity: 0,
  };
  const [lstShippingItems, setLstShippingItems] = useState([defaultShippingItem]);

  useEffect(() => {
    setLstShippingItems(shipmentDetailArray[itemIndex]?.items);
    console.log('shipping items', lstShippingItems);
    console.log('shipping items length', lstShippingItems?.length);
  }, [isShippingItemModalOpen]);

  const handleMaterialItemChange = (newValue, index) => {
    if (newValue?.formattedObject) {
      const formattedObject = newValue?.formattedObject;
      let updatedProducts = lstShippingItems?.map((product, i) => {
        if (i !== index) return product;
        return {
          productId: formattedObject?.productId || '',
          productId: {
            label: formattedObject?.selectedLabelForSales || '',
            value: formattedObject?.productId || '',
          },
          stockOnHand: formattedObject?.stockOnHand,
          quantity: formattedObject?.stockOnHand,
          unit: formattedObject?.unit,
          rate: formattedObject?.price,
          amount: parseFloat(formattedObject?.price) * parseFloat(formattedObject?.stockOnHand),
        };
      });
      setLstShippingItems(updatedProducts);
    } else {
      lstShippingItems[index] = defaultShippingItem;
    }
  };

  const handleMaterialProductChange = async (e, index) => {
    let { name, value } = e.target;
    value = Math.max(0, Number(e.target.value));
    let updatedProducts = lstShippingItems?.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: value };
      return product;
    });
    setLstShippingItems(updatedProducts);
  };

  const getTotalQtyFilled = (itemId) => {
    const shipmentArrayForOtherIndex = shipmentDetailArray?.filter((spd, spdIndex) => spdIndex !== itemIndex);
    return shipmentArrayForOtherIndex
      ?.map((spf) => spf?.items)
      ?.flat()
      ?.filter((ft) => ft?.productId === itemId)
      ?.reduce((sum, record) => sum + (parseInt(record.quantity) || 0), 0);
  };

  const getTotalQtyPerItem = (itemId, orderedQuantity = 0, rowQuantity = 0) => {
    let shipFlattenArray = [];
    let toalQuantity = 0;
    let totalQuantityOtherIndex = 0;
    let message = '';
    if (shipmentDetailArray?.length) {
      shipFlattenArray = shipmentDetailArray?.map((b, bIndex) => b.items?.map((c) => ({ ...c }))).flat() || [];
      totalQuantityOtherIndex = getTotalQtyFilled(itemId) || 0;

      toalQuantity = totalQuantityOtherIndex + lstShippingItems?.filter((ft, ftIndex) => ft?.productId === itemId)?.reduce((sum, record) => sum + parseInt(record.quantity), 0) || 0;
    }
    const userQuantity = toalQuantity - parseInt(orderedQuantity);
    if (userQuantity > 0) {
      message = `User's trying to deliver more quantity than expected.`;
      // if (toalQuantity === rowQuantity && toalQuantity - rowQuantity !== 0) {
      //   message = `User's trying to deliver more quantity than expected. It only requires ${userQuantity} to deliver`;
      // } else if (toalQuantity - rowQuantity === 0) {
      //   message = `User's trying to deliver more quantity than expected. It only requires ${orderedQuantity} to deliver`;
      // } else if (toalQuantity !== rowQuantity) {
      //   message = `User's trying to deliver more quantity than expected.`;
      // }
    }
    return message;
  };

  return (
    <Dialog
      open={isShippingItemModalOpen}
      PaperProps={{
        style: {
          height: '70vh', // Set the maximum height
          minWidth: '70vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom className="m-0 p-0">
            Shipping Items
          </Typography>
          <CloseIcon
            className="cursor-pointer ml-2"
            onClick={() => {
              handleCloseShipmentModal('', 'close');
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent className="pt-2 adj-container">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="ps-2" width="30%">
                      Item Name
                    </TableCell>
                    <TableCell align="right" width="10%">
                      Ordered Qty
                    </TableCell>
                    <TableCell align="right" width="20%">
                      Pending Qty
                    </TableCell>
                    <TableCell align="right" width="25%">
                      Delivery Qty
                    </TableCell>
                    <TableCell align="center" width="3%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lstShippingItems?.length
                    ? lstShippingItems?.map((row, spIndex) => (
                        <TableRow key={spIndex}>
                          <TableCell width="40%">
                            <div className="pt-3 pb-1 ps-2">
                              <CustomAutocomplete
                                value={row?.lvProductId || ''}
                                onChange={(e, newValue) => handleMaterialItemChange(newValue, spIndex)}
                                disabledValues={lstShippingItems?.map((pitem) => pitem?.itemId)?.filter((dis) => dis !== row?.itemId)}
                                disabled={true}
                                options={productItemOptions || []}
                                placeholder="Search..."
                                getOptionLabel={(option) => option.label || ''}
                                isError={!row?.lvProductId?.value}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right" width="10%">
                            {row?.orderedQty} {row?.unit}
                          </TableCell>
                          <TableCell align="right" width="20%">
                            {row?.orderedQty - getTotalQtyFilled(row?.lvProductId?.value)}
                          </TableCell>
                          <TableCell align="right" width="17%" className="position-relative">
                            <div className="pt-3 pb-1 ps-3 d-flex">
                              <TextField
                                type="number"
                                id="quantity"
                                value={row?.quantity}
                                name="quantity"
                                variant="outlined"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    style: { textAlign: 'right' },
                                  },
                                }}
                                onChange={(e) => {
                                  handleMaterialProductChange(e, spIndex);
                                }}
                              />
                              <span className="m-2">{row?.unit}</span>
                            </div>
                            {row?.quantity > 0 ? (
                              <div className="error-red fs-12 ms-4" style={{ textAlign: 'left' }}>
                                {getTotalQtyPerItem(row?.lvProductId?.value, parseInt(row?.orderedQty), parseInt(row?.quantity))}
                              </div>
                            ) : null}
                          </TableCell>

                          <TableCell align="center" width="3%">
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="contained" onClick={() => handleCloseShipmentModal(lstShippingItems)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShipmentDetailDialog;

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Box, Button, Chip, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_LOCATION_ENDPOINT, API_OPERATIONS_ENDPOINT, API_PRODUCTS_ENDPOINT, API_STOCK_ALLOCATION_ENDPOINT, API_USERS_ENDPOINT } from '../../../constants/constants';
import { getAllocationKebabMenuActions, getAllocationStatus, ManageAllocationChips, saKebabKeys } from '../../common-constants/static-values';
import api from '../../common/api';
import CustomAutocomplete from '../../common/autocomplete';
import CustomBreadCrumb from '../../common/breadcrumb';
import { BootstrapTooltip, formatDate, formatEntityLabelValue, formatProduct, formatProductForSales, getBadgeVariant, renderLastModifiedData, stringAvatar } from '../../common/commonUtility';
import { StockAllocationByLocation } from '../../modals/stock-allocation-by-location';
import { StockAllocationForProcure } from '../../modals/stock-allocation-procure';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import { Messages } from '../../common-constants/messages';
import BasicMenu from '../../common/menu';
import { CreateItemModal } from '../../modals/create-item';
import { closeItemModal, openItemModal } from '../../redux/actions/sales-action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { StockAllocationForProduce } from '../../modals/stock-allocation-produce';
import { useToken } from '../../auth/useToken';
import axios from 'axios';
import dayjs from 'dayjs';
export const ManageStockAllocation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { allocationId } = useParams();
  const navigate = useNavigate();
  const { newItemModalData, itemData } = useSelector((state) => state?.sales?.data);
  const stockAllocationEndpoint = API_STOCK_ALLOCATION_ENDPOINT;
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const locationEndpoint = API_LOCATION_ENDPOINT;
  let isStockFormValid = true;
  let formatedProductsOptions = [];
  let formatBOMOptions = [];
  let formatRoutingOptions = [];
  let formatLocationOptions = [];
  let formatUsersOptions = [];
  const [saKebabArray, setSAKebabArray] = useState([]);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Stock Allocation',
      href: '/stockAllocation',
    },
    {
      name: 'Manage Stock Allocation',
      active: true,
    },
  ];

  const fetchAndHandleItem = async () => {
    if (itemData?.productId) {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setItemOptions(formatedProductsOptions);
      const product = formatedProductsOptions?.find((item) => item?.value === itemData?.productId);
      handleItemChange(product, newItemModalData?.index);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${itemData?.productName}' ${Messages.itemAdded}`,
      });
    }
  };

  useEffect(() => {
    fetchAndHandleItem();
    if (!newItemModalData?.isItemModalOpen) {
      dispatch(closeItemModal());
    }
  }, [newItemModalData?.isItemModalOpen, itemData]);
  const [tableLoader, setTableLoader] = useState(false);
  const [stockAllocation, setStockAllocation] = useState({});
  const [itemOptions, setItemOptions] = useState([]);
  const [operationForm, setOperationForm] = useState([]);
  const [showAllocationModal, setShowAllocationModal] = useState(false);
  const [manageAllocationIndex, setManageAllocationIndex] = useState(0);
  const [currentManageAllocationKey, setCurrentManageAllocationKey] = useState('');
  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isValidCompleteStockBtn, setIsValidCompleteStockBtn] = useState(false);
  const [bomOptions, setBomOptions] = useState([]);
  const [routingOptions, setRoutingOptions] = useState([]);

  const [userOptions, setUserOptions] = useState();
  const [locationByItemOptions, setLocationByItemOptions] = useState([]);

  useEffect(() => {
    fetchStockAllocationById();
  }, []);

  useEffect(() => {
    operationForm?.map((op, opIndex) => {
      stockAllocation.stockAllocations[opIndex] = {
        ...stockAllocation.stockAllocations[opIndex],
        totalQty: totalQtyByIndex(op),
      };
    });
    validateStockAllocation();
  }, [operationForm]);

  useEffect(() => {
    validateStockAllocation();
  }, [stockAllocation]);

  const fetchItems = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setItemOptions(formatedProductsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchBOM = async () => {
    const response = await api.get(`${operationsEndpoint}/bom?pageNumber=1&pageSize=100`);
    formatBOMOptions = formatEntityLabelValue(response?.data?.boms, 'name', 'id') || [];
    setBomOptions(formatBOMOptions);
  };

  const fetchRouting = async () => {
    const response = await api.get(`${operationsEndpoint}/routing?pageNumber=1&pageSize=100`);
    formatRoutingOptions = formatEntityLabelValue(response?.data?.routings, 'routingName', 'routingId') || [];
    setRoutingOptions(formatRoutingOptions);
  };

  const fetchLocationByItem = async (itemId) => {
    const response = await api.get(`${locationEndpoint}?pageNumber=1&pageSize=100`);
    formatLocationOptions = formatEntityLabelValue(response?.data?.locations, 'locationName', 'locationId') || [];
    setLocationByItemOptions(formatLocationOptions);
  };

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchUsers = async () => {
    try {
      const uri = `${API_USERS_ENDPOINT}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      formatUsersOptions = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(formatUsersOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const validateStockAllocation = () => {
    isStockFormValid = true;
    stockAllocation?.stockAllocations?.map((op) => {
      if (!op.totalQty) {
        isStockFormValid = false;
        return false;
      }
      if (op.quantity > op.totalQty) {
        isStockFormValid = false;
        return false;
      }
    });
    setIsValidCompleteStockBtn(isStockFormValid);
  };

  const handleItemChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openItemModal(index));
    } else if (newValue?.formattedObject) {
      stockAllocation?.stockAllocations?.forEach((sa, i) => {
        if (i !== index) return sa;
        if (newValue) {
          const formattedObject = newValue?.formattedObject;
          sa.itemId = formattedObject?.productId;
          sa.lvItemId = {
            label: formattedObject?.selectedLabelForSales || '',
            value: formattedObject?.productId || '',
          };
          if (sa.product) {
            sa.product.unit = formattedObject?.unit || '';
          }
        }
      });
      setOperationForm(stockAllocation?.stockAllocations);
    }
  };

  const fetchStockAllocationById = async () => {
    setTableLoader(true);
    try {
      const response = await api.get(`${stockAllocationEndpoint}/${allocationId}`);
      await fetchItems();
      await fetchBOM();
      await fetchRouting();
      await fetchLocationByItem();
      await fetchUsers();
      const stockAllocations = response.data?.stockAllocations?.map((item, saIndex) => {
        let kebabArray = [];
        const findSelectedItem = formatedProductsOptions?.find((fi) => fi?.value === item?.itemId);
        if (item.hasOwnProperty(saKebabKeys.workOrderId)) {
          kebabArray.push({ label: 'Go To Work Order', value: saKebabKeys.workOrderId });
        }
        if (item.hasOwnProperty(saKebabKeys.packagingOrderId)) {
          kebabArray.push({ label: 'Go To Packaging Order', value: saKebabKeys.packagingOrderId });
        }
        if (item.hasOwnProperty(saKebabKeys.qualityOrderId)) {
          kebabArray.push({ label: 'Go To Quality Order', value: saKebabKeys.qualityOrderId });
        }
        return {
          ...item,
          lvItemId: {
            label: findSelectedItem?.formattedObject?.selectedLabelForSales,
            value: item?.itemId,
          },
          pick: { locations: item?.locations || [] },
          produce: {
            ...item.produce,
            lvBomId: formatBOMOptions?.find((fb) => fb.value === item?.produce?.bomId),
            lvRoutingId: formatRoutingOptions?.find((fb) => fb.value === item?.produce?.routingId),
            lvAssignedTo: formatUsersOptions?.find((fu) => fu.value === item?.produce?.assignedTo),
            lvLocationId: formatLocationOptions?.find((fu) => fu.value === item?.produce?.locationId),
            plannedStartDate: item?.produce?.plannedStartDate ? dayjs(item?.produce?.plannedStartDate) : null,
            plannedEndDate: item?.produce?.plannedEndDate ? dayjs(item?.produce?.plannedEndDate) : null,
          },
          actionArray: kebabArray,
        };
      });
      if (response.data?.stockAllocations?.length) {
        response.data.stockAllocations = stockAllocations;
        setOperationForm(stockAllocations);
      }
      setStockAllocation(response?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const pendingStatusTooltip = (
    <Box
      sx={{
        '& ul': { margin: 0, paddingLeft: 2, paddingBottom: 2 },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      }}
    >
      <Typography className="p-2 fs-14">Stock Allocation status will be changed to 'In Progress' when the ordered quantity for all items has been fulfilled.</Typography>
    </Box>
  );

  const submitTooltip = (
    <Box
      sx={{
        '& ul': { margin: 0, paddingLeft: 2, paddingBottom: 2 },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      }}
    >
      <Typography className="p-2 fs-14">User can submit stock allocation when the ordered quantities are fullfilled for all the items.</Typography>
    </Box>
  );

  const handleAllocationChipClick = (operation, index, row) => {
    setShowAllocationModal(true);
    if (operation.value === 'pick' && !operationForm[index]?.hasOwnProperty(operation.value)) {
      operationForm[index] = {
        ...operationForm[index],
        [operation.value]: {
          allocationTitle: operation.label,
          itemId: row?.itemId,
          locations: operationForm[index]?.locations,
        },
      };
    }
    if (['procure', 'produce'].includes(operation.value) && !operationForm[index]?.hasOwnProperty(operation.value)) {
      operationForm[index] = {
        ...operationForm[index],
        [operation.value]: {
          allocationTitle: operation.label,
          itemId: operationForm[index]?.itemId,
          quantity: operationForm[index]?.quantity || 0,
          notes: operationForm[index]?.notes || '',
        },
      };
    }
    setCurrentManageAllocationKey(operation.value);
    setManageAllocationIndex(index);
  };

  const handleCloseAllocationModal = (formData, type) => {
    if (formData && manageAllocationIndex >= 0) {
      const updateFormData = type !== 'close' ? { ...formData } : null;
      let updatedOp = operationForm?.map((operation, mapIndex) => {
        if (mapIndex !== manageAllocationIndex) return operation;

        const updatedOperation = { ...operation };

        if (!updatedOperation?.hasOwnProperty(currentManageAllocationKey)) {
          updatedOperation[currentManageAllocationKey] = { ...updateFormData };
        } else {
          updatedOperation[currentManageAllocationKey] = {
            ...updatedOperation[currentManageAllocationKey],
            ...updateFormData,
          };
        }
        return updatedOperation;
      });
      setOperationForm([...updatedOp]);
    }

    setShowAllocationModal(false);
  };

  const totalQtyByIndex = (formData) => {
    let total = 0;
    if (formData?.hasOwnProperty('procure')) {
      //  total += manageAllocationQty(formData?.Procure?.locations);
      total += Number(formData?.procure?.quantity);
    }
    if (formData?.hasOwnProperty('produce')) {
      // total += manageAllocationQty(formData?.Produce?.locations);
      total += Number(formData?.produce?.quantity);
    }
    if (formData?.hasOwnProperty('pick')) {
      total += manageAllocationQty(formData?.pick?.locations);
    }
    return total;
  };

  const manageAllocationQty = (locations) => {
    return (
      locations?.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.quantity;
      }, 0) || 0
    );
  };

  const getManageAllocationChips = (itemIndex, row) => {
    return ManageAllocationChips?.map((item, opIndex) => {
      let managedQty = 0;
      if (item.value === 'procure') {
        //  total += manageAllocationQty(formData?.Procure?.locations);
        managedQty = Number(operationForm[itemIndex]?.procure?.quantity || 0);
      }
      if (item.value === 'produce') {
        // total += manageAllocationQty(formData?.Produce?.locations);
        managedQty = Number(operationForm[itemIndex]?.produce?.quantity || 0);
      }
      if (item.value === 'pick') {
        managedQty = manageAllocationQty(operationForm[itemIndex]?.pick?.locations);
      }
      // const managedQty = operationForm[itemIndex] ? manageAllocationQty(operationForm[itemIndex][item.value]?.locations) : 0;
      return (
        <>
          <BootstrapTooltip title={managedQty > 0 ? 'Update' : 'Add'}>
            <Chip
              key={opIndex}
              className={`mx-2 mt-2`}
              onClick={() => {
                handleAllocationChipClick(item, itemIndex, row);
              }}
              label={`${item.label} (${managedQty} ${row?.product?.unit || ''})`}
              variant={managedQty > 0 ? '' : 'outlined'}
              color="primary"
              clickable
            />
          </BootstrapTooltip>
        </>
      );
    });
  };

  const saveStockAllocation = async (isSubmit) => {
    stockAllocation?.stockAllocations?.forEach((item, index) => {
      item.procure = operationForm[index]?.procure || { quantity: 0, notes: '' };
      item.produce = operationForm[index]?.produce || { quantity: 0, notes: '' };
      item.locations = operationForm[index]?.pick?.locations || [];
    });
    if (!isSubmit) {
      setSaveLoader(true);
    } else {
      setSubmitLoader(true);
    }
    const response = await api.put(`${stockAllocationEndpoint}/${allocationId}`, stockAllocation);

    if (!response?.error) {
      if (!isSubmit) {
        setSaveLoader(false);
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.stockAllocationSaved}`,
        });
      } else {
        submitAllocation();
      }
    }
  };

  const submitAllocation = async () => {
    const requestObj = {
      newStatus: 2,
    };
    const response = await api.put(`${stockAllocationEndpoint}/${allocationId}/status`, requestObj);
    if (!response?.error) {
      await fetchItems();
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.stockAllocationSubmitted}`,
      });
      fetchStockAllocationById();
    }
    setSubmitLoader(false);
  };

  const handleMenuItemClicked = async (event) => {
    //  const handleMenuItemClicked = async (event, item) => {
    if (event !== 2) {
      try {
        const requestObj = {
          newStatus: event,
        };
        const response = await api.put(`${stockAllocationEndpoint}/${allocationId}/status`, requestObj);
        if (!response?.error) {
          fetchStockAllocationById();
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${Messages.stockAllocationStatusUpdate}`,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      navigate(`/sales/${stockAllocation?.salesOrderId}`);
    }
    // };
  };

  const handleTableKebabClick = (event, index) => {
    switch (event) {
      case saKebabKeys.workOrderId:
        navigate(`/workorders/${operationForm[index]?.workOrderId}`);
        break;
      case saKebabKeys.packagingOrderId:
        navigate(`/packaging/${operationForm[index]?.packagingOrderId}`);
        break;
      case saKebabKeys.qualityOrderId:
        break;
    }
  };

  return (
    <>
      <CreateItemModal />
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <StockAllocationByLocation
        showAllocationModal={showAllocationModal && currentManageAllocationKey === 'pick'}
        handleCloseAllocationModal={handleCloseAllocationModal}
        allocationData={operationForm[manageAllocationIndex]}
        currentKey={currentManageAllocationKey}
        allocationStatus={stockAllocation?.stockAllocationStatus}
      />
      <StockAllocationForProcure
        showAllocationModal={showAllocationModal && currentManageAllocationKey === 'procure'}
        handleCloseAllocationModal={handleCloseAllocationModal}
        allocationData={operationForm[manageAllocationIndex]}
        currentKey={currentManageAllocationKey}
        allocationStatus={stockAllocation?.stockAllocationStatus}
      />
      <StockAllocationForProduce
        showAllocationModal={showAllocationModal && currentManageAllocationKey === 'produce'}
        handleCloseAllocationModal={handleCloseAllocationModal}
        bomOptions={bomOptions}
        routingOptions={routingOptions}
        allocationData={operationForm[manageAllocationIndex]}
        currentKey={currentManageAllocationKey}
        allocationStatus={stockAllocation?.stockAllocationStatus}
        locationByItemOptions={locationByItemOptions}
        userOptions={userOptions}
      />
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <Grid container>
              <Grid items sm={12} md={12} xs={12}>
                {/* <Typography className="mt-2 mb-0" variant="h6" gutterBottom>
                  Stock Allocation
                </Typography> */}
                <Typography variant="body1" gutterBottom>
                  Users can manage stock allocation using three options: Produce, Produce, and Pick to fulfill the ordered quantity.
                </Typography>
              </Grid>
            </Grid>
            {stockAllocation?.orderReferenceNumber ? (
              <div className="d-flex mt-2">
                <div className="d-flex flex-column">
                  <Typography variant="h6" className="d-flex mb-0" gutterBottom>
                    {stockAllocation?.orderReferenceNumber}
                  </Typography>
                  <div className="fs-14">
                    <BootstrapTooltip className="cursor-pointer" title="Account Reference Number">
                      {stockAllocation?.accountReferenceNumber}
                    </BootstrapTooltip>
                  </div>
                </div>
                <span className="mx-2">{getBadgeVariant(getAllocationStatus(stockAllocation?.stockAllocationStatus))}</span>
                {stockAllocation?.stockAllocationStatus === 'Pending' ? (
                  <BootstrapTooltip title={pendingStatusTooltip}>
                    <InfoOutlinedIcon className="me-2 mt-1 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
                  </BootstrapTooltip>
                ) : null}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <CircularProgress className="mx-2" size={15} />{' '}
              </div>
            )}
          </div>
          <div className="d-flex">
            <BasicMenu menuItems={getAllocationKebabMenuActions(stockAllocation?.stockAllocationStatus)} itemClicked={(e) => handleMenuItemClicked(e)} />
            <CloseIcon
              className="cursor-pointer mx-2 mt-2"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>
        <Grid container className="mt-4">
          <Grid items sm={12} md={12} xs={12}>
            <TableContainer className="mt-2" size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="ps-2" width="30%">
                      Ordered Item
                    </TableCell>
                    <TableCell align="right" width="10%">
                      Ordered Quantity
                    </TableCell>
                    <TableCell align="center" width="40%">
                      Manage Allocation
                    </TableCell>
                    <TableCell align="right" width="10%">
                      Filled Quantity
                    </TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!tableLoader ? (
                    stockAllocation?.stockAllocations?.map((row, itemIndex) => (
                      <TableRow key={itemIndex}>
                        {!row?.itemName ? (
                          <TableCell width="30%">
                            <BootstrapTooltip title={row?.lvItemId?.label}>
                              {row?.lvItemId?.label}
                              {/* <CustomAutocomplete disabled={true} value={row?.lvItemId || ''} options={itemOptions || []} placeholder="Search..." getOptionLabel={(option) => option.label || ''} /> */}
                            </BootstrapTooltip>
                          </TableCell>
                        ) : (
                          <Grid container spacing={2} className="p-2">
                            <Grid item xs={12} sm={4}>
                              <TextField value={row?.itemName} name="itemName" variant="outlined" disabled={true} />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <CustomAutocomplete
                                value={row?.lvItemId || ''}
                                options={itemOptions || []}
                                placeholder="Search..."
                                disabled={!['Pending', 'In Progress'].includes(stockAllocation?.stockAllocationStatus)}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(e, newValue) => handleItemChange(newValue, itemIndex)}
                              />
                            </Grid>
                          </Grid>
                        )}
                        <TableCell align="right" width="10%" className="position-relative">
                          {row?.quantity} {row?.product?.unit}
                        </TableCell>
                        <TableCell width="40%">
                          <div className="d-flex justify-content-center">{getManageAllocationChips(itemIndex, row)}</div>
                        </TableCell>
                        <TableCell align="right" width="10%">
                          <span className={row.quantity <= row.totalQty ? 'delta-success' : 'delta-error'}>
                            {row?.totalQty || 0} {row?.product?.unit || ''}
                          </span>
                        </TableCell>
                        <TableCell align="right" width="5%">
                          {row?.actionArray?.length ? <BasicMenu menuItems={row?.actionArray} itemClicked={(e) => handleTableKebabClick(e, itemIndex)} /> : null}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: 'center', fontSize: '13px', fontWeight: 500, padding: '20px' }}>
                        <span>
                          <CircularProgress size={20} />
                        </span>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid items>
            <div className="d-flex justify-content-start mt-3">
              <Button size="small" variant="contained" onClick={() => saveStockAllocation(false)} disabled={saveLoader || ['Allocated', 'Cancelled'].includes(stockAllocation?.stockAllocationStatus)}>
                Save
              </Button>
              {saveLoader && <CircularProgress size={18} className="ms-2 mt-2" />}

              <Button
                size="small"
                variant="contained"
                className="ms-3"
                onClick={() => saveStockAllocation(true)}
                disabled={!isValidCompleteStockBtn || ['Cancelled', 'Allocated'].includes(stockAllocation?.stockAllocationStatus) || submitLoader}
              >
                Submit
              </Button>
              {submitLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
              <BootstrapTooltip title={submitTooltip}>
                <InfoOutlinedIcon className="me-2 mt-1 ms-2 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
              </BootstrapTooltip>
              {stockAllocation?.orderReferenceNumber ? (
                renderLastModifiedData(stockAllocation?.user?.firstName, stockAllocation?.user?.lastName, stockAllocation?.lastModifiedTime)
              ) : (
                <div className="d-flex justify-content-center">
                  <CircularProgress className="mx-2" size={15} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, API_IDS_BASE_URL, API_USERS_ENDPOINT, TOKEN_EMAIL_CLAIMS } from '../../../../../constants/constants';
import { useToken } from '../../../../auth/useToken';
import { useUser } from '../../../../auth/useUser';
import { Messages } from '../../../../common-constants/messages';
import { AccountCategories, AccountStatuses, AccountTypes, EntityTypes, IndustryTypes } from '../../../../common-constants/static-values';
import { AlertStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { formatEntityLabelValue, generateEntityNumber } from '../../../../common/commonUtility';
import InputFileUpload from '../../../../common/upload';
import PrimaryContactModal from '../../../../modals/primary-contact-modal';
import { setAccountDetailsData, setAccountSinceFieldData, setAccountUsersData, setPrimaryContactsData } from '../../../../redux/actions/account-action';
import axios from 'axios';

export const AccountDetail = React.memo(() => {
  const idsEndpoint = API_IDS_BASE_URL;
  const usersEndpoint = API_USERS_ENDPOINT;
  const baseEndpoint = API_BASE_URL;
  const user = useUser();
  const { stateUserOptions, stateAccountDetailForm, stateAccountSinceField, stateFormSubmitted, statePrimaryContactsOptions, isAccountModalOpen } = useSelector((state) => state?.account?.data);
  const dispatch = useDispatch();
  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const defaultAccoutForm = {
    name: '',
    referenceNumber: generateEntityNumber('ACT'),
    priceList: '',
    accountOwner: '',
    lvAccountOwner: '',
    tenantType: '',
    lvTenantType: '',
    primaryContact: null,
    lvPrimaryContact: '',
    entityType: '',
    lvEntityType: '',
    industry: '',
    lvIndustry: '',
    accountCategory: 0,
    lvAccountCategory: '',
    accountStatus: 1,
    lvAccountStatus: { value: 1, label: 'Active' },
    taxRegistrationNumber: '',
    attachment: '',
  };
  const [accountDetailForm, setAccountDetailForm] = useState(defaultAccoutForm);
  const [showPrimaryContactModal, setShowPrimaryContactModal] = useState(false);
  const [accountSinceField, setAccountSinceField] = useState({
    accountSince: null,
  });
  const [userOptions, setUserOptions] = useState([]);
  const [primaryContactOptions, setPrimaryContactOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    if (!statePrimaryContactsOptions?.length) {
      fetchPrimaryContact();
    } else {
      setPrimaryContactOptions(statePrimaryContactsOptions);
    }
    if (stateAccountSinceField) {
      setAccountSinceField({
        accountSince: stateAccountSinceField ? dayjs(stateAccountSinceField) : null,
      });
    }
    if (!stateUserOptions?.length) {
      fetchUsers();
    } else {
      setUserOptions(stateUserOptions);
    }
  }, [stateAccountDetailForm, accountDetailForm, stateAccountSinceField, stateUserOptions]);

  useEffect(() => {
    let prefillAccountDetailForm = {};
    let selectedUser = {};
    let customerType = {};
    if (stateAccountDetailForm) {
      prefillAccountDetailForm = stateAccountDetailForm;
    } else {
      prefillAccountDetailForm = defaultAccoutForm;
    }
    if (!prefillAccountDetailForm?.accountOwner) {
      selectedUser = bindDefaultAccountOwner(stateUserOptions);
    }
    if (isAccountModalOpen && !prefillAccountDetailForm?.tenantType) {
      customerType = AccountTypes.find((ac) => ac.label?.toLowerCase() === 'customer');
    }
    prefillAccountDetailForm = {
      ...prefillAccountDetailForm,
      accountOwner: !prefillAccountDetailForm?.accountOwner ? selectedUser?.accountOwner : prefillAccountDetailForm?.accountOwner,
      lvAccountOwner: !prefillAccountDetailForm?.accountOwner ? selectedUser?.lvAccountOwner : prefillAccountDetailForm?.lvAccountOwner,
      tenantType: !prefillAccountDetailForm?.tenantType ? customerType?.value : prefillAccountDetailForm?.tenantType,
      lvTenantType: !prefillAccountDetailForm?.tenantType ? customerType : prefillAccountDetailForm?.lvTenantType,
    };
    setAccountDetailForm(prefillAccountDetailForm);
    dispatch(setAccountDetailsData(prefillAccountDetailForm));
  }, [stateUserOptions, isAccountModalOpen]);

  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setAccountDetailForm({
      ...accountDetailForm,
      [name]: value,
    });
    dispatch(
      setAccountDetailsData({
        ...accountDetailForm,
        [name]: value,
      })
    );
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${usersEndpoint}`, config);
      if (response?.data?.users?.length) {
        response.data = response?.data?.users?.map((item) => ({
          ...item,
          fullName: `${item.firstName} ${item.lastName}`,
        }));
        const userData = response?.data?.map((ac) => ({
          label: ac?.fullName,
          value: ac?.id,
          emailAddress: ac?.emailAddress,
        }));
        setUserOptions(userData);
        dispatch(setAccountUsersData(userData));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const bindDefaultAccountOwner = (usersData) => {
    console.log('bindDefaultAccountOwner', usersData);
    const selectedUser = usersData?.find((co) => co.emailAddress === user[TOKEN_EMAIL_CLAIMS]);
    let updateUser = {};
    if (selectedUser) {
      updateUser = {
        accountOwner: selectedUser?.value,
        lvAccountOwner: { label: selectedUser?.label, value: selectedUser?.value },
      };
    }
    return updateUser;
  };

  const fetchPrimaryContact = async (contactId) => {
    setLoading(true);
    try {
      const response = await api.get('/contacts?pageNumber=1&pageSize=100');
      if (response.data?.contacts?.length) {
        response.data.contacts = response.data.contacts?.map((co) => ({
          ...co,
          fullName: `${co.firstName} ${co.lastName}`,
        }));
        const primaryContactData = formatEntityLabelValue(response.data?.contacts, 'fullName', 'contactId');
        setPrimaryContactOptions(primaryContactData);
        dispatch(setPrimaryContactsData(primaryContactData));
        if (contactId) {
          const selectedPrimaryContact = primaryContactData.find((item) => item.value === contactId);
          setAccountDetailForm({
            ...accountDetailForm,
            primaryContact: selectedPrimaryContact?.value,
            lvPrimaryContact: selectedPrimaryContact,
          });
          dispatch(
            setAccountDetailsData({
              ...accountDetailForm,
              primaryContact: selectedPrimaryContact?.value,
              lvPrimaryContact: selectedPrimaryContact,
            })
          );
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const addPrimaryContact = () => {
    setShowPrimaryContactModal(true);
  };

  const handlePrimaryContactModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event && reason) {
      fetchPrimaryContact(event);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${reason}' ${Messages.primaryContactCreated}`,
      });
    }
    setShowPrimaryContactModal(false);
  };

  const catchLogoId = (event) => {
    if (event) {
      setAccountDetailForm({
        ...accountDetailForm,
        attachment: event,
      });
      dispatch(
        setAccountDetailsData({
          ...accountDetailForm,
          attachment: event,
        })
      );
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <PrimaryContactModal showModal={showPrimaryContactModal} handleClose={handlePrimaryContactModalClose} />
      <Grid container spacing={3} className={`mt-1 px-2 ${!accountDetailForm?.id ? 'tab-container' : ''}`}>
        <Grid item xs={12} md={6}>
          <TextField
            id="name"
            name="name"
            label="Account Name *"
            variant="outlined"
            error={!accountDetailForm.name?.trim() && stateFormSubmitted}
            helperText={!accountDetailForm.name?.trim() && stateFormSubmitted ? 'Account Name is required.' : ''}
            value={accountDetailForm.name}
            onChange={handleControlChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="referenceNumber"
            name="referenceNumber"
            label="Account Reference Number *"
            variant="outlined"
            error={!accountDetailForm.referenceNumber?.trim() && stateFormSubmitted}
            helperText={!accountDetailForm.referenceNumber?.trim() && stateFormSubmitted ? 'Account Reference Number is required.' : ''}
            value={accountDetailForm.referenceNumber}
            onChange={handleControlChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvAccountOwner}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                accountOwner: newValue?.value,
                lvAccountOwner: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  accountOwner: newValue?.value,
                  lvAccountOwner: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={userOptions}
            inputLabel="Account Owner *"
            disableAddNew={true}
            isError={!accountDetailForm?.lvAccountOwner?.value && stateFormSubmitted}
            helperText={!accountDetailForm?.lvAccountOwner?.value && stateFormSubmitted ? 'Account Owner is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvTenantType}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                tenantType: newValue?.value,
                lvTenantType: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  tenantType: newValue?.value,
                  lvTenantType: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={AccountTypes}
            inputLabel="Account Type *"
            disableAddNew={true}
            disabled={isAccountModalOpen || false}
            isError={!accountDetailForm?.lvTenantType?.value && stateFormSubmitted}
            helperText={!accountDetailForm?.lvTenantType?.value && stateFormSubmitted ? 'Account type is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvPrimaryContact}
            onChange={(event, newValue) => {
              if (newValue?.label.includes('Add')) {
                addPrimaryContact(newValue?.inputValue);
              } else {
                setAccountDetailForm({
                  ...accountDetailForm,
                  primaryContact: newValue?.value,
                  lvPrimaryContact: { value: newValue?.value, label: newValue?.label },
                });
                dispatch(
                  setAccountDetailsData({
                    ...accountDetailForm,
                    primaryContact: newValue?.value,
                    lvPrimaryContact: { value: newValue?.value, label: newValue?.label },
                  })
                );
              }
            }}
            options={primaryContactOptions}
            inputLabel="Primary Contact"
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
          {/* <TextField
            id="primaryContact"
            name="primaryContact"
            label="Phone *"
            variant="outlined"
            error={!accountDetailForm.primaryContact?.trim() && stateFormSubmitted}
            helperText={!accountDetailForm.primaryContact?.trim() && stateFormSubmitted ? 'Phone detail is required.' : ''}
            inputProps={{ maxLength: 10 }}
            value={accountDetailForm.primaryContact}
            onChange={handleControlChange}
          /> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvEntityType}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                entityType: newValue?.value,
                lvEntityType: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  entityType: newValue?.value,
                  lvEntityType: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={EntityTypes}
            inputLabel="Entity Type *"
            disableAddNew={true}
            isError={!accountDetailForm?.lvEntityType?.value && stateFormSubmitted}
            helperText={!accountDetailForm?.lvEntityType?.value && stateFormSubmitted ? 'Entity type is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvIndustry}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                industry: newValue?.value,
                lvIndustry: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  industry: newValue?.value,
                  lvIndustry: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={IndustryTypes}
            inputLabel="Industry"
            disableAddNew={true}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvAccountCategory}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                accountCategory: newValue?.value,
                lvAccountCategory: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  accountCategory: newValue?.value,
                  lvAccountCategory: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={AccountCategories}
            inputLabel="Account Categories"
            disableAddNew={true}
            // isError={!accountDetailForm?.lvAccountCategory?.value && stateFormSubmitted}
            // helperText={!accountDetailForm?.lvAccountCategory?.value && stateFormSubmitted ? 'Account Category is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={accountDetailForm.lvAccountStatus}
            onChange={(event, newValue) => {
              setAccountDetailForm({
                ...accountDetailForm,
                accountStatus: newValue?.value,
                lvAccountStatus: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setAccountDetailsData({
                  ...accountDetailForm,
                  accountStatus: newValue?.value,
                  lvAccountStatus: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={AccountStatuses}
            inputLabel="Account Status *"
            disableAddNew={true}
            isError={!accountDetailForm?.lvAccountStatus?.value && stateFormSubmitted}
            helperText={!accountDetailForm?.lvAccountStatus?.value && stateFormSubmitted ? 'Account Status is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField id="priceList" name="priceList" label="Price List" variant="outlined" value={accountDetailForm.priceList} onChange={handleControlChange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="label">Account Since</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={accountSinceField.accountSince}
              format="DD/MM/YYYY"
              name="accountSince"
              onChange={(e) => {
                setAccountSinceField(dayjs(e));
                dispatch(setAccountSinceFieldData(e?.toISOString()));
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  // error: !accountSinceField.accountSince && stateFormSubmitted,
                  //  helperText: !accountSinceField.accountSince && stateFormSubmitted ? 'Account since is required' : '',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} className="lbl-margin-top">
          <TextField
            id="taxRegistrationNumber"
            name="taxRegistrationNumber"
            label="Tax Registration Number"
            variant="outlined"
            value={accountDetailForm.taxRegistrationNumber}
            onChange={handleControlChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="label">Attachment</Typography>
          <InputFileUpload documentId={accountDetailForm?.attachment} emitLogoId={catchLogoId} />
        </Grid>
      </Grid>
    </>
  );
});

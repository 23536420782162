import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useUser } from '../../auth/useUser';
import { TOKEN_EMAIL_CLAIMS } from '../../../constants/constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InventoryIcon from '@mui/icons-material/Inventory';

const ResponsiveGridLayout = WidthProvider(Responsive);

// Define modern palette based on the logo and additional colors
const modernPalette = {
  lightBlue: '#56CCF2',
  mainBlue: '#2F80ED',
  green: '#1CB5E0',
  red: '#FF6F61',
};

export const Dashboard = () => {
  const [showAlert, setShowAlert] = useState(true);
  const user = useUser();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
      localStorage.setItem('showWelcomeAlert', 'false');
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseAlert = () => {
    setShowAlert(false);
    localStorage.setItem('showWelcomeAlert', 'false');
  };

  const saleOrdersData = [
    { date: '2023-01-01', orders: 10 },
    { date: '2023-01-02', orders: 15 },
    { date: '2023-01-03', orders: 8 },
    { date: '2023-01-04', orders: 12 },
  ];

  const revenueData = [
    { month: 'Jan', revenue: 5000 },
    { month: 'Feb', revenue: 7000 },
    { month: 'Mar', revenue: 6500 },
    { month: 'Apr', revenue: 8000 },
  ];

  const topSoldItems = [
    { id: 1, name: 'Pencil', quantity: 100 },
    { id: 2, name: 'Eraser', quantity: 85 },
    { id: 3, name: 'Geometry Box', quantity: 75 },
    { id: 4, name: 'Ruler', quantity: 60 },
    { id: 5, name: 'Pen', quantity: 50 },
  ];

  const lowStockItems = [
    { id: 1, name: 'Pencil', quantity: 5 },
    { id: 2, name: 'Eraser', quantity: 3 },
    { id: 3, name: 'Geometry Box', quantity: 2 },
    { id: 4, name: 'Ruler', quantity: 1 },
    { id: 5, name: 'Pen', quantity: 0 },
  ];

  const layout = [
    { i: 'accountPayable', x: 0, y: 0, w: 4, h: 1 },
    { i: 'accountReceivable', x: 4, y: 0, w: 4, h: 1 },
    { i: 'itemCount', x: 8, y: 0, w: 4, h: 1 },
    { i: 'topSoldItems', x: 0, y: 1, w: 4, h: 3 },
    { i: 'saleOrders', x: 6, y: 1, w: 8, h: 3 },
    { i: 'revenue', x: 0, y: 3, w: 6, h: 3 },
    { i: 'lowStockItems', x: 6, y: 3, w: 6, h: 3 },
  ];

  return (
    <Container maxWidth={false} sx={{ overflow: 'hidden', paddingTop: '10px' }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        rowHeight={80}
        margin={[8, 8]}
        containerPadding={[0, 0]}
      >
        <Card key="accountPayable" sx={{ p: 0.5, m: 0.5, bgcolor: modernPalette.red, color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AccountBalanceIcon sx={{ mb: 1 }} />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2"><b>Account Payable</b></Typography>
            <Typography variant="body1">$25,000</Typography>
          </CardContent>
        </Card>

        <Card key="accountReceivable" sx={{ p: 0.5, m: 0.5, bgcolor: modernPalette.green, color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AccountBalanceIcon sx={{ mb: 1 }} />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2"><b>Account Receivable</b></Typography>
            <Typography variant="body1">$35,000</Typography>
          </CardContent>
        </Card>

        <Card key="itemCount" sx={{ p: 0.5, m: 0.5, bgcolor: 'rgb(255, 152, 0)', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InventoryIcon sx={{ mb: 1 }} />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2"><b>Items</b></Typography>
            <Typography variant="body1">500</Typography>
          </CardContent>
        </Card>

        <Card key="topSoldItems" sx={{ p: 0.5, m: 0.5 }}>
          <CardContent>
            <Typography variant="body2" style={{color: modernPalette.green}}><b>Top 5 Sold Items</b></Typography>
            <Box sx={{ mt: 1 }}>
              {topSoldItems.map((item) => (
                <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="body2">{item.name}</Typography>
                  <Typography variant="body2">{item.quantity}</Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>

        <Card key="saleOrders" sx={{ p: 0.5, m: 0.5, bgcolor: '#FFF' }}>
          <CardContent>
            <Typography variant="body2"><b>Sale Orders by Date</b></Typography>
            <ResponsiveContainer width="100%" height={180}>
              <LineChart data={saleOrdersData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="orders" stroke={modernPalette.mainBlue} />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card key="revenue" sx={{ p: 0.5, m: 0.5, bgcolor: '#FFF' }}>
          <CardContent>
            <Typography variant="body2"><b>Revenue Over Time</b></Typography>
            <ResponsiveContainer width="100%" height={180}>
              <BarChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="revenue" fill={modernPalette.green} />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card key="lowStockItems" sx={{ p: 0.5, m: 0.5, bgcolor: '#FFF' }}>
          <CardContent>
            <Typography variant="body2" style={{color: modernPalette.red}}><b>Low Stock Items (Top 5)</b></Typography>
            <Box sx={{ mt: 1 }}>
              {lowStockItems.map((item) => (
                <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="body2">{item.name}</Typography>
                  <Typography variant="body2">{item.quantity}</Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </ResponsiveGridLayout>
    </Container>
  );
};

export default Dashboard;

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_ADJUSTMENTS_ENDPOINT, API_ADJUSTMENTS_REASON_ENDPOINT, API_PRODUCT_LOCATION_ENDPOINT } from '../../../../../constants/constants';
import { Messages } from '../../../../common-constants/messages';
import { getAdjustmentKebabMenuActions } from '../../../../common-constants/static-values';
import { AdjustmentStatusValue, AlertStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import CustomBreadCrumb from '../../../../common/breadcrumb';
import {
  BootstrapTooltip,
  formatDate,
  formatEntityLabelValue,
  formatProductLocation,
  generateEntityNumber,
  getBadgeVariant,
  renderLastModifiedData,
  setBadgeType,
  stringAvatar,
} from '../../../../common/commonUtility';
import BasicMenu from '../../../../common/menu';
import InputFileUpload from '../../../../common/upload';
import AdjustmentsOrderItems from '../adjustments-order-items/adjustments-order-items';

export const UpsertAdjustment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const INREASONURL = API_ADJUSTMENTS_REASON_ENDPOINT;
  const PRDOUCTLOCATIONURL = API_PRODUCT_LOCATION_ENDPOINT;
  const INADJURL = API_ADJUSTMENTS_ENDPOINT;
  const breadcrumbs = id
    ? [
        {
          name: 'Home',
          href: '/',
        },
        {
          name: 'Adjustments',
          href: '/adjustments',
        },
        {
          name: 'Adjustment Info',
          active: true,
        },
      ]
    : [
        {
          name: 'Home',
          href: '/',
        },
        {
          name: 'Adjustments',
          href: '/adjustments',
        },
        {
          name: 'New Adjustment',
          active: true,
        },
      ];
  const dummyItemRow = { productId: '', locationId: '', oldAvailableQuantity: '', adjustedQuantity: 0, price: '' };
  const location = useLocation();
  const [adjForm, setAdjForm] = useState({
    referenceNumber: generateEntityNumber('ADJ'),
    adjustmentDate: dayjs(new Date()),
    attachment: '',
    remarks: '',
    adjustmentOrderItems: [dummyItemRow],
    adjustmentReasonId: '',
    submitted: false,
  });

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const [adjReason, setAdjReason] = useState('');
  const [adjReasonOptions, setAdjReasonOption] = useState('');

  const [productItemOptions, setProductItemOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getAdjustmentReasons();
    getProductDetails();
    if (id) {
      setLoading(true);
      getAdjustmentById().finally(() => setLoading(false));
    }
  }, []);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const handleControlChange = (e, index = -1, entity) => {
    const { name, value } = e.target;
    if (index === -1) {
      setAdjForm({
        ...adjForm,
        [name]: value,
      });
    }
    if (index >= 0) {
      const adjustmentOrderItems = name.includes('adjustmentOrderItems') ? 'adjustmentOrderItems' : null;
      if (adjustmentOrderItems) {
        adjForm.adjustmentOrderItems[index] = { ...adjForm[adjustmentOrderItems][index], [entity]: value };
        setAdjForm({
          ...adjForm,
          adjustmentOrderItems: adjForm.adjustmentOrderItems,
        });
      }
    }
  };

  const addOrderItem = () => {
    setAdjForm((formData) => ({
      ...formData,
      adjustmentOrderItems: [...formData.adjustmentOrderItems, dummyItemRow],
    }));
  };

  const deleteOrderItem = (index) => {
    setAdjForm((formData) => {
      const updatedItems = [...formData?.adjustmentOrderItems];
      updatedItems.splice(index, 1);
      return {
        ...formData,
        adjustmentOrderItems: updatedItems,
      };
    });
  };

  const catchLogoId = (event) => {
    if (event) {
      setAdjForm({
        ...adjForm,
        attachment: event,
      });
    }
  };

  const getProductDetails = async () => {
    try {
      const response = await api.get(`${PRDOUCTLOCATIONURL}?pageNumber=1&pageSize=100`);
      const formatedReasonsOptions = formatProductLocation(response?.data?.productLocations) || [];
      setProductItemOptions(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleItemEdit = (newValue, index) => {
    adjForm.adjustmentOrderItems[index] = {
      ...adjForm?.adjustmentOrderItems[index],
      ...newValue?.formattedObject,
      selectedItem: {
        label: newValue?.formattedObject?.selectedLabel,
        value: newValue?.value,
      },
    };
    setAdjForm({
      ...adjForm,
      adjustmentOrderItems: adjForm.adjustmentOrderItems,
    });
  };

  const getAdjustmentReasons = async () => {
    try {
      const response = await api.get(`${INREASONURL}?pageNumber=1&pageSize=100`);
      const formatedReasonsOptions = formatEntityLabelValue(response?.data?.adjustmentReasons, 'reason', 'adjustmentReasonId');
      setAdjReasonOption(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addAdjustmentReason = async (reason) => {
    try {
      const req = { reason };
      const response = await api.post(`${INREASONURL}`, req);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${response?.data?.reason}' ${Messages.adjustmentReasonAdd}`,
      });
      const formatedAdjReason = formatEntityLabelValue(response?.data, 'reason', 'adjustmentReasonId');
      setAdjReason(formatedAdjReason);
      setAdjForm({
        ...adjForm,
        adjustmentReasonId: formatedAdjReason?.value,
      });
      getAdjustmentReasons();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const reasonACChange = (event, newValue) => {
    if (newValue?.label.includes('Add')) {
      addAdjustmentReason(newValue?.inputValue);
    } else {
      setAdjReason({
        value: newValue?.value,
        label: newValue?.label,
      });
      setAdjForm({
        ...adjForm,
        adjustmentReasonId: newValue?.value,
      });
    }
  };

  const getValidatedFormResult = () => {
    let valid = true;
    adjForm.adjustmentOrderItems.map((adjItem) => {
      if (!adjItem?.selectedItem?.value || !adjItem?.adjustedQuantity) {
        valid = false;
        return false;
      }
    });
    if (!valid) {
      return false;
    }
    if (!adjForm.referenceNumber?.trim() || !adjReason?.value || !adjForm.adjustmentDate) {
      return false;
    }
    return true;
  };

  const submitAdjustmentForm = async () => {
    setAdjForm({
      ...adjForm,
      submitted: true,
    });
    if (getValidatedFormResult()) {
      setSubmitting(true);
      try {
        if (!id) {
          const response = await api.post(`${INADJURL}`, JSON.stringify(adjForm));
          navigate('/adjustments', { state: { referenceNumber: response?.data?.referenceNumber, mode: 'add' } });
        } else {
          const response = await api.put(`${INADJURL}/${id}`, JSON.stringify(adjForm));
          navigate('/adjustments', { state: { referenceNumber: adjForm.referenceNumber, id: id } });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getAdjustmentById = async () => {
    try {
      const response = await api.get(`${INADJURL}/${id}`);
      response.data.adjustmentDate = dayjs(response?.data?.adjustmentDate);
      response.data.adjustmentOrderItems = response.data?.adjustmentOrderItems?.map((adj) => ({
        ...adj,
        availableStock: adj.quantity,
        selectedItem: {
          label: `Item: ${adj?.productName} , Location: ${adj?.locationName} , Available Stock: ${adj.oldAvailableQuantity} SKU: ${adj.sku}`,
          value: `${adj?.productId}/${adj?.locationId}`,
        },
      }));
      setAdjForm({ ...adjForm, ...response.data });
      const formatAdjReason = formatEntityLabelValue(response?.data?.adjustmentReason, 'reason', 'adjustmentReasonId');
      setAdjReason(formatAdjReason);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const prepareMenuForAdjustment = (adjustmentStatus) => {
    return getAdjustmentKebabMenuActions(adjustmentStatus);
  };

  const handleMenuItemClicked = async (event, adjustmentId) => {
    if (event > 0) {
      try {
        const reqObj = {
          adjustmentStatus: Number(event),
        };
        await api.post(`${INADJURL}/${adjustmentId}/status`, reqObj);
        getAdjustmentById();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.adjustmentStatusChange} '${Object.keys(AdjustmentStatusValue)[event]}' `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleNavigateBack = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('itemId')) {
      navigate(`/items`, { state: { itemId: searchParams.get('itemId'), itemTab: searchParams.get('itemTab') } });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex justify-content-between align-items-center">
          <CustomBreadCrumb breadcrumbList={breadcrumbs} />
          <div className="d-flex align-items-center">
            {id && adjForm?.adjustmentStatus && adjForm?.adjustmentStatus !== 2 && (
              <BasicMenu menuItems={prepareMenuForAdjustment(adjForm.adjustmentStatus)} itemClicked={(e) => handleMenuItemClicked(e, id)} />
            )}
            <CloseIcon
              className="cursor-pointer ml-2"
              onClick={() => {
                handleNavigateBack();
              }}
            />
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <Typography variant="h6" className="d-flex" gutterBottom>
                {id ? 'Edit Adjustment' : 'Add Adjustment'} {id ? <span className="mx-2"> {getBadgeVariant(setBadgeType(adjForm?.adjustmentStatus))} </span> : null}
              </Typography>
              <div>
                <span className="error-red ">*</span> Required Fields
              </div>
            </div>
            <div className={adjForm?.adjustmentStatus === AdjustmentStatusValue.Adjusted ? 'item-disabled' : ''}>
              <Grid container className="mt-1" spacing={2}>
                <Grid item sm={12} md={6} xs={12}>
                  <TextField
                    id="referenceNumber"
                    name="referenceNumber"
                    label="Reference Number *"
                    variant="outlined"
                    error={!adjForm.referenceNumber?.trim() && adjForm.submitted}
                    helperText={!adjForm.referenceNumber?.trim() && adjForm.submitted ? 'Reference number is required.' : ''}
                    value={adjForm.referenceNumber}
                    onChange={handleControlChange}
                  />
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                  <CustomAutocomplete
                    value={adjReason}
                    onChange={reasonACChange}
                    options={adjReasonOptions}
                    inputLabel="Adjustment Reason *"
                    isError={!adjReason?.value && adjForm.submitted}
                    helperText={!adjReason?.value && adjForm.submitted ? 'Adjusment reason is required.' : ''}
                    placeholder="Search..."
                    getOptionLabel={(option) => option.label || ''}
                  />
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                  <Typography className="label">Adjustment Date *</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={adjForm.adjustmentDate}
                      format="DD/MM/YYYY"
                      name="adjustmentDate"
                      onChange={(e) =>
                        setAdjForm({
                          ...adjForm,
                          adjustmentDate: e,
                        })
                      }
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          error: !adjForm.adjustmentDate && adjForm.submitted,
                          helperText: !adjForm.adjustmentDate && adjForm.submitted ? 'Date is required' : '',
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                  <Typography className="label">Attachment</Typography>
                  <InputFileUpload documentId={adjForm?.attachment} emitLogoId={catchLogoId} />
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <Typography variant="h6">Items</Typography>
                  <AdjustmentsOrderItems
                    ddlProduct={productItemOptions}
                    orderItems={adjForm?.adjustmentOrderItems}
                    handleCellDelete={deleteOrderItem}
                    handleCellEdit={handleControlChange}
                    handleItemEdit={handleItemEdit}
                    adjForm={adjForm}
                  />
                  <BootstrapTooltip title="Add">
                    <Button size="small" className="mt-2" variant="outlined" startIcon={<AddIcon />} onClick={addOrderItem}>
                      Add Items
                    </Button>
                  </BootstrapTooltip>
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <TextField name="remarks" label="Remarks" multiline rows={3} variant="outlined" value={adjForm?.remarks} onChange={handleControlChange} />
                </Grid>
              </Grid>
              <div className="d-flex justify-content-start mt-3">
                <Button size="small" variant="contained" onClick={submitAdjustmentForm} disabled={submitting}>
                  Submit
                </Button>
                {submitting && <CircularProgress size={24} className="ml-2" />}
                {id ? <>{adjForm?.modifiedByUser && renderLastModifiedData(adjForm?.modifiedByUser?.firstName, adjForm?.modifiedByUser?.lastName, adjForm?.modifiedDate)}</> : null}
              </div>
            </div>
          </>
        )}
      </div>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
    </>
  );
};

export default UpsertAdjustment;

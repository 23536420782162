import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Messages } from '../common-constants/messages';
import { AlertStatus } from '../common-constants/status';
import CustomAlert from '../common/alert';
import api from '../common/api';
import CustomAutocomplete from '../common/autocomplete';
import { BootstrapTooltip, formatEntityLabelValue, formatProductLocation } from '../common/commonUtility';
import LocationModal from './location-modal';
import { API_PRODUCT_LOCATION_ENDPOINT } from '../../constants/constants';
export const StockAllocationByLocation = React.memo(({ showAllocationModal, handleCloseAllocationModal, allocationData, currentKey, allocationStatus }) => {
  const PRDOUCTLOCATIONURL = API_PRODUCT_LOCATION_ENDPOINT;
  const [itemForm, setItemForm] = useState({
    allocationTitle: '',
    locations: [{ locationId: {}, quantity: 0 }],
  });
  const [disabledValues, setDisabledValues] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [locationModal, setLocationModal] = useState({
    showModal: false,
    locationIndex: '',
    newLocation: '',
  });

  useEffect(() => {
    if (allocationData?.itemId) {
      searchLocations();
    }
  }, [showAllocationModal]);

  useEffect(() => {
    if (allocationData && currentKey) {
      if (currentKey === 'pick' && allocationData?.itemId) {
        searchLocations();
      }
    }
  }, [showAllocationModal, allocationData, currentKey]);

  useEffect(() => {
    setDisabledValues(itemForm.locations.map((item) => item.selectedItem?.value) || []);
  }, [itemForm]);

  const handleLocationChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      setLocationModal({ showModal: true, location: newValue?.inputValue, locationIndex: index });
    } else {
      locationUpdateBasedOnIndex(newValue, index);
    }
  };

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const locationUpdateBasedOnIndex = (newValue, index) => {
    itemForm.locations[index] = {
      ...itemForm?.locations[index],
      locationId: newValue?.formattedObject?.locationId || '',
      selectedItem: {
        label: newValue?.formattedObject?.selectedLabel || '',
        value: newValue?.value || '',
      },
    };
    setItemForm({
      ...itemForm,
      locations: itemForm.locations,
    });
  };

  const searchLocations = async () => {
    try {
      const response = await api.get(`${PRDOUCTLOCATIONURL}?pageNumber=1&pageSize=100&productId=${allocationData?.itemId}`);
      const formatedReasonsOptions = formatProductLocation(response?.data?.productLocations) || [];
      setLocationOptions(formatedReasonsOptions);
      let allocationByLocationData = { ...allocationData[currentKey] };
      if (!allocationData[currentKey]?.locations?.length) {
        allocationByLocationData = {
          ...allocationByLocationData,
          locations: [{ locationId: {}, quantity: '' }],
        };
      } else {
        allocationByLocationData.locations = allocationByLocationData?.locations?.map((lc) => {
          if (lc?.locationId) {
            return {
              ...lc,
              selectedItem: {
                label: formatedReasonsOptions?.find((item) => item?.formattedObject?.locationId === lc?.locationId)?.formattedObject?.selectedLabel || '',
                value: lc.locationId || '',
              },
            };
          }
        });
      }
      setItemForm(allocationByLocationData);
      //setProductItemOptions(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLocationClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (typeof reason === 'number' && event.locationId) {
      const newLocation = formatEntityLabelValue(event, 'locationName', 'locationId');
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${newLocation?.label}' ${Messages.locationAdded}`,
      });
      locationUpdateBasedOnIndex(newLocation, reason);
      if (allocationData?.itemId) {
        searchLocations();
      }
    }
    setLocationModal(false);
  };

  const handleAddLocationField = () => {
    setItemForm((formData) => ({
      ...formData,
      locations: [...formData.locations, { locationId: {}, quantity: '' }],
    }));
  };

  const handleRemoveLocationField = (index) => {
    setItemForm((formData) => {
      const updatedItems = [...formData?.locations];
      updatedItems.splice(index, 1);
      return {
        ...formData,
        locations: updatedItems,
      };
    });
  };

  const handleLocationFieldChange = (e, index) => {
    const { name, value } = e.target;
    setItemForm((prevItem) => ({
      ...prevItem,
      locations: prevItem?.locations?.map((it, mapIndex) => (mapIndex === index ? { ...it, [name]: Number(value) } : it)),
    }));
  };

  const getValidatedForm = () => {
    let valid = true;
    itemForm.locations.map((loc) => {
      if (!loc?.selectedItem?.value || !loc?.quantity) {
        valid = false;
        return false;
      }
    });
    return valid;
  };

  const handleSubmitAllocation = () => {
    setItemForm({
      ...itemForm,
      submitted: true,
    });
    if (getValidatedForm()) {
      handleCloseAllocationModal({ ...itemForm });
    }
  };

  return (
    <>
      <LocationModal location={locationModal?.location} showModal={locationModal?.showModal} locationIndex={locationModal?.locationIndex} handleClose={handleLocationClose} />
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <Dialog
        open={showAllocationModal}
        PaperProps={{
          style: {
            height: '70vh', // Set the maximum height
            minWidth: '70vw', // Set the width
          },
        }}
      >
        <DialogTitle>
          <div className={`d-flex justify-content-between`}>
            <Typography variant="h6" gutterBottom className="text-capitalize">
              {currentKey}
            </Typography>
            <CloseIcon className="cursor-pointer ml-2" onClick={() => handleCloseAllocationModal(itemForm, 'close')} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container className={`${allocationStatus === 'Allocated' ? 'item-disabled' : ''}`}>
            <Grid item sm={12} md={12} xs={12}>
              {itemForm?.locations?.length
                ? itemForm?.locations?.map((location, index) => (
                    <Grid container key={index} spacing={2} className="mt-1">
                      <Grid item xs={12} md={7}>
                        <CustomAutocomplete
                          value={location.selectedItem || ''}
                          onChange={(e, newValue) => handleLocationChange(newValue, index)}
                          options={locationOptions}
                          disabledValues={disabledValues?.filter((dis) => dis !== location?.selectedItem?.value)}
                          inputLabel="Location"
                          placeholder="Select location..."
                          getOptionLabel={(option) => option.label || ''}
                          isError={!location?.selectedItem?.value && itemForm.submitted}
                          helperText={!location?.selectedItem?.value && itemForm.submitted ? 'Location is required.' : ''}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          type="number"
                          label="Quantity"
                          id="quantity"
                          value={location.quantity || 0}
                          name="quantity"
                          variant="outlined"
                          onChange={(e) => handleLocationFieldChange(e, index)}
                          error={!location?.quantity && itemForm.submitted}
                          helperText={!location?.quantity && itemForm.submitted ? 'Quantity is required.' : ''}
                          InputProps={{
                            endAdornment: <Typography className="ml-2">{allocationData?.product?.unit}</Typography>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={1}>
                        {
                          <BootstrapTooltip title="Delete">
                            <IconButton color="info" onClick={() => handleRemoveLocationField(index)}>
                              <Delete />
                            </IconButton>
                          </BootstrapTooltip>
                        }
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
            <Grid item xs={6} md={6} className="mt-2">
              <BootstrapTooltip title="Add Location">
                <Button size="small" className="mt-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddLocationField}>
                  Add Location
                </Button>
              </BootstrapTooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" className="mt-2 mx-3 my-2" variant="contained" disabled={allocationStatus === 'Allocated'} onClick={handleSubmitAllocation}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

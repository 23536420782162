import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../common/autocomplete';
import { BootstrapTooltip } from '../common/commonUtility';
export const UpsertMaterialModal = React.memo(
  ({ showMaterialsModal, handleCloseMaterialsModal, materialsDataForBinding, materialIndex, productItemOptions, handleViewMaterial, materials, woForm }) => {
    const defaultMaterial = {
      materialId: '',
      lvMaterialId: '',
      locationId: '',
      quantity: 0,
      submitted: false,
    };
    const [materialModalForm, setMaterialModalForm] = useState();
    const [formSubmmited, setFormSubmitted] = useState(false);
    useEffect(() => {
      setFormSubmitted(false);
      setMaterialModalForm([...materialsDataForBinding]);
    }, [materialsDataForBinding]);

    const getValidatedForm = () => {
      let valid = true;
      materialModalForm?.map((ordItem) => {
        if (!ordItem?.materialId || !ordItem?.quantity) {
          valid = false;
        }
      });
      return valid;
    };

    const handleSubmitOperations = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setFormSubmitted(true);
      if (getValidatedForm()) {
        handleCloseMaterialsModal(materialModalForm, materialIndex);
      }
    };

    const handleRoutingStepsChange = async (e, index) => {
      const { name, value } = e.target;
      let updateRoute = materialModalForm?.map((rou, i) => {
        if (i !== index) return rou;

        rou = { ...rou, [name]: value };
        return rou;
      });
      setMaterialModalForm(updateRoute);
    };

    const handleAddRoutingSteps = () => {
      const addedMaterial = [...materialModalForm, defaultMaterial];
      setMaterialModalForm(addedMaterial);
    };

    const handleRemoveRoutingSteps = (index) => {
      const removeMaterial = materialModalForm?.filter((item, i) => i !== index);
      setMaterialModalForm(removeMaterial);
    };

    const handleMaterialItemChange = (newValue, index) => {
      if (newValue?.formattedObject) {
        const formattedObject = newValue?.formattedObject;
        let updatedProducts = materialModalForm?.map((product, i) => {
          if (i !== index) return product;
          return {
            materialId: formattedObject?.productId || '',
            lvMaterialId: {
              label: formattedObject?.selectedLabel || '',
              value: formattedObject?.productId || '',
            },
            locationId: formattedObject?.locationId,
            unit: formattedObject?.unit,
          };
        });
        setMaterialModalForm(updatedProducts);
        //   dispatch(setBOMItemsData(updatedProducts));
      } else {
        materialModalForm[index] = defaultMaterial;
      }
    };
    return (
      <Dialog
        open={showMaterialsModal}
        PaperProps={{
          style: {
            height: '90vh', // Set the maximum height
            minWidth: '90vw', // Set the width
          },
        }}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            <Typography variant="h6" gutterBottom>
              Materials For Processing Details
            </Typography>
            <div>
              {/* <Typography
              variant="body2"
              component="a"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                handleViewMaterial();
              }}
              sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem', textDecoration: 'underline' }}
            >
              View Bill of Materials
            </Typography> */}
              <CloseIcon className="cursor-pointer ms-4" onClick={() => handleCloseMaterialsModal(materialModalForm, materialIndex, 'close')} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <TableContainer size="small" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="ps-2" width="60%">
                        Material*
                      </TableCell>
                      <TableCell width="30%">Quantity*</TableCell>
                      <TableCell align="center" width="10%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialModalForm?.length
                      ? materialModalForm?.map((row, routeIndex) => (
                          <TableRow key={routeIndex}>
                            <TableCell width="60%">
                              <CustomAutocomplete
                                value={row?.lvMaterialId || ''}
                                onChange={(e, newValue) => handleMaterialItemChange(newValue, routeIndex)}
                                disabledValues={materialModalForm?.map((pitem) => `${pitem?.materialId}/${pitem?.locationId}`)?.filter((dis) => dis !== row?.materialId)}
                                options={productItemOptions || []}
                                placeholder="Search..."
                                disableAddNew={true}
                                getOptionLabel={(option) => option.label || ''}
                                isError={!row?.lvMaterialId?.value && formSubmmited}
                              />
                            </TableCell>
                            <TableCell align="left" width="30%">
                              <div className="d-flex">
                                <TextField
                                  type="number"
                                  value={row?.quantity}
                                  name="quantity"
                                  variant="outlined"
                                  onChange={(e) => handleRoutingStepsChange(e, routeIndex)}
                                  error={!row?.quantity && formSubmmited}
                                />
                                <div className="mt-1 ms-1"> {row?.unit}</div>
                              </div>
                            </TableCell>

                            <TableCell align="center" width="10%">
                              {materialModalForm?.length > 1 ? (
                                <BootstrapTooltip title="Delete">
                                  <IconButton className="pt-3 pb-1" color="info" onClick={() => handleRemoveRoutingSteps(routeIndex)}>
                                    <Delete />
                                  </IconButton>
                                </BootstrapTooltip>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
                <div className="d-flex">
                  <BootstrapTooltip title="Add Routing">
                    <Button size="small" className="my-1 ms-1" variant="outlined" startIcon={<AddIcon />} onClick={handleAddRoutingSteps}>
                      Add Material
                    </Button>
                  </BootstrapTooltip>
                </div>
              </TableContainer>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <div className="px-4">
                <Grid item md={12} sm={12} xs={12} className="mx-2">
                  <Typography variant="h6" gutterBottom className="mb-0">
                    Bill of Materials
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className="mx-2">
                  <TableContainer size="small" component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="ps-2" width="40%">
                            Item Name*
                          </TableCell>
                          <TableCell align="right" width="17%">
                            Quantity (%)
                          </TableCell>
                          <TableCell align="right" width="10%">
                            Quantity
                          </TableCell>
                          <TableCell width="40%">Notes</TableCell>
                          <TableCell align="center" width="3%"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {materials?.map((row, productIndex) => (
                          <TableRow key={productIndex}>
                            <TableCell width="40%">
                              <div className="pt-3 pb-1 ps-2">
                                <CustomAutocomplete
                                  value={row?.lvItemId || ''}
                                  disabledValues={materials?.map((pitem) => pitem?.itemId)?.filter((dis) => dis !== row?.itemId)}
                                  options={productItemOptions || []}
                                  placeholder="Search..."
                                  getOptionLabel={(option) => option.label || ''}
                                  disabled={true}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="right" width="17%" className="position-relative">
                              <div className="pt-3 pb-1 ps-3 d-flex">
                                <TextField
                                  type="number"
                                  id="quantity"
                                  value={row?.quantity}
                                  name="quantity"
                                  variant="outlined"
                                  disabled={true}
                                  InputProps={{
                                    inputProps: {
                                      min: 1,
                                      max: 100,
                                      style: { textAlign: 'right' },
                                    },
                                  }}
                                />
                                <span className="p-1 mt-1">%</span>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              {
                                <>
                                  {woForm?.quantity * (row?.quantity / 100)?.toFixed(2)}
                                  <span className="mx-2">{row?.unit} </span>
                                </>
                              }{' '}
                            </TableCell>
                            <TableCell align="left" width="40%">
                              <div className="pt-3 pb-1">
                                <TextField value={row?.notes} name="notes" variant="outlined" multiline rows={2} disabled={true} />
                              </div>
                            </TableCell>
                            <TableCell align="center" width="3%">
                              {/* {materials?.length > 1 ? (
                            <BootstrapTooltip title="Delete">
                              <IconButton className="pt-3 pb-1" color="info" onClick={() => handleMaterialRemoveProduct(productIndex)}>
                                <Delete />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : null} */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      {/* <BootstrapTooltip title="Add Material">
                    <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleMaterialAddProduct}>
                      Add Material
                    </Button>
                  </BootstrapTooltip> */}
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" className="mt-2 mx-3 my-2" variant="contained" onClick={handleSubmitOperations}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

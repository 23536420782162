import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_SALES_LEADS_ENDPOINT } from '../../../../constants/constants';
import { AlertStatus, OrderStatus, OrderStatusValue } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { LeadOverview } from './lead-tabs/lead-overview';
import LeadQuotationList from './lead-tabs/lead-quotation-list';
import { getBadgeVariant, renderLastModifiedData } from '../../../common/commonUtility';
import ActivityLog from '../../../common/activity';
import LeadStatusStepper from './lead-tabs/leads-status-stepper';
import { setLeadsData } from '../../../redux/actions/leads-action';
import api from '../../../common/api';
export const UpsertLeads = () => {
  const salesLeadsEndpoint = API_SALES_LEADS_ENDPOINT;
  const { leadId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { leadsData } = useSelector((state) => state?.leads?.data);
  const [leadStatus, setLeadStatus] = useState('');

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Leads',
      href: '/Leads',
    },
    {
      name: 'Sales Lead',
      active: true,
    },
  ];

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const leadOverviewTab = [
    {
      value: 0,
      label: 'Overview',
    },
  ];

  const leadQuotationActivityTab = [
    {
      value: 1,
      label: 'Quotations',
    },
    // {
    //   value: 2,
    //   label: 'Activity',
    // },
  ];

  const [leadsTab, setLeadsTab] = useState([]);
  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (leadId) {
      setLeadsTab([...leadOverviewTab, ...leadQuotationActivityTab]);
    } else {
      setLeadsTab([...leadOverviewTab]);
    }
  }, [leadId]);

  const handleStatusChange = async (leadStatus) => {
    try {
      const reqObj = {
        ...leadsData,
        status: leadStatus,
      };
      const { data } = await api.put(`${salesLeadsEndpoint}/${leadId}`, reqObj);
      const leadsDataResponse = {
        ...leadsData,
        ...data,
        status: data?.status,
      };
      setLeadStatus(data?.status);
      dispatch(setLeadsData(leadsDataResponse));
      // fetchLeadsById();
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} link={alertData?.link} path={alertData?.path} />
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <Grid container className="d-flex">
          <Grid item xs={3} md={3}>
            <div className="d-flex flex-column">
              <CustomBreadCrumb breadcrumbList={breadcrumbs} />
              <Box className="bg-white border-b border-gray-200 px-6 py-2">
                <Box className="flex justify-between items-center">
                  <Box className="d-flex items-center space-x-4">
                    <Typography variant="h5" className="font-semibold">
                      {leadId ? 'Edit Lead' : 'New Lead'}
                    </Typography>
                    <span className="mx-2"> {getBadgeVariant(leadsData?.status || 'New')}</span>
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            {leadId ? <LeadStatusStepper leadStatus={leadsData?.status} handleStatusChange={handleStatusChange} /> : null}
          </Grid>
          <Grid item xs={3} md={3}>
            <div className="d-flex flex-grow-1 justify-content-end">
              {leadsData?.entityActivities?.length ? <ActivityLog className="cursor-pointer mx-2 mt-2" s leadsData={leadsData} /> : null}
              <CloseIcon
                className="cursor-pointer mx-2 mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Tabs value={activeTab} onChange={handleTabSelect} className="mx-2 mr-4">
              {leadsTab?.length && leadsTab?.map((item, index) => <Tab key={index} value={item.value} label={<Typography className="text-capitalize fs-13">{item.label}</Typography>}></Tab>)}
            </Tabs>
          </Grid>
        </Grid>

        {activeTab === 0 && <LeadOverview />}
        {activeTab === 1 && <LeadQuotationList />}
        {/* {activeTab === 2 && <LeadActivity />} */}
      </div>
    </>
  );
};

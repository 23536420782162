import { TYPES } from '../type-constants/type';

const initialState = {
  data: {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_ACCOUNT_USERS_DATA:
    case TYPES.SET_ACCOUNT_DETAILS_FORM:
    case TYPES.SET_FINANCE_DETAILS_FORM:
    case TYPES.SET_ACCOUNT_ADDRESS_FORM:
    case TYPES.SET_ACCOUNT_ADDITIONAL_INFO_FORM:
    case TYPES.SET_ACCOUNT_SINCE_FIELD:
    case TYPES.SET_ACCOUNT_FORM_SUBMITTED:
    case TYPES.SET_PRIMARY_CONTACTS_DATA:
    case TYPES.SET_ACCOUNT_TENANT_ID:
    case TYPES.OPEN_ACCOUNT_MODAL:
    case TYPES.CLOSE_ACCOUNT_MODAL:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case TYPES.RESET_ACCOUNT_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;

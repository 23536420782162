import { FilterAltRounded, Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_STOCK_ALLOCATION_ENDPOINT } from '../../../constants/constants';
import { Messages } from '../../common-constants/messages';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomBreadCrumb from '../../common/breadcrumb';
import { BootstrapTooltip, formatDate, getCheckboxResult, getYearDifference } from '../../common/commonUtility';
import ConfirmDialog from '../../common/confirm-dialog';
import CustomPagination from '../../common/pagination';
import CustomTable from '../../common/table';
import CustomTableActions from '../../common/table-actions';
import { resetAccountReducer } from '../../redux/actions/account-action';
import { resetDocumentReducer } from '../../redux/actions/document-action';
import { resetSalesReducer } from '../../redux/actions/sales-action';
import CardVisualization from '../../common/card-visualization';
import { getAllocationKebabMenuActions, getAllocationStatus } from '../../common-constants/static-values';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const StockAllocationLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const itemsPerPage = 6;
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [aggregateData, setAggregateData] = useState({});
  const [activeCard, setActiveCard] = useState('');
  const stockAllocationEndpoint = API_STOCK_ALLOCATION_ENDPOINT;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateFilter, setDateFilter] = useState({
    fromDate: dayjs(new Date())?.subtract(1, 'year'),
    toDate: dayjs(new Date()),
  });
  const accountColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Order ID',
      field: 'orderReferenceNumber',
      link: true,
    },
    {
      label: 'Status',
      badge: true,
      field: 'stockAllocationStatus',
    },
    {
      label: 'Last Modified By',
      field: 'modifiedUser',
      avatar: true,
    },
    {
      label: 'Last Modified Time',
      field: 'lastModifiedTime',
      date: true,
    },
    {
      label: 'Action',
      field: 'action',
      menu: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Stock Allocation',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [stockAllocations, setStockAllocations] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    dispatch(resetSalesReducer());
    fetchStockAllocation();
    fetchAggregateData();
    stateHandlerOnLoad();
    dispatch(resetDocumentReducer());
    dispatch(resetAccountReducer());
  }, []);

  useEffect(() => {
    fetchStockAllocation(1, search, activeCard);
    fetchAggregateData();
  }, [JSON.stringify(dateFilter)]);

  const fetchStockAllocation = async (currentPage = 1, search, activeCard = '') => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    const allocationStatus = activeCard >= 0 ? `&status=${activeCard}` : '';
    const fromDate = dateFilter?.fromDate ? `&fromDate=${dateFilter?.fromDate}` : '';
    const toDate = dateFilter?.toDate ? `&toDate=${dateFilter?.toDate}` : '';
    try {
      const response = await api.get(`${stockAllocationEndpoint}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}${allocationStatus}${fromDate}${toDate}`);
      response.data.orders = response.data.orders?.map((item) => ({
        ...item,
        modifiedUser: `${item?.user?.firstName} ${item?.user?.lastName}`,
        stockAllocationStatus: getAllocationStatus(item?.stockAllocationStatus),
        action: getAllocationKebabMenuActions(item?.stockAllocationStatus),
      }));
      const stockAllocations = response.data?.orders;
      setEntityData(response.data);
      setStockAllocations(stockAllocations);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const fromDate = dateFilter?.fromDate ? `?fromDate=${dateFilter?.fromDate}` : '';
      const toDate = dateFilter?.toDate ? `&toDate=${dateFilter?.toDate}` : '';
      const response = await api.get(`${stockAllocationEndpoint}/overview${fromDate}${toDate}`);
      const data = response.data;
      const aggregateData = [
        { title: 'Stock Allocations', value: data?.totalAllocations, bindValue: '', tooltip: `Order Amount:  (${data.currency}) ${data.totalOrdersAmount?.toFixed(2)}` },
        { title: 'Pending', value: data.pendingAllocations, bindValue: 0 },
        { title: 'In Progress', value: data.inProgressAllocations, bindValue: 1 },
        { title: 'Allocated', value: data.allocatedAllocations, bindValue: 2 },
      ]?.filter((item) => item.value !== 0);
      setAggregateData(aggregateData);
      setAggregateLoader(false);
    } catch (error) {
      setAggregateLoader(false);
    }
  };

  const stateHandlerOnLoad = () => {
    if (state?.mode === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.salesCreated}`,
      });
    }
    navigate('.', { replace: true });
  };

  const handleRefreshData = () => {
    fetchStockAllocation(1, search);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleEditEntity = (item) => {
    //   const order = stockAllocations.find((or) => or.transactionId === item?.transactionId);
    navigate(`/stockAllocation/${item.orderId}`);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchStockAllocation(1, searchText, activeCard);
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    fetchStockAllocation(page, search, activeCard);
  };

  const handleDeleteConfirmYes = () => {
    deleteStockAllocations();
  };

  const deleteStockAllocations = async () => {
    const selectedItems = stockAllocations?.filter((item) => item.checked)?.map((adj) => adj.id);
    setDeleteDialog(false);
    try {
      await api.delete(stockAllocationEndpoint, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.salesDeleted,
      });
      fetchStockAllocation();
      fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setStockAllocations(getCheckboxResult(stockAllocations, checkedItem, 'orderId'));
  };

  const handleCardActive = (activeCard) => {
    setActiveCard(activeCard);
    fetchStockAllocation(1, search, activeCard);
  };

  const handleMenuItemClicked = async (event, item) => {
    if (event !== 2) {
      try {
        const requestObj = {
          newStatus: event,
        };
        const response = await api.put(`${stockAllocationEndpoint}/${item?.orderId}/status`, requestObj);
        if (!response?.error) {
          fetchStockAllocation();
          fetchAggregateData();
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${Messages.stockAllocationStatusUpdate}`,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      navigate(`/sales/${item?.salesOrderId}`);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const changeFilter = (name, value) => {
    setDateFilter({
      ...dateFilter,
      [name]: dayjs(value),
    });
    setCurrentPage(1);
    if (name === 'fromDate' && value && dateFilter.toDate && value?.isAfter(dateFilter?.toDate)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        toDate: dayjs(value),
      });
      //  dispatch(fetchStockHistory(1, value, value));
    } else if (name === 'toDate' && value && dateFilter.fromDate && dateFilter.fromDate?.isAfter(value)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        fromDate: dayjs(value),
      });
      //  dispatch(fetchStockHistory(1, value, value));
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-2">
          <Typography className="mt-2 fs-18">Advance Filter</Typography>
          <div className="d-flex flex-column mt-2 mb-4 mx-3">
            <div className="d-flex">
              <div>
                <Typography className="label">From Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.fromDate} onChange={(e) => changeFilter('fromDate', e)} />
                </LocalizationProvider>
              </div>
              <div className="ms-2">
                <Typography className="label">To Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.toDate} onChange={(e) => changeFilter('toDate', e)} />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </Popover>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Stock Allocation
            </Typography>
            <span>
              {getYearDifference(dateFilter?.fromDate.toISOString(), dateFilter?.toDate.toISOString()) === 1
                ? 'Stock Allocation records of past 1 year'
                : `${formatDate(dateFilter?.fromDate)} - ${formatDate(dateFilter?.toDate)}`}
            </span>
          </Grid>
        </div>
        <div className="d-flex mt-2">
          <Grid container>
            <Grid item xs={2}>
              <div className="d-flex">
                <CustomTableActions
                  shouldHideAddBtn={true}
                  disableDelete={!stockAllocations?.filter((cus) => cus.checked)?.length}
                  handleRefresh={handleRefreshData}
                  handleDelete={handleDeleteConfirm}
                ></CustomTableActions>
                <BootstrapTooltip title="Filter">
                  <IconButton color="info" onClick={handleFilterClick}>
                    <FilterAltRounded />
                  </IconButton>
                </BootstrapTooltip>
              </div>
            </Grid>
            <Grid className="d-flex align-items-center flex-wrap" item xs={8}>
              <CardVisualization loading={aggregateLoader} activeCard={activeCard} data={aggregateData} handleCardActive={handleCardActive} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable
          tableColumn={accountColumn}
          loading={tableLoader}
          tableData={stockAllocations}
          toggleSelectedCheckbox={toggleSelectedCheckbox}
          handleEditEntity={handleEditEntity}
          menuItemClicked={handleMenuItemClicked}
        />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default StockAllocationLanding;

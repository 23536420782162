import '@fortawesome/fontawesome-free/css/all.min.css';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { API_PRODUCTS_ENDPOINT, API_SALES_TRANSACTIONS_ENDPOINT } from '../../../../../constants/constants';
import { Messages } from '../../../../common-constants/messages';
import { OperationsChips } from '../../../../common-constants/static-values';
import { AlertStatus, OrderStatusValue } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { BootstrapTooltip, formatDate, formatProductForSales, renderLastModifiedData, stringAvatar } from '../../../../common/commonUtility';
import { CreateItemModal } from '../../../../modals/create-item';
import { CreateOperationsModal } from '../../../../modals/create-operations';
import { closeItemModal, openItemModal, setDeltedItemsData, setEditOperationsData, setEditProductData, setProductsData, setSalesByIdData } from '../../../../redux/actions/sales-action';
import SalesStepper from '../../../../common/sales-stepper';

export const EditOrderItems = () => {
  const { salesId } = useParams();
  const dispatch = useDispatch();

  const { ddlProducts, salesData, editProductData, editOperationData, deletedItems, newItemModalData, itemData } = useSelector((state) => state?.sales?.data);
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  // const orderItemsEndpoint = API_ORDER_ITEMS_ENDPOINT;
  const salesTransactionEndpoint = API_SALES_TRANSACTIONS_ENDPOINT;
  const [submitted, setSubmitted] = useState(false);
  const defaultProduct = {
    orderItemId: crypto.randomUUID(),
    productId: '00000000-0000-0000-0000-000000000000',
    lvProductId: '',
    quantity: 0,
    unit: '',
    rate: 0,
    amount: 0,
  };
  const [currencyUnit, setCurrencyUnit] = useState();
  const [deletedProduct, setDeletedProduct] = useState([]);
  const [operationDataByIndex, setOperationDataByIndex] = useState([]);
  let [operationIndex, setOperationIndex] = useState('');
  const [operationForm, setOperationForm] = useState([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [productItemOptions, setProductItemOptions] = useState([]);
  const [products, setProducts] = useState([defaultProduct]);
  const [extraCost, setExtraCost] = useState({
    tax: 0,
    shippingCharges: 0,
    discount: 0,
    // Add more extra cost details as needed
  });
  const emptyTaxObj = { taxName: '', taxPercentage: 0, taxAmount: 0 };
  const [taxDetails, setTaxDetails] = useState([emptyTaxObj]);
  const subTotalProductAmount = products.reduce((total, product) => total + product.amount, 0);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const steps = [
    { label: 'Work Order', status: 'Completed', active: true },
    { label: 'Quality Control', status: 'In Progress', active: true },
    { label: 'Packaging', status: 'Needs Attention', active: true },
    { label: 'Logistics & Delivery', status: 'Not Started', active: false },
  ];

  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);
  const calculateTaxAmount = (totalAmount, taxPercentage) => {
    if (!isNaN(taxPercentage) && !isNaN(totalAmount)) {
      return (totalAmount * taxPercentage) / 100;
    }
    return 0;
  };
  const discountedSubTotal = subTotalProductAmount * (1 - extraCost.discount / 100);
  const totalTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
  const updatedTotalAmount = discountedSubTotal + totalTaxAmount + Number(extraCost.shippingCharges);

  const [totalAmount, setTotalAmount] = useState(updatedTotalAmount);
  let formatedProductsOptions = [];

  useEffect(() => {
    if (!ddlProducts) {
      fetchProducts();
    } else {
      setProductItemOptions(ddlProducts);
    }
  }, []);

  useEffect(() => {
    if (salesData?.orderItems && productItemOptions?.length) {
      setCurrencyUnit(salesData?.currency);
      setDeletedProduct(deletedItems || []);
      let prepareOperations = [];
      let prepareProducts = [];

      if (!editOperationData && !editProductData) {
        salesData?.orderItems?.forEach((sd, index) => {
          let operationObj = {};
          if (sd?.hasOwnProperty('workOrder') && sd?.workOrder?.hasOwnProperty('isWorkOrderRequired')) {
            operationObj = {
              ...operationObj,
              workOrder: {
                isCreateOperation: sd?.workOrder?.isWorkOrderRequired,
                quantity: sd?.workOrder?.quantity,
                notes: sd?.workOrder?.notes,
                operationTitle: 'Work Order',
                operationType: 'workOrder',
              },
            };
          }
          if (sd?.hasOwnProperty('qualityControl') && sd?.qualityControl?.hasOwnProperty('isQualityCheckRequired')) {
            operationObj = {
              ...operationObj,
              qualityControl: {
                isCreateOperation: sd?.qualityControl?.isQualityCheckRequired,
                notes: sd?.qualityControl?.notes,
                operationTitle: 'Quality Control',
                operationType: 'qualityControl',
              },
            };
          }
          if (sd?.hasOwnProperty('packaging') && sd?.packaging?.hasOwnProperty('isPackagingRequired')) {
            operationObj = {
              ...operationObj,
              packaging: {
                isCreateOperation: sd?.packaging?.isPackagingRequired,
                notes: sd?.packaging?.notes,
                operationTitle: 'Packaging',
                operationType: 'packaging',
              },
            };
          }
          if (sd?.hasOwnProperty('shipping') && sd?.shipping?.hasOwnProperty('isShippingRequired')) {
            operationObj = {
              ...operationObj,
              logisticDelivery: {
                isCreateOperation: sd?.shipping?.isShippingRequired,
                notes: sd?.shipping?.notes,
                operationTitle: 'Logistics & Delivery',
                operationType: 'logisticDelivery',
              },
            };
          }
          prepareOperations.push(operationObj);
          const filterProduct = productItemOptions?.find((pi) => pi?.value === sd?.productId);
          const productObj = {
            orderItemId: sd?.orderItemId,
            productId: sd?.productId,
            lvProductId: { label: filterProduct?.formattedObject?.selectedLabelForSales, value: filterProduct?.value },
            productName: sd?.productName,
            quantity: sd?.quantity,
            rate: sd?.rate,
            unit: sd?.unit,
            stepperVisualization: sd?.stepperVisualization,
            amount: parseFloat(sd?.rate) * parseFloat(sd?.quantity),
          };
          prepareProducts.push(productObj);
        });
      } else {
        prepareOperations = editOperationData;
        prepareProducts = [...editProductData];
      }
      setOperationForm([...operationForm, ...prepareOperations]);
      setProducts(prepareProducts);
      dispatch(setEditProductData(prepareProducts));
      dispatch(setEditOperationsData([...operationForm, ...prepareOperations]));
      if (salesData?.taxInfo?.length) {
        setShowTaxDetails(true);
        setTaxDetails(salesData?.taxInfo);
      }

      if (salesData?.discount) {
        setShowDiscount(true);
        setDiscountAmount(salesData?.discounts);
      }
      if (salesData?.shippingCharges || salesData?.discount) {
        setShowShipping(true);
        setExtraCost({
          ...extraCost,
          discount: salesData?.discount,
          shippingCharges: salesData?.shippingCharges,
        });
      }
    }
  }, [productItemOptions]);

  useEffect(() => {
    const updatedTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
    const updatedShippingCost = Number(extraCost.shippingCharges);
    const discountAmount = subTotalProductAmount * (Number(extraCost.discount) / 100);
    const updatedTotalAmount = discountedSubTotal + updatedTaxAmount + updatedShippingCost;
    setDiscountAmount(discountAmount);
    setExtraCost((prevExtraCost) => ({ ...prevExtraCost, tax: updatedTaxAmount }));
    setTotalAmount(updatedTotalAmount);
  }, [JSON.stringify(products), JSON.stringify(taxDetails), extraCost.shippingCharges, extraCost.discount, discountAmount]);

  useEffect(() => {
    const fetchAndHandleItem = async () => {
      if (itemData?.productId) {
        await fetchProducts();
        const product = formatedProductsOptions?.find((item) => item?.value === itemData?.productId);

        handleItemChange(product, newItemModalData?.index);
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `'${itemData?.productName}' ${Messages.itemAdded}`,
        });
      }
    };
    fetchAndHandleItem();
    if (!newItemModalData?.isItemModalOpen) {
      dispatch(closeItemModal());
    }
  }, [newItemModalData?.isItemModalOpen, itemData]);

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedProductsOptions);
      dispatch(setProductsData(formatedProductsOptions));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAddProduct = () => {
    const addedProduct = [...products, defaultProduct];
    setProducts(addedProduct);
    dispatch(setEditProductData(addedProduct));
  };

  const handleRemoveProduct = (index) => {
    if (products[index]?.orderItemId) {
      const delProducts = [...deletedProduct, products[index]?.orderItemId];
      setDeletedProduct(delProducts || []);
      dispatch(setDeltedItemsData(delProducts));
    }
    const removedProduct = products?.filter((item, i) => i !== index);
    const removeOperations = operationForm?.filter((item, i) => i !== index);
    setOperationForm(removeOperations);
    setProducts(removedProduct);
    dispatch(setEditOperationsData(removeOperations));
    dispatch(setEditProductData(removedProduct));
  };

  const handleItemChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openItemModal(index));
    } else if (newValue?.formattedObject) {
      let updatedProducts = products.map((product, i) => {
        if (i !== index) return product;
        if (newValue) {
          const formattedObject = newValue?.formattedObject;
          return {
            ...product,
            productId: formattedObject?.productId || '',
            lvProductId: {
              label: formattedObject?.selectedLabelForSales || '',
              value: formattedObject?.productId || '',
            },
            stockOnHand: formattedObject?.stockOnHand,
            quantity: formattedObject?.stockOnHand,
            unit: formattedObject?.unit,
            rate: formattedObject?.price,
            amount: parseFloat(formattedObject?.price) * parseFloat(formattedObject?.stockOnHand),
          };
        } else {
          return defaultProduct;
        }
      });
      setProducts(updatedProducts);
      dispatch(setEditProductData(updatedProducts));
    }
  };

  const handleProductChange = async (e, index) => {
    const { name, value } = e.target;
    let updatedProducts = products.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: value };
      const rate = parseFloat(product.rate);
      const quantity = parseFloat(product.quantity);

      if (!isNaN(rate) && !isNaN(quantity)) {
        product.amount = rate * quantity;
      } else {
        product.amount = 0;
      }
      return product;
    });
    setProducts(updatedProducts);
    dispatch(setEditProductData(updatedProducts));
  };

  const handleExtraCostChange = (e) => {
    const { name, value } = e.target;
    setExtraCost((prevExtraCost) => ({ ...prevExtraCost, [name]: value }));
    const updateSalesData = {
      ...salesData,
      [name]: value,
    };
    dispatch(setSalesByIdData(updateSalesData));
  };

  const handleCheckboxChange = (checkboxName, checked) => {
    switch (checkboxName) {
      case 'taxDetails':
        setShowTaxDetails(checked);
        break;
      case 'shipping':
        setShowShipping(checked);
        break;
      case 'discount':
        setShowDiscount(checked);
        break;
      default:
        break;
    }
  };

  const handleAddTaxDetail = () => {
    const addedTax = [...salesData.taxInfo, { taxName: '', taxPercentage: 0, taxAmount: 0 }];
    setTaxDetails(addedTax);
    dispatch(
      setSalesByIdData({
        ...salesData,
        taxInfo: addedTax,
      })
    );
  };

  const handleRemoveTaxDetail = (index) => {
    const removedTax = salesData?.taxInfo?.filter((item, i) => i !== index);
    setTaxDetails(removedTax);
    dispatch(
      setSalesByIdData({
        ...salesData,
        taxInfo: removedTax,
      })
    );
  };

  const handleTaxTypeChange = (e, index) => {
    const { value } = e.target;
    const updateTaxInfo = salesData?.taxInfo?.map((item, i) => (index === i ? { ...item, taxName: value } : item));
    setTaxDetails(updateTaxInfo);
    dispatch(
      setSalesByIdData({
        ...salesData,
        taxInfo: updateTaxInfo,
      })
    );
  };

  const handleTaxPercentageChange = (e, index) => {
    const { value } = e.target;
    const updateTaxPercentage = salesData?.taxInfo?.map((item, i) =>
      index === i ? { ...item, taxPercentage: parseFloat(value), taxAmount: calculateTaxAmount(discountedSubTotal, parseFloat(value)) } : item
    );
    setTaxDetails(updateTaxPercentage);
    dispatch(
      setSalesByIdData({
        ...salesData,
        taxInfo: updateTaxPercentage,
      })
    );
  };

  const handleOperationClick = (operation, index) => {
    setShowOperationModal(true);
    if (!operationForm[index]?.hasOwnProperty(operation.value)) {
      operationForm[index] = {
        ...operationForm[index],
        [operation.value]: {
          operationType: operation.value,
          operationTitle: operation?.label,
          stockOnHand: products[index]?.quantity,
          quantity: products[index]?.quantity,
        },
      };
    }
    setOperationDataByIndex(operationForm[index][operation.value]);
    setOperationIndex(index);
  };

  const handleCloseOperationModal = (formData, rowIndex, type) => {
    if (formData && rowIndex >= 0) {
      const updateFormData = type !== 'close' ? formData : null;
      let updateOperations = operationForm?.map((operation, i) => {
        if (i !== rowIndex) return operation;

        const updatedOperation = { ...operation };

        if (!updatedOperation.hasOwnProperty(formData.operationType)) {
          updatedOperation[formData.operationType] = { ...updateFormData };
        } else {
          updatedOperation[formData.operationType] = {
            ...updatedOperation[formData.operationType],
            ...updateFormData,
          };
        }

        return updatedOperation;
      });
      setOperationForm([...updateOperations]);
      dispatch(setEditOperationsData([...updateOperations]));
    }
    setShowOperationModal(false);
  };

  const getValidatedFormResult = () => {
    let valid = true;
    products?.map((ordItem) => {
      if (
        (!salesData.freeTextProductName && (!ordItem?.lvProductId?.value || !ordItem?.quantity || !ordItem?.rate)) ||
        (salesData.freeTextProductName && (!ordItem?.productName || !ordItem?.unit || !ordItem?.quantity || !ordItem?.rate))
      ) {
        valid = false;
      }
    });
    if (showTaxDetails) {
      taxDetails?.map((taxRow) => {
        if (!taxRow?.taxName?.trim() || !taxRow.taxPercentage) {
          valid = false;
        }
      });
    }
    if (showShipping && !extraCost.shippingCharges) {
      valid = false;
    }
    if (showDiscount && !extraCost.discount) {
      valid = false;
    }
    return valid;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'id-procure' : undefined;
  const [procureStepperData, setProcureStepperData] = useState({});

  const openProcureStepper = (event, procureData) => {
    setAnchorEl(event.currentTarget);
    setProcureStepperData(procureData);
  };

  const openProcureStepperClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open ? 'id-produce' : undefined;
  const [produceStepperData, setProduceStepperData] = useState({});

  const openProduceStepper = (event, produceData) => {
    setAnchorEl1(event.currentTarget);
    setProduceStepperData(produceData);
  };

  const openProduceStepperClose = () => {
    setAnchorEl1(null);
  };

  const handleSaveOrder = async () => {
    try {
      const operations = operationForm?.map((op) => {
        const prepareObj = {};
        if (op.hasOwnProperty('workOrder')) {
          prepareObj.workOrder = {
            isWorkOrderRequired: op?.workOrder?.isCreateOperation,
            quantity: op?.workOrder?.quantity,
            notes: op?.workOrder?.notes,
          };
        } else {
          prepareObj.workOrder = {
            isWorkOrderRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('qualityControl')) {
          prepareObj.qualityControl = {
            isQualityCheckRequired: op?.qualityControl?.isCreateOperation,
            notes: op?.qualityControl?.notes,
          };
        } else {
          prepareObj.qualityControl = {
            isQualityCheckRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('packaging')) {
          prepareObj.packaging = {
            isPackagingRequired: op?.packaging?.isCreateOperation,
            notes: op?.packaging?.notes,
          };
        } else {
          prepareObj.packaging = {
            isPackagingRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('logisticDelivery')) {
          prepareObj.shipping = {
            isShippingRequired: op?.shipping?.isCreateOperation,
            notes: op?.shipping?.notes,
          };
        } else {
          prepareObj.shipping = {
            isShippingRequired: false,
            notes: '',
          };
        }
        return prepareObj;
      });
      const reqObj = {
        taxInfos: taxDetails.map((tx) => ({
          ...tx,
          id: tx?.id ? tx.id : crypto.randomUUID(),
        })),
        freeTextProductName: salesData?.freeTextProductName,
        shippingCharges: extraCost?.shippingCharges,
        discount: extraCost?.discount,
        totalAmount: totalAmount,
        salesOrderItems: products?.map((pr, index) => ({
          ...pr,
          ...operations[index],
        })),
      };
      setSubmitted(true);
      setOrderSubmitLoading(true);
      if (getValidatedFormResult()) {
        const response = await api.put(`${salesTransactionEndpoint}/${salesId}`, reqObj);
        //   const response = await api.put(`${orderItemsEndpoint}/${salesId}/BatchUpdate`, reqObj);
        // if (deletedProduct?.length) {
        //   const deletedRes = await api.delete(`${orderItemsEndpoint}/${salesId}/BatchDelete`, {
        //     data: deletedProduct,
        //   });
        // }
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.salesOrderItemUpdated}`,
        });
        setOrderSubmitLoading(false);
      } else {
        setOrderSubmitLoading(false);
      }
    } catch (error) {
      setOrderSubmitLoading(false);
      console.log('Error Occured', error);
    }
  };

  return (
    <div className="adj-container">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={openProcureStepperClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="p-3">
          <SalesStepper visulization={procureStepperData} />
        </div>
      </Popover>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={openProduceStepperClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="p-3">
          <SalesStepper visulization={produceStepperData} />
        </div>
      </Popover>
      <CreateItemModal />
      <div className={`d-flex flex-grow-1 flex-column px-4}`}>
        <CreateOperationsModal showOperationModal={showOperationModal} operationData={operationDataByIndex} rowIndex={operationIndex} handleCloseOperationModal={handleCloseOperationModal} />
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
        <div className="d-flex">
          <Typography variant="h6" gutterBottom>
            Items
          </Typography>
          {salesData?.orderStatus === OrderStatusValue.Draft ? (
            <FormControlLabel
              className="ms-3"
              control={<Switch name="freeTextProductName" inputProps={{ 'aria-label': 'controlled' }} />}
              label="Link to inventory"
              checked={!salesData?.freeTextProductName}
              disabled="true"
              // onChange={handleLinkedToInventory}
            />
          ) : null}
        </div>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer className="mt-2" size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="32%">
                      <span className="px-4">Item Name *</span>
                    </TableCell>
                    <TableCell align="right" width="26%">
                      Quantity *
                    </TableCell>
                    <TableCell width="13%">Unit</TableCell>
                    <TableCell align="right" width="12%">
                      Rate *
                    </TableCell>
                    <TableCell align="right" width="14%">
                      <div className="pe-4">Amount</div>
                    </TableCell>
                    <TableCell align="center" width="3%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((row, productIndex) => (
                    <TableRow key={productIndex}>
                      <TableCell width="32%" className="vertical-align-top">
                        {/* <div className="pt-3 pb-1 ps-2"> */}
                        <div className="mt-0">
                          {!salesData.freeTextProductName ? (
                            <CustomAutocomplete
                              value={row?.lvProductId || ''}
                              onChange={(e, newValue) => handleItemChange(newValue, productIndex)}
                              disabledValues={products?.map((pitem) => pitem?.productId)?.filter((dis) => dis !== row?.productId)}
                              options={productItemOptions || []}
                              placeholder="Search..."
                              getOptionLabel={(option) => option.label || ''}
                              isError={!row?.lvProductId?.value && submitted}
                              disabled={salesData?.orderStatus != OrderStatusValue.Draft}
                              // helperText={!row?.lvProductId?.value && submitted ? 'Item is required.' : ''}
                            />
                          ) : (
                            <>
                              <TextField
                                value={row?.productName}
                                name="productName"
                                variant="outlined"
                                onChange={(e) => handleProductChange(e, productIndex)}
                                error={!row?.productName && submitted}
                                disabled={salesData?.orderStatus != OrderStatusValue.Draft}
                              />
                            </>
                          )}

                          {salesData?.orderStatus === OrderStatusValue.Draft ? (
                            <div className="d-flex operations-chip-container">
                              {OperationsChips?.map((item, opIndex) => (
                                <>
                                  {item?.shouldDisplay ? (
                                    <BootstrapTooltip
                                      title={`${operationForm[productIndex]?.hasOwnProperty(item.value) && operationForm[productIndex][item.value]?.isCreateOperation ? 'Deselect' : 'Select'} ${
                                        item.label
                                      }`}
                                    >
                                      <Chip
                                        key={opIndex}
                                        className="mx-2 mt-2"
                                        onClick={() => {
                                          handleOperationClick(item, productIndex);
                                        }}
                                        label={`${item.label}`}
                                        variant={operationForm[productIndex]?.hasOwnProperty(item.value) && operationForm[productIndex][item.value]?.isCreateOperation ? '' : 'outlined'}
                                        color="primary"
                                        clickable
                                      />
                                    </BootstrapTooltip>
                                  ) : null}
                                </>
                              ))}
                            </div>
                          ) : (
                            <>
                              {row?.stepperVisualization?.produceVisualization &&
                              Object.keys(row?.stepperVisualization?.produceVisualization)?.length &&
                              row?.stepperVisualization?.procureVisualization &&
                              Object.keys(row?.stepperVisualization?.procureVisualization)?.length ? (
                                <>
                                  <Chip
                                    className="mx-2 mt-2"
                                    onClick={(event) => {
                                      openProduceStepper(event, row?.stepperVisualization?.produceVisualization);
                                    }}
                                    label="Produce"
                                    variant="outlined"
                                    color="primary"
                                    clickable
                                  />

                                  <Chip
                                    className="mx-2 mt-2"
                                    onClick={(event) => {
                                      openProcureStepper(event, row?.stepperVisualization?.procureVisualization);
                                    }}
                                    label="Procure"
                                    variant="outlined"
                                    color="primary"
                                    clickable
                                  />
                                </>
                              ) : row?.stepperVisualization?.produceVisualization && Object.keys(row?.stepperVisualization?.produceVisualization)?.length ? (
                                <div className="d-flex">
                                  <b className="mt-2"> Produce:</b>
                                  <SalesStepper visulization={row?.stepperVisualization?.produceVisualization} />
                                </div>
                              ) : row?.stepperVisualization?.procureVisualization && Object.keys(row?.stepperVisualization?.procureVisualization)?.length ? (
                                <div className="d-flex">
                                  <b className="mt-2"> Procure:</b> <SalesStepper visulization={row?.stepperVisualization?.procureVisualization} />
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="right" width="26%" className="position-relative vertical-align-top">
                        <div>
                          <TextField
                            type="number"
                            id="quantity"
                            value={row?.quantity}
                            name="quantity"
                            variant="outlined"
                            onChange={(e) => handleProductChange(e, productIndex)}
                            InputProps={{
                              inputProps: {
                                style: { textAlign: 'right' },
                              },
                            }}
                            error={!row?.quantity && submitted}
                            disabled={salesData?.orderStatus != OrderStatusValue.Draft}
                            // helperText={!row?.quantity && submitted ? 'Quantity is required.' : ''}
                          />
                          {row.quantity > row.stockOnHand && (
                            <p style={{ color: 'red', position: 'absolute' }}>
                              <i>
                                <b>Warning:</b> Quantity value exceeds from stock on hand
                              </i>
                            </p>
                          )}
                        </div>
                      </TableCell>
                      <TableCell width="13%" className="vertical-align-top">
                        {!salesData.freeTextProductName ? (
                          <div className="mt-2"> {row?.unit} </div>
                        ) : (
                          <TextField
                            value={row?.unit}
                            name="unit"
                            variant="outlined"
                            onChange={(e) => handleProductChange(e, productIndex)}
                            error={!row?.unit && submitted}
                            disabled={salesData?.orderStatus != OrderStatusValue.Draft}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right" width="12%" className="vertical-align-top">
                        <div className="d-flex mt-0 ps-2">
                          {currencyUnit && (
                            <div className="pe-1 mt-2">
                              <b>({currencyUnit}) </b>
                            </div>
                          )}
                          <TextField
                            className="ml-1"
                            type="number"
                            id="rate"
                            value={row?.rate}
                            name="rate"
                            variant="outlined"
                            onChange={(e) => handleProductChange(e, productIndex)}
                            InputProps={{
                              inputProps: {
                                style: { textAlign: 'right' },
                              },
                            }}
                            error={!row?.rate && submitted}
                            disabled={salesData?.orderStatus != OrderStatusValue.Draft}
                            // helperText={!row?.rate && submitted ? 'Rate is required.' : ''}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="right" width="14%" className="vertical-align-top">
                        <div className="mt-0 pe-4">
                          <span>
                            {currencyUnit && (
                              <span className="pe-1">
                                <b> ({currencyUnit})</b>
                              </span>
                            )}
                            {row.amount}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="center" width="3%" className="vertical-align-top">
                        {products?.length !== 1 && salesData?.orderStatus == 0 ? (
                          <BootstrapTooltip title="Delete">
                            <IconButton className="pt-3 pb-5" color="info" onClick={() => handleRemoveProduct(productIndex)}>
                              <Delete />
                            </IconButton>
                          </BootstrapTooltip>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan="6">
                      <BootstrapTooltip title="Add Item">
                        <Button size="small" disabled={salesData?.orderStatus != OrderStatusValue.Draft} className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddProduct}>
                          Add Item
                        </Button>
                      </BootstrapTooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" colSpan="4">
                      <b>Subtotal</b>
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {currencyUnit && (
                          <span className="pe-1">
                            <b> ({currencyUnit})</b>
                          </span>
                        )}
                        {subTotalProductAmount}
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {showDiscount && (
                    <TableRow>
                      <TableCell align="right" colSpan="4">
                        <b>Discount</b>
                      </TableCell>
                      <TableCell align="right">
                        <div>{showDiscount ? extraCost.discount + '% (Amount: ' + (currencyUnit ? '(' + currencyUnit + ') ' : '') + parseFloat(discountAmount?.toFixed(2)) + ')' : 0} </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {showShipping && (
                    <TableRow>
                      <TableCell align="right" colSpan="4">
                        <b>Shipping </b>
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          {currencyUnit && (
                            <span className="pe-1">
                              <b> ({currencyUnit})</b>
                            </span>
                          )}
                          {showShipping ? extraCost.shippingCharges : 0}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {showTaxDetails && (
                    <TableRow>
                      <TableCell align="right" colSpan="4">
                        <b>Tax Details</b>
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          {currencyUnit && (
                            <span className="pe-1">
                              <b> ({currencyUnit})</b>
                            </span>
                          )}
                          {parseFloat(extraCost?.tax?.toFixed(2))}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan="4" align="right">
                      <b>Total Amount</b>
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {currencyUnit && (
                          <span className="pe-1">
                            <b> ({currencyUnit})</b>
                          </span>
                        )}
                        {parseFloat(totalAmount?.toFixed(2))}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={`mt-1 ${salesData?.orderStatus != OrderStatusValue.Draft ? 'item-disabled' : ''}`}>
          <Grid item md={2} sm={6} xs={12}>
            <div className="d-flex">
              <Checkbox
                size="small"
                name="shippingCost"
                checked={showShipping}
                onChange={(e) => {
                  {
                    handleCheckboxChange('shipping', e.target.checked);
                  }
                  if (!e.target.checked) {
                    handleExtraCostChange({ target: { name: 'shippingCharges', value: 0 } });
                  }
                }}
              />
              <Typography className="mt-2"> Include shipping cost</Typography>
            </div>
            {showShipping && (
              <div>
                <TextField
                  className="ms-2 mt-1"
                  type="number"
                  id="shippingCharges"
                  label={`Shipping Rate (in ${!currencyUnit ? 'currency' : currencyUnit})`}
                  value={extraCost.shippingCharges}
                  onChange={handleExtraCostChange}
                  name="shippingCharges"
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                  error={!extraCost?.shippingCharges && submitted}
                />
              </div>
            )}
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="d-flex">
              <Checkbox
                size="small"
                name="shippingCost"
                checked={showDiscount}
                onChange={(e) => {
                  handleCheckboxChange('discount', e.target.checked);
                  if (!e.target.checked) {
                    handleExtraCostChange({ target: { name: 'discount', value: 0 } });
                  }
                }}
              />
              <Typography className="mt-2"> Include discount</Typography>
            </div>
            {showDiscount && (
              <div>
                <TextField
                  className="ms-2 mt-1"
                  type="number"
                  label="Discount (percentage)"
                  name="discount"
                  value={extraCost.discount}
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value) : 0;
                    if (value >= 0 && value <= 100) {
                      handleExtraCostChange(e);
                    }
                  }}
                  InputProps={{
                    endAdornment: <Typography className="ml-2">%</Typography>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      style: { textAlign: 'right' },
                    },
                  }}
                  error={!extraCost?.discount && submitted}
                />
              </div>
            )}
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <div className="d-flex">
              <Checkbox
                size="small"
                name="shippingCost"
                checked={showTaxDetails}
                onChange={(e) => {
                  handleCheckboxChange('taxDetails', e.target.checked);
                  if (!e.target.checked) {
                    setTaxDetails([emptyTaxObj]);
                  }
                }}
              />
              <Typography className="mt-2"> Include tax details</Typography>
            </div>
            {showTaxDetails && (
              <>
                <TableContainer size="small" component={Paper} className="mt-1">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="30%">Tax Type *</TableCell>
                        <TableCell align="right" width="30%">
                          Tax Percentage (%) *
                        </TableCell>
                        <TableCell align="right" width="30%">
                          Amount
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taxDetails.map((taxRow, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <TextField
                              id="taxType"
                              value={taxRow.taxName}
                              name="taxType"
                              variant="outlined"
                              onChange={(e) => handleTaxTypeChange(e, index)}
                              error={!taxRow?.taxName && submitted}
                              // helperText={!taxRow?.taxName && submitted ? 'Tax Type is required.' : ''}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              type="number"
                              id="taxPercentage"
                              value={taxRow.taxPercentage}
                              name="taxPercentage"
                              variant="outlined"
                              onChange={(e) => {
                                const value = e.target.value ? parseInt(e.target.value) : 0;
                                if (value >= 0 && value <= 100) {
                                  handleTaxPercentageChange(e, index);
                                }
                              }}
                              InputProps={{
                                endAdornment: <Typography className="ml-2">%</Typography>,
                                inputProps: {
                                  min: 0,
                                  max: 100,
                                  style: { textAlign: 'right' },
                                },
                              }}
                              error={!taxRow?.taxPercentage && submitted}
                              // helperText={!taxRow?.taxPercentage && submitted ? 'Tax Percentage (%) is required.' : ''}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {currencyUnit && (
                              <span className="pe-1">
                                <b> ({currencyUnit})</b>
                              </span>
                            )}
                            {parseFloat(calculateTaxAmount(discountedSubTotal, taxRow.taxPercentage)?.toFixed(2))}
                          </TableCell>
                          <TableCell className="d-flex justify-content-center">
                            <BootstrapTooltip title="Delete">
                              <IconButton className="p-2" color="info" onClick={() => handleRemoveTaxDetail(index)}>
                                <Delete />
                              </IconButton>
                            </BootstrapTooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={4}>
                          <BootstrapTooltip title="Add Item">
                            <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddTaxDetail}>
                              Add Tax
                            </Button>
                          </BootstrapTooltip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </Grid>
        <div className="mt-4 d-flex">
          <Button
            size="small"
            variant="contained"
            onClick={handleSaveOrder}
            disabled={orderSubmitLoading || [OrderStatusValue.Completed, OrderStatusValue.Cancelled, OrderStatusValue.Pending].includes(salesData?.orderStatus)}
          >
            Submit
          </Button>
          {orderSubmitLoading && <CircularProgress size={24} className="m-2" />}
          {salesData?.orderNumber ? (
            renderLastModifiedData(salesData?.lastModifiedByUser?.firstName, salesData?.lastModifiedByUser?.lastName, salesData?.lastModifiedAt)
          ) : (
            <div className="d-flex justify-content-center">
              <CircularProgress className="mx-2" size={15} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

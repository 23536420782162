import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography } from '@mui/material';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import { API_DOCUMENTS_ENDPOINT, API_WORKFLOW_BASE_URL } from '../../constants/constants';
import { setDocumentFileData } from '../redux/actions/document-action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({ editMode = true, documentId, emitLogoId }) {
  const [token, setToken] = useToken();
  const documentUploadApi = API_WORKFLOW_BASE_URL + API_DOCUMENTS_ENDPOINT;
  const dispatch = useDispatch();
  const { file } = useSelector((state) => state?.document?.data);
  const [fileData, setFileData] = useState({
    name: '',
    type: '',
  });

  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (documentId) {
      getFileLogoUrl(documentId);
    }
  }, [documentId]);

  useEffect(() => {
    if (!logoUrl && file?.fileName && file?.url) {
      setFileData({
        ...fileData,
        name: file?.fileName,
      });
      setLogoUrl(file?.url);
    }
  }, [file]);

  const getFileLogoUrl = (docId) => {
    axios
      .get(`${documentUploadApi}/${docId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        setLogoUrl(imageUrl);
        const fileObj = {
          url: imageUrl,
          fileName: file?.name,
        };
        dispatch(setDocumentFileData(fileObj));
      });
  };

  const handleFileUploadChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('Visibility', 0);
      formData.append('Permissions', JSON.stringify([]));
      setFileData({
        name: file?.name,
        type: file?.type,
      });

      axios
        .post(documentUploadApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const docId = response?.data?.id;
          emitLogoId(docId);
          getFileLogoUrl(docId);
        })
        .catch((error) => {
          console.error('Error uploading the file or updating the account:', error);
        });
    }
  };

  return (
    <div className="d-flex">
      {editMode ? (
        <Button size="small" component="label" role={undefined} variant="outlined" tabIndex={-1} startIcon={<CloudUploadIcon size="small" />}>
          Choose File
          <VisuallyHiddenInput type="file" onChange={handleFileUploadChange} />
        </Button>
      ) : null}
      {logoUrl ? <img src={logoUrl} alt="Item Image" className="logo" /> : ''}
      {/* {fileData?.name ? <Typography className="label mx-2 mt-1">{fileData?.name}</Typography> : null} */}
    </div>
  );
}

export const AdjustmentStatus = {
  Pending: 'Pending',
  Adjusted: 'Adjusted',
  Abandoned: 'Abandoned',
};

export const AdjustmentStatusValue = {
  Unknown: 0,
  Pending: 1,
  Adjusted: 2,
  Abandoned: 3,
};

export const ConfirmationStatus = {
  Warning: 'Warning',
  Error: 'Error',
  Info: 'Info',
};

export const AlertStatus = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
};

export const ItemType = {
  RawMaterial: 'RawMaterial',
  FinishedGoods: 'FinishedGoods',
  Consumables: 'Consumables',
  Services: 'Services',
  PackagingMaterial: 'PackagingMaterial',
};

export const OrderStatus = {
  Draft: 'Draft',
  Pending: 'Pending',
  InProgress: 'InProgress',
  Shipped: 'Shipped',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const OrderStatusValue = {
  Draft: 0,
  Pending: 1,
  InProgress: 2,
  Shipped: 3,
  Completed: 4,
  Cancelled: 5,
};

export const StockAllocationStatus = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Allocated: 'Allocated',
  Cancelled: 'Cancelled',
};

export const StockTrackingStatus = {
  // NeedsAttention: 'Needs Attention',
  Draft: 'Draft',
  Pending: 'Pending',
  Completed: 'Completed',
};

export const WorkOrderStatusValue = {
  Draft: 0,
  InProgress: 1,
  Completed: 2,
  Cancelled: 3,
};

export const WorkOrderStatus = {
  Draft: 'Draft',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const woKebabArray = [{ label: 'Cancel Workorder', value: 3 }];

export const QualityControlStatus = {
  Pending: 'Pending',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const QualityControlStatusValue = {
  Pending: 0,
  Completed: 1,
  Cancelled: 2,
};

export const qcKebabArray = [{ label: 'Cancel Quality Control Order', value: 2 }];

export const LogisticsDeliveryStatus = {
  Pending: 'Pending',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const LogisticsDeliveryStatusValue = {
  Pending: 0,
  Completed: 1,
  Cancelled: 2,
};

export const LogisticsDeliveryKebabArray = [{ label: 'Cancel Logistics & Delivery Order', value: 2 }];

export const PackagingControlStatus = {
  Pending: 'Pending',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const PackagingControlStatusValue = {
  Pending: 0,
  Completed: 1,
  Cancelled: 2,
};

export const pcKebabArray = [{ label: 'Cancel Packaging Control Order', value: 2 }];

export const UserType = {
  Admin: 'Admin',
  User: 'Staff',
};

export const leadStatusOptions = [
  {
    value: 0,
    label: 'New',
  },
  {
    value: 1,
    label: 'Qualified',
  },
  {
    value: 2,
    label: 'Proposition',
  },
  {
    value: 3,
    label: 'Won',
  },
  {
    value: 4,
    label: 'Lost',
  },
];

export const LeadStatus = {
  Draft: 'New',
  Qualified: 'Qualified',
  Proposition: 'Proposition',
  Won: 'Won',
  Lost: 'Lost',
};

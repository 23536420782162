export const Messages = {
  apiError: 'Something went wrong, please contact to administrator.',
  genericError: 'An error occurred while deleting the items:',
  adjustmentDeleted: 'Adjustments has been deleted successfully',
  adjustmentReasonAdd: 'Adjustment reason has been added successfully',
  adjustmentStatusChange: 'Adjustment status has been changed successfully to',
  adjustmentAdded: 'Adjustment with reference : {reference} has been created successfully',
  adjustmentUpdated: 'Adjustment with reference : {reference} has been updated successfully',
  skuAdded: 'SKU has been added successfully',
  locationAdded: 'Location has been added successfully',
  itemAdded: 'Item has been added successfully',
  itemDeleted: 'Item has been deleted successfully',
  categoryAdded: 'Category has been added successfully',
  itemUpdated: 'Item has been updated successfully',
  productLocationUpdate: 'Location has been successfully added to the item.',
  accountCreated: 'Account has been created successfully',
  accountUpdated: 'Account has been updated successfully',
  accountDeleted: 'Account has been deleted successfully',
  primaryContactCreated: 'Contact has been created successfully',
  primaryContactUpdated: 'Contact has been updated successfully',
  primaryContactDelete: 'Contact has been deleted successfully',
  salesCreated: 'Sales Order has been created successfully',
  salesUpdated: 'Sales Order been updated successfully',
  salesDeleted: 'Sales Order has been deleted successfully',
  deliveryMethodAdded: 'Delivery Method has been added successfully',
  customerCreated: 'Customer has been created successfully',
  salesOrderCreated: 'Sales Order has been created successfully',
  salesOrderUpdated: 'Sales Order has been updated successfully',
  salesOrderItemUpdated: 'Sales Order Items has been updated successfully',
  orderStatusChanged: 'Order status has been changed to submitted',
  deletelocationerror: 'Cannot delete locations with non-zero capacity. Please ensure capacity is zero before deleting.',
  stockAllocationSaved: 'Stock Allocation has been saved successfully',
  stockAllocationSubmitted: 'Stock Allocation has been submitted successfully',
  stockAllocationStatusUpdate: 'Stock Allocation Status has been updated successfully',
  salesOrderCompleted: 'Sales order is completed successfully',
  salesOrderCancelled: 'Sales order is cancelled successfully',
  workOrderDeleted: 'Work order has been deleted successfully',
  bomOrderCreated: 'Bills Of Materials created successfully',
  bomOrderUpdated: 'Bills Of Materials updated successfully',
  routingCreated: 'Route created successfully',
  routingUpdated: 'Route updated successfully',
  workorderSaved: 'Workorder has been saved successfully',
  workOrderSubmitted: 'Workorder has been submitted successfully',
  pkgOrderSaved: 'Packaging Order has been saved successfully',
  pkgOrderSubmitted: 'Packaging Order has been submitted successfully',
  qcSaved: 'Quality Control has been saved successfully',
  qcSubmitted: 'Quality Control has been submitted successfully',
  ldSaved: 'Logistics & Delivery data has been saved successfully',
  ldSubmitted: 'Logistics & Delivery data has been submitted successfully',
  woCancelled: 'Workorder has been cancelled sucessfully',
  qcCancelled: 'Quality Control Order has been cancelled sucessfully',
  pcCancelled: 'Packaging Order has been cancelled sucessfully',
  ldCancelled: 'Logistics & Delivery Order has been cancelled sucessfully',
  leadsCreate: 'Leads has been created successfully',
  leadsCreate: 'Leads has been created successfully',
  leadsUpdate: 'Leads has been updated successfully',
  quotationCreated: 'Quotation has been created succcessfully',
  quotationUpdated: 'Quotation has been updated successfully',
};

import { TYPES } from '../type-constants/type';

const initialState = {
  data: {},
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_DOCUMENT_FILE_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case TYPES.RESET_DOCUMENT_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default documentReducer;

import { TYPES } from '../type-constants/type';

const initialState = {
  data: {},
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_DDL_TENANTS_DATA:
    case TYPES.SET_DDL_CONTACTS_DATA:
    case TYPES.SET_DDL_USERS_DATA:
    case TYPES.SET_LEADS_DATA:
    case TYPES.SET_EDIT_PRODUCTS_DATA:
    case TYPES.SET_EDIT_OPERATIONS_DATA:
    case TYPES.SET_DELETED_ITEMS_DATA:
    case TYPES.OPEN_ITEM_MODAL:
    case TYPES.CLOSE_ITEM_MODAL:
    case TYPES.SET_LEADS_QUOTATION_DATA:
    case TYPES.SET_PRODUCTS_DATA:
    case TYPES.SET_CONTACTS_LIST:
    case TYPES.SET_TENANTS_LIST:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case TYPES.RESET_LEADS_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default leadsReducer;

import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_PRODUCTS_ENDPOINT, API_SALES_DELIVERY_NOTES_ENDPOINT, API_SALES_TRANSACTIONS_ENDPOINT, API_TENANTS_ENDPOINT, API_USERS_ENDPOINT } from '../../../../constants/constants';
import { useToken } from '../../../auth/useToken';
import { Messages } from '../../../common-constants/messages';
import { OperationsChips, PaymentTermsTypes } from '../../../common-constants/static-values';
import { AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomAutocomplete from '../../../common/autocomplete';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { BootstrapTooltip, formatEntityLabelValue, formatProduct, formatProductForSales, generateEntityNumber, getCurrencyOptions } from '../../../common/commonUtility';
import { CreateCustomerModal } from '../../../modals/create-customer';
import { CreateItemModal } from '../../../modals/create-item';
import { CreateOperationsModal } from '../../../modals/create-operations';
import { DeliveryMethodModal } from '../../../modals/delivery-method';
import { openAccountModal, resetAccountReducer } from '../../../redux/actions/account-action';
import { closeItemModal, openItemModal } from '../../../redux/actions/sales-action';

export const NewOrder = () => {
  const { isAccountModalOpen, accountData } = useSelector((state) => state?.account?.data);
  const { newItemModalData, itemData } = useSelector((state) => state?.sales?.data);
  const customerEndpoint = API_TENANTS_ENDPOINT;
  const usersEndpoint = API_USERS_ENDPOINT;
  const deliveryNoteEndpoint = API_SALES_DELIVERY_NOTES_ENDPOINT;
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const salesTransactionEndpoint = API_SALES_TRANSACTIONS_ENDPOINT;
  const dispatch = useDispatch();
  let formatedReasonsOptions = [];
  const defaultProduct = {
    productId: '00000000-0000-0000-0000-000000000000',
    lvProductId: '',
    productName: '',
    quantity: 0,
    unit: '',
    rate: 0,
    remarks: '',
    amount: 0,
  };
  const salesDefaultValue = {
    customerId: '',
    lvCustomerId: '',
    currency: '',
    lvCurrency: '',
    salesPerson: '',
    lvSalesPerson: '',
    orderNumber: generateEntityNumber('ORD'),
    orderDate: dayjs(new Date()),
    shipmentDate: null,
    paymentTerms: '',
    lvPaymentTerms: '',
    paymentRemarks: '',
    deliveryNoteId: '',
    lvDeliveryNote: '',
    orderStatus: '', // Set the appropriate status
    paymentStatus: 0, // Set the appropriate status
    totalAmount: '',
    shippingCharges: 0,
    additionalCharges: 0,
    currency: '',
    remarks: '',
    freeTextProductName: false,
    salesOrderItems: [defaultProduct],
    taxInfos: [{ taxName: '', taxPercentage: 0, taxAmount: 0 }],
    discount: 0,
  };
  const [salesOrderForm, setSalesOrderForm] = useState(salesDefaultValue);
  const [operationDataByIndex, setOperationDataByIndex] = useState([]);
  let [operationIndex, setOperationIndex] = useState('');
  const [operationForm, setOperationForm] = useState([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [customerOptions, setCustomerOptions] = useState();
  const [salesPersonOptions, setSalesPersonOptions] = useState();
  const [deliveryNotesOptions, setDeliveryNotesOptions] = useState();
  const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false);
  const [productItemOptions, setProductItemOptions] = useState('');
  const [products, setProducts] = useState([defaultProduct]);
  const [extraCost, setExtraCost] = useState({
    tax: 0,
    shipping: 0,
    discount: 0,
    // Add more extra cost details as needed
  });
  const emptyTaxObj = { taxName: '', taxPercentage: 0, taxAmount: 0 };
  const [taxDetails, setTaxDetails] = useState([emptyTaxObj]);
  const subTotalProductAmount = products.reduce((total, product) => total + product.amount, 0);
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Sales',
      href: '/sales',
    },
    {
      name: 'New Order',
      active: true,
    },
  ];
  const currencyOptions = getCurrencyOptions();
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };
  const [token, setToken] = useToken();
  const navigate = useNavigate();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);
  const calculateTaxAmount = (totalAmount, taxPercentage) => {
    if (!isNaN(taxPercentage) && !isNaN(totalAmount)) {
      return (totalAmount * taxPercentage) / 100;
    }
    return 0;
  };
  const discountedSubTotal = subTotalProductAmount * (1 - extraCost.discount / 100);
  const totalTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
  const updatedTotalAmount = discountedSubTotal + totalTaxAmount + Number(extraCost.shipping);

  const [totalAmount, setTotalAmount] = useState(updatedTotalAmount);
  useEffect(() => {
    fetchAccounts();
    fetchSalesPerson();
    fetchDeliveryNote();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (accountData?.tenantId) {
      fetchAccounts();
      const customer = { label: accountData?.name, value: accountData?.tenantId };
      handleCustomerChange(customer);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${accountData?.name}' ${Messages.customerCreated}`,
      });
    }
    if (!isAccountModalOpen) {
      dispatch(resetAccountReducer());
    }
  }, [isAccountModalOpen, accountData]);

  useEffect(() => {
    const fetchAndHandleItem = async () => {
      if (itemData?.productId) {
        await fetchProducts();
        const product = formatedReasonsOptions?.find((item) => item?.value === itemData?.productId);
        handleItemChange(product, newItemModalData?.index);
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `'${itemData?.productName}' ${Messages.itemAdded}`,
        });
      }
    };
    fetchAndHandleItem();
    if (!newItemModalData?.isItemModalOpen) {
      dispatch(closeItemModal());
    }
  }, [newItemModalData?.isItemModalOpen, itemData]);

  useEffect(() => {
    const updatedTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
    const updatedShippingCost = Number(extraCost.shipping);
    const discountAmount = subTotalProductAmount * (Number(extraCost.discount) / 100);
    const updatedTotalAmount = discountedSubTotal + updatedTaxAmount + updatedShippingCost;

    setDiscountAmount(discountAmount);

    setExtraCost((prevExtraCost) => ({ ...prevExtraCost, tax: updatedTaxAmount }));
    setTotalAmount(updatedTotalAmount);
  }, [JSON.stringify(products), JSON.stringify(taxDetails), extraCost.shipping, extraCost.discount, discountAmount]);

  const fetchAccounts = async () => {
    try {
      const response = await api.get(`${customerEndpoint}?pageNumber=1&pageSize=100`);
      response.data.tenants = response.data.tenants?.map((item) => ({
        ...item,
        user: `${item?.accountOwner?.firstName} ${item?.accountOwner?.lastName}`,
      }));
      const accountsData = response.data?.tenants?.filter((to) => to?.tenantType?.toLowerCase() === 'customer');
      const customerOptions = formatEntityLabelValue(accountsData, 'name', 'id');
      setCustomerOptions(customerOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSalesPerson = async () => {
    try {
      const uri = `${usersEndpoint}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response.data?.users.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      const userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setSalesPersonOptions(userData);
    } catch (error) {
      console.error('Error fetching salesperson suggestions:', error);
    }
  };

  const fetchDeliveryNote = async () => {
    try {
      const response = await api.get(`${deliveryNoteEndpoint}?pageNumber=1&pageSize=100`);
      const deliveryNotesOptions = formatEntityLabelValue(response.data, 'name', 'id');
      setDeliveryNotesOptions(deliveryNotesOptions);
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedReasonsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSalesControlChange = (e) => {
    const { name, value } = e.target;
    setSalesOrderForm({
      ...salesOrderForm,
      [name]: value,
    });
  };

  const handleLinkedToInventory = (e) => {
    const { name, checked } = e.target;
    const emptyProducts = products?.map((item) => ({ ...item, ...defaultProduct }));
    setProducts(emptyProducts);
    setSalesOrderForm({
      ...salesOrderForm,
      [name]: checked,
    });
  };

  const handleCloseDeliveryNote = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event?.id) {
      setSalesOrderForm({
        ...salesOrderForm,
        deliveryNoteId: event?.id,
        lvDeliveryNote: { label: event?.name, value: event?.id },
      });
      fetchDeliveryNote();
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${event?.name}' ${Messages.deliveryMethodAdded}`,
      });
    }
    setShowDeliveryNoteModal(false);
  };

  const handleAddProduct = () => {
    setProducts((prevProducts) => [...prevProducts, defaultProduct]);
  };

  const handleRemoveProduct = (index) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });
  };

  const handleItemChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openItemModal(index));
    } else if (newValue?.formattedObject) {
      const formattedObject = newValue?.formattedObject;
      products[index] = {
        ...products[index],
        productId: formattedObject?.productId || '',
        lvProductId: {
          label: formattedObject?.selectedLabelForSales || '',
          value: formattedObject?.productId || '',
        },
        stockOnHand: formattedObject?.stockOnHand,
        quantity: formattedObject?.stockOnHand,
        unit: formattedObject?.unit,
        rate: formattedObject?.price,
        amount: parseFloat(formattedObject?.price) * parseFloat(formattedObject?.stockOnHand),
      };
    } else {
      products[index] = defaultProduct;
    }
  };

  const handleProductChange = async (e, index) => {
    const { name, value } = e.target;
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = { ...updatedProducts[index], [name]: value };
      const rate = parseFloat(updatedProducts[index].rate);
      const quantity = parseFloat(updatedProducts[index].quantity);

      if (!isNaN(rate) && !isNaN(quantity)) {
        updatedProducts[index].amount = rate * quantity;
      } else {
        updatedProducts[index].amount = 0;
      }
      return updatedProducts;
    });
  };

  const handleCustomerChange = async (newValue) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openAccountModal());
    } else if (newValue?.value) {
      try {
        const response = await api.get(`${customerEndpoint}/${newValue?.value}`);
        updateCustomerRelatedData(response?.data, newValue);
      } catch (error) {
        console.log('error', error);
      }
    } else {
      setSalesOrderForm({
        ...salesOrderForm,
        customerId: newValue?.value,
        lvCustomerId: { value: newValue?.value, label: newValue?.label },
      });
    }
  };

  const updateCustomerRelatedData = (cusByIdData, selectedCustomer) => {
    setSalesOrderForm({
      ...salesOrderForm,
      customerId: selectedCustomer?.value,
      lvCustomerId: { value: selectedCustomer?.value, label: selectedCustomer?.label },
      paymentTerms: cusByIdData?.tenantFinancials?.paymentTerms || '',
      lvPaymentTerms: cusByIdData?.tenantFinancials?.paymentTerms ? PaymentTermsTypes?.find((pt) => pt.value === cusByIdData?.tenantFinancials?.paymentTerms) : '',
      salesPerson: cusByIdData?.accountOwner?.id,
      lvSalesPerson: { label: `${cusByIdData?.accountOwner?.firstName} ${cusByIdData?.accountOwner?.lastName}`, value: cusByIdData?.accountOwner?.id },
      currency: cusByIdData?.tenantFinancials?.currency,
      lvCurrency: currencyOptions?.find((co) => co?.value === cusByIdData?.tenantFinancials?.currency),
    });
  };

  const handleExtraCostChange = (e) => {
    const { name, value } = e.target;
    setExtraCost((prevExtraCost) => ({ ...prevExtraCost, [name]: value }));
  };

  const handleCheckboxChange = (checkboxName, checked) => {
    switch (checkboxName) {
      case 'taxDetails':
        setShowTaxDetails(checked);
        break;
      case 'shipping':
        setShowShipping(checked);
        break;
      case 'discount':
        setShowDiscount(checked);
        break;
      default:
        break;
    }
  };

  const handleAddTaxDetail = () => {
    setTaxDetails((prevTaxDetails) => [...prevTaxDetails, { taxName: '', taxPercentage: 0, taxAmount: 0 }]);
  };

  const handleRemoveTaxDetail = (index) => {
    setTaxDetails((prevTaxDetails) => {
      const updatedTaxDetails = [...prevTaxDetails];
      updatedTaxDetails.splice(index, 1);
      return updatedTaxDetails;
    });
  };

  const handleTaxTypeChange = (e, index) => {
    const { value } = e.target;
    setTaxDetails((prevTaxDetails) => {
      const updatedTaxDetails = [...prevTaxDetails];
      updatedTaxDetails[index] = { ...updatedTaxDetails[index], taxName: value };
      return updatedTaxDetails;
    });
  };

  const handleTaxPercentageChange = (e, index) => {
    const { value } = e.target;
    setTaxDetails((prevTaxDetails) => {
      const updatedTaxDetails = [...prevTaxDetails];
      updatedTaxDetails[index] = {
        ...updatedTaxDetails[index],
        taxPercentage: parseFloat(value),
        taxAmount: calculateTaxAmount(discountedSubTotal, parseFloat(value)),
      };
      return updatedTaxDetails;
    });
  };

  const handleOperationClick = (operation, index) => {
    setShowOperationModal(true);
    // setOperationForm({});
    if (!operationForm[index]?.hasOwnProperty(operation.value)) {
      operationForm[index] = {
        ...operationForm[index],
        [operation.value]: {
          operationType: operation.value,
          operationTitle: operation?.label,
          stockOnHand: products[index]?.quantity,
          quantity: products[index]?.quantity,
        },
      };
    } else {
      operationForm[index][operation.value] = {
        ...operationForm[index][operation.value],
        operationType: operation.value,
        operationTitle: operation?.label,
        stockOnHand: products[index]?.quantity,
        quantity: operationForm[index][operation.value].quantity || products[index]?.quantity,
      };
    }
    setOperationDataByIndex(operationForm[index][operation.value]);
    setOperationIndex(index);
  };
  const handleCloseOperationModal = (formData, rowIndex, type) => {
    if (formData && rowIndex >= 0) {
      const updateFormData = type !== 'close' ? formData : {};
      if (!operationForm[rowIndex]?.hasOwnProperty(formData.operationType)) {
        operationForm[rowIndex] = {
          ...operationForm[rowIndex],
          [formData.operationType]: {
            ...updateFormData,
          },
        };
      } else {
        operationForm[rowIndex][formData.operationType] = {
          ...operationForm[rowIndex][formData.operationType],
          ...updateFormData,
        };
      }
    }
    setShowOperationModal(false);
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (
      !salesOrderForm.lvCustomerId?.value ||
      !salesOrderForm?.orderDate.isValid() ||
      !salesOrderForm.orderNumber ||
      (salesOrderForm.shipmentDate && !salesOrderForm.shipmentDate.isValid()) ||
      !salesOrderForm?.lvCurrency?.value ||
      !salesOrderForm?.lvSalesPerson?.value
    ) {
      return false;
    }
    products?.map((ordItem) => {
      if (
        (salesOrderForm.freeTextProductName && (!ordItem?.lvProductId?.value || !ordItem?.quantity || !ordItem?.rate)) ||
        (!salesOrderForm.freeTextProductName && (!ordItem?.productName || !ordItem?.unit || !ordItem?.quantity || !ordItem?.rate))
      ) {
        valid = false;
      }
    });
    if (showTaxDetails) {
      taxDetails?.map((taxRow) => {
        if (!taxRow?.taxName?.trim() || !taxRow.taxPercentage) {
          valid = false;
        }
      });
    }
    if (showShipping && !extraCost.shipping) {
      valid = false;
    }
    if (showDiscount && !extraCost.discount) {
      valid = false;
    }
    return valid;
  };

  const handleSaveOrder = async () => {
    try {
      const operations = operationForm?.map((op) => {
        const prepareObj = {};
        if (op.hasOwnProperty('workOrder')) {
          prepareObj.workOrder = {
            isWorkOrderRequired: op?.workOrder?.isCreateOperation,
            quantity: op?.workOrder?.quantity,
            notes: op?.workOrder?.notes,
          };
        } else {
          prepareObj.workOrder = {
            isWorkOrderRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('qualityControl')) {
          prepareObj.qualityControl = {
            isQualityCheckRequired: op?.qualityControl?.isCreateOperation,
            notes: op?.qualityControl?.notes,
          };
        } else {
          prepareObj.qualityControl = {
            isQualityCheckRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('packaging')) {
          prepareObj.packaging = {
            isPackagingRequired: op?.packaging?.isCreateOperation,
            notes: op?.packaging?.notes,
          };
        } else {
          prepareObj.packaging = {
            isPackagingRequired: false,
            notes: '',
          };
        }
        if (op.hasOwnProperty('logisticDelivery')) {
          prepareObj.shipping = {
            isShippingRequired: op?.shipping?.isCreateOperation,
            notes: op?.shipping?.notes,
          };
        } else {
          prepareObj.shipping = {
            isShippingRequired: false,
            notes: '',
          };
        }
        return prepareObj;
      });
      const reqObj = {
        ...salesOrderForm,
        freeTextProductName: !salesOrderForm.freeTextProductName,
        taxInfos: taxDetails,
        shippingCharges: extraCost?.shipping,
        discount: extraCost?.discount,
        orderStatus: 'Draft',
        totalAmount: totalAmount,
        salesOrderItems: products?.map((pr, index) => ({
          ...pr,
          ...operations[index],
        })),
      };
      setSalesOrderForm({
        ...salesOrderForm,
        submitted: true,
      });
      if (getValidatedFormResult()) {
        setOrderSubmitLoading(true);
        const response = await api.post(`${salesTransactionEndpoint}`, reqObj);
        navigate('/sales', { state: { mode: 'add' } });
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.salesOrderCreated}`,
        });
        setOrderSubmitLoading(false);
      }
    } catch (error) {
      console.log('Error Occured', error);
      setOrderSubmitLoading(false);
    }
  };

  return (
    <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
      <CreateOperationsModal showOperationModal={showOperationModal} operationData={operationDataByIndex} rowIndex={operationIndex} handleCloseOperationModal={handleCloseOperationModal} />
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <DeliveryMethodModal showModal={showDeliveryNoteModal} handleCloseDeliveryNote={handleCloseDeliveryNote} />
      <CreateCustomerModal />
      <CreateItemModal />
      <div className="d-flex justify-content-between">
        <div>
          <CustomBreadCrumb breadcrumbList={breadcrumbs} />
          <Typography className="mt-2" variant="h5" gutterBottom>
            New Order
          </Typography>
        </div>
        <CloseIcon
          className="cursor-pointer ml-2"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <CustomAutocomplete
            value={salesOrderForm.lvCustomerId}
            onChange={(event, newValue) => handleCustomerChange(newValue)}
            options={customerOptions}
            inputLabel="Customer Name *"
            isError={!salesOrderForm?.lvCustomerId?.value && salesOrderForm?.submitted}
            // helperText={!salesOrderForm?.lvCustomerId?.value && salesOrderForm?.submitted ? 'Customer Name is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CustomAutocomplete
            value={salesOrderForm.lvCurrency}
            onChange={(event, newValue) => {
              setSalesOrderForm({
                ...salesOrderForm,
                currency: newValue?.value,
                lvCurrency: { value: newValue?.value, label: newValue?.label },
              });
            }}
            options={currencyOptions}
            inputLabel="Currency *"
            disableAddNew={true}
            isError={!salesOrderForm?.lvCurrency?.value && salesOrderForm?.submitted}
            // helperText={!salesOrderForm?.lvCurrency?.value && salesOrderForm?.submitted ? 'Currency is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CustomAutocomplete
            value={salesOrderForm.lvSalesPerson}
            onChange={(event, newValue) => {
              setSalesOrderForm({
                ...salesOrderForm,
                salesPerson: newValue?.value,
                lvSalesPerson: { value: newValue?.value, label: newValue?.label },
              });
            }}
            disableAddNew={true}
            options={salesPersonOptions}
            inputLabel="Sales Person *"
            placeholder="Search..."
            isError={!salesOrderForm?.lvSalesPerson?.value && salesOrderForm?.submitted}
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            id="orderNumber"
            name="orderNumber"
            label="Order Number *"
            variant="outlined"
            error={!salesOrderForm.orderNumber?.trim() && salesOrderForm?.submitted}
            // helperText={!salesOrderForm.orderNumber?.trim() && salesOrderForm?.submitted ? 'Order Number is required.' : ''}
            value={salesOrderForm.orderNumber}
            onChange={handleSalesControlChange}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography className="label">Order Date *</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={salesOrderForm.orderDate}
              format="DD/MM/YYYY"
              name="orderDate"
              onChange={(e) => {
                setSalesOrderForm({
                  ...salesOrderForm,
                  orderDate: dayjs(e),
                });
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: !salesOrderForm?.orderDate?.isValid() && salesOrderForm?.submitted,
                  // helperText: !salesOrderForm?.orderDate?.isValid() && salesOrderForm?.submitted ? 'Order Date is required' : '',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={6} xs={12} className="lbl-margin-top">
          <CustomAutocomplete
            value={salesOrderForm.lvPaymentTerms}
            onChange={(event, newValue) => {
              setSalesOrderForm({
                ...salesOrderForm,
                paymentTerms: newValue?.value,
                lvPaymentTerms: { value: newValue?.value, label: newValue?.label },
              });
            }}
            options={PaymentTermsTypes}
            inputLabel="Payment Terms"
            disableAddNew={true}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <TextField
            id="paymentRemarks"
            multiline
            rows={2}
            name="paymentRemarks"
            label="Payment Remarks"
            variant="outlined"
            value={salesOrderForm.paymentRemarks}
            onChange={handleSalesControlChange}
          />
        </Grid>
      </Grid>
      <hr className="mt-4" />
      <Grid container spacing={2} className="mt-1">
        <Grid item md={6} sm={12} xs={12}>
          <Typography className="label">Shipment Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={salesOrderForm.shipmentDate}
              format="DD/MM/YYYY"
              name="orderDate"
              onChange={(e) => {
                setSalesOrderForm({
                  ...salesOrderForm,
                  shipmentDate: dayjs(e),
                });
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  //error: !salesOrderForm?.shipmentDate?.isValid() && salesOrderForm?.submitted,
                  // helperText: !salesOrderForm?.shipmentDate?.isValid() && salesOrderForm?.submitted ? 'Shipment Date is required' : '',
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sm={12} md={6} xs={12} className="lbl-margin-top">
          <CustomAutocomplete
            value={salesOrderForm.lvDeliveryNote}
            onChange={(event, newValue) => {
              if (newValue?.label?.includes('Add')) {
                setShowDeliveryNoteModal(true);
              } else {
                setSalesOrderForm({
                  ...salesOrderForm,
                  deliveryNoteId: newValue?.value,
                  lvDeliveryNote: { value: newValue?.value, label: newValue?.label },
                });
              }
            }}
            options={deliveryNotesOptions}
            inputLabel="Delivery Method"
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
      </Grid>
      <hr className="mt-4" />
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} sm={12} xs={12}>
          <div className="d-flex">
            <Typography variant="h6" gutterBottom>
              Items
            </Typography>
            <FormControlLabel
              className="ms-3"
              control={<Switch name="freeTextProductName" inputProps={{ 'aria-label': 'controlled' }} />}
              label="Link to inventory"
              checked={salesOrderForm?.freeTextProductName}
              onChange={handleLinkedToInventory}
            />
          </div>

          <TableContainer className="mt-2" size="small" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="ps-2" width="32%">
                    Item Name *
                  </TableCell>
                  <TableCell align="right" width="26%">
                    Quantity *
                  </TableCell>
                  <TableCell align="right" width="13%">
                    Unit
                  </TableCell>
                  <TableCell align="right" width="12%">
                    Rate *
                  </TableCell>
                  <TableCell align="right" width="14%">
                    <div className="pe-4">Amount</div>
                  </TableCell>
                  <TableCell align="center" width="3%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, productIndex) => (
                  <TableRow key={productIndex}>
                    <TableCell width="32%">
                      <div className="pt-3 pb-1 ps-2">
                        {salesOrderForm.freeTextProductName ? (
                          <CustomAutocomplete
                            value={row?.lvProductId || ''}
                            onChange={(e, newValue) => handleItemChange(newValue, productIndex)}
                            disabledValues={products?.map((pitem) => pitem?.productId)?.filter((dis) => dis !== row?.productId)}
                            options={productItemOptions || []}
                            placeholder="Search..."
                            getOptionLabel={(option) => option.label || ''}
                            isError={!row?.lvProductId?.value && salesOrderForm.submitted}
                            // helperText={!row?.lvProductId?.value && salesOrderForm.submitted ? 'Item is required.' : ''}
                          />
                        ) : (
                          <TextField
                            value={row?.productName}
                            name="productName"
                            variant="outlined"
                            onChange={(e) => handleProductChange(e, productIndex)}
                            error={!row?.productName && salesOrderForm.submitted}
                            // helperText={!row?.quantity && salesOrderForm.submitted ? 'Quantity is required.' : ''}
                          />
                        )}
                        <div className="d-flex operations-chip-container">
                          {OperationsChips?.map((item, opIndex) => (
                            <>
                              {item?.shouldDisplay ? (
                                <BootstrapTooltip
                                  title={`${operationForm[productIndex]?.hasOwnProperty(item.value) && operationForm[productIndex][item.value]?.isCreateOperation ? 'Deselect' : 'Select'} ${
                                    item.label
                                  }`}
                                >
                                  <Chip
                                    key={opIndex}
                                    className="mx-2 mt-2"
                                    onClick={() => {
                                      handleOperationClick(item, productIndex);
                                    }}
                                    label={`${item.label}`}
                                    variant={operationForm[productIndex]?.hasOwnProperty(item.value) && operationForm[productIndex][item.value]?.isCreateOperation ? '' : 'outlined'}
                                    color="primary"
                                    clickable
                                  />
                                </BootstrapTooltip>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right" width="26%" className="position-relative">
                      <div className="pt-3 pb-5 ps-3">
                        <TextField
                          type="number"
                          id="quantity"
                          value={row?.quantity}
                          name="quantity"
                          variant="outlined"
                          onChange={(e) => handleProductChange(e, productIndex)}
                          InputProps={{
                            inputProps: {
                              style: { textAlign: 'right' },
                            },
                          }}
                          error={!row?.quantity && salesOrderForm.submitted}
                          // helperText={!row?.quantity && salesOrderForm.submitted ? 'Quantity is required.' : ''}
                        />
                        {row.quantity > row.stockOnHand && (
                          <p style={{ color: 'red', position: 'absolute' }}>
                            <i>
                              <b>Warning:</b> Quantity value exceeds from stock on hand
                            </i>
                          </p>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left" width="13%">
                      <div className="pt-3 pb-5">
                        {salesOrderForm.freeTextProductName ? (
                          <> {row?.unit}</>
                        ) : (
                          <TextField
                            value={row?.unit}
                            name="unit"
                            variant="outlined"
                            onChange={(e) => handleProductChange(e, productIndex)}
                            error={!row?.unit && salesOrderForm.submitted}
                            // helperText={!row?.quantity && salesOrderForm.submitted ? 'Quantity is required.' : ''}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right" width="12%">
                      <div className="d-flex pt-3 pb-5 ps-2">
                        {salesOrderForm?.currency && (
                          <div className="pe-1 mt-2">
                            <b>({salesOrderForm?.currency}) </b>
                          </div>
                        )}
                        <TextField
                          className="ml-1"
                          type="number"
                          id="rate"
                          value={row?.rate}
                          name="rate"
                          variant="outlined"
                          onChange={(e) => handleProductChange(e, productIndex)}
                          InputProps={{
                            inputProps: {
                              style: { textAlign: 'right' },
                            },
                          }}
                          error={!row?.rate && salesOrderForm.submitted}
                          // helperText={!row?.rate && salesOrderForm.submitted ? 'Rate is required.' : ''}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right" width="14%">
                      <div className="pt-3 pb-5 pe-4">
                        <span>
                          {salesOrderForm?.currency && (
                            <span className="pe-1">
                              <b> ({salesOrderForm?.currency})</b>
                            </span>
                          )}
                          {row.amount}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="center" width="3%">
                      {productIndex !== 0 ? (
                        <BootstrapTooltip title="Delete">
                          <IconButton className="pt-3 pb-5" color="info" onClick={() => handleRemoveProduct(productIndex)}>
                            <Delete />
                          </IconButton>
                        </BootstrapTooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan="5">
                    <BootstrapTooltip title="Add Item">
                      <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddProduct}>
                        Add Item
                      </Button>
                    </BootstrapTooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" colSpan="4">
                    <b>Subtotal</b>
                  </TableCell>
                  <TableCell align="right">
                    <div>
                      {salesOrderForm?.currency && (
                        <span className="pe-1">
                          <b> ({salesOrderForm?.currency})</b>
                        </span>
                      )}
                      {subTotalProductAmount}
                    </div>
                  </TableCell>
                </TableRow>
                {showDiscount && (
                  <TableRow>
                    <TableCell align="right" colSpan="4">
                      <b>Discount</b>
                    </TableCell>
                    <TableCell align="right">
                      {showDiscount ? extraCost.discount + '% (Amount: ' + (salesOrderForm?.currency ? '(' + salesOrderForm?.currency + ') ' : '') + parseFloat(discountAmount?.toFixed(2)) + ')' : 0}
                    </TableCell>
                  </TableRow>
                )}
                {showShipping && (
                  <TableRow>
                    <TableCell align="right" colSpan="4">
                      <b>Shipping </b>
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {salesOrderForm?.currency && (
                          <span className="pe-1">
                            <b> ({salesOrderForm?.currency})</b>
                          </span>
                        )}
                        {showShipping ? extraCost.shipping : 0}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {showTaxDetails && (
                  <TableRow>
                    <TableCell align="right" colSpan="4">
                      <b>Tax Details</b>
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        {salesOrderForm?.currency && (
                          <span className="pe-1">
                            <b> ({salesOrderForm?.currency})</b>
                          </span>
                        )}
                        {parseFloat(extraCost?.tax?.toFixed(2))}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan="4" align="right">
                    <b>Total Amount</b>
                  </TableCell>
                  <TableCell align="right">
                    <div>
                      {salesOrderForm?.currency && (
                        <span className="pe-1">
                          <b> ({salesOrderForm?.currency})</b>
                        </span>
                      )}
                      {parseFloat(totalAmount?.toFixed(2))}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-1">
        <Grid item md={2} sm={6} xs={12}>
          <div className="d-flex">
            <Checkbox
              size="small"
              name="shippingCost"
              checked={showShipping}
              onChange={(e) => {
                {
                  handleCheckboxChange('shipping', e.target.checked);
                }
                if (!e.target.checked) {
                  handleExtraCostChange({ target: { name: 'shipping', value: 0 } });
                }
              }}
            />
            <Typography className="mt-2"> Include shipping cost</Typography>
          </div>
          {showShipping && (
            <div>
              <TextField
                className="ms-2 mt-1"
                type="number"
                id="shipping"
                label={`Shipping Rate (in ${!salesOrderForm?.currency ? 'currency' : salesOrderForm?.currency})`}
                value={extraCost.shipping}
                onChange={handleExtraCostChange}
                name="shipping"
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' },
                  },
                }}
                error={!extraCost?.shipping && salesOrderForm.submitted}
              />
            </div>
          )}
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <div className="d-flex">
            <Checkbox
              size="small"
              name="shippingCost"
              checked={showDiscount}
              onChange={(e) => {
                handleCheckboxChange('discount', e.target.checked);
                if (!e.target.checked) {
                  handleExtraCostChange({ target: { name: 'discount', value: 0 } });
                }
              }}
            />
            <Typography className="mt-2"> Include discount</Typography>
          </div>
          {showDiscount && (
            <div>
              <TextField
                className="ms-2 mt-1"
                type="number"
                label="Discount (percentage)"
                name="discount"
                value={extraCost.discount}
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : 0;
                  if (value >= 0 && value <= 100) {
                    handleExtraCostChange(e);
                  }
                }}
                InputProps={{
                  endAdornment: <Typography className="ml-2">%</Typography>,
                  inputProps: {
                    min: 0,
                    max: 100,
                    style: { textAlign: 'right' },
                  },
                }}
                error={!extraCost?.discount && salesOrderForm.submitted}
              />
            </div>
          )}
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <div className="d-flex">
            <Checkbox
              size="small"
              name="shippingCost"
              checked={showTaxDetails}
              onChange={(e) => {
                handleCheckboxChange('taxDetails', e.target.checked);
                if (!e.target.checked) {
                  setTaxDetails([emptyTaxObj]);
                }
              }}
            />
            <Typography className="mt-2"> Include tax details</Typography>
          </div>
          {showTaxDetails && (
            <>
              <TableContainer size="small" component={Paper} className="mt-1">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="30%">Tax Type *</TableCell>
                      <TableCell align="right" width="30%">
                        Tax Percentage (%) *
                      </TableCell>
                      <TableCell align="right" width="30%">
                        Amount
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taxDetails.map((taxRow, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TextField
                            id="taxType"
                            value={taxRow.taxName}
                            name="taxType"
                            variant="outlined"
                            onChange={(e) => handleTaxTypeChange(e, index)}
                            error={!taxRow?.taxName && salesOrderForm.submitted}
                            // helperText={!taxRow?.taxName && salesOrderForm.submitted ? 'Tax Type is required.' : ''}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id="taxPercentage"
                            value={taxRow.taxPercentage}
                            name="taxPercentage"
                            variant="outlined"
                            onChange={(e) => {
                              const value = e.target.value ? parseInt(e.target.value) : 0;
                              if (value >= 0 && value <= 100) {
                                handleTaxPercentageChange(e, index);
                              }
                            }}
                            InputProps={{
                              endAdornment: <Typography className="ml-2">%</Typography>,
                              inputProps: {
                                min: 0,
                                max: 100,
                                style: { textAlign: 'right' },
                              },
                            }}
                            error={!taxRow?.taxPercentage && salesOrderForm.submitted}
                            // helperText={!taxRow?.taxPercentage && salesOrderForm.submitted ? 'Tax Percentage (%) is required.' : ''}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {salesOrderForm?.currency && (
                            <span className="pe-1">
                              <b> ({salesOrderForm?.currency})</b>
                            </span>
                          )}
                          {parseFloat(calculateTaxAmount(discountedSubTotal, taxRow.taxPercentage)?.toFixed(2))}
                        </TableCell>
                        <TableCell className="d-flex justify-content-center">
                          <BootstrapTooltip title="Delete">
                            <IconButton className="p-2" color="info" onClick={() => handleRemoveTaxDetail(index)}>
                              <Delete />
                            </IconButton>
                          </BootstrapTooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <BootstrapTooltip title="Add Item">
                          <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddTaxDetail}>
                            Add Tax
                          </Button>
                        </BootstrapTooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item md={12} sm={12} xs={12}>
          <TextField id="remarks" name="remarks" label="Remarks" variant="outlined" multiline rows={2} value={salesOrderForm.remarks} onChange={handleSalesControlChange} />
        </Grid>
      </Grid>
      <div className="mt-4">
        <Button size="small" variant="contained" onClick={handleSaveOrder} disabled={orderSubmitLoading}>
          Submit
        </Button>
        {orderSubmitLoading && <CircularProgress size={18} className="ms-2 mt-3" />}
      </div>
    </div>
  );
};

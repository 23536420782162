import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_USERS_ENDPOINT } from "../../../../constants/constants";
import { useToken } from "../../../auth/useToken";
import { Spinner, Tab, Nav } from "react-bootstrap";
import { Menu, MenuItem, IconButton, Typography, Grid, CircularProgress, Breadcrumbs, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomAlert from "../../../common/alert";
import CustomBreadCrumb from "../../../common/breadcrumb";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const UserInfo = () => {
    const { id } = useParams();  // Get user ID from the URL
    const [token] = useToken();
    const navigate = useNavigate();
    const usersEndpoint = `${API_USERS_ENDPOINT}/${id}`;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [editedUser, setEditedUser] = useState({});
    const [editing, setEditing] = useState(false);
    const [alertData, setAlertData] = useState({ open: false, type: "", message: "" });
    const [anchorEl, setAnchorEl] = useState(null); // For kebab menu

    const breadcrumbs = [
        { name: "Home", href: "/" },
        { name: "Users", href: "/users" },
        { name: id ? "User Info" : "New User", active: true },
    ];

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(usersEndpoint, config);
                setUser(response.data);
                setEditedUser(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(false);
            }
        };
        fetchUser();
    }, [usersEndpoint]);

    const resetPassword = () => {
        handleMenuClose();
        // Implement password reset logic here
        setAlertData({ open: true, type: "success", message: "Password reset link sent." });
    };

    const handleEditClick = () => setEditing(true);

    const handleSaveClick = async () => {
        try {
            await axios.put(usersEndpoint, editedUser, config);
            setEditing(false);
            setAlertData({ open: true, type: "success", message: "User updated successfully." });
        } catch (error) {
            console.error("Error updating user data:", error);
            setAlertData({ open: true, type: "error", message: "Failed to update user." });
        }
    };

    const invokeDeleteUser = async () => {
        const confirmed = window.confirm(`Are you sure you want to delete ${user.firstName}?`);
        if (confirmed) {
            try {
                await axios.delete(usersEndpoint, config);
                navigate("/users");
            } catch (error) {
                console.error("Error deleting user:", error);
                setAlertData({ open: true, type: "error", message: "Failed to delete user." });
            }
        }
    };

    const isMenuOpen = Boolean(anchorEl); // Renamed from `open`

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleUserChange = (key, value) => {
        setEditedUser((prev) => ({ ...prev, [key]: value }));
    };

    const alertClose = () => setAlertData({ ...alertData, open: false });

    return (
        <div className="d-flex flex-grow-1 flex-column p-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <CustomBreadCrumb breadcrumbList={breadcrumbs} />
                <div className="d-flex align-items-center">
                    {/* Kebab Menu Icon */}
                    <IconButton onClick={handleMenuOpen}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <MenuItem onClick={invokeDeleteUser}>Delete User</MenuItem>
                        <MenuItem onClick={resetPassword}>Reset Password</MenuItem>
                    </Menu>

                    {/* Close Icon */}
                    <CloseIcon className="cursor-pointer" onClick={() => navigate(-1)} />
                </div>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Typography variant="h6">{id ? "Edit User" : "New User"}</Typography>
                    </div>

                    <Tab.Container defaultActiveKey="overview">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="overview">Overview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="settings">Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="activity">Activity</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="overview">
                                <UserInfoRow
                                    userObj={editedUser}
                                    editing={editing}
                                    onUserChange={handleUserChange}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="settings">
                                <UserSettingsRow
                                    userObj={editedUser}
                                    editing={editing}
                                    onUserChange={handleUserChange}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="activity">
                                {/* Activity content goes here */}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    <div className="d-flex justify-content-start mt-3">
                        {editing ? (
                            <Button variant="contained" onClick={handleSaveClick}>
                                Save
                            </Button>
                        ) : (
                            <Button variant="outlined" onClick={handleEditClick}>
                                Edit
                            </Button>
                        )}
                    </div>
                </>
            )}

            <CustomAlert
                type={alertData.type}
                message={alertData.message}
                open={alertData.open}
                onClose={alertClose}
            />
        </div>
    );
};

function UserInfoRow({ userObj, editing, onUserChange }) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="body1">First Name</Typography>
                {editing ? (
                    <input
                        type="text"
                        className="form-control"
                        value={userObj.firstName}
                        onChange={(e) => onUserChange("firstName", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.firstName}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Last Name</Typography>
                {editing ? (
                    <input
                        type="text"
                        className="form-control"
                        value={userObj.lastName}
                        onChange={(e) => onUserChange("lastName", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.lastName}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Email Address</Typography>
                {editing ? (
                    <input
                        type="email"
                        className="form-control"
                        value={userObj.emailAddress}
                        onChange={(e) => onUserChange("emailAddress", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.emailAddress}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Phone Number</Typography>
                {editing ? (
                    <input
                        type="text"
                        className="form-control"
                        value={userObj.phoneNumber}
                        onChange={(e) => onUserChange("phoneNumber", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.phoneNumber}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Job Title</Typography>
                {editing ? (
                    <input
                        type="text"
                        className="form-control"
                        value={userObj.jobTitle}
                        onChange={(e) => onUserChange("jobTitle", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.jobTitle}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Country Code</Typography>
                {editing ? (
                    <input
                        type="text"
                        className="form-control"
                        value={userObj.location.countryCode}
                        onChange={(e) => onUserChange("location.countryCode", e.target.value)}
                    />
                ) : (
                    <Typography variant="body2">{userObj.location.countryCode}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Created At</Typography>
                <Typography variant="body2">{new Date(userObj.createdAt).toLocaleString()}</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Last Modified At</Typography>
                <Typography variant="body2">{new Date(userObj.lastModifiedAt).toLocaleString()}</Typography>
            </Grid>
        </Grid>
    );
}

function UserSettingsRow({ userObj, editing, onUserChange }) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="body1">Status</Typography>
                {editing ? (
                    <select
                        className="form-select"
                        value={userObj.isBlocked.toString()}
                        onChange={(e) => onUserChange("isBlocked", e.target.value === "true")}
                    >
                        <option value="false">Active</option>
                        <option value="true">Blocked</option>
                    </select>
                ) : (
                    <Typography variant="body2">{userObj.isBlocked ? "Blocked" : "Active"}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Role</Typography>
                {editing ? (
                    <select
                        className="form-select"
                        value={userObj.role}
                        onChange={(e) => onUserChange("role", parseInt(e.target.value))}
                    >
                        <option value={1}>Administrator</option>
                        <option value={2}>Staff</option>
                    </select>
                ) : (
                    <Typography variant="body2">{userObj.role === 1 ? "Administrator" : "Staff"}</Typography>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">User Type</Typography>
                <Typography variant="body2">
                    {userObj.userType === 0 ? "Internal" : "External"}
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1">Accessible Components</Typography>
                <Typography variant="body2">
                    {userObj.accessibleComponents.length > 0
                        ? userObj.accessibleComponents.join(", ")
                        : "None"}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default UserInfo;

import React, { useEffect, useState, useMemo } from 'react';
import { Collapse } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { MDBIcon, MDBListGroup, MDBListGroupItem, MDBRipple } from 'mdb-react-ui-kit';
import { useAccessControl, RayaComponent } from '../access-control/AccessControl';
import './SideNavBarPanel.css';

const allRoutes = [
  {
    path: '/dashboard',
    text: 'Home',
    icon: 'home',
    access: RayaComponent.Dashboard,
    subItems: [],
  },
  {
    path: '/inventory',
    text: 'Inventory',
    icon: 'box',
    access: RayaComponent.Inventory,
    subItems: [
      { path: '/items', text: 'Items', access: RayaComponent.Inventory },
      { path: '/adjustments', text: 'Adjustments', access: RayaComponent.Inventory },
      { path: '/locations', text: 'Locations', access: RayaComponent.Inventory },
    ],
  },
  {
    path: '/purchase',
    text: 'Purchase',
    icon: 'shopping-cart',
    access: RayaComponent.Purchase,
    subItems: [
      { path: '/procurement', text: 'Procurement', access: RayaComponent.Purchase },
      { path: '/purchase-orders', text: 'Orders', access: RayaComponent.Purchase },
    ],
  },
  {
    path: '/sales',
    text: 'Sales',
    icon: 'money-bill',
    access: RayaComponent.Sales,
    subItems: [
      { path: '/leads', text: 'Leads', access: RayaComponent.Sales },
      // { path: '/opportunities', text: 'Opportunities', access: RayaComponent.Sales },
      { path: '/sales', text: 'Orders', access: RayaComponent.Sales },
    ],
  },
  {
    path: '/operations',
    text: 'Operations',
    icon: 'cogs',
    access: RayaComponent.Operations,
    subItems: [
      { path: '/stockAllocation', text: 'Stock Allocation', access: RayaComponent.Operations },
      { path: '/workorders', text: 'Work Orders', access: RayaComponent.Operations },
      { path: '/qualitycontrol', text: 'Quality Control', access: RayaComponent.Operations },
      { path: '/packaging', text: 'Packaging', access: RayaComponent.Operations },
      { path: '/shipping', text: 'Logistics & Delivery', access: RayaComponent.Operations },
    ],
  },
  {
    path: '/finances',
    text: 'Finances',
    icon: 'wallet',
    access: RayaComponent.Finances,
    subItems: [
      { path: '/general-ledger', text: 'General Ledger', access: RayaComponent.FinancialData },
      { path: '/billing', text: 'Billing', access: RayaComponent.FinancialData },
      { path: '/invoices', text: 'Invoices', access: RayaComponent.FinancialData },
      { path: '/payroll', text: 'Payroll', access: RayaComponent.FinancialData },
      { path: '/taxes', text: 'Taxes', access: RayaComponent.FinancialData },
      { path: '/financial-reports', text: 'Reports', access: RayaComponent.Reports },
    ],
  },
  {
    path: '/documents',
    text: 'Documents',
    icon: 'file',
    access: RayaComponent.Documents,
    subItems: [{ path: '/files', text: 'Files', access: RayaComponent.Documents }],
  },
  {
    path: '/accounts',
    text: 'Accounts',
    icon: 'user',
    access: RayaComponent.AccountsPII,
    subItems: [
      { path: '/accounts', text: 'Accounts', access: RayaComponent.AccountsPII },
      { path: '/contacts', text: 'Contacts', access: RayaComponent.AccountsPII },
    ],
  },
  {
    path: '/analytics',
    text: 'Analytics',
    icon: 'chart-line',
    access: RayaComponent.Analytics,
    subItems: [],
  },
  {
    path: '/settings',
    text: 'Settings',
    icon: 'lock',
    access: RayaComponent.All,
    subItems: [
      { path: '/settings/general', text: 'General', access: RayaComponent.All },
      { path: '/users', text: 'Users', access: RayaComponent.All },
      { path: '/settings/api-keys', text: 'API Keys', access: RayaComponent.All },
      { path: '/settings/raya-billing', text: 'Billing and plans', access: RayaComponent.All },
    ],
  },
];

export const SideNavBarPanel = ({ showPanel, togglePanel }) => {
  let location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  // Call useAccessControl hook once for each possible access level
  const hasAccessMap = {
    [RayaComponent.All]: useAccessControl(RayaComponent.All),
    [RayaComponent.AccountsPII]: useAccessControl(RayaComponent.AccountsPII),
    [RayaComponent.FinancialData]: useAccessControl(RayaComponent.FinancialData),
    [RayaComponent.Dashboard]: useAccessControl(RayaComponent.Dashboard),
    [RayaComponent.Inventory]: useAccessControl(RayaComponent.Inventory),
    [RayaComponent.Operations]: useAccessControl(RayaComponent.Operations),
    [RayaComponent.Sales]: useAccessControl(RayaComponent.Sales),
    [RayaComponent.Purchase]: useAccessControl(RayaComponent.Purchase),
    [RayaComponent.Reports]: useAccessControl(RayaComponent.Reports),
    [RayaComponent.Finances]: useAccessControl(RayaComponent.Finances),
    [RayaComponent.Documents]: useAccessControl(RayaComponent.Documents),
    [RayaComponent.Analytics]: useAccessControl(RayaComponent.Analytics),
  };

  // Filter routes based on access map
  const routes = useMemo(() => {
    return allRoutes.filter((route) => {
      if (!hasAccessMap[route.access]) return false;

      if (route.subItems.length > 0) {
        const filteredSubItems = route.subItems.filter((subItem) => hasAccessMap[subItem.access]);
        route.subItems = filteredSubItems;
        return filteredSubItems.length > 0 || route.path;
      }
      return true;
    });
  }, [hasAccessMap]);

  useEffect(() => {
    setSelectedRoute(location.pathname);
    const isSubItem = routes.findIndex((route) => route.subItems.some((subItem) => location?.pathname?.includes(subItem?.path)));
    if (isSubItem >= 0) {
      setSelectedSubItem(location.pathname);
      setSelectedRoute(null);
      setActiveSubMenu(isSubItem);
    } else {
      setSelectedSubItem(null);
    }

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  const handleRouteClick = (route, index) => {
    setSelectedSubItem(null);
    setSelectedRoute(route.path);
    if (route.subItems.length === 0) {
      navigate(route.path);
      setActiveSubMenu(null);
      if (isMobileView) {
        togglePanel(); // Hide panel in mobile view
      }
    } else {
      setActiveSubMenu(activeSubMenu === index ? null : index);
    }
  };

  const handleSubMenuClick = (subItem) => {
    setSelectedRoute(null);
    setSelectedSubItem(subItem.path);
    navigate(subItem.path);
    if (isMobileView) {
      togglePanel(); // Hide panel in mobile view
    }
  };

  const handleSubMenuToggle = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const selectSidebar = (routePath, index) => {
    return selectedRoute?.includes(routePath.path) || (activeSubMenu !== null && activeSubMenu === index);
  };

  const selectedSubMenuSidebar = (routePath) => {
    return location?.pathname?.includes(routePath?.path);
  };

  return (
    <>
      {showPanel && (
        <nav className="nav-bar">
          <Collapse in={showPanel}>
            <div className={`bg-raya sidebar ${showPanel ? 'sidebar-expanded' : ''}`}>
              <MDBListGroup flush>
                {routes.map((route, index) => (
                  <React.Fragment key={route.path}>
                    <MDBRipple rippleTag="span">
                      <MDBListGroupItem onClick={() => handleRouteClick(route, index)} className={`border-0 border-bottom rounded rounded menu-item ${selectSidebar(route, index) ? 'selected' : ''}`}>
                        <div className={`${selectedSubItem === null ? 'selected-indicator' : ''}`}></div>
                        &nbsp;
                        <MDBIcon fas icon={route.icon} className="me-3" />
                        {route.text}
                        {route.subItems.length > 0 && (
                          <button className="toggle-button" onClick={() => handleSubMenuToggle(index)}>
                            <MDBIcon icon={activeSubMenu === index ? 'angle-up' : 'angle-down'} />
                          </button>
                        )}
                      </MDBListGroupItem>
                    </MDBRipple>
                    {route.subItems.length > 0 && activeSubMenu === index && (
                      <Collapse in={true}>
                        <div className="submenu">
                          {route.subItems.map((subItem, subIndex) => (
                            <MDBListGroupItem key={subIndex} onClick={() => handleSubMenuClick(subItem)} className={`submenu-item ${selectedSubMenuSidebar(subItem) ? 'selected' : ''}`}>
                              {selectedSubMenuSidebar(subItem) && <div className="selected-indicator"></div>}
                              <span className="ms-1"> {subItem.text}</span>
                            </MDBListGroupItem>
                          ))}
                        </div>
                      </Collapse>
                    )}
                  </React.Fragment>
                ))}
              </MDBListGroup>
            </div>
          </Collapse>
        </nav>
      )}
    </>
  );
};

export default SideNavBarPanel;

import { FilterAltRounded, Search } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Grid, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_SALES_TRANSACTIONS_ENDPOINT } from '../../../constants/constants';
import { Messages } from '../../common-constants/messages';
import { getSalesOrderKebabMenuActions, Granularity } from '../../common-constants/static-values';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomAutocomplete from '../../common/autocomplete';
import CustomBreadCrumb from '../../common/breadcrumb';
import CardVisualization from '../../common/card-visualization';
import { BootstrapTooltip, convertToUTCWithEndTime, formatDate, getCheckboxResult, getYearDifference } from '../../common/commonUtility';
import ConfirmDialog from '../../common/confirm-dialog';
import CustomPagination from '../../common/pagination';
import SparkLineChartForSales from '../../common/spark-line-chart';
import CustomTable from '../../common/table';
import CustomTableActions from '../../common/table-actions';
import { resetAccountReducer } from '../../redux/actions/account-action';
import { resetDocumentReducer } from '../../redux/actions/document-action';
import { resetSalesReducer } from '../../redux/actions/sales-action';

const SalesLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const itemsPerPage = 6;
  const [aggregateData, setAggregateData] = useState({});
  const [overviewData, setOverviewData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderTrendsData, setOrderTrendsData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    fromDate: dayjs(new Date())?.subtract(1, 'year'),
    toDate: dayjs(new Date()),
  });
  const [granularFilter, setGranularFilter] = useState({
    granularity: 'daily',
    lvGranularity: {
      label: 'Daily',
      value: 'daily',
    },
  });
  const salesTransactionsEndpoint = API_SALES_TRANSACTIONS_ENDPOINT;
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [activeCard, setActiveCard] = useState('');
  const accountColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Order ID',
      field: 'orderNumber',
      link: true,
    },
    {
      label: 'Customer Name',
      field: 'customerName',
    },
    {
      label: 'Status',
      badge: true,
      field: 'orderStatus',
    },
    {
      label: 'Order Date',
      field: 'orderDate',
      date: true,
    },
    {
      label: 'Sales Person',
      field: 'salesPerson',
      avatar: true,
    },
    {
      label: 'Order Value',
      field: 'totalAmount',
    },
    {
      label: 'Action',
      field: 'action',
      menu: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Sales',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
    link: false,
  });
  const [sales, setSales] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      link: false,
      open: false,
    });
  };

  useEffect(() => {
    dispatch(resetSalesReducer());
    fetchSales(1, search, activeCard);
    fetchAggregateData();
    fetchOrderTrendsData();
    stateHandlerOnLoad();
    dispatch(resetDocumentReducer());
    dispatch(resetAccountReducer());
  }, []);

  useEffect(() => {
    fetchSales(1, search, activeCard);
    fetchAggregateData();
    fetchOrderTrendsData();
  }, [JSON.stringify(dateFilter), JSON.stringify(granularFilter)]);

  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const response = await api.get(`${salesTransactionsEndpoint}/overview?fromDate=${dateFilter?.fromDate}&toDate=${convertToUTCWithEndTime(dateFilter?.toDate)}`);
      const data = response.data;
      const aggregateData = [
        { title: 'Sales', value: data?.totalOrdersCount, bindValue: '', tooltip: `Order Amount:  (${data.currency}) ${data.totalOrdersAmount?.toFixed(2)}` },
        { title: 'Draft', value: data.draftOrdersCount, bindValue: 0, tooltip: `Order Amount:  (${data.currency}) ${data.draftOrdersAmount?.toFixed(2)}` },
        { title: 'Pending', value: data.pendingOrdersCount, bindValue: 1, tooltip: `Order Amount: ${data.currency} ${data.pendingOrdersAmount?.toFixed(2)}` },
        { title: 'In-Progress', value: data.inProgressOrdersCount, bindValue: 2, tooltip: `Order Amount: ${data.currency} ${data.pendingOrdersAmount?.toFixed(2)}` },
        { title: 'Completed', value: data.completedOrdersCount, bindValue: 4, tooltip: `Order Amount: ${data.currency} ${data.completedOrdersAmount?.toFixed(2)}` },
        { title: 'Cancelled', value: data.cancelledOrdersCount, bindValue: 5, tooltip: `Order Amount: ${data.currency} ${data.cancelledOrdersAmount?.toFixed(2)}` },
        // { title: 'Out of Stock', value: data.totalItemsOutOfStock, bindValue: 5, color: 'error' },
        // { title: 'Running Low', value: data.totalItemsRunningLow, bindValue: 6, color: 'error' },
      ]?.filter((item) => item.value !== 0);
      setAggregateData(aggregateData);
      setOverviewData(data);
      setAggregateLoader(false);
    } catch (error) {
      console.error('Error fetching aggregate data:', error);
      setAggregateLoader(false);
    }
  };

  const fetchOrderTrendsData = async () => {
    try {
      setAggregateLoader(true);
      const granularParam = granularFilter?.granularity ? `&granularity=${granularFilter?.granularity}` : '';
      const dateParam = dateFilter?.fromDate && dateFilter?.toDate ? `?fromDate=${dateFilter?.fromDate}&toDate=${convertToUTCWithEndTime(dateFilter?.toDate)}` : '';
      const response = await api.get(`${salesTransactionsEndpoint}/order-trends${dateParam}${granularParam}`);
      const data = response?.data;
      setOrderTrendsData(data);
      setAggregateLoader(false);
    } catch (error) {
      console.error('Error fetching aggregate data:', error);
      setAggregateLoader(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleCardActive = (activeCard) => {
    setActiveCard(activeCard);
    fetchSales(1, '', activeCard);
  };

  const fetchSales = async (currentPage = 1, search, activeCard = '') => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    const orderStatus = activeCard >= 0 ? `&orderStatus=${activeCard}` : '';
    const dateParam = dateFilter?.fromDate && dateFilter?.toDate ? `&fromDate=${dateFilter?.fromDate}&toDate=${convertToUTCWithEndTime(dateFilter?.toDate)}` : '';
    try {
      const response = await api.get(`${salesTransactionsEndpoint}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}${orderStatus}${dateParam}`);
      response.data.salesTransactions = response.data.salesTransactions?.map((item) => ({
        ...item,
        action: getSalesOrderKebabMenuActions(item.orderStatus),
        totalAmount: '(' + item?.currency + ') ' + item?.totalAmount?.toFixed(2),
        salesPerson: `${item?.salesPerson?.firstName} ${item?.salesPerson?.lastName}`,
      }));
      const salesTransactions = response.data?.salesTransactions;
      setEntityData(response.data);
      setSales(salesTransactions);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const stateHandlerOnLoad = () => {
    if (state?.mode === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        link: false,
        type: AlertStatus.Success,
        message: `${Messages.salesCreated}`,
      });
    }
    navigate('.', { replace: true });
  };

  const handleRefreshData = () => {
    fetchSales(1, search, activeCard);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleAddEntity = () => {
    //dispatch(resetAccountReducer());
    navigate('/sales/neworder');
  };

  const handleEditEntity = (item) => {
    const order = sales.find((or) => or.transactionId === item?.transactionId);
    navigate(`/sales/${order.transactionId}`);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchSales(1, searchText, activeCard);
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    fetchSales(page, search, activeCard);
  };

  const handleDeleteConfirmYes = () => {
    deleteAccounts();
  };

  const deleteAccounts = async () => {
    const selectedItems = sales.filter((item) => item.checked)?.map((adj) => adj.id);
    setDeleteDialog(false);
    try {
      await api.delete(salesTransactionsEndpoint, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        link: false,
        type: AlertStatus.Success,
        message: Messages.salesDeleted,
      });
      fetchSales();
      // fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setSales(getCheckboxResult(sales, checkedItem, 'transactionId'));
  };

  const handleMenuItemClicked = async (event, item) => {
    if (event === 1) {
      try {
        const reqObj = {
          // adjustmentStatus: Number(event),
        };
        const result = await api.post(`${salesTransactionsEndpoint}/${item?.transactionId}/submit`, reqObj);
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.orderStatusChanged} and Stock Allocation order is created. Please click on a link to redirect to Stock Allocation, `,
          link: true,
          path: `/stockAllocation/${result?.data?.stockAllocationOrderId}`,
        });
        fetchSales();
        fetchAggregateData();
        fetchOrderTrendsData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (event > 1) {
      try {
        const reqObj = {
          newStatus: Number(event),
        };
        const result = await api.post(`${salesTransactionsEndpoint}/${item?.transactionId}/updateStatus`, reqObj);
        setAlertData({
          ...alertData,
          open: true,
          link: false,
          type: AlertStatus.Success,
          message: event === 4 ? `${Messages.salesOrderCompleted}` : `${Messages.salesOrderCancelled}`,
        });
        fetchSales();
        fetchAggregateData();
        fetchOrderTrendsData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const changeFilter = (name, value) => {
    setDateFilter({
      ...dateFilter,
      [name]: dayjs(value),
    });
    setCurrentPage(1);
    if (name === 'fromDate' && value && dateFilter.toDate && value?.isAfter(dateFilter?.toDate)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        toDate: dayjs(value),
      });
      //  dispatch(fetchStockHistory(1, value, value));
    } else if (name === 'toDate' && value && dateFilter.fromDate && dateFilter.fromDate?.isAfter(value)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        fromDate: dayjs(value),
      });
      //  dispatch(fetchStockHistory(1, value, value));
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-2">
          <Typography className="mt-2 fs-18">Advance Filter</Typography>
          <div className="d-flex flex-column mt-2 mb-4 mx-3">
            <div className="d-flex">
              <div>
                <Typography className="label">From Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.fromDate} onChange={(e) => changeFilter('fromDate', e)} />
                </LocalizationProvider>
              </div>
              <div className="ms-2">
                <Typography className="label">To Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.toDate} onChange={(e) => changeFilter('toDate', e)} />
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <CustomAutocomplete
                value={granularFilter.lvGranularity}
                onChange={(event, newValue) => {
                  setGranularFilter({
                    ...granularFilter,
                    granularity: newValue?.value,
                    lvGranularity: { value: newValue?.value, label: newValue?.label },
                  });
                }}
                className="lbl-margin-top"
                options={Granularity}
                inputLabel="Granularity"
                disableAddNew={true}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
              />
            </div>
          </div>
        </div>
      </Popover>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid md={4} container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="d-flex mb-0" variant="h5" gutterBottom>
              Sales
            </Typography>
            <span>
              {getYearDifference(dateFilter?.fromDate.toISOString(), dateFilter?.toDate.toISOString()) === 1
                ? 'Sales orders of past 1 year'
                : `${formatDate(dateFilter?.fromDate)} - ${formatDate(dateFilter?.toDate)}`}
            </span>
          </Grid>
          <Grid container md={8} className="d-flex justify-content-end">
            <Grid item md={4}>
              {orderTrendsData?.data?.length > 1 ? <SparkLineChartForSales chartData={orderTrendsData} granularity={granularFilter?.lvGranularity?.label} /> : null}
            </Grid>
            {overviewData?.totalOrdersAmount ? (
              <Grid item className="d-flex flex-column justify-content-center border rounded p-2 ms-2 bg-light-green">
                <Typography className="fs-18 success-green">
                  <AccountBalanceWalletIcon />
                  &nbsp; Total Orders
                </Typography>
                <Typography className="fs-13">
                  Amount:{' '}
                  <b>
                    ({overviewData?.currency}) {overviewData?.totalOrdersAmount?.toFixed(2)}
                  </b>
                </Typography>
                <Typography className="fs-13">
                  Count: <b> {overviewData?.totalOrdersCount}</b>
                </Typography>
              </Grid>
            ) : null}
            {overviewData?.overdueOrdersAmount ? (
              <Grid item className="d-flex flex-column justify-content-center border rounded p-2 ms-3 bg-light-red">
                <Typography className="fs-18" color="error">
                  <ShoppingCartCheckoutIcon /> Overdue Orders
                </Typography>
                <Typography className="fs-13">
                  Amount:
                  <b>
                    ({overviewData?.currency}) {overviewData?.overdueOrdersAmount?.toFixed(2)}
                  </b>
                </Typography>
                <Typography className="fs-13">
                  Count: <b>{overviewData?.overdueOrdersCount}</b>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </div>
        <div className="d-flex mt-2">
          <Grid container>
            <Grid item xs={2} className="d-flex">
              <CustomTableActions
                disableDelete={!sales.filter((cus) => cus.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={handleAddEntity}
              ></CustomTableActions>
              <BootstrapTooltip title="Filter">
                <IconButton color="info" onClick={handleFilterClick}>
                  <FilterAltRounded />
                </IconButton>
              </BootstrapTooltip>
            </Grid>
            <Grid className="d-flex align-items-center flex-wrap" item xs={8}>
              <CardVisualization loading={aggregateLoader} activeCard={activeCard} data={aggregateData} handleCardActive={handleCardActive} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable
          tableColumn={accountColumn}
          loading={tableLoader}
          tableData={sales}
          toggleSelectedCheckbox={toggleSelectedCheckbox}
          handleEditEntity={handleEditEntity}
          menuItemClicked={handleMenuItemClicked}
        />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} rowsPerPage={itemsPerPage} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} link={alertData?.link} path={alertData?.path} />
      </div>
    </>
  );
};

export default SalesLanding;

import { Alert, AlertTitle, Avatar, Badge, Box, Collapse } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import currencyCodes from 'currency-codes';
import { createRoot } from 'react-dom/client';
import { IndustryTypes, LocationTypes } from '../common-constants/static-values';
import {
  AdjustmentStatus,
  AdjustmentStatusValue,
  ConfirmationStatus,
  ItemType,
  LeadStatus,
  LogisticsDeliveryKebabArray,
  LogisticsDeliveryStatus,
  OrderStatus,
  PackagingControlStatus,
  pcKebabArray,
  qcKebabArray,
  QualityControlStatus,
  StockAllocationStatus,
  UserType,
  woKebabArray,
  WorkOrderStatus,
  WorkOrderStatusValue,
} from '../common-constants/status';
export const getCheckboxResult = (items, checkedItem, uniqueId) => {
  const hideItems = items.filter((i) => i.disabledCheckbox === true) || [];
  const checkedEntity = items.find((item) => item[uniqueId] === checkedItem[uniqueId]);
  if (checkedEntity) {
    checkedEntity.checked = !checkedEntity.checked;
  }
  if (checkedItem === 'all') {
    items.map((item) => {
      if (item.disabledCheckbox !== true) {
        item.checked = !item.checkedAll;
      }
    });
  }
  const countOfCheckedBoxes = items.filter((item) => item.checked && item.disabledCheckbox !== true)?.length;
  items.map((item) => (item.checkedAll = countOfCheckedBoxes === items?.length - hideItems?.length));
  return items;
};

export const formatDate = (date) => {
  const formattedDate =
    Date.parse(date) !== NaN
      ? new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '';
  return formattedDate;
};

export const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip className="d-flex" {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const setBadgeType = (type) => {
  let returnType = '';
  switch (type) {
    case AdjustmentStatusValue.Pending:
      returnType = AdjustmentStatus.Pending;
      break;
    case AdjustmentStatusValue.Adjusted:
      returnType = AdjustmentStatus.Adjusted;
      break;
    case AdjustmentStatusValue.Abandoned:
      returnType = AdjustmentStatus.Abandoned;
      break;
  }
  return returnType || type;
};

export const getBadgeVariant = (type) => {
  let shapeStyles = {
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    color: 'black',
    fontSize: '12px',
    fontWeight: 500,
  };
  switch (type) {
    case AdjustmentStatus.Pending:
    case ConfirmationStatus.Warning:
      shapeStyles = { ...shapeStyles, width: 80, bgcolor: '#e8c600', color: '#000000;' };
      break;
    case AdjustmentStatus.Adjusted:
    case ConfirmationStatus.Info:
    case StockAllocationStatus.Allocated:
    case OrderStatus.Completed:
    case QualityControlStatus.Passed:
    case LeadStatus.Won:
    case 'Passed':
    case UserType.Admin:
      shapeStyles = { ...shapeStyles, width: 80, bgcolor: '#007a4d;', color: '#ffffff;' };
      break;
    case AdjustmentStatus.Abandoned:
    case ConfirmationStatus.Error:
    case OrderStatus.Cancelled:
    case QualityControlStatus.Failed:
    case LeadStatus.Lost:
    case 'Failed':
    case 'Blocked':
      shapeStyles = { ...shapeStyles, width: 80, bgcolor: '#d31510', color: '#ffffff;' };
      break;
    case OrderStatus.Draft:
    case 'In Progress':
    case LeadStatus.New:
    case UserType.User:
      shapeStyles = { ...shapeStyles, width: 80, bgcolor: '#0a66c2', color: '#ffffff;' };
      break;
    case ItemType.Consumables:
    case ItemType.PackagingMaterial:
    case IndustryTypes[0]?.label:
    case LocationTypes[0]?.label:
    case IndustryTypes[7]?.label:
    case IndustryTypes[14]?.label:
    case IndustryTypes[3]?.label:
    case IndustryTypes[10]?.label:
    case IndustryTypes[17]?.label:
    case LeadStatus.Proposition:
      type = type?.replace(/([A-Z])/g, ' $1').trim();
      shapeStyles = { ...shapeStyles, width: 120, bgcolor: '#0078d4', color: '#ffffff;' };
      break;
    case ItemType.Services:
    case IndustryTypes[1]?.label:
    case LocationTypes[1]?.label:
    case LocationTypes[4]?.label:
    case IndustryTypes[8]?.label:
    case IndustryTypes[15]?.label:
    case IndustryTypes[4]?.label:
    case IndustryTypes[11]?.label:
    case IndustryTypes[18]?.label:
    case LeadStatus.Qualified:
      shapeStyles = { ...shapeStyles, width: 120, bgcolor: '#7e3878', color: '#ffffff;' };
      break;
    case ItemType.FinishedGoods:
    case IndustryTypes[5]?.label:
    case LocationTypes[5]?.label:
    case LocationTypes[2]?.label:
    case IndustryTypes[12]?.label:
    case IndustryTypes[19]?.label:
      type = type?.replace(/([A-Z])/g, ' $1').trim();
      shapeStyles = { ...shapeStyles, width: 120, bgcolor: '#007a4d;', color: '#ffffff;' };
      break;
    case ItemType.RawMaterial:
    case LocationTypes[3]?.label:
    case IndustryTypes[6]?.label:
    case IndustryTypes[13]?.label:
    case IndustryTypes[20]?.label:
    case IndustryTypes[2]?.label:
    case IndustryTypes[9]?.label:
    case IndustryTypes[16]?.label:
      type = type?.replace(/([A-Z])/g, ' $1').trim();
      shapeStyles = { ...shapeStyles, width: 120, bgcolor: '#e8c600', color: '#000000;' };
      break;
    default:
      shapeStyles = { ...shapeStyles, width: 120, bgcolor: '#0a66c2', color: '#ffffff;' };
      break;
  }
  return (
    <Badge color="secondary" badgeContent={0}>
      <Box component="span" sx={shapeStyles}>
        {type}
      </Box>
    </Badge>
  );
};

export const formatEntityLabelValue = (entity, label, value) => {
  let formatedEntity;
  if (Array.isArray(entity)) {
    formatedEntity = entity.map((en) => ({
      label: en[label],
      value: en[value],
    }));
  } else if (typeof entity === 'object') {
    formatedEntity = {
      label: entity[label],
      value: entity[value],
    };
  }
  return formatedEntity;
};

export const convertToUTCWithEndTime = (dateString) => {
  const date = new Date(dateString);

  // Set the time to 23:59:59
  date.setUTCHours(23);
  date.setUTCMinutes(59);
  date.setUTCSeconds(59);

  // Convert the date to UTC
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

  return utcDate.toISOString();
};

export const generateEntityNumber = (entityName) => {
  const initials = entityName;
  // Get current date and time
  const now = new Date();
  const datePart = now.toISOString().split('T')[0].replace(/-/g, '');
  const timePart = now.toTimeString().split(' ')[0].replace(/:/g, '');

  // Generate a unique sequence (you may use a more robust method)
  const sequence = Math.floor(Math.random() * 1000);

  // Concatenate all parts to create the order number
  const entityNumber = `${initials}-${datePart}-${timePart}-${sequence}`;

  return entityNumber;
};

export const stringAvatar = (name) => {
  const firstName = name?.split(' ')[0]?.length ? name?.split(' ')[0][0] : '';
  const lastName = name?.split(' ')[1]?.length ? name?.split(' ')[1][0] : '';
  return {
    sx: {
      bgcolor: '#1f6cfa;',
      fontSize: '16px',
      cursor: 'pointer',
    },
    children: `${firstName}${lastName}`,
  };
};

export const formatProductLocation = (productLocations) => {
  return productLocations?.map((en) => ({
    label: `Item: <b> ${en.productName} </b>, Location: <b> ${en.locationName} </b>, Available stock: <b> ${en.availableStock} </b> , Unit: <b>  ${en.unit} </b>,  SKU: <b> ${en.sku} </b>`,
    value: `${en.productId}/${en.locationId}`,
    formattedObject: {
      productId: en.productId,
      stockOnHand: en.stockOnHand,
      locationId: en.locationId,
      price: en.priceValue,
      oldAvailableQuantity: en.availableStock,
      unit: en?.unit,
      selectedLabel: `Item: ${en.productName} , Location: ${en.locationName} , Available stock: ${en.availableStock}, SKU: ${en.sku},  Unit: ${en.unit}`,
      labelForSales: `Item: ${en.productName}, Unit: ${en.unit}, SKU: ${en.sku}`,
    },
  }));
};

export const getCurrencyOptions = () => {
  return currencyCodes.codes().map((code) => {
    const currency = currencyCodes.code(code);
    return { value: code, label: `${currency.code} - ${currency.currency}` };
  });
};

export const formatProduct = (productLocations) => {
  return productLocations?.map((en) => ({
    label: `Item: <b> ${en.productName} </b>, Available stock: <b> ${en.stockAvailable} </b> , SKU: <b> ${en.sku?.skuName} </b>,`,
    value: `${en.productId}`,
    formattedObject: {
      productId: en.productId,
      stockOnHand: en.stockOnHand,
      locationId: en.locationId,
      price: en.estimatedValuePerUnit,
      oldAvailableQuantity: en.stockAvailable,
      unit: en?.unit,
      selectedLabel: `Item: ${en.productName} , Available stock: ${en.stockAvailable}, SKU: ${en.sku?.skuName}`,
      selectedLabelForSales: `Item: ${en.productName}, Unit: ${en.unit}, SKU: ${en.sku?.skuName}`,
    },
  }));
};

export const formatProductForSales = (productLocations) => {
  return productLocations?.map((en) => ({
    label: `Item: <b> ${en.productName} </b>, Unit: <b>  ${en.unit} </b> , SKU: <b> ${en.sku?.skuName} </b>`,
    value: `${en.productId}`,
    formattedObject: {
      productId: en.productId,
      stockOnHand: en.stockOnHand,
      locationId: en.locationId,
      price: en.estimatedValuePerUnit,
      oldAvailableQuantity: en.stockAvailable,
      unit: en?.unit,
      selectedLabel: `Item: ${en.productName} , Available stock: ${en.stockAvailable}, SKU: ${en.sku?.skuName}`,
      selectedLabelForSales: `Item: ${en.productName}, Unit: ${en.unit}, SKU: ${en.sku?.skuName}`,
    },
  }));
};

export const showErrorAlert = (message) => {
  const alertContainer = document.createElement('div');
  document.body.appendChild(alertContainer);

  const root = createRoot(alertContainer);
  root.render(
    <Collapse
      in={true}
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        width: '300px',
        zIndex: 9999,
      }}
    >
      <Alert
        variant="filled"
        severity="error"
        sx={{
          mb: 2,
          p: 1,
          '& .MuiAlertTitle-root': {
            fontSize: '0.875rem',
          },
          '& .MuiAlert-message': {
            fontSize: '0.75rem',
          },
        }}
      >
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    </Collapse>
  );

  setTimeout(() => {
    root.unmount();
    document.body.removeChild(alertContainer);
  }, 3000);
};

export const mapWorkOrderStatus = (status) => {
  let returnType = '';
  switch (status) {
    case WorkOrderStatusValue.Draft:
      returnType = WorkOrderStatus.Draft;
      break;
    case WorkOrderStatusValue.InProgress:
      returnType = WorkOrderStatus.InProgress;
      break;
    case WorkOrderStatusValue.Completed:
      returnType = WorkOrderStatus.Completed;
      break;
    case WorkOrderStatusValue.Cancelled:
      returnType = WorkOrderStatus.Cancelled;
      break;
  }
  return returnType;
};

export const mapWorkOrderStatusValue = (status) => {
  let returnType = '';
  switch (status) {
    case WorkOrderStatus.Draft:
      returnType = WorkOrderStatusValue.Draft;
      break;
    case WorkOrderStatus.InProgress:
      returnType = WorkOrderStatusValue.InProgress;
      break;
    case WorkOrderStatus.Completed:
      returnType = WorkOrderStatusValue.Completed;
      break;
    case WorkOrderStatus.Cancelled:
      returnType = WorkOrderStatusValue.Cancelled;
      break;
  }
  return returnType;
};

export const renderLastModifiedData = (firstName, lastName, lastModifiedTime) => {
  return (
    <div className="divider cursor-pointer mx-2">
      <BootstrapTooltip className="d-flex" title={`Last modified By : ${firstName} ${lastName}`}>
        <Avatar {...stringAvatar(`${firstName} ${lastName}`)} />
        <div className="d-flex flex-column">
          <span className="mx-2 fs-12">
            {firstName} {lastName}
          </span>
          <span className="mx-2 fs-12">{formatDate(lastModifiedTime)}</span>
        </div>
      </BootstrapTooltip>
    </div>
  );
};

export const getWOKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case WorkOrderStatus.Draft:
    case WorkOrderStatus.InProgress:
      statusActionByRow = woKebabArray;
      break;
  }
  return statusActionByRow;
};

export const getQCKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case QualityControlStatus.Pending:
      statusActionByRow = qcKebabArray;
      break;
  }
  return statusActionByRow;
};

export const getLDKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case LogisticsDeliveryStatus.Pending:
      statusActionByRow = LogisticsDeliveryKebabArray;
      break;
  }
  return statusActionByRow;
};

export const getPCKebabMenuActions = (type) => {
  let statusActionByRow = [];

  switch (type) {
    case PackagingControlStatus.Pending:
      statusActionByRow = pcKebabArray;
      break;
  }
  return statusActionByRow;
};

export const getYearDifference = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const yearDiff = d2.getFullYear() - d1.getFullYear();

  // Check if the second date hasn't reached the same month and day as the first date
  if (d2.getMonth() != d1.getMonth() || d2.getDate() != d1.getDate()) {
    return yearDiff - 1;
  }

  return yearDiff;
};

export const formatProductWithLocation = (productLocations) => {
  return productLocations?.map((en) => ({
    label: `Item: <b> ${en.productName} </b>, Location: <b> ${en.locationName} </b>,  Unit: <b>  ${en.unit} </b>`,
    value: `${en.productId}/${en.locationId}`,
    formattedObject: {
      productId: en.productId,
      locationId: en.locationId,
      unit: en?.unit,
      selectedLabel: `Item: ${en.productName} , Location: ${en.locationName} , Unit: ${en.unit}`,
    },
  }));
};

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const TaskStatusBlock = ({ task = 'Stock Allocation', status = 'pending', handleTaskRedirection }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: 350,
        height: 40,
        backgroundColor: '#bdc1ca',
        position: 'relative',
        // '&::after': {
        //   content: '""',
        //   position: 'absolute',
        //   right: -18,
        //   top: 0,
        //   width: 0,
        //   height: 0,
        //   borderTop: '20px solid transparent',
        //   borderBottom: '20px solid transparent',
        //   borderLeft: '20px solid #bdc1ca',
        // },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          px: 2,
        }}
      >
        <Typography className="fs-12" fontWeight="bold">
          {task}: &nbsp;
        </Typography>
        <Typography variant="body2" component="a" href="#" onClick={handleTaskRedirection} className="link">
           {status}
        </Typography>
      </Box>
    </Paper>
  );
};

export default TaskStatusBlock;

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import TimerIcon from '@mui/icons-material/Timer';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';
import { StockTrackingStatus } from '../common-constants/status';
import { BootstrapTooltip } from './commonUtility';

const getStepIcon = (status) => {
  switch (status) {
    case StockTrackingStatus.Draft:
    case 'Allocated':
      return <NotStartedIcon color="action" className="stepper-icon" />;
    case StockTrackingStatus.Pending:
      return <TimerIcon color="primary" className="stepper-icon" />;
    case StockTrackingStatus.Completed:
      return <CheckCircleIcon color="success" className="stepper-icon" />;
    default:
      return <CircleIcon color="action" className="stepper-icon" />;
  }
};

const SalesStepper = ({ visulization }) => {
  return (
    <Box sx={{ width: '100%', marginTop: '0.5rem' }}>
      <Stepper alternativeLabel>
        {visulization?.WorkOrder ? (
          <Step key={visulization?.WorkOrder} active={true}>
            <BootstrapTooltip title={visulization?.WorkOrder}>
              <StepLabel
                StepIconComponent={() => getStepIcon(visulization?.WorkOrder)}
                sx={{
                  '& .MuiStepLabel-labelContainer': {
                    marginTop: '0px !important', // Adjust this value to reduce the margin
                  },
                  '& .MuiStepLabel-label': {
                    marginTop: '0px !important', // This ensures the label itself is also adjusted
                    fontSize: '10px',
                  },
                }}
              >
                Work Order
              </StepLabel>
            </BootstrapTooltip>
          </Step>
        ) : null}
        {visulization?.QualityControl ? (
          <Step key={visulization?.QualityControl} active={true}>
            <BootstrapTooltip title={visulization?.QualityControl}>
              <StepLabel
                StepIconComponent={() => getStepIcon(visulization?.QualityControl)}
                sx={{
                  '& .MuiStepLabel-labelContainer': {
                    marginTop: '0px !important', // Adjust this value to reduce the margin
                  },
                  '& .MuiStepLabel-label': {
                    marginTop: '0px !important', // This ensures the label itself is also adjusted
                    fontSize: '10px',
                  },
                }}
              >
                Quality Control
              </StepLabel>
            </BootstrapTooltip>
          </Step>
        ) : null}
        {visulization?.Packaging ? (
          <Step key={visulization?.Packaging} active={true}>
            <BootstrapTooltip title={visulization?.Packaging}>
              <StepLabel
                StepIconComponent={() => getStepIcon(visulization?.Packaging)}
                sx={{
                  '& .MuiStepLabel-labelContainer': {
                    marginTop: '0px !important', // Adjust this value to reduce the margin
                  },
                  '& .MuiStepLabel-label': {
                    marginTop: '0px !important', // This ensures the label itself is also adjusted
                    fontSize: '10px',
                  },
                }}
              >
                Packaging
              </StepLabel>
            </BootstrapTooltip>
          </Step>
        ) : null}
        {/* {stepperVisualization?.pickVisualization?.Pick ? (
          <Step key={stepperVisualization?.pickVisualization?.Pick} active={true}>
            <BootstrapTooltip title={stepperVisualization?.pickVisualization?.Pick}>
              <StepLabel
                StepIconComponent={() => getStepIcon(stepperVisualization?.pickVisualization?.Pick)}
                sx={{
                  '& .MuiStepLabel-labelContainer': {
                    marginTop: '0px !important', // Adjust this value to reduce the margin
                  },
                  '& .MuiStepLabel-label': {
                    marginTop: '0px !important', // This ensures the label itself is also adjusted
                    fontSize: '10px',
                  },
                }}
              >
                Logistics & Delivery
              </StepLabel>
            </BootstrapTooltip>
          </Step>
        ) : null} */}
      </Stepper>
    </Box>
  );
};
export default SalesStepper;

import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, styled, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_PRODUCTS_ENDPOINT } from '../../../../constants/constants';
import { Messages } from '../../../common-constants/messages';
import { AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import CardVisualization from '../../../common/card-visualization';
import { BootstrapTooltip, getCheckboxResult } from '../../../common/commonUtility';
import ConfirmDialog from '../../../common/confirm-dialog';
import CustomPagination from '../../../common/pagination';
import CustomTable from '../../../common/table';
import CustomTableActions from '../../../common/table-actions';
import { resetItemReducer } from '../../../redux/actions/item-action';
import InventoryInfo from './view-inventory-info/inventory-info';
import { resetDocumentReducer } from '../../../redux/actions/document-action';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import { useToken } from '../../../auth/useToken';

const InventoryManagement = () => {
  const location = useLocation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [tableLoader, setTableLoader] = useState(true);
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [activeCard, setActiveCard] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [aggregateData, setAggregateData] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const title = 'Items';

  const tableColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
      width: '5%',
    },
    {
      label: 'Name',
      field: 'productName',
      link: true,
      width: '15%',
    },
    {
      label: 'Item Type',
      field: 'productType',
      badge: true,
      width: '25%',
    },
    {
      label: 'Available Quantity',
      field: 'stockAvailable',
      width: '20%',
    },
    {
      label: 'Category',
      field: 'productCategory',
    },
    {
      label: 'SKU',
      field: 'sku.skuName',
    },
  ];

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Items',
      active: true,
    },
  ];

  const itemsPerPage = 6;
  const productEndpoint = API_PRODUCTS_ENDPOINT;

  useEffect(() => {
    fetchItems();
    stateHandlerOnLoad();
  }, [currentPage]);

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.itemTab === 'itemActivity' && location?.state?.itemId) {
        setSelectedItem(location?.state?.itemId);
        setActiveTab(location?.state?.itemTab);
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(resetDocumentReducer());
    fetchAggregateData(); // Fetch aggregate data on component mount
  }, []);

  const refreshItemGrid = () => {
    fetchItems();
    setActiveCard(0);
  };

  const stateHandlerOnLoad = () => {
    if (state?.productName) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${state?.productName}' ${Messages.itemAdded}`,
      });
    }
  };

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const handleNewItemOpen = () => {
    navigate('/items/newItem');
  };

  const closeSideNavBar = () => {
    setSelectedItem(null);
  };

  const handleItemClick = (item) => {
    if (item && item.productId !== selectedItem) {
      dispatch(resetItemReducer());
      setActiveTab('');
      setSelectedItem(item.productId);
    }
  };

  const handleCardActive = (activeCard) => {
    setActiveCard(activeCard);
    fetchItems(1, '', activeCard);
  };

  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const response = await api.get(`${API_BASE_URL}/products/overview`);
      const data = response.data;
      const aggregateData = [
        { title: 'Items', value: data.totalProducts, bindValue: 0 },
        { title: 'Raw Materials', value: data.totalRawMaterials, bindValue: 1 },
        { title: 'Finished Goods', value: data.totalFinishedGoods, bindValue: 2 },
        { title: 'Consumables', value: data.totalConsumables, bindValue: 3 },
        { title: 'Services', value: data.totalServices, bindValue: 4 },
        { title: 'Packaging Material', value: data.totalPackagingMaterials, bindValue: 7 },
        { title: 'Out of Stock', value: data.totalItemsOutOfStock, bindValue: 5, color: 'error' },
        { title: 'Running Low', value: data.totalItemsRunningLow, bindValue: 6, color: 'error' },
      ].filter((item) => item.value !== 0);
      setAggregateData(aggregateData);
      setAggregateLoader(false);
    } catch (error) {
      console.error('Error fetching aggregate data:', error);
      setAggregateLoader(false);
    }
  };

  const fetchItems = async (page = currentPage, query = search, activeCard = 0) => {
    setTableLoader(true);
    const queryParam = query ? `&query=${encodeURIComponent(query)}` : '';
    const itemType = activeCard > 0 ? `&itemType=${activeCard}` : '';
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=${page}&pageSize=${itemsPerPage}${queryParam}${itemType}`);
      if (response.data?.products) {
        response.data.products = response.data?.products?.map((item) => ({
          ...item,
          stockAvailable: item.trackInventory ? `${item.stockAvailable} ${item.unit}` : 'N/A',
        }));
      }

      const { totalCount, products, pageNumber, totalPages } = response.data;
      setItems(products);
      setCurrentPage(pageNumber);
      setTotalPages(totalPages);
      setTotalCount(totalCount);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const debouncedFetchItems = useCallback(
    debounce((value) => {
      fetchItems(1, value);
    }, 500),
    []
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchItems(page);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setCurrentPage(1);
    debouncedFetchItems(value);
  };

  const handleRefreshData = () => {
    fetchItems();
  };

  const handleDeleteSelectedItems = () => {
    setShowDeleteWarningModal(true);
  };

  const handleConfirmDelete = async () => {
    const selectedItems = items.filter((item) => item.checked)?.map((itm) => itm.productId);
    setShowDeleteWarningModal(false);
    try {
      await api.delete(productEndpoint, { data: selectedItems });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.itemDeleted,
      });
      fetchItems();
      fetchAggregateData();
    } catch (error) {
      console.error('An error occurred while deleting the items:', error);
    }
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setItems(getCheckboxResult(items, checkedItem, 'productId'));
  };

  const hiddenInputStyle = {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  };

  // Container style to make the whole component clickable
  const containerStyle = {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  };
  const [token, setToken] = useToken();
  const handleFileUploadChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('Visibility', 0);
      formData.append('Permissions', JSON.stringify([]));

      axios
        .post(API_BASE_URL + productEndpoint + '/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `Bulk import for Items has been completed successfully`,
          });
          fetchItems(1);
        })
        .catch((error) => {
          console.error('Error uploading the file or updating the account:', error);
        });
    }
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <CustomBreadCrumb breadcrumbList={breadcrumbs} />
        <Typography className="mt-2" variant="h5" gutterBottom>
          {title}
        </Typography>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={2}>
              <div className="d-flex">
                <CustomTableActions
                  disableDelete={!items.filter((item) => item.checked)?.length}
                  handleAddEntity={handleNewItemOpen}
                  handleRefresh={handleRefreshData}
                  handleDelete={handleDeleteSelectedItems}
                />
                <div className="ms-2 mt-2">
                  <BootstrapTooltip title="Bulk Item Upload">
                    {/* <UploadFileIcon />
                    <VisuallyHiddenInput type="file" onChange={handleFileUploadChange} /> */}
                    <div style={containerStyle}>
                      <label className="cursor-pointer">
                        <UploadFileIcon className="w-6 h-6 action-btn" />
                        <input type="file" onChange={handleFileUploadChange} style={hiddenInputStyle} aria-label="Upload file" />
                      </label>
                    </div>
                  </BootstrapTooltip>
                </div>
              </div>
            </Grid>
            <Grid className="d-flex align-items-center flex-wrap" item xs={8}>
              <CardVisualization loading={aggregateLoader} activeCard={activeCard} data={aggregateData} handleCardActive={handleCardActive} />
            </Grid>
            <Grid item xs={1.8}>
              <TextField
                variant="outlined"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={tableColumn} loading={tableLoader} tableData={items} toggleSelectedCheckbox={toggleSelectedCheckbox} handleEditEntity={handleItemClick} />
        <CustomPagination totalCount={totalCount} totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <ConfirmDialog showModal={showDeleteWarningModal} handleConfirmYes={handleConfirmDelete} handleConfirmNo={() => setShowDeleteWarningModal(false)} />
      {selectedItem && (
        <div className="inventory-info">
          <InventoryInfo itemId={selectedItem} onClose={closeSideNavBar} refreshItemGrid={refreshItemGrid} preSelectedTab={activeTab} />
        </div>
      )}
    </>
  );
};

export default InventoryManagement;

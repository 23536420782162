import { Avatar, Button, CircularProgress, Grid, TextField, Typography, debounce } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { API_PRODUCTS_ENDPOINT, API_PRODUCT_CATEGORY_ENDPOINT, API_SKU_ENDPOINT, TOKEN_USER_ROLE, UNIT_OPTIONS } from '../../../../../constants/constants';
import { useUser } from '../../../../auth/useUser';
import { Messages } from '../../../../common-constants/messages';
import { AlertStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { BootstrapTooltip, formatDate, formatEntityLabelValue, stringAvatar } from '../../../../common/commonUtility';
import InputFileUpload from '../../../../common/upload';

export const InventoryOverview = ({ id, inventoryData, refreshItemGrid, initialLoader }) => {
  const skuEndpoint = API_SKU_ENDPOINT;
  const productEndpoint = `${API_PRODUCTS_ENDPOINT}`;
  const categoryEndpoint = API_PRODUCT_CATEGORY_ENDPOINT;
  const user = useUser();
  const [editing, setEditing] = useState(false);
  const isAdminUser = user[TOKEN_USER_ROLE] === 'Admin' ? true : false;
  const [itemForm, setItemForm] = useState(inventoryData);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedSku, setSelectedSku] = useState('');
  const [skuOptions, setSkuOptions] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryOptions, setCategoryOptions] = useState('');
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchSkus();
    searchCategories();
    const selectedCategoryFromEdit = formatEntityLabelValue(itemForm?.categoryMetadata, 'categoryName', 'productCategoryId');
    if (itemForm?.categoryMetadata) {
      setSelectedCategory(selectedCategoryFromEdit);
    }
    const selectedSKUFromEdit = formatEntityLabelValue(itemForm?.sku, 'skuName', 'skuId');
    setSelectedSku(selectedSKUFromEdit);
    const findUnit = UNIT_OPTIONS.find((unt) => unt?.label == itemForm?.unit);
    if (findUnit) {
      setSelectedUnit(findUnit);
    }
    setItemForm({
      ...itemForm,
      sku: selectedSKUFromEdit?.value,
      productCategory: selectedCategoryFromEdit?.value,
      unit: findUnit?.value,
    });
  }, []);

  const searchSkus = debounce(() => {
    const url = `${skuEndpoint}?pageNumber=1&pageSize=100`;
    api
      .get(url)
      .then((response) => {
        const formatedSkuOptions = formatEntityLabelValue(response?.data?.skus, 'skuName', 'skuId');
        setSkuOptions(formatedSkuOptions);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const handleSkuChange = (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      addSku(newValue?.inputValue);
    } else {
      setSelectedSku({
        value: newValue?.value,
        label: newValue?.label,
      });
      setItemForm({
        ...itemForm,
        sku: newValue?.value,
      });
    }
  };

  const addSku = async (sku) => {
    try {
      const req = { skuName: sku };
      const response = await api.post(`${skuEndpoint}`, req);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${response?.data?.skuName}' ${Messages.skuAdded}`,
      });
      const formatedAdjReason = formatEntityLabelValue(response?.data, 'skuName', 'skuId');
      setSelectedSku(formatedAdjReason);
      setItemForm({
        ...itemForm,
        sku: formatedAdjReason?.value,
      });
      searchSkus();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const searchCategories = debounce(() => {
    const url = `${categoryEndpoint}?pageNumber=1&pageSize=100`;
    api
      .get(url)
      .then((response) => {
        const formatCategoryOptions = formatEntityLabelValue(response?.data?.productCategories, 'categoryName', 'productCategoryId');
        setCategoryOptions(formatCategoryOptions);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const handleCategoryChange = (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      addCategory(newValue?.inputValue);
    } else {
      setSelectedCategory({
        value: newValue?.value,
        label: newValue?.label,
      });
      setItemForm({
        ...itemForm,
        productCategory: newValue?.value,
      });
    }
  };

  const addCategory = async (category) => {
    try {
      const req = { name: category };
      const response = await api.post(`${categoryEndpoint}`, req);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${response?.data?.categoryName}' ${Messages.categoryAdded}`,
      });
      const formatedAdjReason = formatEntityLabelValue(response?.data, 'categoryName', 'productCategoryId');
      setSelectedCategory(formatedAdjReason);
      setItemForm({
        ...itemForm,
        productCategory: formatedAdjReason?.value,
      });
      searchCategories();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onUserChange = (field, value) => {
    setItemForm({
      ...itemForm,
      [field]: value,
    });
  };

  const catchLogoId = (event) => {
    if (event) {
      setItemForm({
        ...itemForm,
        productImage: event,
      });
    }
  };

  const getValidatedFormResult = () => {
    if (!itemForm.productName?.trim() || !itemForm.productCategory || !itemForm.sku || !itemForm.unit) {
      return false;
    }
    return true;
  };

  const handleUpdate = async () => {
    setItemForm({
      ...itemForm,
      submitted: true,
    });
    if (getValidatedFormResult()) {
      try {
        setLoading(true);
        const response = await api.put(`${productEndpoint}/${itemForm.productId}`, JSON.stringify(itemForm));
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `'${itemForm?.productName}' ${Messages.itemUpdated}`,
        });
        refreshItemGrid();
        setLoading(false);
        setEditing(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {initialLoader ? (
        <>
          <span>
            <CircularProgress size={20} />
          </span>
        </>
      ) : (
        <>
          <div className="d-flex">
            <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
            <Grid container className="mx-4">
              {itemForm?.productName && (
                <Grid item xs={12} md={12}>
                  <div className="d-flex justify-content-between mt-2">
                    {editing ? (
                      <TextField
                        className="mt-1 mb-1"
                        name="productName"
                        label="Name *"
                        variant="outlined"
                        error={!itemForm.productName?.trim() && itemForm.submitted}
                        helperText={!itemForm.productName?.trim() && itemForm.submitted ? 'Item Name is required.' : ''}
                        value={itemForm.productName}
                        onChange={(e) => onUserChange('productName', e.target.value)}
                      />
                    ) : (
                      <>
                        <Typography className="label">Name *</Typography>
                        <Typography className="entity-value">
                          <b>{itemForm?.productName}</b>
                        </Typography>
                      </>
                    )}
                  </div>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between mt-2">
                    {editing ? (
                      <CustomAutocomplete
                        className="d-flex flex-1 mt-1 mb-1"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        options={categoryOptions || []}
                        inputLabel="Category *"
                        isError={!selectedCategory?.value && itemForm.submitted}
                        helperText={!selectedCategory?.value && itemForm.submitted ? 'Category is required.' : ''}
                        placeholder="Select or type category... *"
                        getOptionLabel={(option) => option.label || ''}
                      />
                    ) : (
                      <>
                        <Typography className="label">Category *</Typography>
                        <Typography className="entity-value">
                          <b>{selectedCategory?.label} </b>
                        </Typography>
                      </>
                    )}
                  </div>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between mt-2">
                    {editing ? (
                      <CustomAutocomplete
                        className="d-flex flex-1 mt-1"
                        value={selectedSku}
                        onChange={handleSkuChange}
                        options={skuOptions}
                        inputLabel="SKU *"
                        isError={!selectedSku?.value && itemForm.submitted}
                        helperText={!selectedSku?.value && itemForm.submitted ? 'SKU is required.' : ''}
                        placeholder="Select or type SKU... *"
                        getOptionLabel={(option) => option.label || ''}
                      />
                    ) : (
                      <>
                        <Typography className="label">SKU *</Typography>
                        <Typography className="entity-value">
                          <b>{selectedSku?.label}</b>
                        </Typography>
                      </>
                    )}
                  </div>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between mt-2">
                    <Typography className="label">Item Type </Typography>
                    <Typography className="entity-value">
                      <b>{itemForm?.productType}</b>
                    </Typography>
                  </div>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between mt-2">
                    {/* {editing ? (
                  <CustomAutocomplete
                    className="d-flex flex-1 mt-2"
                    value={selectedUnit}
                    onChange={(event, selectedOption) => {
                      const unitValue = { label: selectedOption?.label, value: selectedOption?.value };
                      setSelectedUnit(unitValue);
                      onUserChange('unit', unitValue?.value);
                    }}
                    options={UNIT_OPTIONS}
                    disableAddNew={true}
                    inputLabel="Unit *"
                    isError={!selectedUnit?.value && itemForm.submitted}
                    helperText={!selectedUnit?.value && itemForm.submitted ? 'Unit is required.' : ''}
                    placeholder="Select Unit... *"
                    getOptionLabel={(option) => option.label || ''}
                  />
                ) : ( */}
                    <>
                      <Typography className="label">Unit * </Typography>
                      <Typography className="entity-value">
                        <b>{selectedUnit?.label}</b>
                      </Typography>
                    </>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    {editing ? (
                      <TextField
                        name="productDescription"
                        className="mt-2 mb-1"
                        label="Description"
                        multiline
                        rows={1}
                        variant="outlined"
                        value={itemForm?.productDescription}
                        onChange={(e) => onUserChange('productDescription', e.target.value)}
                      />
                    ) : (
                      <>
                        <Typography className="label">Description </Typography>
                        <Typography className="entity-value">
                          <b>{itemForm?.productDescription}</b>
                        </Typography>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <Typography className="label">Item Image</Typography>
                    {editing ? <InputFileUpload documentId={itemForm?.productImage} emitLogoId={catchLogoId} /> : <InputFileUpload documentId={itemForm?.productImage} editMode={false} />}
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    {editing ? (
                      <TextField name="remarks" label="Remarks" multiline rows={1} variant="outlined" value={itemForm?.remarks} onChange={(e) => onUserChange('remarks', e.target.value)} />
                    ) : (
                      <>
                        <Typography className="label">Remarks </Typography>
                        <Typography className="entity-value">
                          <b>{itemForm?.remarks}</b>
                        </Typography>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="d-flex mt-2">
                    {inventoryData?.user && (
                      <>
                        <BootstrapTooltip className="d-flex" title={`Created By : ${inventoryData?.user?.firstName} ${inventoryData?.user?.lastName}`}>
                          <Avatar {...stringAvatar(`${inventoryData?.user?.firstName} ${inventoryData?.user?.lastName}`)} style={{ width: 30, height: 30 }} />
                          <div className="d-flex flex-column">
                            <span className="mx-2 fs-12">
                              {inventoryData?.user?.firstName} {inventoryData?.user?.lastName}
                            </span>
                            <span className="mx-2 fs-12">{formatDate(itemForm?.createdAt)}</span>
                          </div>
                        </BootstrapTooltip>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="mt-3">
                    {isAdminUser ? (
                      editing ? (
                        <>
                          <Button size="small" variant="contained" disabled={loading} onClick={handleUpdate}>
                            UPDATE
                          </Button>
                          {loading && <CircularProgress className="mx-2" size={15} />}
                        </>
                      ) : (
                        <Button size="small" variant="outlined" onClick={() => setEditing(true)}>
                          EDIT
                        </Button>
                      )
                    ) : null}
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container className="right-visualization">
              {itemForm?.trackInventory ? (
                <div className="d-flex flex-column flex-1">
                  <div className="d-flex justify-content-between mt-2">
                    <Typography className="label">
                      <span className="circle blue"></span>
                      <span> Stock On Hand</span>
                    </Typography>
                    <Typography className="entity-value">
                      <b>
                        {itemForm?.stockOnHand} {selectedUnit?.label}
                      </b>
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <Typography className="label">
                      <span className="circle green"></span>
                      <span> Stock Available</span>
                    </Typography>
                    <Typography className="entity-value">
                      <b>
                        {itemForm?.stockAvailable} {selectedUnit?.label}
                      </b>
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <Typography className="label">
                      <span className="circle red"> </span>
                      <span>Committed Stock</span>
                    </Typography>
                    <Typography className="entity-value">
                      <b>
                        {itemForm?.stockComitted} {selectedUnit?.label}
                      </b>
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <i>Tracking inventory has been turned off.</i>
                </>
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LeadStatus } from '../../../../common-constants/status';

// Custom styles for the paper component
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  margin: theme.spacing(1),
  minWidth: 600,
  paddingTop: 15,
  // margin: '0 auto'
}));

const LeadStatusStepper = ({ leadStatus, handleStatusChange }) => {
  const [activeStep, setActiveStep] = useState(0);
  const defaultSteps = ['New', 'Qualified', 'Proposition', 'Won', 'Lost'];
  const [steps, setSteps] = useState(defaultSteps);
  // const steps = ['New', 'Qualified', 'Proposition', 'Won', 'Lost'];

  const handleStep = (step, label) => () => {
    setActiveStep(step);
    handleStatusChange(label);
  };

  useEffect(() => {
    if (leadStatus) {
      let filterSteps = [];
      if (leadStatus === 'Lost') {
        filterSteps = defaultSteps?.filter((ds) => ds !== 'Won');
      } else if (leadStatus === 'Won') {
        filterSteps = defaultSteps?.filter((ds) => ds !== 'Lost');
      } else {
        filterSteps = defaultSteps;
      }
      setSteps(filterSteps);
      setActiveStep(steps?.indexOf(leadStatus));
    }
  }, [leadStatus]);

  const StepIcon = ({ active, completed }) => {
    return (
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: active || completed ? '#1976d2' : '#bdbdbd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  };

  return (
    <StyledPaper elevation={3}>
      <div className={[LeadStatus.Won, LeadStatus.Lost].includes(leadStatus) ? 'item-disabled' : ''}>
        <Stepper activeStep={activeStep} alternativeLabel nonLinear>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={activeStep > index}
              onClick={handleStep(index, label)}
              sx={{
                cursor: 'pointer',
                '& .MuiStepLabel-root': {
                  '&:hover': {
                    color: 'primary.main',
                  },
                },
              }}
            >
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </StyledPaper>
  );
};

export default LeadStatusStepper;

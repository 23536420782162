import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_DOCUMENTS_ENDPOINT, API_WORKFLOW_BASE_URL } from '../../constants/constants';
import { useToken } from '../auth/useToken';
import { CircularProgress, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function MultiFileUpload({ editMode = true, listOfFiles, addFile, removeFile, disabledFileList = false }) {
  const [token, setToken] = useToken();
  const documentUploadApi = API_WORKFLOW_BASE_URL + API_DOCUMENTS_ENDPOINT;
  const dispatch = useDispatch();
  const { file } = useSelector((state) => state?.document?.data);
  const [fileData, setFileData] = useState({
    name: '',
    type: '',
  });
  const [uploadLoader, setUploadLoader] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  //   useEffect(() => {
  //     if (documentId) {
  //       getFileLogoUrl(documentId);
  //     }
  //   }, [documentId]);

  useEffect(() => {
    if (!logoUrl && file?.fileName && file?.url) {
      setFileData({
        ...fileData,
        name: file?.fileName,
      });
      setLogoUrl(file?.url);
    }
  }, [file]);

  const getFileData = (docId) => {
    axios
      .get(`${documentUploadApi}/${docId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const docUrl = URL.createObjectURL(response.data);
        window.open(docUrl);
      });
  };

  const handleFileUploadChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('Visibility', 0);
      formData.append('Permissions', JSON.stringify([]));
      setFileData({
        name: file?.name,
        type: file?.type,
      });
      setUploadLoader(true);
      await axios
        .post(documentUploadApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const docId = response?.data?.id;
          addFile(docId);
          setUploadLoader(false);
        })
        .catch((error) => {
          setUploadLoader(false);
          console.error('Error uploading the file or updating the account:', error);
        });
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        {editMode ? (
          <Button size="small" component="label" role={undefined} variant="outlined" tabIndex={-1} startIcon={<CloudUploadIcon size="small" />}>
            Choose File
            <VisuallyHiddenInput type="file" onChange={handleFileUploadChange} />
          </Button>
        ) : null}
        {uploadLoader && <CircularProgress size={18} className="ms-2 mt-1" />}
      </div>

      {listOfFiles?.length ? (
        <Grid container spacing={2}>
          <Grid item sm={12} md={12} xs={12}>
            {listOfFiles?.map((item, index) => (
              <div key={index} className="d-flex flex-grow-1 justify-content-between items-center">
                <div>
                  <Typography
                    variant="body2"
                    component="a"
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      getFileData(item);
                    }}
                    sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                  >
                    {item}
                  </Typography>
                </div>

                <CloseIcon className="cursor-pointer  h-1 w-2" onClick={() => removeFile(index)} />
              </div>
            ))}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </div>
  );
}

import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomAutocomplete from '../../../../common/autocomplete';
import { PaymentMethods, PaymentTermsTypes } from '../../../../common-constants/static-values';
import { useDispatch, useSelector } from 'react-redux';
import { setFinanceDetailsData } from '../../../../redux/actions/account-action';
import { getCurrencyOptions } from '../../../../common/commonUtility';

export const FinanceDetail = () => {
  const { stateFinanceForm, stateFormSubmitted } = useSelector((state) => state?.account?.data);
  const dispatch = useDispatch();
  const [financeForm, setFinanceForm] = useState({
    creditLimit: 0,
    lendingLimit: 0,
    paymentTerms: '',
    lvPaymentTerms: '',
    currency: '',
    lvCurrency: '',
    paymentMethod: '',
    lvPaymentMethod: '',
  });
  const currencyOptions = getCurrencyOptions();

  useEffect(() => {
    if (stateFinanceForm) {
      setFinanceForm(stateFinanceForm);
    }
  }, [stateFinanceForm]);

  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setFinanceForm({
      ...financeForm,
      [name]: value,
    });
    dispatch(
      setFinanceDetailsData({
        ...financeForm,
        [name]: value,
      })
    );
  };

  return (
    <>
      <Grid container spacing={3} className="mt-1 px-2">
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={financeForm.lvCurrency}
            onChange={(event, newValue) => {
              setFinanceForm({
                ...financeForm,
                currency: newValue?.value,
                lvCurrency: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setFinanceDetailsData({
                  ...financeForm,
                  currency: newValue?.value,
                  lvCurrency: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={currencyOptions}
            inputLabel="Default Currency *"
            disableAddNew={true}
            isError={!financeForm?.lvCurrency?.value && stateFormSubmitted}
            helperText={!financeForm?.lvCurrency?.value && stateFormSubmitted ? 'Currency is required.' : ''}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={financeForm.lvPaymentTerms}
            onChange={(event, newValue) => {
              setFinanceForm({
                ...financeForm,
                paymentTerms: newValue?.value,
                lvPaymentTerms: { value: newValue?.value, label: newValue?.label },
              });

              dispatch(
                setFinanceDetailsData({
                  ...financeForm,
                  paymentTerms: newValue?.value,
                  lvPaymentTerms: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={PaymentTermsTypes}
            inputLabel="Payment Terms"
            disableAddNew={true}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="creditLimit"
            name="creditLimit"
            label="Credit Limit"
            variant="outlined"
            // error={!financeForm.creditLimit && stateFormSubmitted}
            // helperText={!financeForm.creditLimit && stateFormSubmitted ? 'Credit Limit is required.' : ''}
            value={financeForm.creditLimit}
            onChange={handleControlChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="lendingLimit"
            name="lendingLimit"
            label="Lending Limit"
            variant="outlined"
            // error={!financeForm.lendingLimit && stateFormSubmitted}
            // helperText={!financeForm.lendingLimit && stateFormSubmitted ? 'Lending Limit is required.' : ''}
            value={financeForm.lendingLimit}
            onChange={handleControlChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomAutocomplete
            value={financeForm.lvPaymentMethod}
            onChange={(event, newValue) => {
              setFinanceForm({
                ...financeForm,
                paymentMethod: newValue?.value,
                lvPaymentMethod: { value: newValue?.value, label: newValue?.label },
              });
              dispatch(
                setFinanceDetailsData({
                  ...financeForm,
                  paymentMethod: newValue?.value,
                  lvPaymentMethod: { value: newValue?.value, label: newValue?.label },
                })
              );
            }}
            options={PaymentMethods}
            inputLabel="Payment Method"
            disableAddNew={true}
            placeholder="Search..."
            getOptionLabel={(option) => option.label || ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

import { TYPES } from '../type-constants/type';

export const setItemLocationData = (data) => ({
  type: TYPES.SET_ITEM_LOCATION_DATA,
  payload: { itemLocation: data },
});

export const setItemActivityData = (data) => ({
  type: TYPES.SET_ITEM_ACTIVITY_DATA,
  payload: { itemActivity: data },
});

export const setItemTransactionData = (data) => ({
  type: TYPES.SET_ITEM_TRANSACTION_DATA,
  payload: { itemTransactions: data },
});

export const setItemOverviewId = (data) => ({
  type: TYPES.SET_ITEM_OVERVIEW_ID,
  payload: { itemOverviewId: data },
});

export const setItemLocationId = (data) => ({
  type: TYPES.SET_ITEM_LOCATION_ID,
  payload: { itemLocationId: data },
});

export const setItemActivityId = (data) => ({
  type: TYPES.SET_ITEM_ACTIVTY_ID,
  payload: { itemActivityId: data },
});

export const setItemTransactionId = (data) => ({
  type: TYPES.SET_ITEM_TRANSACTION_ID,
  payload: { itemTransactionId: data },
});

export const setLocationSearch = (data) => ({
  type: TYPES.LOCATION_SEARCH,
  payload: { locationSearch: data },
});

export const setActivitySearch = (data) => ({
  type: TYPES.ACTIVITY_SEARCH,
  payload: { activitySearch: data },
});

export const setTransactionSearch = (data) => ({
  type: TYPES.TRANSACTION_SEARCH,
  payload: { transactionSearch: data },
});

export const setAccountFormSubmitted = (data) => ({
  type: TYPES.SET_ACCOUNT_FORM_SUBMITTED,
  payload: { stateAccountFormSubmitted: data },
});

export const resetItemReducer = (data) => ({
  type: TYPES.RESET_ITEM_REDUCER,
});

import CloseIcon from '@mui/icons-material/Close';
import { Grid, Button, TextField, CircularProgress, Typography, IconButton, Checkbox, FormControlLabel, Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CustomBreadCrumb from '../../../common/breadcrumb';
import CustomAutocomplete from '../../../common/autocomplete';
import api from '../../../common/api';
import AddIcon from '@mui/icons-material/Add';
import { BootstrapTooltip } from '../../../common/commonUtility';
import NewItemAtLocation from './new-item-at-location';
import { LocationTypes } from '../../../common-constants/static-values';

const AddLocation = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('locationDetails');
  const tabs = ['locationDetails', 'inventoryTracking'];
  const [locationOptions, setLocationOptions] = useState([{ value: 'new', label: 'Add a new Location' }]);
  const [itemOptions, setItemOptions] = useState([]);

  const breadcrumbs = [
    { name: 'Home', href: '/dashboard' },
    { name: 'Locations', href: '/locations' },
    { name: 'Add Location', active: true },
  ];

  const [locationCreationLoading, setLocationCreationLoading] = useState(false);

  useEffect(() => {
    searchLocations();
    searchItems();
  }, []);

  const searchItems = debounce(() => {
    const url = '/products?pageNumber=1&pageSize=100';
    api
      .get(url)
      .then((response) => {
        const options = response?.data?.products?.map((item) => ({
          value: item.productId,
          label: `${item.productName}${item.unit ? `, Unit: ${item.unit}` : ''}`.trim(),
          unit: item.unit
        }));
        setItemOptions(options);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  });

  const searchLocations = debounce(() => {
    const url = '/locations?pageNumber=1&pageSize=100';
    api
      .get(url)
      .then((response) => {
        const options = response?.data?.locations?.map((location) => ({
          value: location.locationId,
          label: location.locationName,
        }));
        setLocationOptions(options);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const handleLocationFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocationForm({
      ...locationForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocationForm({
      ...locationForm,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLocationCreationLoading(true);
  
    // Transform the form data to match the required JSON structure
    const transformedData = {
      locationName: locationForm.locationName,
      locationType: locationForm.locationType.value,
      address: {
        address: locationForm.area, // assuming 'area' is used as address
        city: locationForm.city,
        countryCode: locationForm.state, // assuming state is used as countryCode
        postalCode: locationForm.pinCode
      },
      contactInfo: locationForm.contactInformation,
      securityLevel: locationForm.securityLevel,
      operationalHours: locationForm.operationalHours,
      temperatureControl: locationForm.temperatureControl,
      productLocations: locationForm.items.map(item => ({
        productId: item.selectedItem?.value,
        openingBalance: Number(item.quantity) || 0
      })).filter(item => item.productId) // Only include items that have been selected
    };
  
    api
      .post('/locations', transformedData)
      .then((response) => {
        navigate('/locations', { state: { locationName: response?.data?.locationName } });
        setLocationCreationLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLocationCreationLoading(false);
      });
  };

  const [locationForm, setLocationForm] = useState({
    locationName: '',
    locationType: '',
    city: '',
    state: '',
    area: '',
    pinCode: '',
    contactInformation: '',
    temperatureControl: false,
    securityLevel: '',
    operationalHours: '',
    trackInventory: false,
    items: [{ selectedItem: null, quantity: '' }],
    submitted: false,
  });

  const handleItemChange = (newValue, index) => {
    setLocationForm((prevForm) => {
      const updatedItems = [...prevForm.items];
      updatedItems[index] = {
        ...updatedItems[index],
        selectedItem: newValue ? {
          value: newValue.value,
          label: newValue.label,
          unit: newValue.unit
        } : null
      };
      return {
        ...prevForm,
        items: updatedItems
      };
    });
  };

  const handleItemFieldChange = (e, index) => {
    const { name, value } = e.target;
    setLocationForm((prevForm) => {
      const updatedItems = [...prevForm.items];
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value
      };
      return {
        ...prevForm,
        items: updatedItems
      };
    });
  };

  const handleRemoveItemField = (index) => {
    setLocationForm((prevForm) => {
      const updatedItems = [...prevForm.items];
      updatedItems.splice(index, 1);
      return {
        ...prevForm,
        items: updatedItems
      };
    });
  };

  const handleAddItemField = () => {
    setLocationForm((prevForm) => ({
      ...prevForm,
      items: [...prevForm.items, { selectedItem: null, quantity: '' }]
    }));
  };

  return (
    <div className="d-flex flex-grow-1 flex-column p-4" style={{ position: 'relative' }}>
      <div style={{ marginBottom: '16px' }}>
        <div className="d-flex align-items-center justify-content-between">
          <CustomBreadCrumb breadcrumbList={breadcrumbs} />
          <IconButton
            onClick={() => navigate('/locations')}
            style={{ position: 'absolute', top: '20px', right: '20px' }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <Typography variant="h6" gutterBottom>
          Add Location
        </Typography>
        <p>
          <span className="error-red">*</span> Required Fields
        </p>
      </div>

      <div className="pe-3 ps-3 flex-grow-1 border rounded">
        <Tabs value={activeTab} onChange={handleTabSelect} className="mb-2">
          <Tab value="locationDetails" label="Location Details" />
          <Tab value="inventoryTracking" label="Inventory" />
        </Tabs>

        {activeTab === 'locationDetails' && (
          <Grid container className="mt-1" spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Location Name"
                name="locationName"
                value={locationForm.locationName}
                onChange={handleLocationFieldChange}
                fullWidth
                required
                error={!locationForm.locationName.trim() && locationForm.submitted}
                helperText={!locationForm.locationName.trim() && locationForm.submitted ? 'Location Name is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAutocomplete
                inputLabel="Location Type *"
                name="locationType"
                options={LocationTypes}
                value={locationForm.locationType}
                onChange={(e, value) => setLocationForm({ ...locationForm, locationType: value })}
                fullWidth
                required
                error={!locationForm.locationType && locationForm.submitted}
                helperText={!locationForm.locationType && locationForm.submitted ? 'Location Type is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="City"
                name="city"
                value={locationForm.city}
                onChange={handleLocationFieldChange}
                fullWidth
                required
                error={!locationForm.city.trim() && locationForm.submitted}
                helperText={!locationForm.city.trim() && locationForm.submitted ? 'City is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="State"
                name="state"
                value={locationForm.state}
                onChange={handleLocationFieldChange}
                fullWidth
                required
                error={!locationForm.state.trim() && locationForm.submitted}
                helperText={!locationForm.state.trim() && locationForm.submitted ? 'State is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Area"
                name="area"
                value={locationForm.area}
                onChange={handleLocationFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pin Code"
                name="pinCode"
                value={locationForm.pinCode}
                onChange={handleLocationFieldChange}
                fullWidth
                required
                error={!locationForm.pinCode.trim() && locationForm.submitted}
                helperText={!locationForm.pinCode.trim() && locationForm.submitted ? 'Pin Code is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Contact Information"
                name="contactInformation"
                value={locationForm.contactInformation}
                onChange={handleLocationFieldChange}
                fullWidth
                required
                error={!locationForm.contactInformation.trim() && locationForm.submitted}
                helperText={!locationForm.contactInformation.trim() && locationForm.submitted ? 'Contact Information is required.' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Security Level"
                name="securityLevel"
                value={locationForm.securityLevel}
                onChange={handleLocationFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Operational Hours"
                name="operationalHours"
                value={locationForm.operationalHours}
                onChange={handleLocationFieldChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={locationForm.temperatureControl}
                    onChange={handleLocationFieldChange}
                    name="temperatureControl"
                    color="primary"
                  />
                }
                label="Temperature Controlled"
              />
            </Grid>
          </Grid>
        )}

        {activeTab === 'inventoryTracking' && (
          <Grid container className="mt-1" spacing={3}>
            <Grid item xs={12}>
              <div className="d-flex">
                <Typography className="label mt-2">Track Inventory</Typography>
                <Checkbox
                  size="small"
                  name="trackInventory"
                  checked={locationForm.trackInventory}
                  onChange={handleInputChange}
                />
              </div>

              {locationForm.trackInventory && (
                <>
                  <NewItemAtLocation
                    items={locationForm.items}
                    handleItemChange={handleItemChange}
                    handleItemFieldChange={handleItemFieldChange}
                    handleRemoveItemField={handleRemoveItemField}
                    itemOptions={itemOptions}
                    locationForm={locationForm}
                  />
                  <Grid item xs={6} md={6} className="mt-2">
                    <BootstrapTooltip title="Add Item">
                      <Button size="small" className="mt-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddItemField}>
                        Add Item to this location
                      </Button>
                    </BootstrapTooltip>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}

        <div className="d-flex align-items-center my-3">
          {activeTab !== tabs[0] && (
            <Button size="small" variant="outlined" onClick={() => setActiveTab(tabs[tabs.indexOf(activeTab) - 1])}>
              Previous
            </Button>
          )}
          {activeTab !== tabs[tabs.length - 1] ? (
            <Button size="small" variant="outlined" onClick={() => setActiveTab(tabs[tabs.indexOf(activeTab) + 1])}>
              Next
            </Button>
          ) : (
            <div className="d-flex align-items-center mx-2">
              <Button size="small" type="submit" variant="contained" disabled={locationCreationLoading} onClick={handleSubmit}>
                Submit
              </Button>
              {locationCreationLoading && <CircularProgress className="mx-2" size={15} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddLocation;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useMediaQuery,
  useTheme, 
  DialogContentText
} from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { API_API_KEYS_ENDPOINT } from '../../../../constants/constants';
import { useToken } from '../../../auth/useToken';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDate } from '../../../common/commonUtility';

const CreateApiKeyDialog = ({ open, handleClose, handleSave }) => {
  const [apiKeyName, setApiKeyName] = useState('');
  const [permissions, setPermissions] = useState('Read');
  const [userType, setUserType] = useState('User');

  const handleChange = (e) => {
    setApiKeyName(e.target.value);
  };

  const handleSubmit = () => {
    handleSave(apiKeyName, permissions, userType);
    setApiKeyName('');
    setPermissions('Read');
    setUserType('User');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New API Key</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="apiKeyName"
          label="API Key Name"
          type="text"
          fullWidth
          variant="outlined"
          value={apiKeyName}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Permissions</InputLabel>
          <Select
            value={permissions}
            onChange={(e) => setPermissions(e.target.value)}
          >
            <MenuItem value={0}>Read</MenuItem>
            <MenuItem value={1}>ReadWrite</MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset" margin="dense">
          <Typography variant="body2" color="textSecondary" gutterBottom>User Type</Typography>
          <RadioGroup
            value={userType}
            onChange={(e) => setUserType(Number(e.target.value))}
            row
          >
            <FormControlLabel value={0} control={<Radio />} label="User" />
            <FormControlLabel value={1} control={<Radio />} label="Service User" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="info">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create Key
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const breadcrumbs = [
  {
    name: 'Home',
    href: '/dashboard',
  },
  {
    name: 'Settings',
  },
  {
    name: 'API Keys',
    active: true,
  },
];

const ShowApiKeyDialog = ({ open, handleClose, apiKeyDetails }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(apiKeyDetails.key);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>API Key Created</DialogTitle>
      <DialogContent>
        <Typography variant="body1">API Key</Typography>
        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
          {apiKeyDetails.key}
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography variant="body1" style={{ marginTop: 16 }}>Permissions</Typography>
        <Typography variant="body2">{apiKeyDetails.permissions === 0 ? 'Read' : 'ReadWrite'}</Typography>
        <Typography variant="body1" style={{ marginTop: 16 }}>User Type</Typography>
        <Typography variant="body2">{apiKeyDetails.userType === 0 ? 'User' : 'Service User'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Done</Button>
      </DialogActions>
    </Dialog>
  );
};

const ApiKeyManagement = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [newApiKeyDetails, setNewApiKeyDetails] = useState({ key: '', permissions: '', userType: '' });

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        console.log(config);
        const response = await axios.get(API_API_KEYS_ENDPOINT, config);
        setApiKeys(response.data.apiKeys);
      } catch (error) {
        console.error('Error fetching API keys:', error);
      }
    };
    fetchApiKeys();
  }, []);

  const handleCloseApiKeyDialog = () => setShowApiKeyDialog(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleSaveApiKey = async (apiKeyName, permissions, userType) => {
    const newApiKey = {
      name: apiKeyName,
      permissions: Number(permissions),
      userType: Number(userType),
    };
    try {
      const response = await axios.post(API_API_KEYS_ENDPOINT, newApiKey, config);
      setApiKeys([...apiKeys, response.data]);
      setNewApiKeyDetails(response.data); // Set the new API key details
      setShowApiKeyDialog(true); // Show the API key dialog
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const [keyToRevoke, setKeyToRevoke] = useState(null);

  const handleRevokeClick = (apiKey) => {
    setKeyToRevoke(apiKey);
    setOpenRevokeDialog(true);
  };

  const handleRevokeConfirm = async () => {
    if (keyToRevoke) {
      try {
        await axios.delete(`${API_API_KEYS_ENDPOINT}/${keyToRevoke.id}`, config);
        setApiKeys(apiKeys.filter(key => key.id !== keyToRevoke.id));
        setOpenRevokeDialog(false);
        setKeyToRevoke(null);
      } catch (error) {
        console.error('Error revoking API key:', error);
      }
    }
  };

  const handleRevokeCancel = () => {
    setOpenRevokeDialog(false);
    setKeyToRevoke(null);
  };

  const renderApiKeyTable = () => {
    return (
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table aria-label="API keys table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '16px 24px' }}>Name</TableCell>
              <TableCell style={{ padding: '16px 24px' }}>API Key</TableCell>
              {!isMobile && (
                <>
                  <TableCell style={{ padding: '16px 24px' }}>User Type</TableCell>
                  <TableCell style={{ padding: '16px 24px' }}>Permissions</TableCell>
                  <TableCell style={{ padding: '16px 24px' }}>Created At</TableCell>
                </>
              )}
              <TableCell style={{ padding: '16px 24px' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((apiKey) => (
              <TableRow key={apiKey.id}>
                <TableCell component="th" scope="row" style={{ padding: '16px 24px' }}>
                  <Typography variant="body1">{apiKey.name}</Typography>
                  {isMobile && (
                    <Typography variant="caption" color="textSecondary">
                      {apiKey.userType === 0 ? 'User' : 'Service User'} | {apiKey.permissions === 0 ? 'Read' : 'ReadWrite'}
                    </Typography>
                  )}
                </TableCell>
                <TableCell style={{ padding: '16px 24px' }}>
                  <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                    {apiKey.key.substring(0, 10)}...
                    <Tooltip title="Copy API Key">
                      <IconButton onClick={() => handleCopyApiKey(apiKey.key)} size="small" style={{ marginLeft: 8 }}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell style={{ padding: '16px 24px' }}>
                      <Chip
                        label={apiKey.userType === 0 ? 'User' : 'Service User'}
                        color={apiKey.userType === 0 ? 'primary' : 'secondary'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '16px 24px' }}>
                      <Chip
                        label={apiKey.permissions === 0 ? 'Read' : 'ReadWrite'}
                        color={apiKey.permissions === 0 ? 'info' : 'warning'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '16px 24px' }}>
                      {formatDate(apiKey.createdAt)}
                    </TableCell>
                  </>
                )}
                <TableCell style={{ padding: '16px 24px' }}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleRevokeClick(apiKey)}
                    size="small"
                  >
                    Revoke
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleCopyApiKey = (key) => {
    navigator.clipboard.writeText(key);
  };

  return (
    <div className="d-flex flex-grow-1 flex-column p-4">
      <div className="d-flex justify-between align-items-center">
        <Grid container className="d-flex flex-column">
          <CustomBreadCrumb breadcrumbList={breadcrumbs} />
          <Typography className="mt-2" variant="h5" gutterBottom>
            API Keys
          </Typography>
        </Grid>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          size="sm"
          style={{ backgroundColor: '#10A37F', color: 'white', height: 40 , width: 200, fontSize: 12 }}
        >
          Create new API key
        </Button>
      </div>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="body1" gutterBottom>
            As an owner of this project, you can view and manage all API keys of this Raya Account.
          </Typography>
          <Typography variant="body2" gutterBottom color="textSecondary">
            Do not share your API key with others, or expose it in the browser or other client-side code.
            In order to protect the security of your account, Raya may also automatically disable any API key that has leaked publicly.
          </Typography>
          <CreateApiKeyDialog open={openDialog} handleClose={handleCloseDialog} handleSave={handleSaveApiKey} />
          <ShowApiKeyDialog open={showApiKeyDialog} handleClose={handleCloseApiKeyDialog} apiKeyDetails={newApiKeyDetails} />

          {apiKeys.length > 0 && renderApiKeyTable()}

          <Dialog
              open={openRevokeDialog}
              onClose={handleRevokeCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Revoke API Key"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to revoke this API key? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRevokeCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleRevokeConfirm} color="error" autoFocus>
                  Revoke
                </Button>
              </DialogActions>
            </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApiKeyManagement;

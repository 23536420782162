import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();
const CustomAutocomplete = ({
  value,
  onChange,
  options = [],
  inputLabel,
  placeholder,
  multiple,
  getOptionLabel,
  freeSolo = true,
  sx,
  isError,
  disableAddNew,
  helperText,
  disabledValues = [],
  className = '',
  disabled = false,
}) => {
  return (
    <Autocomplete
      className={className}
      value={value}
      onChange={onChange}
      filterOptions={(options, params) => {
        let filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.value);
        if (inputValue !== '' && !isExisting && !disableAddNew) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        if (inputValue !== '' && disableAddNew && !filtered.length) {
          filtered.push({
            value: '',
            label: `No options available`,
          });
        }
        return filtered || [];
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => {
        const isDisabled = disabledValues?.includes(option.value) || option.value === '';
        return (
          <li {...props} className={`${value?.value === option.value ? 'active-item' : 'select-option'} ${isDisabled ? 'item-disabled' : ''}`} key={option.value}>
            <span dangerouslySetInnerHTML={{ __html: option.label }} />
          </li>
        );
      }}
      noOptionsText="No result found"
      multiple={multiple}
      freeSolo={freeSolo}
      disableClearable={!value}
      renderInput={(params) => <TextField {...params} label={inputLabel} placeholder={placeholder} variant="outlined" error={isError} helperText={helperText} />}
      sx={sx}
    />
  );
};

export default CustomAutocomplete;

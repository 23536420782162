import { Search } from '@mui/icons-material';
import {  Grid, InputAdornment, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from '../../../../constants/constants';
import { Messages } from '../../../common-constants/messages';
import { AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import CustomBreadCrumb from '../../../common/breadcrumb';
import CustomTable from '../../../common/table';
import CustomTableActions from '../../../common/table-actions';
import ConfirmDialog from '../../../common/confirm-dialog';
import CustomPagination from '../../../common/pagination';
import api from '../../../common/api';
import CardVisualization from '../../../common/card-visualization';
import { resetDocumentReducer } from '../../../redux/actions/document-action';

const getCheckboxResult = (data, checkedItem, key) => {
  return data.map(item =>
    item[key] === checkedItem[key] ? { ...item, checked: !item.checked } : item
  );
};

const LocationLanding = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [tableLoader, setTableLoader] = useState(true);
  const [overviewData, setOverviewData] = useState({}); // New state for overview data
  const [aggregateData, setAggregateData] = useState([]);
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [activeCard, setActiveCard] = useState(0);

  const title = 'Locations';

  // Updated table columns to reflect new fields
  const tableColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
      width: '5%',
    },
    {
      label: 'Name',
      field: 'locationName',
      link: true,
      width: '20%',
      onClick: (item) => handleEditLocation(item.locationId),
    },
    {
      label: 'Temperature Controlled',
      field: 'temperatureControl',
      width: '15%'
    },
    {
      label: 'Location Type',
      field: 'locationType',
      width: '15%',
      badge : true
    },
    {
      label: 'Goods Value',
      field: 'goodsValue', // Renamed column
      width: '15%',
    },
    {
      label: 'Created By',
      field: 'createdByUser',
      width: '15%',
      avatar: true
    },
  ];

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Locations',
      active: true,
    },
  ];

  const itemsPerPage = 6;
  const locationEndpoint = `${API_BASE_URL}/locations`;

  const fetchLocations = useCallback(async (page = currentPage, query = search, activeCard = 0) => {
    setTableLoader(true);
    const queryParam = query ? `&query=${encodeURIComponent(query)}` : '';
    const locationType = activeCard > 0 ? `&locationType=${activeCard}` : '';
    try {
      const response = await api.get(`${locationEndpoint}?pageNumber=${page}&pageSize=${itemsPerPage}${queryParam}${locationType}`);
      
      response.data.locations = response.data.locations?.map((item) => ({
        ...item,
        createdByUser: `${item?.createdByUser?.firstName} ${item?.createdByUser?.lastName}`,
      }));
      const { totalCount, locations = [], pageNumber, totalPages } = response.data;
      console.log('locations:', locations);
      setLocations(locations);
      setCurrentPage(pageNumber);
      setTotalPages(totalPages);
      setTotalCount(totalCount);
    } catch (error) {
      setAlertData({
        open: true,
        type: AlertStatus.Error,
        message: 'Failed to fetch locations',
      });
    }
    setTableLoader(false);
  }, [currentPage, search, locationEndpoint, itemsPerPage]);

  // New function to fetch overview data
  const fetchOverviewData = useCallback(async () => {
    try {
      const response = await api.get('/Locations/overview');
      setOverviewData(response.data);
    } catch (error) {
      setAlertData({
        open: true,
        type: AlertStatus.Error,
        message: 'Failed to fetch overview data',
      });
    }
  }, []);

  useEffect(() => {
    fetchLocations();
    // fetchOverviewData(); // Fetch overview data when the component mounts
  }, [fetchLocations]);
  useEffect(() => {
    dispatch(resetDocumentReducer());
    fetchAggregateData(); // Fetch aggregate data on component mount
  }, []);

  const debouncedFetchLocations = useCallback(
    debounce((value) => {
      fetchLocations(1, value);
    }, 500),
    [fetchLocations]
  );

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
    fetchLocations(page);
  }, [fetchLocations]);

  const handleSearch = (value) => {
    setSearch(value);
    setCurrentPage(1);
    debouncedFetchLocations(value);
  };

  const handleNewLocationOpen = () => {
    navigate('/locations/new');
  };

  const handleRefreshData = () => {
    fetchLocations();
  };

  const handleDeleteSelectedLocations = () => {
    setShowDeleteWarningModal(true);
  };

  const handleTransferClick = () => {
    navigate('/locations/transfer');
  };

  const handleCardActive = (activeCard) => {
    setActiveCard(activeCard);
    fetchLocations(1, '', activeCard);
  };

  const handleConfirmDelete = async () => {
    const selectedLocations = locations.filter((location) => location.checked);
    const locationsWithNonZeroCapacity = selectedLocations.filter((loc) => loc.capacity > 0);


    if (locationsWithNonZeroCapacity.length > 0) {
      setAlertData({
        open: true,
        type: AlertStatus.Error,
        message: 'Cannot delete locations with non-zero capacity. Please ensure capacity is zero before deleting.',
      });
      setShowDeleteWarningModal(false);
      return;
    }

    const selectedLocationIds = selectedLocations.map((loc) => loc.locationId);
    setShowDeleteWarningModal(false);
    try {
      await api.delete(locationEndpoint, { data: selectedLocationIds });
      setAlertData({
        open: true,
        type: AlertStatus.Success,
        message: Messages.locationDeleted,
      });
      fetchLocations();
      fetchAggregateData();
    } catch (error) {
      setAlertData({
        open: true,
        type: AlertStatus.Error,
        message: 'An error occurred while deleting the locations',
      });
    }
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setLocations(getCheckboxResult(locations, checkedItem, 'locationId'));
  };

  const handleEditLocation = (locationId) => {
    navigate(`/locations/edit/${locationId}`);
  };
  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const response = await api.get(`${API_BASE_URL}/locations/overview`);
      const data = response.data;
      const aggregateData = [
        { title: 'Locations', value: data.totalLocations, bindValue: 0 },
        { title: 'Warehouses', value: data.totalWarehouses, bindValue: 1 },
        { title: 'Stores', value: data.totalStores, bindValue: 2 },
        { title: 'Shops', value: data.totalShops, bindValue: 3 },
        { title: 'Offices', value: data.totalOffices, bindValue: 4 },
        { title: 'Factories', value: data.totalFactories, bindValue: 5 },
        { title: 'Homes', value: data.totalHomes, bindValue: 6 },
      ].filter((locationType) => locationType.value !== 0);
      setAggregateData(aggregateData);
      setAggregateLoader(false);
    } catch (error) {
      console.error('Error fetching aggregate data:', error);
      setAggregateLoader(false);
    }
  };

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <CustomBreadCrumb breadcrumbList={breadcrumbs} />
        <Typography className="mt-2" variant="h5" gutterBottom>
          {title}
        </Typography>
        <div className="d-flex">
          <Grid container xs={12}>
            <Grid item xs={1.2}>
              <CustomTableActions
                disableDelete={!locations.some((location) => location.checked)}
                handleAddEntity={handleNewLocationOpen}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteSelectedLocations}
              />
            </Grid>
             {/* Card Visualization Section */}
          <Grid className="d-flex align-items-center flex-wrap" item xs={9}>
              <CardVisualization loading={aggregateLoader} handleCardActive={handleCardActive} data={aggregateData} activeCard={activeCard}  />
            </Grid>
            <Grid item xs={1.8}>
              <TextField
                variant="outlined"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable
          tableColumn={tableColumn}
          loading={tableLoader}
          tableData={locations}
          toggleSelectedCheckbox={toggleSelectedCheckbox}
        />
        
        <CustomPagination
          totalCount={totalCount}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>
      <CustomAlert
        type={alertData?.type}
        message={alertData.message}
        open={alertData?.open}
        onClose={alertClose}
      />
      <ConfirmDialog
        showModal={showDeleteWarningModal}
        handleConfirmYes={handleConfirmDelete}
        handleConfirmNo={() => setShowDeleteWarningModal(false)}
      />
    </>
  );
};

export default LocationLanding;

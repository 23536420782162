import { configureStore } from '@reduxjs/toolkit';
import itemReducer from './reducers/item-reducer';
import accountReducer from './reducers/account-reducer';
import documentReducer from './reducers/document-reducer';
import salesReducer from './reducers/sales-reducer';
import WorkorderReducer from './reducers/workorder-reducer';
import leadsReducer from './reducers/leads-reducer';

const store = configureStore({
  reducer: {
    item: itemReducer,
    account: accountReducer,
    document: documentReducer,
    sales: salesReducer,
    workorder: WorkorderReducer,
    leads: leadsReducer,
  },
});

export default store;

import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Messages } from '../../../common-constants/messages';
import { AlertStatus } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { formatEntityLabelValue, getCheckboxResult } from '../../../common/commonUtility';
import ConfirmDialog from '../../../common/confirm-dialog';
import CustomPagination from '../../../common/pagination';
import CustomTable from '../../../common/table';
import CustomTableActions from '../../../common/table-actions';
import PrimaryContactModal from '../../../modals/primary-contact-modal';
import { setPrimaryContactsData } from '../../../redux/actions/account-action';

const ContactLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const itemsPerPage = 6;
  const contactColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Name',
      field: 'fullName',
      link: true,
    },
    {
      label: 'Phone',
      field: 'mobile',
    },
    {
      label: 'Created Date',
      field: 'createdDate',
      date: true,
    },
    {
      label: 'City',
      field: 'city',
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Contacts',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const [showPrimaryContactModal, setShowPrimaryContactModal] = useState(false);
  const [primaryContactOptions, setPrimaryContactOptions] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async (currentPage = 1, search, activeCard = 0) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    try {
      const response = await api.get(`/contacts?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}`);
      response.data.contacts = response.data.contacts?.map((item) => ({
        ...item,
        fullName: `${item?.firstName} ${item?.lastName}`,
        city: item?.mailingAddress?.city,
      }));
      const contactsData = response.data?.contacts;
      setEntityData(response.data);
      setContacts(contactsData);
      setCurrentPage(currentPage);
      const primaryContactData = formatEntityLabelValue(response.data?.contacts, 'fullName', 'contactId');
      setPrimaryContactOptions(primaryContactData);
      dispatch(setPrimaryContactsData(primaryContactData));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const handleRefreshData = () => {
    fetchContacts();
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleEditEntity = (item) => {
    setContactId(item?.contactId);
    setShowPrimaryContactModal(true);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchContacts(1, searchText);
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    fetchContacts(page);
  };

  const handleDeleteConfirmYes = () => {
    deleteContacts();
  };

  const deleteContacts = async () => {
    const selectedItems = contacts.filter((item) => item.checked)?.map((adj) => adj.contactId);
    setDeleteDialog(false);
    try {
      await api.delete('/contacts', {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.primaryContactDelete,
      });
      fetchContacts();
      // fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setContacts(getCheckboxResult(contacts, checkedItem, 'contactId'));
  };

  const addPrimaryContact = () => {
    setContactId('');
    setPrimaryContactOptions('');
    setShowPrimaryContactModal(true);
  };

  const handlePrimaryContactModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event && reason !== 'escapeKeyDown') {
      fetchContacts();
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${reason}' ${Messages.primaryContactCreated}`,
      });
    } else if (reason && reason !== 'escapeKeyDown') {
      fetchContacts();
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${reason}' ${Messages.primaryContactUpdated}`,
      });
    }
    setContactId('');
    setPrimaryContactOptions('');
    setShowPrimaryContactModal(false);
  };
  return (
    <>
      <PrimaryContactModal contactId={contactId} showModal={showPrimaryContactModal} handleClose={handlePrimaryContactModalClose} />
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Contacts
            </Typography>
          </Grid>
        </div>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={10}>
              <CustomTableActions
                disableDelete={!contacts.filter((cus) => cus.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={addPrimaryContact}
              ></CustomTableActions>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={contactColumn} loading={tableLoader} tableData={contacts} toggleSelectedCheckbox={toggleSelectedCheckbox} handleEditEntity={handleEditEntity} />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default ContactLanding;

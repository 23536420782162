import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_OPERATIONS_ENDPOINT, API_PRODUCT_LOCATION_ENDPOINT, API_PRODUCTS_ENDPOINT, API_SALES_DELIVERY_NOTES_ENDPOINT, API_USERS_ENDPOINT } from '../../../constants/constants';
import { useToken } from '../../auth/useToken';
import { Messages } from '../../common-constants/messages';
import { AlertStatus, LogisticsDeliveryStatus, LogisticsDeliveryStatusValue, QualityControlStatus, WorkOrderStatus, WorkOrderStatusValue } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomAutocomplete from '../../common/autocomplete';
import CustomBreadCrumb from '../../common/breadcrumb';
import {
  BootstrapTooltip,
  formatEntityLabelValue,
  formatProductForSales,
  generateEntityNumber,
  getBadgeVariant,
  getCheckboxResult,
  getLDKebabMenuActions,
  renderLastModifiedData,
} from '../../common/commonUtility';
import MultiFileUpload from '../../common/multi-upload';
import { DeliveryMethodModal } from '../../modals/delivery-method';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openItemModal } from '../../redux/actions/sales-action';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BasicMenu from '../../common/menu';
import ShipmentDetailDialog from '../../modals/shipment-detail';

export const ManageLogisticsDelivery = () => {
  const { deliveryOrderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  const deliveryNoteEndpoint = API_SALES_DELIVERY_NOTES_ENDPOINT;
  //   const customerEndpoint = API_TENANTS_ENDPOINT;
  const productLocationEndpoint = API_PRODUCT_LOCATION_ENDPOINT;
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [ldFiles, setLDFiles] = useState();

  const defaultShippingDetail = {
    shipmentDetailId: '',
    deliveryNoteId: '',
    lvDeliveryNoteId: '',
    trackingNumber: '',
    carrierDetails: '',
    estimatedDeliveryDate: null,
    actualDeliveryDate: null,
    deliveryRemarks: '',
    items: [],
  };
  const [lstShippingDetail, setLstShippingDetail] = useState([defaultShippingDetail]);
  const [ldForm, setLDForm] = useState({
    assignedTo: '',
    lvAssignedTo: '',
    estimatedDeliveryDateForOrder: null,
    remarks: '',
  });
  const [userOptions, setUserOptions] = useState();

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [productItemOptions, setProductItemOptions] = useState([]);

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let userData = [];
  let dnOptions = [];
  let formatedReasonsOptions = [];
  const [deliveryIndex, setDeliveryIndex] = useState('');
  const [deliveryNotesOptions, setDeliveryNotesOptions] = useState();
  const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false);
  const [isShippingItemModalOpen, setIsShippingModalOpen] = useState(false);
  const defaultShippingItem = {
    disabledCheckbox: false,
    checkedItemIndex: '',
    productId: '',
    productId: '',
    quantity: 0,
  };
  const [defaultLstItems, setDefaultLstItems] = useState([defaultShippingItem]);
  const [itemIndex, setItemIndex] = useState('');

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const breadcrumbs = [
    {
      name: 'Logistics & Delivery',
      href: '/shipping',
    },
    {
      name: 'Manage Logistics & Delivery',
      active: true,
    },
  ];

  useEffect(() => {
    fetchLDById();
  }, []);

  const fetchUsers = async () => {
    try {
      const uri = `${API_USERS_ENDPOINT}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedReasonsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDeliveryNote = async () => {
    try {
      const response = await api.get(`${deliveryNoteEndpoint}?pageNumber=1&pageSize=100`);
      dnOptions = formatEntityLabelValue(response.data, 'name', 'id');
      setDeliveryNotesOptions(dnOptions);
    } catch (error) {
      console.error('Error fetching delivery notes:', error);
    }
  };

  const fetchLDById = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${operationsEndpoint}/delivery-orders/${deliveryOrderId}`);
      await fetchUsers();
      await fetchDeliveryNote();
      await fetchProducts();
      setLDFiles(response?.data?.files || []);
      const finalQCObj = {
        ...response.data,
        deliveryOrderReferenceNumber: response?.data?.deliveryOrderReferenceNumber || generateEntityNumber('LD'),
        estimatedDeliveryDateForOrder: response?.data?.estimatedDeliveryDateForOrder ? dayjs(response?.data?.estimatedDeliveryDateForOrder) : null,
        lvAssignedTo: userData?.find((uo) => uo?.value === response?.data?.assignedTo),
      };
      setLDForm(finalQCObj);
      let shipmentDetails = [];
      const mapShipmentItemForModal = mapShipmentItemsForModal(response?.data?.salesOrderItems, false, response?.data?.salesOrderItems);
      setDefaultLstItems(mapShipmentItemForModal);
      if (response?.data?.shipmentDetails?.length) {
        shipmentDetails = response?.data?.shipmentDetails?.map((sd) => ({
          ...sd,
          lvDeliveryNoteId: sd?.deliveryNoteId ? dnOptions?.find((ud) => ud?.value === sd?.deliveryNoteId) : '',
          actualDeliveryDate: sd?.actualDeliveryDate ? dayjs(sd?.actualDeliveryDate) : null,
          estimatedDeliveryDate: sd?.estimatedDeliveryDate ? dayjs(sd?.estimatedDeliveryDate) : null,
          items: mapShipmentItemsForModal(sd?.items || sd?.shipmentItems, true, response?.data?.salesOrderItems),
        }));
        setLstShippingDetail(shipmentDetails);
      } else if (!response?.data?.shipmentDetails?.length) {
        lstShippingDetail[0].items = mapShipmentItemForModal;
        setLstShippingDetail(lstShippingDetail);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const mapShipmentItemsForModal = (lst = [], isQuantityAllocate, salesOrderItems) => {
    const listOrderItems = lst?.map((so, soIndex) => {
      const product = salesOrderItems?.find((pio) => pio?.productId === so?.productId) || salesOrderItems?.find((pio) => pio?.productId === so?.itemId);
      const formattedProduct = formatedReasonsOptions?.find((pio) => pio?.value === so?.productId) || formatedReasonsOptions?.find((pio) => pio?.value === so?.itemId);
      return {
        ...so,
        quantity: isQuantityAllocate ? so?.quantity : '',
        lvProductId: { label: formattedProduct?.formattedObject?.selectedLabelForSales, value: so?.productId || so?.itemId },
        productId: so?.productId || so?.itemId,
        unit: product?.formattedObject?.unit,
        orderedQty: product?.quantity,
      };
    });
    return listOrderItems;
  };

  const handleUserChange = async (newValue) => {
    if (newValue?.value) {
      setLDForm({
        ...ldForm,
        assignedTo: newValue?.value,
        lvAssignedTo: { value: newValue?.value, label: newValue?.label },
      });
    }
  };

  const handleAddFile = (event) => {
    if (event) {
      ldFiles.push(event);
      setLDForm({
        ...ldForm,
        files: ldFiles,
      });
    }
  };

  const handleRemoveFile = (index) => {
    if (index) {
      const filteredFile = ldFiles.filter((fi, i) => i !== index);
      setLDForm({
        ...ldForm,
        files: filteredFile,
      });
    }
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (!ldForm?.deliveryOrderReferenceNumber?.trim() || !ldForm?.lvDeliveryNoteId?.value) {
      return false;
    }
    if (!valid) {
      return false;
    }
    return true;
  };

  const handleSaveLogisticsDelivery = async (isSubmit) => {
    console.log('ldForm', ldForm);
    console.log('lstShippingDetail', lstShippingDetail);

    const finalLstShippingDetail = lstShippingDetail?.map((ls, lsIndex) => {
      if (!ls.deliveryNoteId) {
        delete ls?.deliveryNoteId;
      }
      return {
        ...ls,
        shipmentDetailId: ls?.shipmentDetailId || crypto.randomUUID(),
        items: ls?.items?.map((item) => ({
          itemId: item?.productId,
          quantity: parseInt(item?.quantity) || 0,
        })),
      };
    });

    const reqObj = {
      ...ldForm,
      shipmentDetails: finalLstShippingDetail,
      files: ldFiles,
    };
    if (!isSubmit) {
      setSaveLoader(true);
    }
    const response = await api.put(`${operationsEndpoint}/delivery-orders/${deliveryOrderId}`, reqObj);
    if (!isSubmit) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.ldSaved}`,
      });
    }
    if (isSubmit) {
      const submitObj = {
        //  locationId: ldForm?.locationIdForProduce,
        status: LogisticsDeliveryStatusValue?.Completed,
      };
      setSubmitLoader(true);
      const response = await api.put(`${operationsEndpoint}/delivery-orders/${deliveryOrderId}/status`, submitObj);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.ldSubmitted}`,
      });
      setSubmitLoader(false);
    }
    fetchLDById(deliveryOrderId);
    setSaveLoader(false);
  };

  const handleLDControlChange = (e) => {
    const { name, value } = e.target;
    setLDForm({
      ...ldForm,
      [name]: value,
    });
  };

  const handleCloseDeliveryNote = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event?.id) {
      fetchDeliveryNote();
      let lstUpdated = lstShippingDetail.map((ship, i) => {
        if (i !== deliveryIndex) return ship;

        ship = { ...ship, deliveryNoteId: event?.id, lvDeliveryNoteId: { value: event?.id, label: event?.name } };
        return ship;
      });
      setLstShippingDetail(lstUpdated);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${event?.name}' ${Messages.deliveryMethodAdded}`,
      });
    }
    setShowDeliveryNoteModal(false);
  };

  const handleDateChange = async (e, index, name) => {
    //  const { name, value } = e.target;
    let lstUpdated = lstShippingDetail.map((ship, i) => {
      if (i !== index) return ship;

      ship = { ...ship, [name]: e };
      return ship;
    });
    setLstShippingDetail(lstUpdated);
  };

  const handleShipDetailChange = (e, index) => {
    const { name, value } = e.target;
    let lstUpdated = lstShippingDetail.map((ship, i) => {
      if (i !== index) return ship;

      ship = { ...ship, [name]: value };
      return ship;
    });
    setLstShippingDetail(lstUpdated);
    //  dispatch(setBOMItemsData(updatedProducts));
  };

  const handleDeliveryNoteChange = (e, newValue, index) => {
    setDeliveryIndex(index);
    if (newValue?.label?.includes('Add')) {
      setShowDeliveryNoteModal(true);
    } else {
      let lstUpdated = lstShippingDetail.map((ship, i) => {
        if (i !== index) return ship;

        ship = { ...ship, deliveryNoteId: newValue?.value, lvDeliveryNoteId: { value: newValue?.value, label: newValue?.label } };
        return ship;
      });
      setLstShippingDetail(lstUpdated);
    }
  };

  const handleMaterialAddShipment = () => {
    const addedShipment = [...lstShippingDetail, { defaultShippingDetail, items: defaultLstItems }];
    setLstShippingDetail(addedShipment);
  };

  const handleMaterialRemoveShipment = (index) => {
    const removedShipment = lstShippingDetail?.filter((item, i) => i !== index);
    setLstShippingDetail(removedShipment);
  };

  const handleOperationClick = (operation, index) => {
    setIsShippingModalOpen(true);
    setItemIndex(index);
  };

  const handleMenuItemClicked = async (event) => {
    if (event > 0) {
      try {
        const reqObj = {
          status: Number(event),
        };
        await api.put(`${operationsEndpoint}/delivery-orders/${deliveryOrderId}/status`, reqObj);
        fetchLDById();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.ldCancelled} `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleCloseShipmentModal = (formData, type) => {
    if (formData && itemIndex >= 0) {
      const updateFormData = type !== 'close' ? [...formData] : null;
      let updatedOp = lstShippingDetail?.map((operation, mapIndex) => {
        if (mapIndex !== itemIndex) return operation;

        if (operation?.items) {
          operation = { ...operation, items: [...updateFormData] };
        }
        return operation;
      });
      setLstShippingDetail([...updatedOp]);
    }
    setIsShippingModalOpen(false);
  };

  return (
    <>
      <ShipmentDetailDialog
        productItemOptions={productItemOptions}
        itemIndex={itemIndex}
        shipmentDetailArray={lstShippingDetail}
        isShippingItemModalOpen={isShippingItemModalOpen}
        handleCloseShipmentModal={handleCloseShipmentModal}
      />
      <DeliveryMethodModal showModal={showDeliveryNoteModal} handleCloseDeliveryNote={handleCloseDeliveryNote} />
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      {/* </Popover> */}
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <div className="d-flex">
              {deliveryOrderId ? <BasicMenu menuItems={getLDKebabMenuActions(ldForm?.deliveryStatus)} itemClicked={(e, item) => handleMenuItemClicked(e, item)} /> : ''}
              <CloseIcon
                className="cursor-pointer mt-2"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress className="mx-2" size={15} />
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex ">
                    <div className="d-flex">
                      <Typography variant="h6" className="d-flex mb-0" gutterBottom>
                        {/* {ldForm?.item?.productName} ({ldForm?.quantity} {ldForm?.item?.unit}) */}
                        Manage Logistics & Delivery Order
                      </Typography>
                      <span className="mx-4">{getBadgeVariant(ldForm?.deliveryStatus)}</span>
                    </div>
                    <div className="divider">
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (ldForm?.salesOrder?.transactionId) {
                              navigate(`/sales/${ldForm?.salesOrder?.transactionId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {ldForm?.salesOrder?.orderNumber}
                        </Typography>
                      </div>
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (ldForm?.tenant?.tenantId) {
                              navigate(`/accounts/${ldForm?.tenant?.tenantId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {ldForm?.tenant?.referenceNumber}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <span className="error-red ">*</span> Required Fields
            </div>
          </div>
        </div>
        <Grid container spacing={2} className="mt-1">
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="deliveryOrderReferenceNumber"
              name="deliveryOrderReferenceNumber"
              label="Reference Number"
              variant="outlined"
              error={!ldForm.deliveryOrderReferenceNumber?.trim() && ldForm.submitted}
              value={ldForm.deliveryOrderReferenceNumber || ''}
              onChange={handleLDControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={ldForm?.lvAssignedTo || ''}
              onChange={(event, newValue) => handleUserChange(newValue)}
              options={userOptions || []}
              inputLabel="Assigned To"
              isError={!ldForm?.lvAssignedTo?.value && ldForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Estimated Delivery Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={ldForm.estimatedDeliveryDateForOrder}
                format="DD/MM/YYYY"
                name="estimatedDeliveryDateForOrder"
                onChange={(e) =>
                  setLDForm({
                    ...ldForm,
                    estimatedDeliveryDateForOrder: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label m-0">Files</Typography>
            <MultiFileUpload listOfFiles={ldForm?.files?.length > 0 ? ldForm?.files : ''} addFile={handleAddFile} removeFile={handleRemoveFile} />
          </Grid>
          <Grid item className="d-flex justify-content-between mt-3">
            <Typography variant="h6" className="d-flex mb-0" gutterBottom>
              Shipping Details
            </Typography>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <TableContainer size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="18%">Tracking No.</TableCell>
                    <TableCell width="18%">Delivery Note</TableCell>
                    <TableCell width="15%">Carrier Details</TableCell>
                    <TableCell width="17%">Estimated Delivery Date</TableCell>
                    {/* <TableCell width="12%">Act. Del. Date</TableCell> */}
                    <TableCell align="center" width="10%">
                      Items
                    </TableCell>
                    <TableCell width="15%">Remarks</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    lstShippingDetail?.length
                      ? lstShippingDetail?.map((row, shipIndex) => (
                          <TableRow key={shipIndex}>
                            <TableCell width="18%">
                              <TextField
                                id="trackingNumber"
                                name="trackingNumber"
                                variant="outlined"
                                // error={!packagingForm.packagingLocation?.trim() && packagingForm.submitted}
                                value={row.trackingNumber || ''}
                                onChange={(e) => handleShipDetailChange(e, shipIndex)}
                              />
                            </TableCell>
                            <TableCell width="18%">
                              <CustomAutocomplete
                                value={row.lvDeliveryNoteId || ''}
                                onChange={(event, newValue) => handleDeliveryNoteChange(event, newValue, shipIndex)}
                                options={deliveryNotesOptions}
                                isError={!row?.lvDeliveryNoteId?.value && ldForm?.submitted}
                                placeholder="Search..."
                                getOptionLabel={(option) => option.label || ''}
                              />
                            </TableCell>
                            <TableCell width="15%">
                              <TextField
                                id="carrierDetails"
                                name="carrierDetails"
                                variant="outlined"
                                // error={!packagingForm.packagingLocation?.trim() && packagingForm.submitted}
                                value={row.carrierDetails || ''}
                                onChange={(e) => handleShipDetailChange(e, shipIndex)}
                              />
                            </TableCell>
                            <TableCell width="17%" className="position-relative">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={row.estimatedDeliveryDate}
                                  format="DD/MM/YYYY"
                                  name="estimatedDeliveryDate"
                                  onChange={(e) => {
                                    console.log(e);
                                    handleDateChange(e, shipIndex, 'estimatedDeliveryDate');
                                  }}
                                  slotProps={{
                                    textField: {
                                      variant: 'outlined',
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            {/* <TableCell width="12%">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={row.actualDeliveryDate}
                                  format="DD/MM/YYYY"
                                  name="actualDeliveryDate"
                                  onChange={(e) => {
                                    console.log(e);
                                    handleDateChange(e, shipIndex, 'actualDeliveryDate');
                                  }}
                                  slotProps={{
                                    textField: {
                                      variant: 'outlined',
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </TableCell> */}
                            <TableCell width="10%">
                              <div className="d-flex operations-chip-container justify-content-center">
                                <BootstrapTooltip>
                                  <Chip
                                    key={shipIndex}
                                    className="mx-2 mt-2"
                                    onClick={() => {
                                      handleOperationClick(row, shipIndex);
                                    }}
                                    variant={row?.items?.filter((lsi) => lsi?.quantity > 0)?.length ? '' : 'outlined'}
                                    label="Items"
                                    color="primary"
                                    clickable
                                  />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                            <TableCell align="left" width="15%">
                              <div className="pt-3 pb-1">
                                <TextField value={row?.deliveryRemarks} name="deliveryRemarks" variant="outlined" multiline rows={2} onChange={(e) => handleShipDetailChange(e, shipIndex)} />
                              </div>
                            </TableCell>
                            <TableCell>
                              {lstShippingDetail?.length > 1 ? (
                                <BootstrapTooltip title="Delete">
                                  <IconButton className="pt-3 pb-1" color="info" onClick={() => handleMaterialRemoveShipment(shipIndex)}>
                                    <Delete />
                                  </IconButton>
                                </BootstrapTooltip>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))
                      : ''
                    // (
                    //   <TableRow>
                    //     <TableCell align="center" colSpan={8}>
                    //       <div className="justify-center m-2 fs-14 error-red">Processing Details are mandatory to complete the work order, Please select a Route.</div>
                    //     </TableCell>
                    //   </TableRow>
                    // )
                  }
                </TableBody>
                <BootstrapTooltip title="Add Material">
                  <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleMaterialAddShipment}>
                    Add Shipment
                  </Button>
                </BootstrapTooltip>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item sm={12} md={12} xs={12}>
            <TextField name="remarks" label="Remarks" multiline rows={2} variant="outlined" value={ldForm?.remarks || ''} onChange={handleLDControlChange} />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-start mt-3">
          <Button
            size="small"
            variant="contained"
            onClick={() => handleSaveLogisticsDelivery(false)}
            disabled={saveLoader || [LogisticsDeliveryStatus.Completed, LogisticsDeliveryStatus.Cancelled].includes(ldForm?.deliveryStatus)}
          >
            Save
          </Button>
          {saveLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          <Button
            size="small"
            variant="contained"
            className="ms-3"
            onClick={() => handleSaveLogisticsDelivery(true)}
            disabled={[LogisticsDeliveryStatus.Completed, LogisticsDeliveryStatus.Cancelled].includes(ldForm?.deliveryStatus) || submitLoader}
          >
            Submit
          </Button>
          {submitLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress className="mx-2" size={15} />
            </div>
          ) : (
            renderLastModifiedData(ldForm?.lastModifiedByUser?.firstName, ldForm?.lastModifiedByUser?.lastName, ldForm?.lastModifiedTime)
          )}
        </div>
      </div>
    </>
  );
};

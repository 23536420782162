import { Delete } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import React from 'react';
import CustomAutocomplete from '../../../common/autocomplete';
import { BootstrapTooltip } from '../../../common/commonUtility';

const NewItemAtLocation = ({ items = [], handleItemChange, handleItemFieldChange, handleRemoveItemField, itemOptions, locationForm }) => {
    return (
      <>
        {items?.length > 0 &&
          items.map((item, index) => (
            <Grid container key={index} spacing={2} className="mt-1">
              <Grid item xs={12} md={5}>
                <CustomAutocomplete
                  value={item.selectedItem}
                  onChange={(e, newValue) => handleItemChange(newValue, index)}
                  options={itemOptions}
                  inputLabel="Item"
                  placeholder="Select item..."
                  getOptionLabel={(option) => option?.label || ''}
                  isError={!item?.selectedItem?.value && locationForm.submitted}
                  helperText={!item?.selectedItem?.value && locationForm.submitted ? 'Item is required.' : ''}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  type="number"
                  label="Opening Balance"
                  id={`quantity-${index}`}
                  value={item.quantity}
                  name="quantity"
                  variant="outlined"
                  onChange={(e) => handleItemFieldChange(e, index)}
                  error={!item?.quantity && locationForm.submitted}
                  helperText={!item?.quantity && locationForm.submitted ? 'Quantity is required.' : ''}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={2}>
                {items?.length > 1 && (
                  <BootstrapTooltip title="Delete">
                    <IconButton color="info" onClick={() => handleRemoveItemField(index)}>
                      <Delete />
                    </IconButton>
                  </BootstrapTooltip>
                )}
              </Grid>
            </Grid>
          ))}
      </>
    );
  };

export default NewItemAtLocation;

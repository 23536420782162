import { TYPES } from '../type-constants/type';

const initialState = {
  data: {},
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_CUSTOMER_DATA:
    case TYPES.SET_SALES_PERSON_DATA:
    case TYPES.SET_DELIVERY_NOTE_DATA:
    case TYPES.SET_PRODUCTS_DATA:
    case TYPES.SET_SALES_BY_ID_DATA:
    case TYPES.SET_EDIT_PRODUCTS_DATA:
    case TYPES.SET_EDIT_OPERATIONS_DATA:
    case TYPES.SET_DELETED_ITEMS_DATA:
    case TYPES.OPEN_ITEM_MODAL:
    case TYPES.CLOSE_ITEM_MODAL:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case TYPES.RESET_SALES_REDUCER:
      return initialState;
    default:
      return state;
  }
};

export default salesReducer;

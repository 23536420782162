import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_SALES_TRANSACTIONS_ENDPOINT } from '../../../../constants/constants';
import { Messages } from '../../../common-constants/messages';
import { getSalesOrderKebabMenuActions } from '../../../common-constants/static-values';
import { AlertStatus, OrderStatus, OrderStatusValue } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomBreadCrumb from '../../../common/breadcrumb';
import { getBadgeVariant } from '../../../common/commonUtility';
import BasicMenu from '../../../common/menu';
import TaskStatusBlock from '../../../common/triangle-status-block';
import { setSalesByIdData } from '../../../redux/actions/sales-action';
import EditInvoice from './edit-order-tabs/edit-invoice';
import { EditOrderItems } from './edit-order-tabs/edit-order-items';
import { EditOrderOverview } from './edit-order-tabs/edit-order-overview';
export const EditOrder = () => {
  const salesTransactionEndpoint = API_SALES_TRANSACTIONS_ENDPOINT;
  const { salesId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { salesData } = useSelector((state) => state?.sales?.data);

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Sales',
      href: '/sales',
    },
    {
      name: 'Order Info',
      active: true,
    },
  ];

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const editAccountTabs = [
    {
      value: 0,
      label: 'Overview',
    },
    {
      value: 1,
      label: 'Items',
    },
  ];
  const invoiceTabs = [
    {
      value: 2,
      label: 'Invoice',
    },
    {
      value: 3,
      label: 'Payment',
    },
  ];
  const [orderTabs, setOrderTabs] = useState([]);
  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [activeTab, setActiveTab] = useState(0);

  const mapOrderStatus = (status) => {
    switch (parseInt(status)) {
      case OrderStatusValue.Draft:
        return OrderStatus.Draft;
        break;
      case OrderStatusValue.Pending:
        return OrderStatus.Pending;
        break;
      case OrderStatusValue.Completed:
        return OrderStatus.Completed;
        break;
      case OrderStatusValue.Cancelled:
        return OrderStatus.Cancelled;
        break;
    }
  };

  const fetchSalesByIdData = async () => {
    try {
      const { data } = await api.get(`${salesTransactionEndpoint}/${salesId}`);
      const salesDataResponse = {
        ...salesData,
        ...data,
        orderStatus: data?.orderStatus,
      };
      dispatch(setSalesByIdData(salesDataResponse));
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleMenuItemClicked = async (event) => {
    if (event === 1) {
      try {
        const reqObj = {
          // adjustmentStatus: Number(event),
        };
        const result = await api.post(`${salesTransactionEndpoint}/${salesId}/submit`, reqObj);
        fetchSalesByIdData();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.orderStatusChanged} and Stock Allocation order is created. Please click on a link to redirect to Stock Allocation, `,
          link: true,
          path: `/stockAllocation/${result?.data?.stockAllocationOrderId}`,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (event > 1) {
      try {
        const reqObj = {
          newStatus: Number(event),
        };
        const result = await api.post(`${salesTransactionEndpoint}/${salesId}/updateStatus`, reqObj);
        fetchSalesByIdData();
        setAlertData({
          ...alertData,
          open: true,
          link: false,
          type: AlertStatus.Success,
          message: event === 4 ? `${Messages.salesOrderCompleted}` : `${Messages.salesOrderCancelled}`,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleTaskRedirection = () => {
    navigate(`/stockAllocation/${salesData?.stockAllocationOrderResponse?.id}`);
  };

  useEffect(() => {
    if (salesData?.orderStatus === OrderStatusValue.Draft) {
      setOrderTabs([...editAccountTabs]);
    } else {
      setOrderTabs([...editAccountTabs, ...invoiceTabs]);
    }
  }, [salesData?.orderStatus]);

  const handleNavigateBack = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('itemId')) {
      navigate(`/items`, { state: { itemId: searchParams.get('itemId'), itemTab: searchParams.get('itemTab') } });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} link={alertData?.link} path={alertData?.path} />
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            {salesData?.orderNumber ? (
              <div className="d-flex">
                <Typography variant="h6" className="d-flex" gutterBottom>
                  {salesData?.orderNumber}
                </Typography>
                <span className="mx-2">{getBadgeVariant(mapOrderStatus(salesData?.orderStatus))}</span>
                {salesData?.stockAllocationOrderResponse?.stockAllocationStatus ? (
                  <div className="divider">
                    <TaskStatusBlock status={salesData?.stockAllocationOrderResponse?.stockAllocationStatus} handleTaskRedirection={handleTaskRedirection} />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <CircularProgress className="mx-2" size={15} />
              </div>
            )}
          </div>
          <div className="d-flex">
            {getSalesOrderKebabMenuActions(mapOrderStatus(salesData?.orderStatus))?.length ? (
              <BasicMenu menuItems={getSalesOrderKebabMenuActions(mapOrderStatus(salesData?.orderStatus))} itemClicked={handleMenuItemClicked} />
            ) : null}
            <CloseIcon
              className="cursor-pointer mx-2 mt-2"
              onClick={() => {
                handleNavigateBack();
              }}
            />
          </div>
        </div>
        <Grid container>
          <Grid item xs={9} md={9}>
            <Tabs value={activeTab} onChange={handleTabSelect} className="mx-2 mr-4">
              {orderTabs?.length && orderTabs?.map((item, index) => <Tab key={index} value={item.value} label={<Typography className="text-capitalize fs-13">{item.label}</Typography>}></Tab>)}
            </Tabs>
          </Grid>
        </Grid>

        {activeTab === 0 && <EditOrderOverview />}
        {activeTab === 1 && <EditOrderItems />}
        {salesData?.orderStatus !== OrderStatusValue.Draft && activeTab === 2 && <EditInvoice />}
        {salesData?.orderStatus !== OrderStatusValue.Draft && activeTab === 3 && <Typography className="pt-4">Coming Soon...</Typography>}
      </div>
    </>
  );
};

import { Avatar, Checkbox, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import React from 'react';
import { BootstrapTooltip, formatDate, getBadgeVariant, setBadgeType, stringAvatar } from './commonUtility';
import BasicMenu from './menu';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const CustomTable = ({ loading, tableColumn, tableData, toggleSelectedCheckbox, handleEditEntity, menuItemClicked }) => {
  const getNestedValue = (obj, path) => {
    return path?.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const renderTableHeaders = () =>
    tableColumn?.length
      ? tableColumn.map((theader, hindex) => (
          <TableCell align={theader?.align} width={theader?.width} key={hindex} sx={{ padding: '4px 16px' }} className={theader?.className}>
            {theader?.checkbox ? (
              tableData?.length ? (
                <Checkbox size="small" checked={tableData[0]?.checkedAll || false} onChange={() => toggleSelectedCheckbox('all')} />
              ) : null
            ) : (
              <Typography variant="subtitle2">{theader.label}</Typography>
            )}
          </TableCell>
        ))
      : null;

  const tableRowStyle = {
    '&:last-child td, &:last-child th': { border: 0 },
    // Target the first TableCell
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: '16px !important', // Adjust this value as needed
    },
  };

  const renderTableData = () =>
    !loading ? (
      tableData?.length > 0 ? (
        tableData.map((item, index) => (
          <TableRow key={index} sx={tableRowStyle} className={item?.assigned ? 'assigned-row' : ''}>
            {tableColumn?.length
              ? tableColumn.map((theader, hindex) => (
                  <TableCell align={theader?.align} width={theader?.width} key={hindex} className={theader?.className}>
                    {theader.checkbox ? (
                      <Checkbox size="small" checked={item.checked || false} disabled={item?.disabledCheckbox || false} onChange={() => toggleSelectedCheckbox(item)} />
                    ) : theader.link ? (
                      <Typography
                        variant="body2"
                        component="a"
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          handleEditEntity(item, theader);
                        }}
                        sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                      >
                        {getNestedValue(item, theader?.field)}
                      </Typography>
                    ) : theader.badge || theader.shorthandFirst || theader.shorthandSecond || theader?.icon ? (
                      <div className="d-flex">
                        {theader.badge && getNestedValue(item, theader.field) ? getBadgeVariant(setBadgeType(getNestedValue(item, theader.field))) : null}
                        {theader.shorthandFirst ? (
                          <BootstrapTooltip title={getNestedValue(item, theader?.shorthandFirst)}>
                            <Typography className="shorthand-first-circle mx-1 cursor-pointer"> {getNestedValue(item, theader?.shorthandFirst)?.charAt()}</Typography>
                          </BootstrapTooltip>
                        ) : null}
                        {theader.shorthandSecond ? (
                          <BootstrapTooltip title={getNestedValue(item, theader?.shorthandSecond)}>
                            <Typography className="shorthand-second-circle mx-1 cursor-pointer" color="secondary">
                              {getNestedValue(item, theader?.shorthandSecond)?.charAt()}
                            </Typography>
                          </BootstrapTooltip>
                        ) : null}
                        {theader.icon === 'entityType' ? (
                          <BootstrapTooltip title={getNestedValue(item, theader?.icon)}>
                            {getNestedValue(item, theader?.icon) === 'Business' ? (
                              <BusinessCenterIcon className="mx-1 cursor-pointer" color="action" />
                            ) : (
                              <PersonIcon className="mx-1 cursor-pointer" color="action" />
                            )}
                          </BootstrapTooltip>
                        ) : null}
                      </div>
                    ) : theader.date ? (
                      formatDate(getNestedValue(item, theader.field))
                    ) : theader.menu && getNestedValue(item, theader.field)?.length ? (
                      <BasicMenu menuItems={getNestedValue(item, theader.field)} itemClicked={(e) => menuItemClicked(e, item)} />
                    ) : theader?.avatar ? (
                      !item[theader.field]?.includes('undefined') ? (
                        <>
                          <BootstrapTooltip title={getNestedValue(item, theader.field)}>
                            <Avatar {...stringAvatar(getNestedValue(item, theader.field))} />
                          </BootstrapTooltip>
                        </>
                      ) : (
                        '-'
                      )
                    ) : (
                      <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                        {typeof getNestedValue(item, theader?.field) === 'boolean' ? (getNestedValue(item, theader?.field) ? 'Yes' : 'No') : getNestedValue(item, theader?.field)}
                      </Typography>
                    )}
                  </TableCell>
                ))
              : null}
          </TableRow>
        ))
      ) : (
        <tr>
          <td colSpan={tableColumn?.length} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 500, padding: '20px' }}>
            <span>No Data</span>
          </td>
        </tr>
      )
    ) : (
      <tr>
        <td colSpan={tableColumn?.length} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 500, padding: '20px' }}>
          <span>
            <CircularProgress size={20} />
          </span>
        </td>
      </tr>
    );

  return (
    <>
      <TableContainer className="tab-container mt-2" size="small" component={Paper}>
        {/* stickyHeader */}
        <Table>
          <TableHead>
            <TableRow>{renderTableHeaders()}</TableRow>
          </TableHead>
          <TableBody>{renderTableData()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;

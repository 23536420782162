import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountAdditionalInfoData } from '../../../../redux/actions/account-action';

export const AccountAdditionalInfo = () => {
  const { stateAddtionalInfoForm } = useSelector((state) => state?.account?.data);
  const dispatch = useDispatch();
  const [addtionalInfoForm, setAdditionalInfoForm] = useState({
    website: '',
    remarks: '',
  });

  useEffect(() => {
    if (stateAddtionalInfoForm) {
      setAdditionalInfoForm(stateAddtionalInfoForm);
    }
  }, [stateAddtionalInfoForm]);

  const handleControlChange = (e) => {
    const { name, value } = e.target;
    setAdditionalInfoForm({
      ...addtionalInfoForm,
      [name]: value,
    });
    dispatch(
      setAccountAdditionalInfoData({
        ...addtionalInfoForm,
        [name]: value,
      })
    );
  };

  return (
    <>
      <Grid container spacing={3} className="mt-1 px-2">
        <Grid item xs={12} md={6}>
          <TextField id="website" name="website" label="Website" variant="outlined" value={addtionalInfoForm.website} onChange={handleControlChange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField id="remarks" multiline rows={2} name="remarks" label="Remarks" variant="outlined" value={addtionalInfoForm.remarks} onChange={handleControlChange} />
        </Grid>
      </Grid>
    </>
  );
};

import { Dialog } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { NewProduct } from '../inventory-mgmt-app/inventory/items/new-product/new-product';

export const CreateItemModal = React.memo(() => {
  const { newItemModalData } = useSelector((state) => state?.sales?.data);
  return (
    <Dialog
      open={newItemModalData?.isItemModalOpen}
      PaperProps={{
        style: {
          height: '90vh', // Set the maximum height
          minWidth: '90vw', // Set the width
        },
      }}
    >
      <NewProduct />
    </Dialog>
  );
});

import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomAlert = ({ open = false, onClose, type, message, link = false, path = '' }) => {
  const navigate = useNavigate();
  const collapseStyle = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    width: '300px',
    zIndex: 9999,
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose(false); // Hide the alert after 3000 milliseconds (3 seconds)
    }, 3000);

    return () => clearTimeout(timeout); // Clear the timeout on component unmount
  }, [open, onClose]);

  const handleLinkClicked = () => {
    navigate(path);
  };
  return (
    <Collapse in={open} style={collapseStyle}>
      <Alert
        variant="filled"
        onClose={onClose}
        severity={type}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          mb: 2,
          p: 1, // Reduce padding
          '& .MuiAlertTitle-root': {
            fontSize: '0.875rem', // Reduce font size of title
          },
          '& .MuiAlert-message': {
            fontSize: '0.75rem', // Reduce font size of message
          },
        }}
      >
        <AlertTitle>{type.charAt(0).toUpperCase() + type.slice(1)}</AlertTitle>
        {message}
        {link && path ? (
          <Typography
            variant="body2"
            component="a"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              handleLinkClicked();
            }}
            sx={{ cursor: 'pointer', fontSize: '0.875rem', color: 'white', textDecoration: 'underline', fontSize: '14px' }}
          >
            Link.
          </Typography>
        ) : null}
      </Alert>
    </Collapse>
  );
};

export default CustomAlert;

import { Search } from '@mui/icons-material';
import { Button, Grid, InputAdornment, TextField, Typography, debounce } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_PRODUCT_LOCATION_ENDPOINT } from '../../../../../constants/constants';
import { AlertStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomPagination from '../../../../common/pagination';
import CustomTable from '../../../../common/table';
import { setItemLocationData, setItemLocationId, setLocationSearch } from '../../../../redux/actions/item-action';
import ProductLocation from '../../../../modals/product-location';
import { BootstrapTooltip } from '../../../../common/commonUtility';
import AddIcon from '@mui/icons-material/Add';
import { Messages } from '../../../../common-constants/messages';

export const InventoryItemLocations = React.memo(({ id, inventoryData, locationOptions }) => {
  const { itemLocation, itemLocationId, locationSearch } = useSelector((state) => state?.item?.data, true);
  const locationEndpoint = `${API_PRODUCT_LOCATION_ENDPOINT}?productId=${id}`;
  const dispatch = useDispatch();
  const itemPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const locationColumn = [
    {
      label: 'Location Name',
      field: 'locationName',
      className: 'px-2',
    },
    {
      label: 'Stock On Hand',
      field: 'stockOnHand',
      align: 'center',
    },
    {
      label: 'Available Stock',
      field: 'availableStock',
      align: 'center',
    },
    {
      label: 'Committed Stock',
      field: 'commitedStock',
      align: 'center',
    },
  ];
  const [tableLoader, setTableLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [showLocationModal, setShowLocationModal] = useState(false);

  useEffect(() => {
    if (itemLocationId !== id) {
      dispatch(fetchLocations());
      dispatch(setItemLocationId(id));
    }
    setSearch(locationSearch || '');
    setCurrentPage(itemLocation?.pageNumber);
  }, []);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const fetchLocations = (searchText = '', page = 1) => {
    return async (dispatch) => {
      try {
        setTableLoader(true);
        const search = searchText ? `&query=${searchText}` : '';
        const pageNumber = page ? `&pageNumber=${page}&pageSize=${itemPerPage}` : '';
        const finalUrl = `${locationEndpoint}${search}${pageNumber}`;
        const response = await api.get(finalUrl);
        response.data.productLocations = response.data.productLocations?.map((item) => ({
          ...item,
          stockOnHand: `${item.stockOnHand} ${inventoryData?.unit}`,
          availableStock: `${item.availableStock} ${inventoryData?.unit}`,
          commitedStock: `${item.commitedStock} ${inventoryData?.unit}`,
        }));
        dispatch(setItemLocationData(response?.data));
        dispatch(setLocationSearch(searchText));
        setTableLoader(false);
      } catch (error) {
        setTableLoader(false);
        console.error('Error fetching stock history data:', error);
      }
    };
  };

  const debouncedFetchItems = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(1);
      dispatch(fetchLocations(value));
    }, 500),
    []
  );

  const handleSearch = (value) => {
    debouncedFetchItems(value);
  };

  const handlePageChange = (page) => {
    // Update the state or make an API call with the new page number
    setCurrentPage(page);
    dispatch(fetchLocations(search, page));
  };

  const handleLocationClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (event === 'added') {
      dispatch(fetchLocations());
      dispatch(setLocationSearch(''));
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.productLocationUpdate}`,
      });
    }
    dispatch(fetchLocations);
    setShowLocationModal(false);
  };

  const openLocationModal = () => {
    setShowLocationModal(true);
  };

  return (
    <Grid container>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <ProductLocation id={id} ddlProductLocation={locationOptions} handleClose={handleLocationClose} showModal={showLocationModal} />
      <Grid item xs={9}>
        <BootstrapTooltip title="Add Item Location">
          <Button size="small" className="mt-2" variant="outlined" startIcon={<AddIcon />} onClick={openLocationModal}>
            Add Item Location
          </Button>
        </BootstrapTooltip>
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: { borderRadius: '7px' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable tableColumn={locationColumn} loading={tableLoader} tableData={itemLocation?.productLocations} />
        <CustomPagination totalCount={itemLocation?.totalCount} rowsPerPage={itemPerPage} totalPages={itemLocation?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </Grid>
    </Grid>
  );
});

import { Grid, Pagination, Typography } from '@mui/material';

const CustomPagination = ({ handlePageChange, rowsPerPage = 7, totalCount, totalPages, currentPage = 1 }) => {
  const startIndex = Number(totalCount) > 0 ? (Number(currentPage) - 1) * Number(rowsPerPage) + 1 : 0;
  const endIndex = Number(totalCount) > 0 ? Math.min(Number(currentPage) * Number(rowsPerPage), Number(totalCount)) : 0;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className="mt-3">
        <Grid item>
          <Typography variant="body2">{`Showing ${startIndex} to ${endIndex} of ${totalCount} records`}</Typography>
        </Grid>
        <Grid item>
          {totalCount > 0 ? (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => handlePageChange(page)}
              className="mt-3"
              size="small"
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomPagination;

import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import { Messages } from '../common-constants/messages';
import { showErrorAlert } from './commonUtility';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to include JWT token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('API Error:', error);

    const errorMessage = Messages?.apiError || 'An error occurred';
    showErrorAlert(errorMessage);

    // Instead of rejecting, return a resolved promise with an error flag
    return Promise.reject({ 
      error: true, 
      message: errorMessage,
      originalError: error
    });
  }
);

// Define the getOverviewData function
export const getOverviewData = async () => {
  try {
    const response = await api.get('/overview'); // Adjust endpoint if needed
    return response.data;
  } catch (error) {
    console.error('Error fetching overview data:', error);
    throw error;
  }
};

export default api;

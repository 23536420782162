import { FilterAltRounded, Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_OPERATIONS_ENDPOINT } from '../../../constants/constants';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomBreadCrumb from '../../common/breadcrumb';
import CardVisualization from '../../common/card-visualization';
import CustomPagination from '../../common/pagination';
import CustomTable from '../../common/table';
import CustomTableActions from '../../common/table-actions';
import { BootstrapTooltip, formatDate, getPCKebabMenuActions, getYearDifference } from '../../common/commonUtility';
import { Messages } from '../../common-constants/messages';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const PackagingLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  const itemsPerPage = 6;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateFilter, setDateFilter] = useState({
    fromDate: dayjs(new Date())?.subtract(1, 'year'),
    toDate: dayjs(new Date()),
  });
  const packagingColumn = [
    {
      label: 'Packaging ID',
      field: 'packagingId',
      link: true,
    },
    {
      label: 'Item Name',
      field: 'itemName',
    },
    {
      label: 'Status',
      field: 'status',
      badge: true,
    },
    {
      label: 'Order Number',
      field: 'orderReferenceNumber',
      link: true,
    },
    {
      label: 'Account Reference Number',
      field: 'accountReferenceNumber',
      link: true,
    },
    {
      label: 'Assigned To',
      field: 'assignedTo',
      avatar: true,
    },
    {
      label: 'Order Date',
      field: 'orderDate',
      date: true,
    },
    {
      label: '',
      field: 'action',
      menu: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Packaging',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [packagingData, setPackagingData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const [aggregateLoader, setAggregateLoader] = useState(false);
  const [aggregateData, setAggregateData] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    fetchPackagingData();
    fetchAggregateData();
  }, []);

  useEffect(() => {
    fetchPackagingData(1, search, activeCard);
    fetchAggregateData();
  }, [JSON.stringify(dateFilter)]);

  const fetchPackagingData = async (currentPage = 1, search = '', activeCard = '') => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    const qualityControlStatus = `&packagingStatus=${activeCard}`;
    const fromDate = dateFilter?.fromDate ? `&fromDate=${dateFilter?.fromDate}` : '';
    const toDate = dateFilter?.toDate ? `&toDate=${dateFilter?.toDate}` : '';
    try {
      const response = await api.get(`${operationsEndpoint}/packaging-orders?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}${qualityControlStatus}${fromDate}${toDate}`);
      response.data.packagingOrders = response.data.packagingOrders?.map((qc) => ({
        ...qc,
        itemName: `${qc?.item?.productName} (${qc?.quantity} ${qc?.item?.unit})`,
        orderReferenceNumber: qc?.order?.orderNumber,
        accountReferenceNumber: qc?.tenant?.referenceNumber,
        orderDate: qc?.order?.orderDate,
        assignedTo: `${qc?.packagedByUser?.firstName} ${qc?.packagedByUser?.lastName}`,
        action: getPCKebabMenuActions(qc?.status),
        assigned: qc?.packagedByUser?.firstName ? true : false,
      }));
      const packagingData = response.data?.packagingOrders;
      setEntityData(response.data);
      setPackagingData(packagingData);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const handleRefreshData = () => {
    fetchPackagingData();
  };

  const handleEditEntity = (item, header) => {
    switch (header?.field) {
      case 'packagingId':
        navigate(`/packaging/${item?.packagingId}`);
        break;
      case 'orderReferenceNumber':
        navigate(`/sales/${item?.order?.transactionId}`);
        break;
      case 'accountReferenceNumber':
        navigate(`/accounts/${item?.tenant?.tenantId}`);
        break;
    }
  };

  const fetchAggregateData = async () => {
    try {
      setAggregateLoader(true);
      const fromDate = dateFilter?.fromDate ? `?fromDate=${dateFilter?.fromDate}` : '';
      const toDate = dateFilter?.toDate ? `&toDate=${dateFilter?.toDate}` : '';
      const response = await api.get(`${operationsEndpoint}/packaging-orders/overview${fromDate}${toDate}`);
      const data = response.data;
      const aggregateData = [
        { title: 'All', value: data.totalPackagingOrders, bindValue: '' },
        { title: 'Pending', value: data.totalPackagingOrdersPending, bindValue: 0 },
        { title: 'Completed', value: data.totalPackagingOrdersCompleted, bindValue: 1 },
        { title: 'Cancelled', value: data.totalPackagingOrdersCancelled, bindValue: 2 },
      ].filter((item) => item.value !== 0);
      setAggregateData(aggregateData);
      setAggregateLoader(false);
      setActiveCard('');
    } catch (error) {
      setAggregateLoader(false);
      console.error('Error fetching aggregate data:', error);
    }
  };

  const handleCardActive = (actCard, item) => {
    setActiveCard(actCard);
    fetchPackagingData(1, search, actCard);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchPackagingData(1, searchText);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchPackagingData(page);
  };

  const menuItemClicked = async (event, item) => {
    if (event > 0) {
      try {
        const reqObj = {
          status: Number(event),
        };
        await api.put(`${operationsEndpoint}/packaging-orders/${item?.packagingId}/status`, reqObj);
        fetchPackagingData();
        fetchAggregateData();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.pcCancelled} `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const changeFilter = (name, value) => {
    setDateFilter({
      ...dateFilter,
      [name]: dayjs(value),
    });
    setCurrentPage(1);
    if (name === 'fromDate' && value && dateFilter.toDate && value?.isAfter(dateFilter?.toDate)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        toDate: dayjs(value),
      });
    } else if (name === 'toDate' && value && dateFilter.fromDate && dateFilter.fromDate?.isAfter(value)) {
      setDateFilter({
        ...dateFilter,
        [name]: dayjs(value),
        fromDate: dayjs(value),
      });
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-2">
          <Typography className="mt-2 fs-18">Advance Filter</Typography>
          <div className="d-flex flex-column mt-2 mb-4 mx-3">
            <div className="d-flex">
              <div>
                <Typography className="label">From Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.fromDate} onChange={(e) => changeFilter('fromDate', e)} />
                </LocalizationProvider>
              </div>
              <div className="ms-2">
                <Typography className="label">To Date </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" value={dateFilter?.toDate} onChange={(e) => changeFilter('toDate', e)} />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </Popover>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Packaging Order
            </Typography>
            <span>
              {getYearDifference(dateFilter?.fromDate.toISOString(), dateFilter?.toDate.toISOString()) === 1
                ? 'Packaging orders of past 1 year'
                : `${formatDate(dateFilter?.fromDate)} - ${formatDate(dateFilter?.toDate)}`}
            </span>
          </Grid>
        </div>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={2}>
              <div className="d-flex">
                <CustomTableActions
                  shouldHideAddBtn={true}
                  shouldHideDeleteBtn={true}
                  disableDelete={!packagingData.filter((cus) => cus.checked)?.length}
                  handleRefresh={handleRefreshData}
                ></CustomTableActions>
                <BootstrapTooltip title="Filter">
                  <IconButton color="info" onClick={handleFilterClick}>
                    <FilterAltRounded />
                  </IconButton>
                </BootstrapTooltip>
              </div>
            </Grid>
            <Grid className="d-flex align-items-center" item xs={8}>
              <CardVisualization loading={aggregateLoader} activeCard={activeCard} data={aggregateData} handleCardActive={handleCardActive} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={packagingColumn} loading={tableLoader} tableData={packagingData} handleEditEntity={handleEditEntity} menuItemClicked={menuItemClicked} />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

import { TYPES } from '../type-constants/type';

export const setAccountUsersData = (data) => ({
  type: TYPES.SET_ACCOUNT_USERS_DATA,
  payload: { stateUserOptions: data },
});

export const setPrimaryContactsData = (data) => ({
  type: TYPES.SET_PRIMARY_CONTACTS_DATA,
  payload: { statePrimaryContactsOptions: data },
});

export const setAccountDetailsData = (data) => ({
  type: TYPES.SET_ACCOUNT_DETAILS_FORM,
  payload: { stateAccountDetailForm: data },
});

export const setAccountSinceFieldData = (data) => ({
  type: TYPES.SET_ACCOUNT_SINCE_FIELD,
  payload: { stateAccountSinceField: data },
});

export const setFinanceDetailsData = (data) => ({
  type: TYPES.SET_FINANCE_DETAILS_FORM,
  payload: { stateFinanceForm: data },
});

export const setAccountAddressData = (data) => ({
  type: TYPES.SET_ACCOUNT_ADDRESS_FORM,
  payload: { stateBillingAddressForm: data },
});

export const setAccountAdditionalInfoData = (data) => ({
  type: TYPES.SET_ACCOUNT_ADDITIONAL_INFO_FORM,
  payload: { stateAddtionalInfoForm: data },
});

export const setAccountFormSubmitted = (data) => ({
  type: TYPES.SET_ACCOUNT_FORM_SUBMITTED,
  payload: { stateFormSubmitted: data },
});

export const setTenantId = (data) => ({
  type: TYPES.SET_ACCOUNT_TENANT_ID,
  payload: { stateTenantId: data },
});

export const openAccountModal = () => ({
  type: TYPES.OPEN_ACCOUNT_MODAL,
  payload: { isAccountModalOpen: true },
});

export const closeAccountModal = (data) => ({
  type: TYPES.CLOSE_ACCOUNT_MODAL,
  payload: { isAccountModalOpen: false, accountData: data },
});

export const resetAccountReducer = (data) => ({
  type: TYPES.RESET_ACCOUNT_REDUCER,
});

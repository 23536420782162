import { Search } from '@mui/icons-material';
import { Avatar, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_PRODUCTS_ENDPOINT, API_SALES_LEADS_ENDPOINT } from '../../../../../constants/constants';
import { AlertStatus } from '../../../../common-constants/status';
import api from '../../../../common/api';
import { Messages } from '../../../../common-constants/messages';
import { BootstrapTooltip, formatProductForSales, getCheckboxResult, stringAvatar } from '../../../../common/commonUtility';
import CustomTableActions from '../../../../common/table-actions';
import CustomTable from '../../../../common/table';
import CustomPagination from '../../../../common/pagination';
import ConfirmDialog from '../../../../common/confirm-dialog';
import CustomAlert from '../../../../common/alert';
import { LeadQuotation } from './lead-quotation';
import { setLeadsQuotationData, setProductsData } from '../../../../redux/actions/leads-action';
import { useSelector } from 'react-redux';

const LeadQuotationList = () => {
  const { leadsData } = useSelector((state) => state?.leads?.data);
  const { leadId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const salesLeadsEndpoint = API_SALES_LEADS_ENDPOINT;
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const itemsPerPage = 6;
  const contactColumn = [
    {
      label: 'Quotation ID',
      field: 'id',
      link: true,
    },
    {
      label: 'Status',
      field: 'quotationStatus',
      badge: true,
    },
    {
      label: 'Quotation Amount',
      field: 'quotationAmount',
    },
    {
      label: 'Last modified Date',
      field: 'lastModifiedTime',
      date: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [quotationList, setQuotationList] = useState([]);
  const [quotationId, setQuotationId] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const [showLeadQuotation, setShowLeadQuotation] = useState(false);
  const [productItemOptions, setProductItemOptions] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    //   dispatch(resetSalesReducer());
    fetchQuotationList();
    fetchProducts();
  }, []);

  const fetchQuotationList = async (currentPage = 1, search = '', activeCard = 0) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    try {
      const response = await api.get(`${salesLeadsEndpoint}/${leadId}/quotations?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}`);
      response.data.quotations = response.data.quotations?.map((item) => ({
        ...item,
        quotationAmount: (leadsData?.currency ? `(${leadsData?.currency}) ` : '') + item?.estimatedAmount?.toFixed(2),
        // tenantUser: item?.account?.name,
        // contactUser: `${item?.contact?.firstName} ${item?.contact?.lastName}`,
        // assignedUser: `${item?.assignedToUser?.firstName} ${item?.assignedToUser?.lastName}`,
      }));
      const quotationLst = response.data?.quotations;
      setEntityData(response.data);
      setQuotationList(quotationLst);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const handleRefreshData = () => {
    fetchQuotationList();
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleEditEntity = async (item) => {
    setQuotationId(item?.leadId);
    const { data } = await api.get(`${salesLeadsEndpoint}/${leadId}/quotations/${item?.id}`);
    const finalData = {
      ...data,
      orderItems: data?.salesOrderItems,
      taxInfo: data?.taxInfos,
    };
    dispatch(setLeadsQuotationData(finalData));
    setShowLeadQuotation(true);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchQuotationList(1, searchText);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchQuotationList(page);
  };

  const handleDeleteConfirmYes = () => {
    deleteQuotations();
  };

  const deleteQuotations = async () => {
    const selectedItems = quotationList?.filter((item) => item.checked)?.map((adj) => adj.contactId);
    setDeleteDialog(false);
    try {
      await api.delete(salesLeadsEndpoint, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.primaryContactDelete,
      });
      fetchQuotationList();
      // fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setQuotationList(getCheckboxResult(quotationList, checkedItem, 'leadId'));
  };

  const addQuotation = () => {
    setShowLeadQuotation(true);
    dispatch(setLeadsQuotationData({}));
    //   navigate('/leads/newLead');
  };

  const handleCloseQuotation = (data, type) => {
    setShowLeadQuotation(false);

    if (type === 'update') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.quotationUpdated}`,
      });
      fetchQuotationList();
    } else if (type === 'add') {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.quotationCreated}`,
      });
      fetchQuotationList();
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      const formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedProductsOptions);
      dispatch(setProductsData(formatedProductsOptions));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      {showLeadQuotation && <LeadQuotation showLeadQuotation={showLeadQuotation} handleCloseQuotation={handleCloseQuotation} />}
      <div className="d-flex flex-grow-1 flex-column">
        <div className="d-flex justify-between">
          {/* <Grid container className="d-flex flex-column">
            <Typography className="mt-2" variant="h5" gutterBottom>
              Quotations
            </Typography>
          </Grid> */}
        </div>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={8}>
              <CustomTableActions
                disableDelete={!quotationList?.filter((cus) => cus.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={addQuotation}
              ></CustomTableActions>
            </Grid>
            <Grid item xs={4}>
              <div className="d-flex flex-end">
                {leadsData?.lvAssignedToUserId?.label && (
                  <div className=" d-flex">
                    <span className="mt-2">Sales Person: </span>
                    <span className="mx-2">
                      <BootstrapTooltip className="d-flex" title={leadsData?.lvAssignedToUserId?.label}>
                        <Avatar {...stringAvatar(leadsData?.lvAssignedToUserId?.label)} />
                      </BootstrapTooltip>
                    </span>
                  </div>
                )}
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    style: { borderRadius: '7px' },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={contactColumn} loading={tableLoader} tableData={quotationList} toggleSelectedCheckbox={toggleSelectedCheckbox} handleEditEntity={handleEditEntity} />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default LeadQuotationList;

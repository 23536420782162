import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter } from 'react-router-dom';
import { RayaRoutes } from './Routes';
import { TopNavBar } from './pages/inventory-mgmt-app/top-navbar-header/TopNavBar';
import { SideNavBarPanel } from './pages/inventory-mgmt-app/side-navbar-panel/SideNavBarPanel';
import SessionRenewalModal from './pages/inventory-mgmt-app/common/SessionRenewalModal';
import { useToken } from './pages/auth/useToken';
import { API_AUTH_RENEW_TOKEN, DOMAIN, WEBSITE_URI } from './constants/constants';
import axios from 'axios';
import './App.css';
import './css/mui-material.css';
import { Spinner } from 'react-bootstrap';
import UnauthenticatedView from './pages/notfound/UnauthenticatedView';

const sessionChannel = new BroadcastChannel('session_channel');

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPanel, setShowPanel] = useState(true);
  const [showSessionRenewModal, setShowSessionRenewModal] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [token, setToken] = useToken();
  const renewSessionEndpoint = API_AUTH_RENEW_TOKEN;
  const sessionTimeout = 15 * 60 * 1000; // 15 mins
  const [lastActivity, setLastActivity] = useState(Date.now());

  const redirectToLogin = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    Cookies.remove('authToken');
    localStorage.removeItem('showWelcomeAlert');
    window.location.href = WEBSITE_URI + 'login';
  };

  const handleRenewSession = async () => {
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      try {
        const renewResponse = await axios.post(renewSessionEndpoint, {
          token: token
        }, config);
        const newToken = renewResponse.data.token;
        setToken(newToken);
        setShowSessionRenewModal(false);
        const newLastActivity = Date.now();
        setLastActivity(newLastActivity);
        sessionChannel.postMessage({ type: 'renew', token: newToken, lastActivity: newLastActivity });
      } catch (error) {
        console.error('Error renewing session:', error);
        setShowSessionRenewModal(false);
        redirectToLogin();
      }
    }
  };

  const checkTokenExpiry = useCallback(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = decodeJWT(token);
      const tokenExpiry = decodedToken.exp;
      const tokenExpiryMilliseconds = tokenExpiry * 1000;
      const currentTime = Date.now() / 1000;

      if (tokenExpiry < currentTime) {
        setShowSessionRenewModal(true);
        setSessionExpired(true);
      }

      if (tokenExpiryMilliseconds && Date.now() >= tokenExpiryMilliseconds - 5 * 60 * 1000) {
        setShowSessionRenewModal(true);
      }
    }
  }, [handleRenewSession]);

  const handleActivity = useCallback(() => {
    const newLastActivity = Date.now();
    setLastActivity(newLastActivity);
    checkTokenExpiry();
    sessionChannel.postMessage({ type: 'activity', lastActivity: newLastActivity });
  }, [checkTokenExpiry]);

  function decodeJWT(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(payload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    const interval = setInterval(() => {
      if (Date.now() - lastActivity >= sessionTimeout) {
        setShowSessionRenewModal(true);
      }
    }, 1000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearInterval(interval);
    };
  }, [lastActivity, handleActivity, sessionTimeout]);

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  };

  const removeTokenFromUrl = () => {
    const url = new URL(window.location);
    url.searchParams.delete('token');
    window.history.replaceState({}, '', url.toString());
  };

  useEffect(() => {
    sessionChannel.onmessage = (event) => {
      if (event.data.type === 'renew') {
        setToken(event.data.token);
        setLastActivity(event.data.lastActivity);
      } else if (event.data.type === 'activity') {
        setLastActivity(event.data.lastActivity);
      }
    };

    const tokenFromCookie = Cookies.get('authToken');
    const tokenFromUrl = getTokenFromUrl();

    if (tokenFromCookie) {
      setIsAuthenticated(true);
      setToken(tokenFromCookie);
      removeTokenFromUrl();
    } else if (tokenFromUrl) {
      setIsAuthenticated(true);
      setToken(tokenFromUrl);
      Cookies.set('authToken', tokenFromUrl, { domain: DOMAIN, secure: true, sameSite: 'None' });
      removeTokenFromUrl();
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div><Spinner animation="border" /></div>;
  }

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <>
          <TopNavBar
                isAuthenticated={(status) => setIsAuthenticated(status)}
                showPanel={showPanel}
                togglePanel={() => setShowPanel(!showPanel)}
                forceClose={() => setShowPanel(false)} />
          <div className={`admin-center bg-raya ${showPanel ? 'panel-open' : 'panel-closed'}`}>
            <div className='child-container'>
                <SideNavBarPanel showPanel={showPanel} togglePanel={() => setShowPanel(!showPanel)} />
                <SessionRenewalModal
                          show={showSessionRenewModal}
                          sessionExpired={sessionExpired}
                          onRenewSession={handleRenewSession}
                          onLogout={redirectToLogin}
                />
                <RayaRoutes />
              </div>
          </div>
        </>
      ) : (
        <>
          <UnauthenticatedView onLogin={() => window.location.href = WEBSITE_URI + 'login'} />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;

import { TYPES } from '../type-constants/type';

export const setDocumentFileData = (data) => ({
  type: TYPES.SET_DOCUMENT_FILE_DATA,
  payload: { file: data },
});

export const resetDocumentReducer = (data) => ({
    type: TYPES.RESET_DOCUMENT_REDUCER,
  });

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { AppBar, Box, Button, CircularProgress, FormControl, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  API_ACCOUNT_ENDPOINT,
  API_DOCUMENTS_ENDPOINT,
  API_WORKFLOW_BASE_URL,
  BI_APP_URI,
  DASHBOARD_APP_URI,
  TOKEN_ACCOUNT_NAME_CLAIMS,
  TOKEN_COMPANY_LOGO_CLAIMS,
  TOKEN_EMAIL_CLAIMS,
  TOKEN_USER_GIVEN_NAME,
  TOKEN_USER_ROLE,
  WEBSITE_URI,
} from '../../../constants/constants';
import { useToken } from '../../auth/useToken';
import { useUser } from '../../auth/useUser';
import { UserIconFullName } from '../common/UserIcon';
import './TopNavBar.css';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  padding: 0,
  flexWrap: 'nowrap',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper, // Assuming 'bg-raya' is light
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const TopNavBar = ({ isAuthenticated, showPanel, togglePanel, forceClose }) => {
  const user = useUser();
  const [token, setToken] = useToken();
  const navigate = useNavigate();
  const companyLogo = user[TOKEN_COMPANY_LOGO_CLAIMS];
  const isAdmin = user[TOKEN_USER_ROLE] === 'Admin';
  const [logoUrl, setLogoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const documentUploadApi = API_WORKFLOW_BASE_URL + API_DOCUMENTS_ENDPOINT;
  const rayaAuthAccountApi = API_ACCOUNT_ENDPOINT;

  const rayaProducts = [
    {
      label: 'Business Suite',
      value: 1,
    },
    {
      label: 'Business Intelligence',
      value: 2,
    },
  ];

  const [rayaLicense, setRayaLicense] = useState(1);

  const logOutFunc = () => {
    window.location.href = WEBSITE_URI + 'login';
    localStorage.removeItem('token');
    Cookies.remove('authToken');
    localStorage.removeItem('showWelcomeAlert');
  };

  const resize = () => {
    var zoomPercentage = Math.round(window.devicePixelRatio * 100) / 2;
    var viewportWidth = window.innerWidth;

    const minWidthThreshold = 1000;

    if (zoomPercentage > 125 || viewportWidth < minWidthThreshold) {
      forceClose();
    } else {
      if (!showPanel) {
        togglePanel();
      }
    }
  };

  const triggerFileInput = () => {
    document.getElementById('file-upload').click();
  };

  const handleNotificationsClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const handleUserClick = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = WEBSITE_URI + 'login';
    }

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (companyLogo) {
      setIsLoading(true);
      axios
        .get(`${documentUploadApi}/${companyLogo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          setLogoUrl(imageUrl);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching the company logo:', error);
          setIsLoading(false);
        });
    }
  }, [companyLogo, token]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('Visibility', 0);
      formData.append('Permissions', JSON.stringify([]));

      axios
        .post(documentUploadApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const documentId = response.data.id;
          const accountUpdateData = {
            CompanyLogo: documentId,
          };
          return axios.put(rayaAuthAccountApi, accountUpdateData, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
        })
        .then((response) => {
          axios
            .get(`${documentUploadApi}/${response.data.companyLogo}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType: 'blob',
            })
            .then((response) => {
              const imageUrl = URL.createObjectURL(response.data);
              setLogoUrl(imageUrl);
            });
        })
        .catch((error) => {
          console.error('Error uploading the file or updating the account:', error);
        });
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box
      className="top-nav-container"
      sx={{
        height: '3rem',
        marginTop: '0px',
        '& header': {
          boxShadow: 'none',
        },
        '& header div': {
          minHeight: 'inherit',
          marginTop: '0px',
          display: 'flex',
          alignItems: 'center',
          paddingleft: '0.5rem',
        },
      }}
    >
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <div className="d-flex flex-grow-1 justify-content-between">
            <div>
              <IconButton size="large" edge="start" aria-label="menu" onClick={togglePanel}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : logoUrl ? (
                  <img src={logoUrl} alt="Company Logo" style={{ width: '70px', height: '35px', borderRadius: '5px', marginLeft: '10px', cursor: isAdmin ? 'pointer' : 'default' }} />
                ) : (
                  isAdmin && (
                    <Button
                      variant="contained"
                      className="bg-raya"
                      size="small"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon size="small" />}
                      sx={{ borderRadius: '20px', marginLeft: '10px', color: 'gray' }}
                    >
                      <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                      Upload Logo
                    </Button>
                  )
                )}
                <span className="customer-name ms-2 pe-2">{user[TOKEN_ACCOUNT_NAME_CLAIMS]}</span>
                <span className="customer-name"> | Business Suite</span>
              </Typography>
            </div>
            <div>
              <FormControl fullWidth>
                {/* <InputLabel>Location</InputLabel> */}
                <Select
                  value={rayaLicense}
                  label="License"
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  onChange={(e) => {
                    const token = localStorage.getItem('authToken') || Cookies.get('authToken');
                    setRayaLicense(e.target.value);
                    if (e.target.value === 2) {
                      window.location.href = `${BI_APP_URI}bi/dashboard?token=${token}`;
                    } else {
                      window.location.href = `${DASHBOARD_APP_URI}dashboard?token=${token}`;
                    }
                  }}
                >
                  {rayaProducts.map((pr, index) => (
                    <MenuItem key={index} value={pr?.value}>
                      {pr?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={handleNotificationsClick}>
                <NotificationsOutlinedIcon />
              </IconButton>
              <IconButton onClick={handleUserClick}>
                <AccountCircleOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </StyledToolbar>
      </StyledAppBar>

      <Menu anchorEl={anchorElNotifications} open={Boolean(anchorElNotifications)} onClose={handleNotificationsClose}>
        <MenuItem>
          <Typography variant="h6">Notifications</Typography>
        </MenuItem>
        <MenuItem>You have no new notifications.</MenuItem>
      </Menu>

      <Menu anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleUserClose}>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <div>
            <Typography variant="h6">{user[TOKEN_ACCOUNT_NAME_CLAIMS]}</Typography>
            <UserIconFullName fullName={user[TOKEN_USER_GIVEN_NAME]} />
            <Typography>{user[TOKEN_EMAIL_CLAIMS]}</Typography>
            <div className="mt-2">
              <Button size="small" variant="outlined" color="primary">
                Profile
              </Button>
              <Button className="ms-2" size="small" variant="outlined" color="error" onClick={logOutFunc}>
                Logout
              </Button>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </Box>
  );
};

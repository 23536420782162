import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { 
    Tabs, Tab, Grid, TextField, Button, CircularProgress, Typography, Autocomplete, Checkbox 
} from '@mui/material';
import { API_USERS_ENDPOINT } from '../../../../constants/constants';
import { useToken } from '../../../auth/useToken';
import CustomAlert from '../../../common/alert';
import CustomAutocomplete from '../../../common/autocomplete';
import { RayaComponent } from '../../access-control/AccessControl';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

export const NewUser = () => {
    const [token] = useToken();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('userDetails');
    const [tabs] = useState(['userDetails', 'roles']);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        countryCode: '',
        postalCode: '',
        phoneNumber: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
        role: { value: 0, label: 'Normal User' },
        accessibleComponents: []
    });
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [userCreationLoading, setUserCreationLoading] = useState(false);
    const [alertData, setAlertData] = useState({ open: false, type: 'success', message: '' });

    const config = { headers: { Authorization: `Bearer ${token}` } };

    const handleTabSelect = (event, newValue) => setActiveTab(newValue);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        setPasswordMatch(user.password === user.confirmPassword);
    }, [user.password, user.confirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!passwordMatch) return;

        const userBody = {
            firstName: user.firstName,
            lastName: user.lastName,
            location: {
                address: user.address,
                city: user.city,
                countryCode: user.countryCode,
                postalCode: user.postalCode,
            },
            phoneNumber: user.phoneNumber,
            emailAddress: user.emailAddress,
            password: user.password,
            role: user.role.value,
            accessibleComponents: user.accessibleComponents.map((component) => component.value),
        };

        setUserCreationLoading(true);
        try {
            await axios.post(API_USERS_ENDPOINT, userBody, config);
            navigate('/users');
        } catch (error) {
            console.error('Error:', error);
            setAlertData({ open: true, type: 'error', message: 'User creation failed.' });
        } finally {
            setUserCreationLoading(false);
        }
    };

    const handleRoleChange = (event, newValue) => {
        setUser((prev) => ({
            ...prev,
            role: newValue || { value: 0, label: 'Normal User' },
        }));
    };

    const rolesOptions = [
        { value: 0, label: 'Normal User' },
        { value: 1, label: 'Administrator' },
    ];

    const handleAccessibleComponentsChange = (newValue) => {
        setUser((prev) => ({
            ...prev,
            accessibleComponents: newValue,
        }));
    };

    const componentOptions = Object.entries(RayaComponent).map(([key, label]) => ({
        value: key,
        label,
    }));

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return (
        <div className="d-flex flex-grow-1 flex-column p-4 mt-1">
            <div className="d-flex justify-content-between">
                <Typography variant="h6" gutterBottom>
                    New User
                </Typography>
            </div>
            <div className="pe-3 ps-3 flex-grow-1 border rounded">
                <Tabs value={activeTab} onChange={handleTabSelect} className="mb-2">
                    <Tab value="userDetails" label="User Details" />
                    <Tab value="roles" label="Role & Permissions" />
                </Tabs>

                {activeTab === 'userDetails' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="firstName"
                                label="First Name"
                                variant="outlined"
                                value={user.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="lastName"
                                label="Last Name"
                                variant="outlined"
                                value={user.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="emailAddress"
                                label="Email Address"
                                variant="outlined"
                                value={user.emailAddress}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                value={user.phoneNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="address"
                                label="Address"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={user.address}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="city"
                                label="City"
                                variant="outlined"
                                value={user.city}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                name="countryCode"
                                label="Country Code"
                                variant="outlined"
                                value={user.countryCode}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                name="postalCode"
                                label="Postal Code"
                                variant="outlined"
                                value={user.postalCode}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                )}

                {activeTab === 'roles' && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CustomAutocomplete
                                value={user.role}
                                onChange={handleRoleChange}
                                options={rolesOptions}
                                inputLabel="Select Role"
                                getOptionLabel={(option) => option.label}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={componentOptions}
                                value={user.accessibleComponents}
                                onChange={(event, newValue) => handleAccessibleComponentsChange(newValue)}
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option.value}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                            style={{ marginRight: 8 }}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <span key={option.value} {...getTagProps({ index })}>
                                            {option.label}
                                        </span>
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Accessible Components" />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                name="password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                value={user.password}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                variant="outlined"
                                value={user.confirmPassword}
                                onChange={handleInputChange}
                                error={!passwordMatch}
                                helperText={!passwordMatch && 'Passwords do not match.'}
                                required
                            />
                        </Grid>
                    </Grid>
                )}

                <div className="d-flex align-items-center my-3">
                    {activeTab !== tabs[0] && (
                        <Button
                            variant="outlined"
                            onClick={() => setActiveTab(tabs[0])}
                            sx={{ marginRight: 2 }} // Adds space between buttons
                        >
                            Previous
                        </Button>
                    )}
                    {activeTab !== tabs[tabs.length - 1] ? (
                        <Button
                            variant="outlined"
                            onClick={() => setActiveTab(tabs[1])}
                            sx={{ marginRight: 2 }} // Adds space between buttons
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={userCreationLoading || !passwordMatch}
                            sx={{ marginRight: 2 }} // Adds space before the loading indicator
                        >
                            Submit
                        </Button>
                    )}
                    {userCreationLoading && <CircularProgress size={24} className="mx-2" />}
                </div>

            </div>

            <CustomAlert
                type={alertData.type}
                message={alertData.message}
                open={alertData.open}
                onClose={() => setAlertData({ ...alertData, open: false })}
            />
        </div>
    );
};

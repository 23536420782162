import { Box, Button, Chip, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Calendar, Mail, Phone, Star, Tag, User } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { API_SALES_LEADS_ENDPOINT, API_TENANTS_ENDPOINT, API_USERS_ENDPOINT } from '../../../../../constants/constants';
import { useToken } from '../../../../auth/useToken';
import { Messages } from '../../../../common-constants/messages';
import { AlertStatus, LeadStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import { formatEntityLabelValue, getBadgeVariant } from '../../../../common/commonUtility';
import { openAccountModal, resetAccountReducer } from '../../../../redux/actions/account-action';
import { setDdlContactsData, setDdlTenantsData, setDdlUsersData, setLeadsData, setTenantList } from '../../../../redux/actions/leads-action';

export const LeadOverview = () => {
  const { leadId } = useParams();
  const tenantEndpoint = API_TENANTS_ENDPOINT;
  const usersEndpoint = API_USERS_ENDPOINT;
  const salesLeadsEndpoint = API_SALES_LEADS_ENDPOINT;
  const dispatch = useDispatch();
  const { ddlTenants, ddlContacts, ddlUsers, leadsData, contactList, tenantList } = useSelector((state) => state?.leads?.data);
  const { isAccountModalOpen, accountData } = useSelector((state) => state?.account?.data);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);
  const [LeadSubmitLoading, setLeadSubmitLoading] = useState(false);
  const [leadOverviewForm, setLeadOverviewForm] = useState({
    leadName: '',
    tenantId: '',
    lvTenantId: '',
    contactId: '',
    lvContactId: '',
    status: '',
    lvStatus: '',
    source: '',
    estimatedValue: '',
    notes: '',
    assignedToUserId: '',
    lvAssignedToUserId: '',
    lastContactDate: null,
    nextFollowUpDate: null,
    leadScore: 0,
    closeDate: null,
    probability: '',
  });
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const navigate = useNavigate();
  let userFormattedOptions = [];
  const [contacts, setContacts] = useState([]);
  const [tenants, setTenants] = useState([]);
  let tnList = [];
  let ctList = [];
  let contactFormattedOptions = [];
  let tenantFormattedOptions = [];

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    if (!ddlTenants || !tenantList) {
      fetchTenants();
    } else if (!ddlTenants) {
      setTenantOptions(ddlTenants);
    } else if (!tenantList) {
      tnList = tenantList;
      setTenants(tenantList);
    }
    if (!ddlUsers) {
      fetchUsers();
    } else {
      setUserOptions(ddlUsers);
    }

    if (!ddlContacts || !contactList) {
      fetchContacts();
    } else if (!ddlContacts) {
      setContactOptions(ddlContacts);
    } else if (!contactList) {
      ctList = contactList;
      setContacts(contactList);
    }
    if (leadId && !leadsData) {
      fetchLeadsById();
    } else if (leadsData) {
      setLeadOverviewForm({
        ...leadsData,
        lastContactDate: leadsData?.lastContactDate ? dayjs(leadsData?.lastContactDate) : null,
        nextFollowUpDate: leadsData?.nextFollowUpDate ? dayjs(leadsData?.nextFollowUpDate) : null,
        closeDate: leadsData?.closeDate ? dayjs(leadsData?.closeDate) : null,
      });
    }
  }, []);

  useEffect(() => {
    if (accountData?.tenantId) {
      fetchTenants();
      const customer = { label: accountData?.name, value: accountData?.tenantId };
      handleTenantChange(customer);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${accountData?.name}' ${Messages.customerCreated}`,
      });
    }
    if (!isAccountModalOpen) {
      dispatch(resetAccountReducer());
    }
  }, [isAccountModalOpen, accountData]);

  const fetchTenants = async () => {
    try {
      const response = await api.get(`${tenantEndpoint}?pageNumber=1&pageSize=100`);
      response.data.tenants = response?.data?.tenants?.map((item) => ({
        ...item,
        user: `${item?.accountOwner?.firstName} ${item?.accountOwner?.lastName}`,
      }));
      tnList = response.data.tenants;
      tenantFormattedOptions = formatEntityLabelValue(response.data.tenants, 'name', 'id');
      setTenantOptions(tenantFormattedOptions);
      dispatch(setDdlTenantsData(tenantFormattedOptions));
      setTenants(response.data.tenants);
      dispatch(setTenantList(response.data.tenants));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchUsers = async () => {
    try {
      const uri = `${usersEndpoint}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      userFormattedOptions = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(userFormattedOptions);
      dispatch(setDdlUsersData(userFormattedOptions));
    } catch (error) {
      console.error('Error fetching salesperson suggestions:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await api.get(`/contacts?pageNumber=1&pageSize=100`);
      response.data.contacts = response.data.contacts?.map((item) => ({
        ...item,
        fullName: `${item?.firstName} ${item?.lastName}`,
      }));
      ctList = response?.data?.contacts;
      setContacts(response?.data?.contacts);
      contactFormattedOptions = formatEntityLabelValue(response?.data?.contacts, 'fullName', 'contactId');
      setContactOptions(contactFormattedOptions);
      dispatch(setDdlContactsData(contactFormattedOptions));
      dispatch(setContacts(response?.data?.contacts));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLeadsById = async () => {
    try {
      const response = await api.get(`${salesLeadsEndpoint}/${leadId}`);
      const leadResult = response.data;
      const findContact = ctList?.find((cl) => cl.contactId === leadResult?.contactId);
      const findCurrency = tnList?.find((tn) => tn.id === leadResult?.tenantId)?.tenantFinancials?.currency;
      const finalObj = {
        ...leadResult,
        lvTenantId: { label: leadResult?.account?.name, value: leadResult?.account?.tenantId },
        tenantId: leadResult?.account?.tenantId,
        contactId: leadResult?.contact?.contactId,
        lvContactId: { label: `${leadResult?.contact?.firstName} ${leadResult?.contact?.lastName}`, value: leadResult?.contact?.contactId },
        lvAssignedToUserId: { label: `${leadResult?.assignedToUser?.firstName} ${leadResult?.assignedToUser?.lastName}`, value: leadResult?.contact?.id },
        lastContactDate: leadResult?.lastContactDate ? dayjs(leadResult?.lastContactDate) : null,
        nextFollowUpDate: leadResult?.nextFollowUpDate ? dayjs(leadResult?.nextFollowUpDate) : null,
        closeDate: leadResult?.closeDate ? dayjs(leadResult?.closeDate) : null,
        email: findContact?.email,
        mobile: findContact?.mobile,
        currency: findCurrency,
      };
      setLeadOverviewForm({
        ...leadOverviewForm,
        ...finalObj,
      });
      console.log('leadOverviewForm', leadOverviewForm);
      dispatch(
        setLeadsData({
          ...leadOverviewForm,
          ...finalObj,
        })
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTenantChange = async (newValue) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openAccountModal());
    } else if (newValue?.value) {
      try {
        const response = await api.get(`${tenantEndpoint}/${newValue?.value}`);
        updateCustomerRelatedData(response?.data, newValue);
      } catch (error) {
        console.log('error', error);
      }
    } else if (newValue) {
      const findCurrency = tenants?.find((tn) => tn.id === newValue?.value)?.currency;
      const updateTenant = {
        ...leadOverviewForm,
        currency: findCurrency,
        tenantId: newValue?.value,
        lvTenantId: { value: newValue?.value, label: newValue?.label },
      };
      setLeadOverviewForm(updateTenant);
      dispatch(setLeadsData(updateTenant));
    } else {
      const updateTenant = {
        ...leadOverviewForm,
        currency: '',
        tenantId: '',
        lvTenantId: { value: '', label: '' },
      };
      setLeadOverviewForm(updateTenant);
      dispatch(setLeadsData(updateTenant));
    }
  };

  const updateCustomerRelatedData = (cusByIdData, selectedCustomer) => {
    const findCurrency = tenants?.find((tn) => tn.id === selectedCustomer?.value)?.currency;
    const needsUpdate = {
      tenantId: selectedCustomer?.value,
      currency: findCurrency,
      lvTenantId: { value: selectedCustomer?.value, label: selectedCustomer?.label },
      salesPerson: cusByIdData?.accountOwner?.id,
      lvSalesPerson: { label: `${cusByIdData?.accountOwner?.firstName} ${cusByIdData?.accountOwner?.lastName}`, value: cusByIdData?.accountOwner?.id },
    };
    setLeadOverviewForm({
      ...leadOverviewForm,
      ...needsUpdate,
    });
    dispatch(
      setLeadsData({
        ...leadOverviewForm,
        ...needsUpdate,
      })
    );
  };

  const handleLeadFieldChange = (e) => {
    const { name, value } = e.target;
    setLeadOverviewForm({
      ...leadOverviewForm,
      [name]: value,
    });
    dispatch(
      setLeadsData({
        ...leadOverviewForm,
        [name]: value,
      })
    );
  };

  const handleSaveLead = async () => {
    let response = '';
    if (leadId) {
      response = await api.put(`${salesLeadsEndpoint}/${leadId}`, leadsData);
    } else {
      //leadsData.status = 0;
      const leadStatusData = {
        ...leadOverviewForm,
        status: 'New',
      };
      response = await api.post(salesLeadsEndpoint, leadStatusData);
    }
    if (leadId) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${leadsData?.leadName}' ${Messages.leadsUpdate}`,
      });
    } else {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${leadsData?.leadName}' ${Messages.leadsCreate}`,
      });
      navigate(`/leads/${response?.data?.leadId}`);
    }
  };

  return (
    <>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <Box className="bg-gray-50 min-h-screen">
        <Box className="p-6">
          <Grid container spacing={2} className="pt-3">
            {/* Left Column */}
            <Grid item xs={12} md={8}>
              {/* Basic Information Section */}
              <Paper className="p-6 mb-2">
                <Typography variant="h6" className="mb-2 flex items-center">
                  <User className="w-5 h-5 mx-2" />
                  Basic Information
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Box padding={1}>
                      <TextField label="Lead Name" name="leadName" value={leadOverviewForm.leadName} onChange={handleLeadFieldChange} fullWidth variant="outlined" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box padding={1}>
                      <CustomAutocomplete
                        value={leadOverviewForm.lvTenantId}
                        onChange={(event, newValue) => handleTenantChange(newValue)}
                        options={tenantOptions}
                        inputLabel="Customer"
                        placeholder="Select customer..."
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box padding={1}>
                      <CustomAutocomplete
                        value={leadOverviewForm.lvAssignedToUserId}
                        onChange={(event, newValue) => {
                          if (newValue?.value) {
                            const updateAssignedUser = {
                              ...leadOverviewForm,
                              assignedToUserId: newValue?.value,
                              lvAssignedToUserId: { value: newValue?.value, label: newValue?.label },
                            };
                            setLeadOverviewForm(updateAssignedUser);
                            dispatch(setLeadsData(updateAssignedUser));
                          } else {
                            const updateAssignedUser = {
                              ...leadOverviewForm,
                              assignedToUserId: '',
                              lvAssignedToUserId: { value: '', label: '' },
                            };
                            setLeadOverviewForm(updateAssignedUser);
                            dispatch(setLeadsData(updateAssignedUser));
                          }
                        }}
                        options={userOptions}
                        inputLabel="Sales Person"
                        placeholder="Select sales person..."
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              {/* Dates & Progress Section */}
              <Paper className="mb-2 pt-3">
                <Typography variant="h6" className="mb-2 flex items-center">
                  <Calendar className="w-5 h-5 mx-2" />
                  Dates & Progress
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box padding={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Last Contact"
                          value={leadOverviewForm?.lastContactDate}
                          onChange={(e) => {
                            setLeadOverviewForm({
                              ...leadOverviewForm,
                              lastContactDate: dayjs(e),
                            });
                            dispatch(
                              setLeadsData({
                                ...leadOverviewForm,
                                lastContactDate: e?.toISOString(),
                              })
                            );
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box padding={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Next Follow-up"
                          value={leadOverviewForm?.nextFollowUpDate}
                          onChange={(e) => {
                            setLeadOverviewForm({
                              ...leadOverviewForm,
                              nextFollowUpDate: dayjs(e),
                            });
                            dispatch(
                              setLeadsData({
                                ...leadOverviewForm,
                                nextFollowUpDate: e?.toISOString(),
                              })
                            );
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box padding={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Expected Close"
                          value={leadOverviewForm?.closeDate}
                          onChange={(e) => {
                            setLeadOverviewForm({
                              ...leadOverviewForm,
                              closeDate: dayjs(e),
                            });
                            dispatch(
                              setLeadsData({
                                ...leadOverviewForm,
                                closeDate: e?.toISOString(),
                              })
                            );
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              {/* Additional Info Section */}
              <Paper className="pt-3 mb-2">
                <Box padding={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" className="mb-2 flex items-center">
                        <Tag className="w-5 h-5 mx-2" />
                        Additional Info
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="Source" name="source" value={leadOverviewForm.source} onChange={handleLeadFieldChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="Notes" name="notes" value={leadOverviewForm.notes} onChange={handleLeadFieldChange} fullWidth multiline rows={4} />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              {/* Save Button at the Bottom */}
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={4}>
              {/* Deal Value Section */}
              <Paper className="p-6 mb-2">
                <Box padding={1}>
                  <Typography variant="h6" className="mb-3 flex items-center">
                    <Star className="w-5 h-5 mx-2" />
                    Potential Deal Value
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Estimated Value"
                        name="estimatedValue"
                        type="number"
                        value={leadOverviewForm.estimatedValue}
                        onChange={handleLeadFieldChange}
                        fullWidth
                        InputProps={{
                          startAdornment: <Typography className="mx-2">{leadOverviewForm?.currency}</Typography>,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Probability"
                        name="probability"
                        type="number"
                        value={leadOverviewForm.probability}
                        onChange={handleLeadFieldChange}
                        fullWidth
                        InputProps={{
                          endAdornment: <Typography className="ml-2">%</Typography>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              {/* Contact Details Section */}
              <Paper className="pt-3 mb-2">
                <Box padding={1}>
                  <Typography variant="h6" className="mb-2 flex items-center">
                    <Mail className="w-5 h-5 mx-2" />
                    Contact Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CustomAutocomplete
                        value={leadOverviewForm.lvContactId}
                        onChange={(event, newValue) => {
                          if (newValue?.value) {
                            const findContact = contacts?.find((cl) => cl.contactId === newValue?.value);
                            const updateContact = {
                              ...leadOverviewForm,
                              contactId: newValue?.value,
                              lvContactId: { value: newValue?.value, label: newValue?.label },
                              email: findContact?.email,
                              mobile: findContact?.mobile,
                            };
                            setLeadOverviewForm(updateContact);
                            dispatch(setLeadsData(updateContact));
                          } else {
                            const updateContact = {
                              ...leadOverviewForm,
                              contactId: '',
                              lvContactId: { value: '', label: '' },
                              email: '',
                              mobile: '',
                            };
                            setLeadOverviewForm(updateContact);
                            dispatch(setLeadsData(updateContact));
                          }
                        }}
                        options={contactOptions}
                        inputLabel="Contact Person"
                        placeholder="Select contact..."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        name="email"
                        value={leadOverviewForm.email}
                        onChange={handleLeadFieldChange}
                        fullWidth
                        disabled
                        InputProps={{
                          startAdornment: <Mail className="w-4 h-4 mx-2 text-gray-400" />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Phone"
                        name="phone"
                        disabled
                        value={leadOverviewForm.mobile}
                        onChange={handleLeadFieldChange}
                        fullWidth
                        InputProps={{
                          startAdornment: <Phone className="w-4 h-4 mx-2 text-gray-400" />,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item>
              <Box display="flex" mt={1}>
                <Button variant="contained" size="large" onClick={handleSaveLead} disabled={LeadSubmitLoading || [LeadStatus.Won, LeadStatus.Lost].includes(leadsData?.status)}>
                  Save
                </Button>
                {LeadSubmitLoading && <CircularProgress size={24} />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

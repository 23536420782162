import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, CircularProgress, Grid, Tab, Tabs, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_PRODUCTS_ENDPOINT, API_PRODUCT_CATEGORY_ENDPOINT, API_SKU_ENDPOINT, ITEM_TYPE_OPTIONS, UNIT_OPTIONS } from '../../../../../constants/constants';
import { Messages } from '../../../../common-constants/messages';
import { AlertStatus } from '../../../../common-constants/status';
import CustomAlert from '../../../../common/alert';
import api from '../../../../common/api';
import CustomAutocomplete from '../../../../common/autocomplete';
import CustomBreadCrumb from '../../../../common/breadcrumb';
import { BootstrapTooltip, formatEntityLabelValue } from '../../../../common/commonUtility';
import InputFileUpload from '../../../../common/upload';
import LocationModal from '../../../../modals/location-modal';
import NewItemLocation from './new-item-location';
import { useSelector } from 'react-redux';
import { closeItemModal } from '../../../../redux/actions/sales-action';
import { useDispatch } from 'react-redux';

export const NewProduct = ({ handleNewItemClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newItemModalData } = useSelector((state) => state?.sales?.data);
  const productEndpoint = `${API_PRODUCTS_ENDPOINT}`;
  const skuEndpoint = API_SKU_ENDPOINT;
  const categoryEndpoint = API_PRODUCT_CATEGORY_ENDPOINT;
  const [activeTab, setActiveTab] = useState('itemDetails');
  const [tabs] = useState(['itemDetails', 'inventoryTracking']);
  const [selectedSku, setSelectedSku] = useState('');
  const [skuOptions, setSkuOptions] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryOptions, setCategoryOptions] = useState('');
  const [locationOptions, setLocationOptions] = useState([{ value: 'new', label: `Add a new Location` }]);
  const [itemCreationLoading, setItemCreationLoading] = useState(false);
  const [locationModal, setLocationModal] = useState({
    showModal: false,
    locationIndex: '',
    newLocation: '',
  });

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });

  const [itemForm, setItemForm] = useState({
    productName: '',
    productDescription: '',
    productCategory: '',
    estimatedValuePerUnit: '',
    openingStock: '',
    productType: '',
    unit: '',
    remarks: '',
    productImage: '',
    trackInventory: false,
    sku: '',
    locations: [{ location: {}, openingBalance: '' }],
    submitted: false,
  });

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Items',
      href: '/items',
    },
    {
      name: 'New Item',
      active: true,
    },
  ];

  useEffect(() => {
    searchSkus();
    searchLocations();
    searchCategories();
  }, []);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const searchSkus = debounce(() => {
    const url = `${skuEndpoint}?pageNumber=1&pageSize=100`;
    api
      .get(url)
      .then((response) => {
        const formatedSkuOptions = formatEntityLabelValue(response?.data?.skus, 'skuName', 'skuId');
        setSkuOptions(formatedSkuOptions);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const handleSkuChange = (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      addSku(newValue?.inputValue);
    } else {
      setSelectedSku({
        value: newValue?.value,
        label: newValue?.label,
      });
      setItemForm({
        ...itemForm,
        sku: newValue?.value,
      });
    }
  };

  const addSku = async (sku) => {
    try {
      const req = { skuName: sku };
      const response = await api.post(`${skuEndpoint}`, req);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${response?.data?.skuName}' ${Messages.skuAdded}`,
      });
      const formatedAdjReason = formatEntityLabelValue(response?.data, 'skuName', 'skuId');
      setSelectedSku(formatedAdjReason);
      setItemForm({
        ...itemForm,
        sku: formatedAdjReason?.value,
      });
      searchSkus();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const searchCategories = debounce(() => {
    const url = `${categoryEndpoint}?pageNumber=1&pageSize=100`;
    api
      .get(url)
      .then((response) => {
        const formatCategoryOptions = formatEntityLabelValue(response?.data?.productCategories, 'categoryName', 'productCategoryId');
        setCategoryOptions(formatCategoryOptions);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const handleCategoryChange = (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      addCategory(newValue?.inputValue);
    } else {
      setSelectedCategory({
        value: newValue?.value,
        label: newValue?.label,
      });
      setItemForm({
        ...itemForm,
        productCategory: newValue?.value,
      });
    }
  };

  const addCategory = async (category) => {
    try {
      const req = { name: category };
      const response = await api.post(`${categoryEndpoint}`, req);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${response?.data?.categoryName}' ${Messages.categoryAdded}`,
      });
      const formatedAdjReason = formatEntityLabelValue(response?.data, 'categoryName', 'productCategoryId');
      setSelectedCategory(formatedAdjReason);
      setItemForm({
        ...itemForm,
        productCategory: formatedAdjReason?.value,
      });
      searchCategories();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const searchLocations = debounce((searchText) => {
    const url = `/locations?pageNumber=1&pageSize=100`;
    api
      .get(url)
      .then((response) => {
        const options = response?.data?.locations?.map((location) => ({
          value: location.locationId,
          label: location.locationName,
        }));
        setLocationOptions(options);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, 500);

  const handleLocationChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      setLocationModal({ showModal: true, location: newValue?.inputValue, locationIndex: index });
    } else {
      locationUpdateBasedOnIndex(newValue, index);
    }
  };

  const locationUpdateBasedOnIndex = (newValue, index) => {
    itemForm.locations[index] = {
      ...itemForm?.locations[index],
      locationId: newValue?.value || '',
      selectedItem: {
        label: newValue?.label || '',
        value: newValue?.value || '',
      },
    };
    setItemForm({
      ...itemForm,
      locations: itemForm.locations,
    });
  };

  const handleLocationClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    } else if (typeof reason === 'number' && event.locationId) {
      const newLocation = formatEntityLabelValue(event, 'locationName', 'locationId');
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${newLocation?.label}' ${Messages.locationAdded}`,
      });
      locationUpdateBasedOnIndex(newLocation, reason);
      searchLocations();
    }
    setLocationModal(false);
  };

  const handleAddLocationField = () => {
    setItemForm((formData) => ({
      ...formData,
      locations: [...formData.locations, { location: {}, openingBalance: '' }],
    }));
  };

  const handleRemoveLocationField = (index) => {
    setItemForm((formData) => {
      const updatedItems = [...formData?.locations];
      updatedItems.splice(index, 1);
      return {
        ...formData,
        locations: updatedItems,
      };
    });
  };

  const handleLocationFieldChange = (e, index) => {
    const { name, value } = e.target;
    itemForm.locations[index] = { ...itemForm.locations[index], [name]: Number(value) };
    setItemForm({
      ...itemForm,
      locations: itemForm.locations,
    });
  };

  useEffect(() => {
    if (itemForm.trackInventory && itemForm?.locations.length === 0) {
      handleAddLocationField();
    }
  }, [itemForm.trackInventory]);

  const catchLogoId = (event) => {
    if (event) {
      setItemForm({
        ...itemForm,
        productImage: event,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setItemForm((prevState) => {
      const updatedItemData = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      };
      return updatedItemData;
    });
  };

  const getValidatedFormResult = () => {
    let valid = true;
    itemForm.locations.map((loc) => {
      if (!loc?.selectedItem?.value || !loc?.openingBalance) {
        valid = false;
        return false;
      }
    });
    if (!valid) {
      return false;
    }
    if (!itemForm.productName?.trim() || !itemForm.productCategory || !itemForm.estimatedValuePerUnit?.trim() || !itemForm.sku || !itemForm.itemType?.value || !itemForm.unit?.value) {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setItemForm({
      ...itemForm,
      submitted: true,
    });

    if (getValidatedFormResult()) {
      setItemCreationLoading(true);
      const jsonData = {
        ...itemForm,
        productType: itemForm.itemType?.value,
        unit: itemForm.unit?.value,
      };
      api
        .post(productEndpoint, jsonData)
        .then((response) => {
          if (newItemModalData?.isItemModalOpen || newItemModalData?.index) {
            dispatch(closeItemModal(response?.data, newItemModalData?.index));
          } else {
            navigate('/items', { state: { productName: response?.data?.productName } });
          }
          setItemCreationLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setItemCreationLoading(false);
        });
    }
  };

  const handleTabSelect = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex < tabs.length - 1) {
      setActiveTab(tabs[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    const currentIndex = tabs.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };

  return (
    <>
      <div className={`d-flex flex-grow-1 flex-column p-4 mt-1`}>
        {!handleNewItemClose && (
          <>
            {!newItemModalData?.isItemModalOpen ? (
              <div className="d-flex justify-content-between">
                <CustomBreadCrumb breadcrumbList={breadcrumbs} />
              </div>
            ) : null}

            <div className="d-flex justify-content-between">
              <Typography variant="h6" gutterBottom>
                Add Item
              </Typography>
              <div>
                <div className="d-flex justify-content-end">
                  <CloseIcon
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      if (newItemModalData?.isItemModalOpen || newItemModalData?.index) {
                        dispatch(closeItemModal({}, newItemModalData?.index));
                      } else {
                        navigate('/items');
                      }
                    }}
                  />
                </div>
                <p>
                  <span className="error-red ">*</span>Required Fields
                </p>
              </div>
            </div>
          </>
        )}
        <div className="pe-3 ps-3 flex-grow-1 border rounded">
          <Tabs value={activeTab} onChange={handleTabSelect} className="mb-2">
            <Tab value="itemDetails" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Overview</Typography>}></Tab>
            <Tab value="inventoryTracking" className="text-capitalize fs-13" label={<Typography className="text-capitalize fs-13">Inventory</Typography>}></Tab>
          </Tabs>
          {activeTab === 'itemDetails' && (
            <Grid container className="mt-1 adj-container" spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="productName"
                  label="Name *"
                  variant="outlined"
                  error={!itemForm.productName?.trim() && itemForm.submitted}
                  helperText={!itemForm.productName?.trim() && itemForm.submitted ? 'Item Name is required.' : ''}
                  value={itemForm.productName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  options={categoryOptions}
                  inputLabel="Category *"
                  isError={!selectedCategory?.value && itemForm.submitted}
                  helperText={!selectedCategory?.value && itemForm.submitted ? 'Category is required.' : ''}
                  placeholder="Select or type category... *"
                  getOptionLabel={(option) => option.label || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField name="productDescription" label="Description" multiline rows={4} variant="outlined" value={itemForm?.productDescription} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField name="remarks" label="Remarks" multiline rows={4} variant="outlined" value={itemForm?.remarks} onChange={handleInputChange} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  value={selectedSku}
                  onChange={handleSkuChange}
                  options={skuOptions}
                  inputLabel="SKU *"
                  isError={!selectedSku?.value && itemForm.submitted}
                  helperText={!selectedSku?.value && itemForm.submitted ? 'SKU is required.' : ''}
                  placeholder="Select or type SKU... *"
                  getOptionLabel={(option) => option.label || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  value={itemForm.itemType}
                  onChange={(event, selectedOption) => {
                    handleInputChange({ target: { name: 'itemType', value: { label: selectedOption?.label, value: selectedOption?.value } } });
                  }}
                  options={ITEM_TYPE_OPTIONS}
                  inputLabel="Item type *"
                  disableAddNew={true}
                  isError={!itemForm?.itemType?.value && itemForm.submitted}
                  helperText={!itemForm?.itemType?.value && itemForm.submitted ? 'Item type is required.' : ''}
                  placeholder="Select Item Type..."
                  getOptionLabel={(option) => option.label || ''}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="label">Item Image</Typography>
                <div className="d-flex">
                  <InputFileUpload emitLogoId={catchLogoId} />
                </div>
              </Grid>
            </Grid>
          )}
          {activeTab === 'inventoryTracking' && (
            <Grid container className="mt-1 inventory-container" spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  value={itemForm.unit}
                  onChange={(event, selectedOption) => {
                    handleInputChange({ target: { name: 'unit', value: { label: selectedOption?.label, value: selectedOption?.value } } });
                  }}
                  options={UNIT_OPTIONS}
                  disableAddNew={true}
                  inputLabel="Unit *"
                  isError={!itemForm?.unit?.value && itemForm.submitted}
                  helperText={!itemForm?.unit?.value && itemForm.submitted ? 'Unit is required.' : ''}
                  placeholder="Select Unit... *"
                  getOptionLabel={(option) => option.label || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  label="Estimated value per unit *"
                  id="estimatedValuePerUnit"
                  value={itemForm.estimatedValuePerUnit}
                  name="estimatedValuePerUnit"
                  variant="outlined"
                  onChange={handleInputChange}
                  error={!itemForm?.estimatedValuePerUnit && itemForm.submitted}
                  helperText={!itemForm?.estimatedValuePerUnit && itemForm.submitted ? 'Estimated value per unit is required.' : ''}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="d-flex">
                  <Typography className="label mt-2">Track Inventory</Typography>
                  <Checkbox size="small" name="trackInventory" checked={itemForm.trackInventory || false} onChange={handleInputChange} />
                </div>
                {itemForm.trackInventory && (
                  <>
                    {/* {itemForm.locations.map((location, index) => ( */}
                    <NewItemLocation
                      locations={itemForm.locations}
                      handleLocationChange={handleLocationChange}
                      handleLocationFieldChange={handleLocationFieldChange}
                      handleRemoveLocationField={handleRemoveLocationField}
                      locationOptions={locationOptions}
                      searchLocations={searchLocations}
                      itemForm={itemForm}
                    />
                    {/* ))} */}
                    <Grid item xs={6} md={6} className="mt-2">
                      <BootstrapTooltip title="Add Location">
                        <Button size="small" className="mt-2" variant="outlined" startIcon={<AddIcon />} onClick={handleAddLocationField}>
                          Add Location
                        </Button>
                      </BootstrapTooltip>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          <div className="d-flex align-items-center my-3">
            {activeTab !== tabs[0] && (
              <Button size="small" variant="outlined" onClick={handlePrevious}>
                Previous
              </Button>
              // <Button size='sm' variant="outline-primary" onClick={handlePrevious}>Previous</Button>
            )}
            {activeTab !== tabs[tabs.length - 1] ? (
              <Button size="small" variant="outlined" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <div className="d-flex align-items-center mx-2">
                <Button size="small" type="submit" variant="contained" disabled={itemCreationLoading} onClick={handleSubmit}>
                  Submit
                </Button>
                {itemCreationLoading && <CircularProgress className="mx-2" size={15} />}
              </div>
            )}
          </div>
        </div>
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
        <LocationModal location={locationModal?.location} showModal={locationModal?.showModal} locationIndex={locationModal?.locationIndex} handleClose={handleLocationClose} />
      </div>
    </>
  );
};

import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_LOCATION_ENDPOINT, API_OPERATIONS_ENDPOINT, API_PRODUCT_LOCATION_ENDPOINT, API_PRODUCTS_ENDPOINT, API_TENANTS_ENDPOINT, API_USERS_ENDPOINT } from '../../../../constants/constants';
import { useToken } from '../../../auth/useToken';
import { Messages } from '../../../common-constants/messages';
import { MfgChips } from '../../../common-constants/static-values';
import { AlertStatus, WorkOrderStatus, WorkOrderStatusValue } from '../../../common-constants/status';
import CustomAlert from '../../../common/alert';
import api from '../../../common/api';
import CustomAutocomplete from '../../../common/autocomplete';
import CustomBreadCrumb from '../../../common/breadcrumb';
import {
  BootstrapTooltip,
  formatEntityLabelValue,
  formatProductForSales,
  formatProductWithLocation,
  generateEntityNumber,
  getBadgeVariant,
  getWOKebabMenuActions,
  mapWorkOrderStatus,
  renderLastModifiedData,
} from '../../../common/commonUtility';
import BasicMenu from '../../../common/menu';
import MultiFileUpload from '../../../common/multi-upload';
import { CreateItemModal } from '../../../modals/create-item';
import { UpsertMaterialModal } from '../../../modals/upsert-materials';
import { closeItemModal, openItemModal } from '../../../redux/actions/sales-action';

export const ManageWorkOrder = () => {
  const { newItemModalData, itemData } = useSelector((state) => state?.sales?.data);
  const { workorderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const operationsEndpoint = API_OPERATIONS_ENDPOINT;
  const customerEndpoint = API_TENANTS_ENDPOINT;
  const productLocationEndpoint = API_PRODUCT_LOCATION_ENDPOINT;
  const locationEndpoint = API_LOCATION_ENDPOINT;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [bomOptions, setBomOptions] = useState([]);
  const [routingOptions, setRoutingOptions] = useState([]);
  let bomItemIds = [];
  const defaultRoute = {
    stepNumber: '',
    stepDescription: '',
    machineName: '',
    estimatedTime: 0,
  };
  const defaultMfgProcess = {
    routingStepId: '',
    startTime: null,
    endTime: null,
    notes: '',
  };
  const [woFiles, setWoFiles] = useState();

  const defaultMaterial = {
    materialId: '',
    lvMaterialId: '',
    quantity: 0,
  };
  const [mfgProcessForm, setMfgProcessForm] = useState();
  const [mfgMaterialsForm, setMfgMaterialsForm] = useState([[defaultMaterial]]);
  const [isBomModalOpen, setIsBomModalOpen] = useState(false);
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [routingLoader, setRoutingLoader] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [productItemOptions, setProductItemOptions] = useState([]);
  const [productLocationOptions, setProductLocationOptions] = useState([]);
  const [routingForm, setRoutingForm] = useState({
    name: '',
    itemId: '',
    submitted: false,
  });
  const defaultProduct = {
    itemId: '',
    lvItemId: '',
    quantity: 0,
    notes: '',
  };
  const [bomLoader, setBomLoader] = useState(false);
  const [bomForm, setBomForm] = useState({
    name: '',
    itemId: '',
    submitted: false,
  });
  const [materials, setMaterials] = useState([defaultProduct]);
  const [routingSteps, setRoutingSteps] = useState([defaultRoute]);
  const [qcForm, setQcForm] = useState({});
  const [woForm, setWoForm] = useState({
    bomId: '',
    lvBomId: '',
    workerUserId: '',
    lvWorkerUserId: '',
    routingId: '',
    lvRoutingId: '',
    locationIdForProduce: '',
    lvLocationIdForProduce: '',
    qualityCheckItemQuantity: 0,
    qualityCheckOrderCreated: false,
  });
  const [manufacturingForm, setManufacturingForm] = useState({
    outputQuantity: 0,
    scrapQuantity: 0,
  });
  const [operationDataByIndex, setOperationDataByIndex] = useState([]);
  let [operationIndex, setOperationIndex] = useState('');
  const [userOptions, setUserOptions] = useState();

  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [locationByItemOptions, setLocationByItemOptions] = useState([]);
  let formatedProductLocationOptions = [];
  let formatLocationOptions = [];
  const [viewBOMModal, setViewBOMModal] = useState(false);

  const fetchAndHandleItem = async () => {
    if (itemData?.productId) {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      const formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedProductsOptions);
      const product = formatedProductsOptions?.find((item) => item?.value === itemData?.productId);
      handleMaterialItemChange(product, newItemModalData?.index);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `'${itemData?.productName}' ${Messages.itemAdded}`,
      });
    }
  };

  useEffect(() => {
    fetchAndHandleItem();
    if (!newItemModalData?.isItemModalOpen) {
      dispatch(closeItemModal());
    }
  }, [newItemModalData?.isItemModalOpen, itemData]);

  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const breadcrumbs = [
    {
      name: 'Work Orders',
      href: '/workOrders',
    },
    {
      name: 'Manage Work Order',
      active: true,
    },
  ];

  useEffect(() => {
    fetchProducts();
    // fetchProductsLocations();
    fetchBOM();
    fetchRouting();
    fetchUsers();
    fetchWorkOrdersById();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      const formatedReasonsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedReasonsOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const fetchProductsLocations = async () => {
  //   try {
  //     const response = await api.get(`${productLocationEndpoint}?pageNumber=1&pageSize=100`);
  //     formatedProductLocationOptions = formatProductLocation(response?.data?.productLocations) || [];
  //     setProductLocationOptions(formatedProductLocationOptions);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const fetchBOM = async () => {
    const response = await api.get(`${operationsEndpoint}/bom?pageNumber=1&pageSize=100`);
    const formatBOMOptions = formatEntityLabelValue(response?.data?.boms, 'name', 'id') || [];
    setBomOptions(formatBOMOptions);
  };

  const fetchRouting = async () => {
    const response = await api.get(`${operationsEndpoint}/routing?pageNumber=1&pageSize=100`);
    const formatRoutingOptions = formatEntityLabelValue(response?.data?.routings, 'routingName', 'routingId') || [];
    setRoutingOptions(formatRoutingOptions);
  };

  const fetchProductByBOM = async () => {
    await handleEditBomDetail();
    if (bomItemIds?.length) {
      const response = await api.post(`${productLocationEndpoint}/FetchProductLocations`, bomItemIds);
      formatedProductLocationOptions = formatProductWithLocation(response?.data) || [];
      setProductLocationOptions(formatedProductLocationOptions);
    } else {
      setProductLocationOptions([]);
    }
  };

  const [token, setToken] = useToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchUsers = async () => {
    try {
      const uri = `${API_USERS_ENDPOINT}`;
      const response = await axios.get(`${uri}`, config);
      response.data = response?.data?.users?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
      }));
      const userData = formatEntityLabelValue(response.data, 'fullName', 'id');
      setUserOptions(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchWorkOrdersById = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${operationsEndpoint}/work-orders/${workorderId}`);
      await fetchLocationByItem(response?.data?.product?.productId);
      if (response?.data?.qualityCheckOrderId) {
        const qualityResponse = await api.get(`${operationsEndpoint}/quality-orders/${response?.data?.qualityCheckOrderId}`);
        const finalQC = {
          ...qualityResponse?.data,
        };
        setQcForm(finalQC);
      }
      const routingDetails = response?.data?.routingDetails;
      if (routingDetails?.routingId) {
        await handleEditRoutingDetail(routingDetails?.routingId);
      }
      const bomDetails = response?.data?.bomDetails;
      setWoFiles(response?.data?.files || []);
      const finalWorkFormObj = {
        ...response.data,
        workOrderNumber: response?.data?.workOrderNumber || generateEntityNumber('WO'),
        lvBomId: { label: bomDetails?.name, value: bomDetails?.bomId },
        lvRoutingId: { label: routingDetails?.name, value: routingDetails?.routingId },
        plannedStartDate: response?.data?.plannedStartDate ? dayjs(response?.data?.plannedStartDate) : null,
        plannedEndDate: response?.data?.plannedEndDate ? dayjs(response?.data?.plannedEndDate) : null,
        actualStartDate: response?.data?.actualStartDate ? dayjs(response?.data?.actualStartDate) : null,
        actualEndDate: response?.data?.actualEndDate ? dayjs(response?.data?.actualEndDate) : null,
        workerUserId: response?.data?.assignedToUser?.id,
        lvWorkerUserId: response?.data?.assignedToUser
          ? { label: `${response?.data?.assignedToUser?.firstName} ${response?.data?.assignedToUser?.lastName}`, value: response?.data?.assignedToUser?.id }
          : '',
        lvLocationIdForProduce: formatLocationOptions?.find((lit) => lit.value === response?.data?.manufacturingDetails?.workOrder?.locationIdForProduce),
        locationIdForProduce: response?.data?.manufacturingDetails?.workOrder?.locationIdForProduce,
      };
      setWoForm(finalWorkFormObj);
      setBomForm({
        ...bomForm,
        itemId: response?.data?.product?.productId,
      });
      setRoutingForm({
        ...routingForm,
        name: routingDetails?.name,
        itemId: response?.data?.product?.productId,
      });

      const finalObjForMfgDetails = {
        outputQuantity: response?.data?.manufacturingDetails?.outputQuantity,
        scrapQuantity: response?.data?.manufacturingDetails?.scrapQuantity,
      };
      setManufacturingForm(finalObjForMfgDetails);

      if (response?.data?.manufacturingDetails?.processes?.length) {
        const finalObjForProcess = response?.data?.manufacturingDetails?.processes?.map((md, mdIndex) => ({
          ...md,
          startTime: md?.startTime ? dayjs(md?.startTime) : null,
          endTime: md?.endTime ? dayjs(md?.endTime) : null,
          machineName: routingDetails?.routingItems[mdIndex]?.operation?.machineName,
          estimatedTime: routingDetails?.routingItems[mdIndex]?.operation?.estimatedTime,
          stepDescription: routingDetails?.routingItems[mdIndex]?.operation?.stepDescription,
          materials: md?.materials?.map((mdm) => ({
            ...mdm,
            lvMaterialId: {
              value: `${mdm?.materialId}/${mdm?.locationId}`,
              label: formatedProductLocationOptions?.find((pitem) => pitem?.value === `${mdm?.materialId}/${mdm?.locationId}`)?.formattedObject?.selectedLabel,
            },
          })),
        }));
        setMfgProcessForm(finalObjForProcess);
        const finalObjForMaterials = finalObjForProcess?.map((it) => it?.materials);
        setMfgMaterialsForm(finalObjForMaterials);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchLocationByItem = async (itemId) => {
    if (itemId) {
      const response = await api.get(`${locationEndpoint}?pageNumber=1&pageSize=100`);
      formatLocationOptions = formatEntityLabelValue(response?.data?.locations, 'locationName', 'locationId') || [];
      setLocationByItemOptions(formatLocationOptions);
    }
  };
  const handleRouteInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRoutingForm((prevState) => {
      const updatedItemData = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      };
      return updatedItemData;
    });
  };

  const handleBOMInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBomForm((prevState) => {
      const updatedItemData = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      };
      return updatedItemData;
    });
  };

  const handleRoutingStepsChange = async (e, index) => {
    const { name, value } = e.target;
    let updateRoute = routingSteps.map((rou, i) => {
      if (i !== index) return rou;

      rou = { ...rou, [name]: value };
      return rou;
    });
    setRoutingSteps(updateRoute);
  };

  const handleAddRoutingSteps = () => {
    const addedProduct = [...routingSteps, defaultRoute];
    setRoutingSteps(addedProduct);
  };

  const handleRemoveRoutingSteps = (index) => {
    const removedProduct = routingSteps?.filter((item, i) => i !== index);
    setRoutingSteps(removedProduct);
  };

  const getRoutingValidatedFormResult = () => {
    let valid = true;
    // if (!routingForm.lvItemId?.value || !routingForm.name) {
    if (!routingForm.name) {
      return false;
    }
    routingSteps?.map((ordItem) => {
      if (!ordItem?.machineName || !ordItem?.estimatedTime) {
        valid = false;
      }
    });
    return valid;
  };

  const handleEditBomDetail = async (isModalOpen = false) => {
    try {
      const response = await api.get(`${operationsEndpoint}/bom/${woForm?.bomId}`);
      const finalEditObject = {
        ...response?.data,
        itemId: woForm?.product?.productId,
      };
      bomItemIds = response?.data?.bomItems?.map((bo) => bo?.itemId);
      response?.data?.bomItems?.forEach((rd) => {
        const filterItem = productItemOptions?.find((pi) => pi.value === rd?.itemId);
        rd.lvItemId = { label: filterItem?.formattedObject?.selectedLabelForSales, value: filterItem?.value };
        rd.unit = filterItem?.formattedObject?.unit;
        // return rd;
      });
      setBomForm(finalEditObject);
      setMaterials(response?.data?.bomItems);
      if (isModalOpen) {
        setIsBomModalOpen(true);
      }
    } catch (error) {}
  };

  const openRoutingModal = () => {
    setIsRouteModalOpen(true);
  };

  const handleEditRoutingDetail = async (routingId) => {
    try {
      const response = await api.get(`${operationsEndpoint}/routing/${routingId}`);
      const finalEditObject = {
        ...response?.data,
        itemId: woForm?.product?.productId,
      };
      const mfgProcessArray = [];
      const routingItems = response?.data?.routingItems?.map((rd) => {
        const mfgProcessObj = { ...defaultMfgProcess, ...rd?.operation, routingStepId: rd.routingItemId };
        mfgProcessArray.push(mfgProcessObj);
        return { ...rd, ...rd?.operation };
      });
      setMfgProcessForm(mfgProcessArray);
      console.log(mfgProcessArray);
      setRoutingForm(finalEditObject);
      setRoutingSteps(routingItems);
    } catch (error) {}
  };

  const handleBOMSaveOrder = async () => {
    setBomForm({
      ...bomForm,
      submitted: true,
    });
    if (getBOMValidatedFormResult()) {
      if (!woForm?.bomId) {
        const requestObj = {
          ...bomForm,
          items: materials,
        };
        setBomLoader(true);
        try {
          const response = await api.post(`${operationsEndpoint}/bom`, requestObj);
          setWoForm({
            ...woForm,
            bomId: response?.data?.bomId,
            lvBomId: { label: response?.data?.name, value: response?.data?.bomId },
          });
          setBomLoader(false);
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${response?.data?.name} - ${Messages.bomOrderCreated}`,
          });
          fetchBOM();
          setIsBomModalOpen(false);
        } catch (error) {
          setBomLoader(false);
        }
      } else {
        const requestObj = {
          ...bomForm,
          items: materials,
        };
        setBomLoader(true);
        try {
          const response = await api.put(`${operationsEndpoint}/bom/${woForm?.bomId}`, requestObj);
          setWoForm({
            ...woForm,
            bomId: woForm?.bomId,
            lvBomId: { label: bomForm?.name, value: woForm?.bomId },
          });
          setBomLoader(false);
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${bomForm?.name} - ${Messages.bomOrderUpdated}`,
          });
          fetchBOM();
          setIsBomModalOpen(false);
        } catch (error) {
          setBomLoader(false);
        }
      }
    }
  };

  const handleRoutingSaveOrder = async () => {
    setRoutingForm({
      ...routingForm,
      submitted: true,
    });
    if (getRoutingValidatedFormResult()) {
      if (!woForm?.routingId) {
        const requestObj = {
          ...routingForm,
          routingItems: routingSteps?.map((rou, index) => ({
            ...rou,
            stepNumber: index + 1,
            operationRequest: rou,
          })),
        };
        setRoutingLoader(true);
        try {
          const response = await api.post(`${operationsEndpoint}/routing`, requestObj);
          setWoForm({
            ...woForm,
            routingId: response?.data?.routingId,
            lvRoutingId: { label: response?.data?.name, value: response?.data?.routingId },
          });
          setRoutingLoader(false);
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${response?.data?.name} - ${Messages.routingCreated}`,
          });
          fetchRouting();
          setMfgMaterialsForm([defaultMaterial]);
          handleEditRoutingDetail(response?.data?.routingId);
          setIsRouteModalOpen(false);
        } catch (error) {
          setRoutingLoader(false);
        }
      } else {
        const requestObj = {
          ...routingForm,
          routingItems: routingSteps?.map((rou, index) => ({
            ...rou,
            stepNumber: index + 1,
            operationRequest: rou,
          })),
        };
        setRoutingLoader(true);
        try {
          const response = await api.put(`${operationsEndpoint}/routing/${woForm?.routingId}`, requestObj);
          setWoForm({
            ...woForm,
            routingId: woForm?.routingId,
            lvRoutingId: { label: routingForm?.name, value: woForm?.routingId },
          });
          setRoutingLoader(false);
          setAlertData({
            ...alertData,
            open: true,
            type: AlertStatus.Success,
            message: `${routingForm?.name} - ${Messages.routingUpdated}`,
          });
          fetchRouting();
          setIsRouteModalOpen(false);
        } catch (error) {
          setRoutingLoader(false);
        }
      }
    }
  };

  const handleMaterialItemChange = (newValue, index) => {
    if (newValue?.label?.includes('Add')) {
      dispatch(openItemModal(index));
    } else if (newValue?.formattedObject) {
      const formattedObject = newValue?.formattedObject;
      let updatedProducts = materials.map((product, i) => {
        if (i !== index) return product;
        return {
          ...product,
          itemId: formattedObject?.productId || '',
          lvItemId: {
            label: formattedObject?.selectedLabelForSales || '',
            value: formattedObject?.productId || '',
          },
          stockOnHand: formattedObject?.stockOnHand,
          //   quantity: formattedObject?.stockOnHand,
          unit: formattedObject?.unit,
          rate: formattedObject?.price,
          amount: parseFloat(formattedObject?.price) * parseFloat(formattedObject?.stockOnHand),
        };
      });
      setMaterials(updatedProducts);
      //   dispatch(setBOMItemsData(updatedProducts));
    } else {
      materials[index] = defaultProduct;
    }
  };

  const handleMaterialProductChange = async (e, index) => {
    const { name, value } = e.target;
    let updatedProducts = materials.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: value };
      return product;
    });
    setMaterials(updatedProducts);
    //  dispatch(setBOMItemsData(updatedProducts));
  };

  const handleMaterialAddProduct = () => {
    const addedProduct = [...materials, defaultProduct];
    setMaterials(addedProduct);
    //  dispatch(setBOMItemsData(addedProduct));
  };

  const handleMaterialRemoveProduct = (index) => {
    const removedProduct = materials?.filter((item, i) => i !== index);
    setMaterials(removedProduct);
    //  dispatch(setBOMItemsData(removedProduct));
  };

  const getBOMValidatedFormResult = () => {
    let valid = true;
    // if (!bomForm.lvItemId?.value || !bomForm.name) {
    if (!bomForm.name) {
      return false;
    }
    materials?.map((ordItem) => {
      if (!ordItem?.lvItemId?.value || !ordItem?.quantity) {
        valid = false;
      }
    });
    return valid;
  };

  const handleBOMChange = async (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      setMaterials([defaultProduct]);
      setBomForm({
        ...bomForm,
        name: newValue?.label?.replace('Add ', '').replace(/"/g, ''),
      });
      setIsBomModalOpen(true);
      const updateItem = {
        ...woForm,
        bomId: '',
        lvBomId: { value: '', label: '' },
      };
      setWoForm(updateItem);
    } else if (newValue?.value) {
      const updateItem = {
        ...woForm,
        bomId: newValue?.value,
        lvBomId: { value: newValue?.value, label: newValue?.label },
      };
      setWoForm(updateItem);
    } else {
      const updateItem = {
        ...woForm,
        bomId: '',
        lvBomId: { value: '', label: '' },
      };
      setWoForm(updateItem);
    }
  };

  const handleRouteChange = async (event, newValue) => {
    if (newValue?.label?.includes('Add')) {
      setRoutingSteps([defaultRoute]);
      setRoutingForm({
        ...routingForm,
        name: newValue?.label?.replace('Add ', '').replace(/"/g, ''),
      });
      setIsRouteModalOpen(true);
      const updateItem = {
        ...woForm,
        routingId: '',
        lvRoutingId: { value: '', label: '' },
      };
      setWoForm(updateItem);
    } else if (newValue?.value) {
      const updateItem = {
        ...woForm,
        routingId: newValue?.value,
        lvRoutingId: { value: newValue?.value, label: newValue?.label },
      };
      handleEditRoutingDetail(newValue?.value);
      setMfgMaterialsForm([]);
      setWoForm(updateItem);
    } else {
      const updateItem = {
        ...woForm,
        routingId: '',
        lvRoutingId: { value: '', label: '' },
      };
      setWoForm(updateItem);
    }
  };

  const handleWOControlChange = (e) => {
    const { name, value } = e.target;
    setWoForm({
      ...woForm,
      [name]: value,
    });
  };

  const handleManufecturingChange = (e) => {
    const { name, value } = e.target;
    setManufacturingForm({
      ...manufacturingForm,
      [name]: value,
    });
  };

  const handleUserChange = async (newValue) => {
    if (newValue?.value) {
      setWoForm({
        ...woForm,
        workerUserId: newValue?.value,
        lvWorkerUserId: { value: newValue?.value, label: newValue?.label },
      });
    }
  };

  const submitRouteTooltip = (
    <Box
      sx={{
        '& ul': { margin: 0, paddingLeft: 2, paddingBottom: 2 },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      }}
    >
      <Typography className="p-2 fs-14">User can not Add/Delete routing steps once the Route is created.</Typography>
    </Box>
  );

  const handleOperationClick = async (operation, index) => {
    await fetchProductByBOM();
    if (mfgMaterialsForm[index]?.length) {
      mfgMaterialsForm[index] = mfgMaterialsForm[index]?.map((mf) => {
        const findProduct = formatedProductLocationOptions?.find((pitem) => pitem?.value === `${mf?.materialId}/${mf?.locationId}`);
        return {
          ...mf,
          unit: findProduct?.formattedObject?.unit,
          lvMaterialId: {
            value: `${mf?.materialId}/${mf?.locationId}`,
            label: findProduct?.formattedObject?.selectedLabel,
          },
        };
      });
      setMfgMaterialsForm(mfgMaterialsForm);
    }
    setShowMaterialModal(true);
    if (!mfgMaterialsForm[index]) {
      mfgMaterialsForm.push([defaultMaterial]);
    }
    setOperationDataByIndex([...mfgMaterialsForm[index]]);
    setOperationIndex(index);
  };

  const handleCloseOperationModal = (formData, rowIndex, type) => {
    if (formData && rowIndex >= 0) {
      const updateFormData = type !== 'close' ? formData : mfgMaterialsForm[rowIndex];
      mfgMaterialsForm[rowIndex] = [...updateFormData];
    }
    setShowMaterialModal(false);
  };

  const handleProcessChange = (e, index) => {
    const { name, value } = e.target;
    let updatedProducts = mfgProcessForm.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: value };
      return product;
    });
    setMfgProcessForm(updatedProducts);
    //  dispatch(setBOMItemsData(updatedProducts));
  };

  const handleTimeChange = async (e, index, name) => {
    //  const { name, value } = e.target;
    let updatedProducts = mfgProcessForm.map((product, i) => {
      if (i !== index) return product;

      product = { ...product, [name]: e };
      return product;
    });
    setMfgProcessForm(updatedProducts);
  };

  const handleAddFile = (event) => {
    if (event) {
      woFiles.push(event);
      setWoForm({
        ...woForm,
        files: woFiles,
      });
    }
  };

  const handleRemoveFile = (index) => {
    if (index) {
      const filteredFile = woFiles.filter((fi, i) => i !== index);
      setWoForm({
        ...woForm,
        files: filteredFile,
      });
    }
  };

  const getValidatedFormResult = () => {
    let valid = true;
    if (
      !woForm?.workOrderNumber?.trim() ||
      !woForm?.lvBomId?.value ||
      !woForm?.lvRoutingId?.value ||
      !woForm?.lvWorkerUserId?.value ||
      !woForm?.lvLocationIdForProduce?.value ||
      !woForm?.actualStartDate?.isValid() ||
      !woForm?.actualEndDate?.isValid()
    ) {
      return false;
    }
    mfgProcessForm?.map((mfgProcessForm) => {
      if (!mfgProcessForm?.startTime || !mfgProcessForm?.endTime) {
        valid = false;
        return false;
      }
    });
    if (mfgMaterialsForm?.length) {
      if (!mfgMaterialsForm.flat()?.filter((it) => it.lvMaterialId && it.quantity)?.length) {
        valid = false;
      }
    } else {
      valid = false;
    }
    if (!valid) {
      return false;
    }
    return true;
  };

  const handleSaveWorkOrder = async (isSubmit) => {
    const finalMfgProcessForm = mfgProcessForm?.map((mp, index) => ({
      ...mp,
      materials: mfgMaterialsForm?.length
        ? mfgMaterialsForm[index]
            ?.map((mfg) => ({
              ...mfg,
              processMaterialId: mfg?.processMaterialId || crypto.randomUUID(),
            }))
            ?.filter((mfg) => mfg?.materialId)
        : [],
    }));

    const reqObj = {
      ...woForm,
      manufacturingDetails: {
        ...manufacturingForm,
        manufacturingProcess: finalMfgProcessForm?.length ? [...finalMfgProcessForm] : [],
      },
    };
    if (!isSubmit) {
      setSaveLoader(true);
    }
    const response = await api.put(`${operationsEndpoint}/work-orders/${workorderId}`, reqObj);
    if (!isSubmit) {
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.workorderSaved}`,
      });
    }
    if (isSubmit) {
      const submitObj = {
        locationId: woForm?.locationIdForProduce,
        status: WorkOrderStatusValue?.Completed,
      };
      setSubmitLoader(true);
      const response = await api.put(`${operationsEndpoint}/work-orders/${workorderId}/status`, submitObj);
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: `${Messages.workOrderSubmitted}`,
      });
      setSubmitLoader(false);
    }
    fetchWorkOrdersById(workorderId);
    setSaveLoader(false);
  };

  const handleLocationChange = (newValue) => {
    if (newValue) {
      const locationObj = {
        lvLocationIdForProduce: newValue,
        locationIdForProduce: newValue?.value,
      };
      setWoForm({ ...woForm, ...locationObj });
    }
  };

  const handleMenuItemClicked = async (event) => {
    if (event > 0) {
      try {
        const reqObj = {
          status: Number(event),
        };
        await api.put(`${operationsEndpoint}/work-orders/${workorderId}/status`, reqObj);
        fetchWorkOrdersById();
        setAlertData({
          ...alertData,
          open: true,
          type: AlertStatus.Success,
          message: `${Messages.woCancelled} `,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleQualityCheck = (e) => {
    const { name, checked } = e.target;
    setWoForm({
      ...woForm,
      [name]: checked,
    });
  };

  const handleViewMaterial = () => {
    setIsBomModalOpen(true);
    setViewBOMModal(true);
  };

  const handleNavigateBack = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('itemId')) {
      navigate(`/items`, { state: { itemId: searchParams.get('itemId'), itemTab: searchParams.get('itemTab') } });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CreateItemModal />
      <UpsertMaterialModal
        showMaterialsModal={showMaterialModal}
        handleCloseMaterialsModal={handleCloseOperationModal}
        materialsDataForBinding={operationDataByIndex}
        materialIndex={operationIndex}
        productItemOptions={productLocationOptions}
        handleViewMaterial={handleViewMaterial}
        materials={materials}
        woForm={woForm}
      ></UpsertMaterialModal>
      <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      <Dialog
        open={isRouteModalOpen}
        PaperProps={{
          style: {
            height: '75vh', // Set the maximum height
            minWidth: '70vw', // Set the width
          },
        }}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            <Typography variant="h6" gutterBottom className="m-0 p-0">
              {woForm?.routingId ? 'Edit Route' : 'Add Route'}
            </Typography>
            <CloseIcon
              className="cursor-pointer ml-2"
              onClick={() => {
                setIsRouteModalOpen(false);
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="pt-2 adj-container">
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField name="name" label="Name *" variant="outlined" error={!routingForm.name?.trim() && routingForm.submitted} value={routingForm.name} onChange={handleRouteInputChange} />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h6" gutterBottom className="mb-0 mt-0">
                Routing Steps
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TableContainer size="small" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="ps-2" width="10%">
                        Step Number*
                      </TableCell>
                      <TableCell width="20%">Machine Name*</TableCell>
                      <TableCell width="20%">
                        Estimated Time * <span className="fs-12">(minutes)</span>
                      </TableCell>
                      <TableCell width="47%">Step Details</TableCell>
                      <TableCell align="center" width="3%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routingSteps?.map((row, routeIndex) => (
                      <TableRow key={routeIndex}>
                        <TableCell width="10%">
                          <div className="pt-3 pb-1 ps-2 d-flex">
                            <TextField type="number" id="quantity" value={routeIndex + 1} name="quantity" variant="outlined" disabled={true} />
                            <span className="p-1 mt-1">{row?.unit}</span>
                          </div>
                        </TableCell>
                        <TableCell width="20%" className="position-relative">
                          <div className="pt-3 pb-1 ps-3">
                            <TextField
                              id="machineName"
                              value={row?.machineName}
                              name="machineName"
                              variant="outlined"
                              onChange={(e) => handleRoutingStepsChange(e, routeIndex)}
                              InputProps={{
                                inputProps: {
                                  style: { textAlign: 'right' },
                                },
                              }}
                              error={!row?.machineName && routingForm.submitted}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="left" width="20%">
                          <div className="pt-3 pb-1">
                            <TextField
                              type="number"
                              value={row?.estimatedTime}
                              name="estimatedTime"
                              variant="outlined"
                              onChange={(e) => handleRoutingStepsChange(e, routeIndex)}
                              error={!row?.estimatedTime && routingForm.submitted}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="left" width="47%">
                          <div className="pt-3 pb-1">
                            <TextField value={row?.stepDescription} name="stepDescription" variant="outlined" multiline rows={2} onChange={(e) => handleRoutingStepsChange(e, routeIndex)} />
                          </div>
                        </TableCell>
                        <TableCell align="center" width="3%">
                          {routingSteps?.length > 1 && !woForm?.routingId ? (
                            <BootstrapTooltip title="Delete">
                              <IconButton className="pt-3 pb-1" color="info" onClick={() => handleRemoveRoutingSteps(routeIndex)}>
                                <Delete />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="d-flex">
                  <BootstrapTooltip title="Add Routing">
                    <Button size="small" className="my-1 ms-1" variant="outlined" startIcon={<AddIcon />} disabled={woForm?.routingId} onClick={handleAddRoutingSteps}>
                      Add Routing Steps
                    </Button>
                  </BootstrapTooltip>
                  <BootstrapTooltip title={submitRouteTooltip}>
                    <InfoOutlinedIcon className="mx-1 mt-2 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
                  </BootstrapTooltip>
                </div>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleRoutingSaveOrder} disabled={routingLoader}>
            Submit
          </Button>
          <BootstrapTooltip title={submitRouteTooltip}>
            <InfoOutlinedIcon className="me-2 mt-1 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
          </BootstrapTooltip>
          {routingLoader && <CircularProgress size={18} className="ms-2 mt-3" />}
        </DialogActions>
      </Dialog>

      <Dialog
        open={isBomModalOpen}
        PaperProps={{
          style: {
            height: '70vh', // Set the maximum height
            minWidth: '70vw', // Set the width
          },
        }}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            <Typography variant="h6" gutterBottom className="m-0 p-0">
              {!viewBOMModal ? (woForm?.bomId ? 'Edit Bill of materials' : 'Add Bill of materials') : 'View Bill of Materials'}
            </Typography>
            <CloseIcon
              className="cursor-pointer ml-2"
              onClick={() => {
                setIsBomModalOpen(false);
                setViewBOMModal(false);
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="pt-2 adj-container">
          <Grid container spacing={2} className={viewBOMModal ? 'item-disabled' : ''}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                name="name"
                label="Name *"
                variant="outlined"
                error={!bomForm.name?.trim() && bomForm.submitted}
                //    helperText={!bomForm.name?.trim() && bomForm.submitted ? 'BOM Name is required.' : ''}
                value={bomForm.name || ''}
                onChange={handleBOMInputChange}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h6" gutterBottom className="mb-0">
                List of Materials
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TableContainer size="small" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="ps-2" width="40%">
                        Item Name*
                      </TableCell>
                      <TableCell align="right" width="17%">
                        Quantity (%)
                      </TableCell>
                      <TableCell align="right" width="10%">
                        Quantity
                      </TableCell>
                      <TableCell width="40%">Notes</TableCell>
                      <TableCell align="center" width="3%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materials?.map((row, productIndex) => (
                      <TableRow key={productIndex}>
                        <TableCell width="40%">
                          <div className="pt-3 pb-1 ps-2">
                            <CustomAutocomplete
                              value={row?.lvItemId || ''}
                              onChange={(e, newValue) => handleMaterialItemChange(newValue, productIndex)}
                              disabledValues={materials?.map((pitem) => pitem?.itemId)?.filter((dis) => dis !== row?.itemId)}
                              options={productItemOptions || []}
                              placeholder="Search..."
                              getOptionLabel={(option) => option.label || ''}
                              isError={!row?.lvItemId?.value && bomForm.submitted}
                              // helperText={!row?.lvProductId?.value && bomForm.submitted ? 'Item is required.' : ''}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right" width="17%" className="position-relative">
                          <div className="pt-3 pb-1 ps-3 d-flex">
                            <TextField
                              type="number"
                              id="quantity"
                              value={row?.quantity}
                              name="quantity"
                              variant="outlined"
                              onChange={(e) => {
                                const value = parseInt(e.target.value) || 0;
                                if (value >= 0 && value <= 100) {
                                  handleMaterialProductChange(e, productIndex);
                                }
                              }}
                              InputProps={{
                                endAdornment: <Typography className="ml-2">%</Typography>,
                                inputProps: {
                                  min: 0,
                                  max: 100,
                                  style: { textAlign: 'right' },
                                },
                              }}
                              error={!row?.quantity && bomForm.submitted}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {
                            <>
                              {woForm?.quantity * (row?.quantity / 100)?.toFixed(2)}
                              <span className="mx-2">{row?.unit} </span>
                            </>
                          }{' '}
                        </TableCell>
                        <TableCell align="left" width="40%">
                          <div className="pt-3 pb-1">
                            <TextField
                              value={row?.notes}
                              name="notes"
                              variant="outlined"
                              multiline
                              rows={2}
                              onChange={(e) => handleMaterialProductChange(e, productIndex)}
                              //error={!row?.unit && bomForm.submitted}
                              // helperText={!row?.quantity && bomForm.submitted ? 'Quantity is required.' : ''}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center" width="3%">
                          {materials?.length > 1 ? (
                            <BootstrapTooltip title="Delete">
                              <IconButton className="pt-3 pb-1" color="info" onClick={() => handleMaterialRemoveProduct(productIndex)}>
                                <Delete />
                              </IconButton>
                            </BootstrapTooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <BootstrapTooltip title="Add Material">
                    <Button size="small" className="my-2 ms-2" variant="outlined" startIcon={<AddIcon />} onClick={handleMaterialAddProduct}>
                      Add Material
                    </Button>
                  </BootstrapTooltip>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleBOMSaveOrder} disabled={bomLoader || viewBOMModal}>
            Submit
          </Button>
          {bomLoader && <CircularProgress size={18} className="ms-2 mt-3" />}
        </DialogActions>
      </Dialog>
      {/* </Popover> */}
      <div className="d-flex flex-grow-1 flex-column p-4 adj-container">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <CustomBreadCrumb breadcrumbList={breadcrumbs} />
            <div className="d-flex">
              {workorderId ? <BasicMenu menuItems={getWOKebabMenuActions(woForm?.workOrderStatusStr)} itemClicked={(e, item) => handleMenuItemClicked(e, item)} /> : ''}
              <CloseIcon
                className="cursor-pointer mt-2"
                onClick={() => {
                  handleNavigateBack();
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress className="mx-2" size={15} />
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex ">
                    <div className="d-flex">
                      <Typography variant="h6" className="d-flex mb-0" gutterBottom>
                        {woForm?.product?.productName} ({woForm?.quantity} {woForm?.product?.unit})
                      </Typography>
                      <span className="mx-4">{getBadgeVariant(mapWorkOrderStatus(woForm?.workOrderStatus))}</span>
                    </div>
                    <div className="divider">
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (woForm?.salesTransactions?.transactionId) {
                              navigate(`/sales/${woForm?.salesTransactions?.transactionId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {woForm?.orderReferenceNumber}
                        </Typography>
                      </div>
                      <div className="mt-1">
                        <Typography
                          variant="body2"
                          component="a"
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            if (woForm?.tenantId) {
                              navigate(`/accounts/${woForm?.tenantId}`);
                            }
                          }}
                          sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
                        >
                          {woForm?.accountReferenceNumber}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <span className="error-red ">*</span> Required Fields
            </div>
          </div>
        </div>
        <Grid container spacing={2} className="mt-1">
          <Grid item sm={12} md={6} xs={12}>
            <TextField
              id="workOrderNumber"
              name="workOrderNumber"
              label="Work Order Number *"
              variant="outlined"
              error={!woForm.workOrderNumber?.trim() && woForm.submitted}
              value={woForm.workOrderNumber || ''}
              onChange={handleWOControlChange}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <TextField id="priority" name="priority" label="Priority" variant="outlined" value={woForm.priority || 0} onChange={handleWOControlChange} />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="position-relative">
            <div className="d-flex">
              <CustomAutocomplete
                className="d-flex flex-grow-1"
                value={woForm?.lvBomId || ''}
                inputLabel="Bill Of Materials Name *"
                onChange={(e, newValue) => handleBOMChange(e, newValue)}
                options={bomOptions || []}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
                isError={!woForm.lvBomId?.value && woForm.submitted}
              />
              {woForm?.bomId ? (
                <BootstrapTooltip title="Edit BOM">
                  <ModeEditIcon className="edit-wo-icon mx-1 edit-wo-icon" onClick={() => handleEditBomDetail(true)} />
                </BootstrapTooltip>
              ) : null}
              <BootstrapTooltip title="A bill of materials (BOM) is an extensive list of raw materials, components, and instructions required to construct, manufacture, or repair a product or service.">
                <InfoOutlinedIcon className="mx-1 mt-2 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
              </BootstrapTooltip>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="position-relative">
            <div className="d-flex">
              <CustomAutocomplete
                className="d-flex flex-grow-1"
                value={woForm?.lvRoutingId || ''}
                inputLabel="Route Name *"
                onChange={(e, newValue) => handleRouteChange(e, newValue)}
                options={routingOptions || []}
                placeholder="Search..."
                getOptionLabel={(option) => option.label || ''}
                isError={!woForm.lvRoutingId?.value && woForm.submitted}
              />
              {woForm?.routingId ? (
                <BootstrapTooltip title="Edit Route">
                  <ModeEditIcon className="edit-wo-icon mx-1 edit-wo-icon" onClick={openRoutingModal} />
                </BootstrapTooltip>
              ) : null}
              <BootstrapTooltip title="Routes are a sequential list of steps that detail the order in which tasks should be executed.">
                <InfoOutlinedIcon className="mx-1 mt-2 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
              </BootstrapTooltip>
            </div>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Planned Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={woForm.plannedStartDate}
                format="DD/MM/YYYY"
                name="plannedStartDate"
                onChange={(e) =>
                  setWoForm({
                    ...woForm,
                    plannedStartDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <Typography className="label">Planned End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={woForm.plannedEndDate}
                format="DD/MM/YYYY"
                name="plannedEndDate"
                onChange={(e) =>
                  setWoForm({
                    ...woForm,
                    plannedEndDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={woForm?.lvWorkerUserId || ''}
              onChange={(event, newValue) => handleUserChange(newValue)}
              options={userOptions || []}
              inputLabel="Assigned User *"
              isError={!woForm?.lvWorkerUserId && woForm?.submitted}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12}>
            <CustomAutocomplete
              value={woForm?.lvLocationIdForProduce || ''}
              onChange={(e, newValue) => handleLocationChange(newValue)}
              disableAddNew={true}
              inputLabel="Location *"
              options={locationByItemOptions || []}
              placeholder="Search..."
              getOptionLabel={(option) => option.label || ''}
              isError={!woForm?.lvLocationIdForProduce && woForm?.submitted}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} xs={12} className="mt-3 mb-2">
          <Typography variant="h6" className="d-flex mb-0" gutterBottom>
            Processing Details
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <TableContainer style={{ maxWidth: '95%' }} size="small" component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ position: 'sticky', left: 0, zIndex: 1, background: 'white' }} width="5%">
                      Step No.
                    </TableCell>
                    <TableCell style={{ position: 'sticky', left: '5%', zIndex: 1, background: 'white' }} width="10%">
                      Name
                    </TableCell>
                    <TableCell style={{ position: 'sticky', left: '12%', zIndex: 1, background: 'white' }} width="10%">
                      Estimated Time
                    </TableCell>
                    {/* style={{ position: 'sticky', left: '20%', zIndex: 1, background: 'white' }} */}
                    <TableCell width="15%">Step Details</TableCell>
                    {/* style={{ position: 'sticky', left: '27%', zIndex: 1, background: 'white' }} */}
                    <TableCell width="25%">Notes</TableCell>
                    <TableCell width="15%">Start Time*</TableCell>
                    <TableCell width="15%">End Time *</TableCell>
                    <TableCell width="7%">
                      <div className="d-flex">
                        Materials*
                        <BootstrapTooltip title="User must need to add atleast one material to complete the order">
                          <InfoOutlinedIcon className="mx-1 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
                        </BootstrapTooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mfgProcessForm?.length ? (
                    mfgProcessForm?.map((row, mfgProcessIndex) => (
                      <TableRow key={mfgProcessIndex}>
                        <TableCell style={{ position: 'sticky', left: 0, zIndex: 1, background: 'white' }} width="5%">
                          {mfgProcessIndex + 1}
                        </TableCell>
                        <TableCell style={{ position: 'sticky', left: '5%', zIndex: 1, background: 'white' }} width="10%">
                          {row?.machineName}
                        </TableCell>
                        <TableCell style={{ position: 'sticky', left: '12%', zIndex: 1, background: 'white' }} width="10%">
                          {row?.estimatedTime} Min
                        </TableCell>
                        {/* style={{ position: 'sticky', left: '20%', zIndex: 1, background: 'white' }}  */}
                        <TableCell width="10%">{row?.stepDescription}</TableCell>
                        {/* style={{ position: 'sticky', left: '27%', zIndex: 1, background: 'white' }}  */}
                        <TableCell width="30%">
                          <TextField value={row?.notes} name="notes" variant="outlined" multiline rows={2} onChange={(e) => handleProcessChange(e, mfgProcessIndex)} />
                        </TableCell>
                        <TableCell width="15%">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker']}>
                              <DateTimePicker
                                value={row.startTime}
                                format="DD/MM/YYYY hh:mm a"
                                name="startTime"
                                onChange={(e) => {
                                  console.log(e);
                                  handleTimeChange(e, mfgProcessIndex, 'startTime');
                                }}
                                slotProps={{
                                  textField: {
                                    variant: 'outlined',
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell align="left" width="15%">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker']}>
                              <DateTimePicker
                                value={row.endTime}
                                format="DD/MM/YYYY hh:mm a"
                                name="endTime"
                                onChange={(e) => {
                                  handleTimeChange(e, mfgProcessIndex, 'endTime');
                                }}
                                slotProps={{
                                  textField: {
                                    variant: 'outlined',
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell align="left" width="7%">
                          <div className="d-flex operations-chip-container">
                            {MfgChips?.map((item, opIndex) => (
                              <>
                                {item?.shouldDisplay ? (
                                  <BootstrapTooltip
                                    title={`${
                                      mfgMaterialsForm[mfgProcessIndex]?.length && mfgMaterialsForm[mfgProcessIndex][0]?.materialId && mfgMaterialsForm[mfgProcessIndex][0]?.quantity > 0
                                        ? 'Deselect'
                                        : 'Select'
                                    } ${item.label}`}
                                  >
                                    <Chip
                                      key={opIndex}
                                      className="mx-2 mt-2"
                                      onClick={() => {
                                        handleOperationClick(item, mfgProcessIndex);
                                      }}
                                      label={`${item.label}`}
                                      variant={
                                        mfgMaterialsForm[mfgProcessIndex]?.length && mfgMaterialsForm[mfgProcessIndex][0]?.materialId && mfgMaterialsForm[mfgProcessIndex][0]?.quantity > 0
                                          ? ''
                                          : 'outlined'
                                      }
                                      color="primary"
                                      clickable
                                    />
                                  </BootstrapTooltip>
                                ) : null}
                              </>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <div className="justify-center m-2 fs-14 error-red">Processing Details are mandatory to complete the work order, Please select a Route.</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} md={12} xs={12}>
            <Typography variant="h6" className="d-flex mb-0" gutterBottom>
              Quality Check
            </Typography>
          </Grid>
          {!woForm?.qualityCheckOrderId ? (
            <>
              <Grid item sm={12} md={6} xs={12} className="d-flex">
                <TextField
                  type="number"
                  id="qualityCheckItemQuantity"
                  value={woForm?.qualityCheckItemQuantity}
                  label="Quality Check Item Quantity"
                  name="qualityCheckItemQuantity"
                  variant="outlined"
                  onChange={handleWOControlChange}
                  InputProps={{
                    endAdornment: <Typography className="ml-2">{woForm?.product?.unit}</Typography>,
                    inputProps: {
                      style: { textAlign: 'right' },
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12} md={3} xs={12}>
                <div className="d-flex">
                  <FormControlLabel
                    className="ms-3"
                    control={<Switch name="qualityCheckOrderCreated" inputProps={{ 'aria-label': 'controlled' }} />}
                    label="Create Quality Check Order"
                    checked={woForm?.qualityCheckOrderCreated}
                    onChange={handleQualityCheck}
                  />
                </div>
              </Grid>
            </>
          ) : (
            // <div className="d-flex p-1">
            //   <div className='mx-2'>
            //     <Typography
            //       variant="body2"
            //       component="a"
            //       href="#"
            //       onClick={(event) => {
            //         event.preventDefault();
            //         navigate(`/qualitycontrol/${qcForm?.qualityCheckId}`);
            //       }}
            //       sx={{ color: '#0078d4', cursor: 'pointer', fontSize: '0.875rem' }}
            //     >
            //       {qcForm?.qualityCheckId}
            //     </Typography>
            //   </div>
            //   <div className="mx-2">Quality Status : {getBadgeVariant(qcForm?.status)}</div>
            //   <div className="mx-2">Quality Check :{qcForm?.hasOwnProperty('passedCheck') ? <>{qcForm?.passedCheck ? getBadgeVariant('Passed') : getBadgeVariant('Failed')}</> : ''}</div>
            // </div>
            <Box
              className="mx-2"
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
              }}
            >
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="flex-start">
                <Typography
                  component="a"
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/qualitycontrol/${qcForm?.qualityCheckId}`);
                  }}
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    fontWeight: 'medium',
                  }}
                >
                  ID: {qcForm?.qualityCheckId}
                </Typography>

                <Stack direction="row" spacing={2} alignItems="center">
                  <Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Status
                    </Typography>
                    {getBadgeVariant(qcForm?.status)}
                  </Box>

                  {qcForm?.hasOwnProperty('passedCheck') && (
                    <Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Quality Check
                      </Typography>
                      {getBadgeVariant(qcForm?.passedCheck ? 'Passed' : 'Failed')}
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
          <Grid item sm={12} md={12} xs={12}>
            <Typography variant="h6" className="d-flex mb-0" gutterBottom>
              Output Quantity
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} xs={12} className="d-flex">
            <TextField
              type="number"
              id="outputQuantity"
              value={manufacturingForm?.outputQuantity}
              label="Output Quantity"
              name="outputQuantity"
              variant="outlined"
              onChange={handleManufecturingChange}
              error={woForm?.quantity > manufacturingForm?.outputQuantity}
              // helperText={woForm?.quantity > manufacturingForm?.outputQuantity ? 'Output quantity is lesser than ordered quantity.' : ''}
              InputProps={{
                endAdornment: <Typography className="ml-2">{woForm?.product?.unit}</Typography>,
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
            />
          </Grid>
          <Grid item sm={12} md={6} xs={12} className="d-flex">
            <TextField
              type="number"
              id="scrapQuantity"
              value={manufacturingForm?.scrapQuantity}
              label="Scrap Quantity"
              name="scrapQuantity"
              variant="outlined"
              onChange={handleManufecturingChange}
              InputProps={{
                endAdornment: <Typography className="ml-2">{woForm?.product?.unit}</Typography>,
                inputProps: {
                  style: { textAlign: 'right' },
                },
              }}
            />
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Typography className="label">Actual Start Date *</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={woForm.actualStartDate}
                format="DD/MM/YYYY"
                name="actualStartDate"
                onChange={(e) =>
                  setWoForm({
                    ...woForm,
                    actualStartDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: !woForm?.actualStartDate?.isValid() && woForm?.submitted,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Typography className="label">Actual End Date *</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={woForm.actualEndDate}
                format="DD/MM/YYYY"
                name="actualEndDate"
                onChange={(e) =>
                  setWoForm({
                    ...woForm,
                    actualEndDate: e,
                  })
                }
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: !woForm?.actualEndDate?.isValid() && woForm?.submitted,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Typography className="label">Files</Typography>
            <MultiFileUpload listOfFiles={woForm?.files?.length > 0 ? woForm?.files : ''} addFile={handleAddFile} removeFile={handleRemoveFile} />
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <TextField name="remarks" label="Remarks" multiline rows={2} variant="outlined" value={woForm?.remarks || ''} onChange={handleWOControlChange} />
          </Grid>
        </Grid>
        <div className="d-flex justify-content-start mt-3">
          <Button
            size="small"
            variant="contained"
            onClick={() => handleSaveWorkOrder(false)}
            disabled={saveLoader || [WorkOrderStatus?.Completed, WorkOrderStatus.Cancelled].includes(woForm?.workOrderStatusStr)}
          >
            Save
          </Button>
          {saveLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          <Button
            size="small"
            variant="contained"
            className="ms-3"
            onClick={() => handleSaveWorkOrder(true)}
            disabled={!getValidatedFormResult() || [WorkOrderStatus?.Completed, WorkOrderStatus.Cancelled].includes(woForm?.workOrderStatusStr) || submitLoader}
          >
            Submit
          </Button>
          {submitLoader && <CircularProgress size={18} className="ms-2 mt-2" />}
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress className="mx-2" size={15} />
            </div>
          ) : (
            renderLastModifiedData(woForm?.modifiedByUser?.firstName, woForm?.modifiedByUser?.lastName, woForm?.modifiedByUser?.lastModifiedAt)
          )}
        </div>
      </div>
    </>
  );
};

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BootstrapTooltip } from '../common/commonUtility';
export const CreateOperationsModal = React.memo(({ showOperationModal, handleCloseOperationModal, operationData, rowIndex }) => {
  const theme = useTheme();
  const [operationsModalForm, setOperationModalForm] = useState({
    isCreateOperation: false,
    quantity: '',
    notes: '',
    operationTitle: '',
    submitted: false,
  });
  const [operationName, setOperationName] = useState('');

  useEffect(() => {
    setOperationModalForm(operationData);
    setOperationName(operationData?.operationTitle?.toLowerCase().includes('order') ? operationData?.operationTitle : operationData?.operationTitle + ' order');
  }, [operationData]);

  const getValidatedForm = () => {
    if (operationName?.toLocaleLowerCase().includes('work') && operationsModalForm?.isCreateOperation && !operationsModalForm?.quantity) {
      return false;
    }
    return true;
  };

  const handleSubmitOperations = () => {
    setOperationModalForm({
      ...operationsModalForm,
      submitted: true,
    });
    if (getValidatedForm()) {
      handleCloseOperationModal(operationsModalForm, rowIndex);
    }
  };

  const tooltipContent = (
    <Box
      sx={{
        '& ul': { margin: 0, paddingLeft: 2, paddingBottom: 2 },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      }}
    >
      <Typography className="p-2 fs-14">When Create {operationName} checkbox is checked :</Typography>
      <ul>
        <li>A new {operationName} will be automatically generated when the order is approved by Admin.</li>
        <li>The record will be reflected in operations tab of {operationName}. </li>
        <li>User will receive a confirmation email with the {operationName} details.</li>
      </ul>
    </Box>
  );
  return (
    <Dialog
      open={showOperationModal}
      PaperProps={{
        style: {
          height: '50vh', // Set the maximum height
          width: '50vw', // Set the width
        },
      }}
    >
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <Typography variant="h6" gutterBottom>
            {operationsModalForm?.operationTitle}
          </Typography>
          <CloseIcon className="cursor-pointer ml-2" onClick={() => handleCloseOperationModal(operationsModalForm, rowIndex, 'close')} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={12} xs={12}>
            <div className="d-flex">
              <Checkbox
                size="small"
                name="shippingCost"
                checked={operationsModalForm?.isCreateOperation || false}
                onChange={(e) => {
                  setOperationModalForm({
                    ...operationsModalForm,
                    isCreateOperation: e.target?.checked,
                  });
                }}
              />
              <Typography className="mt-2"> Create {operationName} </Typography>
              <BootstrapTooltip title={tooltipContent}>
                <InfoOutlinedIcon className="ms-2 mt-2 cursor-pointer" sx={{ fontSize: 20 }}></InfoOutlinedIcon>
              </BootstrapTooltip>
            </div>
          </Grid>
          {operationName?.toLocaleLowerCase().includes('work') && operationsModalForm?.isCreateOperation && (
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                type="number"
                id="quantity"
                name="quantity"
                label="Work Order Quantity"
                variant="outlined"
                value={operationsModalForm?.quantity || ''}
                error={operationName?.toLocaleLowerCase().includes('work') && operationsModalForm?.isCreateOperation && !operationsModalForm?.quantity && operationsModalForm?.submitted}
                onChange={(e) => {
                  setOperationModalForm({
                    ...operationsModalForm,
                    quantity: e.target?.value,
                  });
                }}
              />
              {operationsModalForm?.quantity > operationsModalForm?.stockOnHand && (
                <p style={{ color: 'red' }}>
                  <i>
                    <b>Warning:</b>
                    {operationName} Quantity value exceeds from stock on hand
                  </i>
                </p>
              )}
            </Grid>
          )}
          <Grid item sm={12} md={12} xs={12}>
            <TextField
              id="note"
              name="note"
              label="Note"
              variant="outlined"
              multiline
              rows={2}
              value={operationsModalForm?.notes || ''}
              onChange={(e) => {
                setOperationModalForm({
                  ...operationsModalForm,
                  notes: e.target?.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" className="mt-2 mx-3 my-2" variant="contained" onClick={handleSubmitOperations}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
});

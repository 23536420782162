import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { API_DOCUMENTS_ENDPOINT, API_PRODUCTS_ENDPOINT, API_WORKFLOW_BASE_URL } from '../../../../../constants/constants';
import api from '../../../../common/api';
import { formatDate, formatProductForSales } from '../../../../common/commonUtility';
import { setProductsData } from '../../../../redux/actions/sales-action';
import { useToken } from '../../../../auth/useToken';
import axios from 'axios';

export default function EditInvoice() {
  const dispatch = useDispatch();
  const productEndpoint = API_PRODUCTS_ENDPOINT;
  const { ddlProducts, salesData, editProductData, editOperationData } = useSelector((state) => state?.sales?.data);
  const [productItemOptions, setProductItemOptions] = useState([]);
  const contentRef = useRef(null);
  const emptyTaxObj = { taxName: '', taxPercentage: 0, taxAmount: 0 };
  const tableRowStyle = {
    '&:last-child td, &:last-child th': { border: 0 },
    // Target the first TableCell
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: '16px !important', // Adjust this value as needed
    },
  };
  const defaultProduct = {
    orderItemId: crypto.randomUUID(),
    productId: '00000000-0000-0000-0000-000000000000',
    lvProductId: '',
    quantity: 0,
    unit: '',
    rate: 0,
    amount: 0,
  };
  const [products, setProducts] = useState([defaultProduct]);
  const [extraCost, setExtraCost] = useState({
    tax: 0,
    shippingCharges: 0,
    discount: 0,
    // Add more extra cost details as needed
  });
  const [taxDetails, setTaxDetails] = useState([emptyTaxObj]);
  const [currencyUnit, setCurrencyUnit] = useState();
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [orderSubmitLoading, setOrderSubmitLoading] = useState(false);

  const subTotalProductAmount = products.reduce((total, product) => total + product.amount, 0);
  const calculateTaxAmount = (totalAmount, taxPercentage) => {
    if (!isNaN(taxPercentage) && !isNaN(totalAmount)) {
      return (totalAmount * taxPercentage) / 100;
    }
    return 0;
  };
  const discountedSubTotal = subTotalProductAmount * (1 - extraCost.discount / 100);
  const totalTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
  const updatedTotalAmount = discountedSubTotal + totalTaxAmount + Number(extraCost.shippingCharges);

  const [totalAmount, setTotalAmount] = useState(updatedTotalAmount);
  let formatedProductsOptions = [];

  useEffect(() => {
    if (!ddlProducts) {
      fetchProducts();
    } else {
      setProductItemOptions(ddlProducts);
    }
  }, []);

  useEffect(() => {
    if (salesData?.orderItems && productItemOptions?.length) {
      setCurrencyUnit(salesData?.currency);
      let prepareOperations = [];
      let prepareProducts = [];

      if (!editOperationData && !editProductData) {
        salesData?.orderItems?.forEach((sd, index) => {
          const filterProduct = productItemOptions?.find((pi) => pi?.value === sd?.productId);
          const productObj = {
            orderItemId: sd?.orderItemId,
            productId: sd?.productId,
            lvProductId: { label: filterProduct?.formattedObject?.selectedLabelForSales, value: filterProduct?.value },
            productName: sd?.productName,
            quantity: sd?.quantity,
            rate: sd?.rate,
            unit: sd?.unit,
            stepperVisualization: sd?.stepperVisualization,
            amount: parseFloat(sd?.rate) * parseFloat(sd?.quantity),
          };
          prepareProducts.push(productObj);
        });
      } else {
        prepareOperations = editOperationData;
        prepareProducts = [...editProductData];
      }
      setProducts(prepareProducts);
      if (salesData?.taxInfo?.length) {
        setShowTaxDetails(true);
        setTaxDetails(salesData?.taxInfo);
      }

      if (salesData?.discount) {
        setShowDiscount(true);
        setDiscountAmount(salesData?.discounts);
      }
      if (salesData?.shippingCharges || salesData?.discount) {
        setShowShipping(true);
        setExtraCost({
          ...extraCost,
          discount: salesData?.discount,
          shippingCharges: salesData?.shippingCharges,
        });
      }
    }
  }, [productItemOptions]);

  useEffect(() => {
    const updatedTaxAmount = taxDetails?.reduce((total, tax) => total + calculateTaxAmount(discountedSubTotal, tax.taxPercentage), 0);
    const updatedShippingCost = Number(extraCost.shippingCharges);
    const discountAmount = subTotalProductAmount * (Number(extraCost.discount) / 100);
    const updatedTotalAmount = discountedSubTotal + updatedTaxAmount + updatedShippingCost;
    setDiscountAmount(discountAmount);
    setExtraCost((prevExtraCost) => ({ ...prevExtraCost, tax: updatedTaxAmount }));
    setTotalAmount(updatedTotalAmount);
  }, [JSON.stringify(products), JSON.stringify(taxDetails), extraCost.shippingCharges, extraCost.discount, discountAmount]);

  const fetchProducts = async () => {
    try {
      const response = await api.get(`${productEndpoint}?pageNumber=1&pageSize=100`);
      formatedProductsOptions = formatProductForSales(response?.data?.products) || [];
      setProductItemOptions(formatedProductsOptions);
      dispatch(setProductsData(formatedProductsOptions));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [token, setToken] = useToken();
  const documentUploadApi = API_WORKFLOW_BASE_URL + API_DOCUMENTS_ENDPOINT;
  const downloadInvoice = () => {
    if (salesData?.invoiceId) {
      axios
        .get(`${documentUploadApi}/${salesData?.invoiceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const docUrl = URL.createObjectURL(response.data);
          window.open(docUrl);
        });
    }
  };

  const generateInvoice = useReactToPrint({ contentRef });
  return (
    <div className="adj-container">
      <Box className="p-1">
        {/* Action Buttons */}
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" color="primary" onClick={generateInvoice}>
            🖨️ Print
          </Button>
          <Button variant="outlined" color="primary" onClick={downloadInvoice}>
            ⬇️ Download
          </Button>
          <Button variant="outlined" color="primary">
            📧 Email Invoice
          </Button>
        </Box>

        {/* Invoice Header */}
        <div className="p-4" ref={contentRef}>
          <Box mb={2}>
            <Typography variant="h6" gutterBottom className="mb-0">
              INVOICE
            </Typography>
            <Typography color="textSecondary">#{salesData?.orderNumber}</Typography>
            <Typography color="textSecondary">Order Date: {salesData?.orderDate ? formatDate(salesData?.orderDate) : null}</Typography>
            {/* <Typography color="textSecondary">Due Date: 19/11/2024</Typography> */}
          </Box>

          {/* Billing and Shipping Information */}
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <Typography variant="h6" gutterBottom className="mb-0">
                Bill To
              </Typography>
              <Typography className="fs-16"> {salesData ? salesData.customer?.name : ''}</Typography>
              <Typography color="textSecondary">
                {salesData ? salesData.customer?.billingAddress?.address : ''}
              </Typography>
              <div className="d-flex fs-12">
                <Typography color="textSecondary">
                  {salesData && salesData.customer?.billingAddress?.city
                    ? ', ' + salesData.customer?.billingAddress?.city
                    : ''}
                </Typography>
                <Typography color="textSecondary">
                  {salesData && salesData.customer?.billingAddress?.countryCode
                    ? ', ' + salesData.customer?.billingAddress?.countryCode
                    : ''}
                </Typography>
                <Typography color="textSecondary">
                  {salesData && salesData.customer?.billingAddress?.postalCode
                    ? ', ' + salesData.customer?.billingAddress?.postalCode
                    : ''}
                </Typography>
              </div>
            </div>
            {salesData && salesData.customer?.shippingAddress?.address ? (
              <div style={{ width: '50%' }}>
                <Typography variant="h6" gutterBottom className="mb-0">
                  Ship To
                </Typography>
                <Typography className="fs-16">{salesData ? salesData.customer?.name : ''}</Typography>
                <div className="d-flex fs-12">
                  <Typography color="textSecondary">
                    {salesData?.deliveryNote?.salesTransactions?.length && salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.city
                      ? ', ' + salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.city
                      : ''}
                  </Typography>
                  <Typography color="textSecondary">
                    {salesData?.deliveryNote?.salesTransactions?.length && salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.countryCode
                      ? ', ' + salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.countryCode
                      : ''}
                  </Typography>
                  <Typography color="textSecondary">
                    {salesData?.deliveryNote?.salesTransactions?.length && salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.postalCode
                      ? ', ' + salesData?.deliveryNote?.salesTransactions[0]?.customer?.shippingAddress?.postalCode
                      : ''}
                  </Typography>
                </div>
              </div>
            ) : (
              <div style={{ width: '50%' }}>
                <Typography variant="h6" gutterBottom className="mb-0">
                  Ship To
                </Typography>
                <Typography className="fs-16"> {salesData ? salesData.customer?.name : ''}</Typography>
                <Typography color="textSecondary">
                  {salesData ? salesData.customer?.billingAddress?.address : ''}
                </Typography>
                <div className="d-flex fs-12">
                  <Typography color="textSecondary">
                    {salesData && salesData.customer?.billingAddress?.city
                      ? ', ' + salesData.customer?.billingAddress?.city
                      : ''}
                  </Typography>
                  <Typography color="textSecondary">
                    {salesData && salesData.customer?.billingAddress?.countryCode
                      ? ', ' + salesData.customer?.billingAddress?.countryCode
                      : ''}
                  </Typography>
                  <Typography color="textSecondary">
                    {salesData && salesData.customer?.billingAddress?.postalCode
                      ? ', ' + salesData.customer?.billingAddress?.postalCode
                      : ''}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <TableContainer component={Paper} className="mt-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px 16px' }}>Item Name</TableCell>
                  <TableCell sx={{ padding: '4px 16px' }}>Quantity</TableCell>
                  <TableCell sx={{ padding: '4px 16px' }}>Rate</TableCell>
                  <TableCell sx={{ padding: '4px 16px' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, productIndex) => (
                  <TableRow key={productIndex} sx={tableRowStyle}>
                    <TableCell className="p-2">
                      {/* <div className="pt-3 pb-1 ps-2"> */}
                      <div>{!salesData.freeTextProductName ? row?.lvProductId?.label : <>{row?.productName}</>}</div>
                    </TableCell>
                    <TableCell className="p-2">
                      <span>
                        {row?.quantity} <span className="mx-1">{row?.unit}</span>
                      </span>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="d-flex">
                        {currencyUnit && (
                          <div className="pe-1">
                            <b>({currencyUnit}) </b>
                          </div>
                        )}
                        <span>{row?.rate}</span>
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div>
                        <span>
                          {currencyUnit && (
                            <span className="pe-1">
                              <b> ({currencyUnit})</b>
                            </span>
                          )}
                          {row.amount}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right" colSpan="3" className="p-2">
                    <b>Subtotal</b>
                  </TableCell>
                  <TableCell className="p-2">
                    <div>
                      {currencyUnit && (
                        <span className="pe-1">
                          <b> ({currencyUnit})</b>
                        </span>
                      )}
                      {subTotalProductAmount}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {showDiscount && (
                  <TableRow>
                    <TableCell align="right" colSpan="3" className="p-2">
                      <b>Discount</b>
                    </TableCell>
                    <TableCell>
                      <div>{showDiscount ? extraCost.discount + '% (Amount: ' + (currencyUnit ? '(' + currencyUnit + ') ' : '') + parseFloat(discountAmount?.toFixed(2)) + ')' : 0} </div>
                    </TableCell>
                  </TableRow>
                )}
                {showShipping && (
                  <TableRow>
                    <TableCell align="right" colSpan="3" className="p-2">
                      <b>Shipping </b>
                    </TableCell>
                    <TableCell className="p-2">
                      <div>
                        {currencyUnit && (
                          <span className="pe-1">
                            <b> ({currencyUnit})</b>
                          </span>
                        )}
                        {showShipping ? extraCost.shippingCharges : 0}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {showTaxDetails && (
                  <TableRow>
                    <TableCell align="right" colSpan="3" className="p-2">
                      <b>Tax Details</b>
                    </TableCell>
                    <TableCell className="p-2">
                      <div>
                        {currencyUnit && (
                          <span className="pe-1">
                            <b> ({currencyUnit})</b>
                          </span>
                        )}
                        {parseFloat(extraCost?.tax?.toFixed(2))}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan="3" align="right" className="p-2">
                    <b>Total Amount</b>
                  </TableCell>
                  <TableCell className="p-2">
                    <div>
                      {currencyUnit && (
                        <span className="pe-1">
                          <b> ({currencyUnit})</b>
                        </span>
                      )}
                      {parseFloat(totalAmount?.toFixed(2))}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {showTaxDetails && (
            <Grid container spacing={4} mt={1}>
              <Grid item xs={12} md={6}>
                <>
                  <Typography variant="h6" gutterBottom>
                    Tax Details
                  </Typography>
                  <TableContainer size="small" component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ padding: '4px 16px' }}>Tax Type</TableCell>
                          <TableCell align="center" sx={{ padding: '4px 16px' }}>
                            Tax Percentage (%)
                          </TableCell>
                          <TableCell sx={{ padding: '4px 16px' }}>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taxDetails.map((taxRow, index) => (
                          <TableRow key={index} sx={tableRowStyle}>
                            <TableCell className="p-2">{taxRow.taxName}</TableCell>
                            <TableCell align="center" className="p-2">
                              {taxRow.taxPercentage}
                            </TableCell>
                            <TableCell className="p-2">
                              {currencyUnit && (
                                <span className="pe-1">
                                  <b> ({currencyUnit})</b>
                                </span>
                              )}
                              {parseFloat(calculateTaxAmount(discountedSubTotal, taxRow.taxPercentage)?.toFixed(2))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </Grid>
            </Grid>
          )}

          {/* Additional Information */}
          <div style={{ width: '100%', display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '50%' }}>
              {salesData?.deliveryNote?.salesTransactions?.length && salesData?.deliveryNote?.salesTransactions[0]?.paymentTerms ? (
                <Typography color="textSecondary" variant="body2" className="p-1">
                  <> Payment Terms: {salesData?.deliveryNote?.salesTransactions[0]?.paymentTerms} </>
                </Typography>
              ) : null}
              {salesData?.currency ? (
                <Typography color="textSecondary" variant="body2" className="p-1">
                  Currency: {salesData?.currency}
                </Typography>
              ) : null}
              {salesData?.shipmentDate ? (
                <Typography color="textSecondary" variant="body2" className="p-1">
                  Shipment Date: {formatDate(salesData?.shipmentDate)}
                </Typography>
              ) : null}
            </div>
            <div style={{ width: '50%' }}>
              <Typography color="textSecondary" variant="body2" className="p-1">
                Sales Person: Sharvil Shah
              </Typography>
              {salesData?.createdDate ? (
                <Typography color="textSecondary" variant="body2" className="p-1">
                  Created: {formatDate(salesData?.createdDate)}
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

import { Breadcrumbs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomBreadCrumb = ({ breadcrumbList }) => {
  const navigate = useNavigate();
  const naviagteBreadcrumb = (url) => {
    navigate(url);
  };
  return breadcrumbList?.length > 0 ? (
    <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '0.875rem', marginBottom: '8px' }}>
      {breadcrumbList.map((br, index) => (
        <Typography key={index} color={br.active ? 'textPrimary' : '#3b71ca !important'} component={br.active ? 'span' : 'a'} onClick={() => naviagteBreadcrumb(br.href)} sx={{ fontSize: '0.875rem' }}>
          {br.name}
        </Typography>
      ))}
    </Breadcrumbs>
  ) : null;
};

export default CustomBreadCrumb;

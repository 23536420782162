import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_SALES_LEADS_ENDPOINT } from '../../../constants/constants';
import { Messages } from '../../common-constants/messages';
import { AlertStatus } from '../../common-constants/status';
import CustomAlert from '../../common/alert';
import api from '../../common/api';
import CustomBreadCrumb from '../../common/breadcrumb';
import { getCheckboxResult } from '../../common/commonUtility';
import ConfirmDialog from '../../common/confirm-dialog';
import CustomPagination from '../../common/pagination';
import CustomTable from '../../common/table';
import CustomTableActions from '../../common/table-actions';
import { resetSalesReducer } from '../../redux/actions/leads-action';

const LeadsLanding = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const salesLeadsEndpoint = API_SALES_LEADS_ENDPOINT;
  const itemsPerPage = 6;
  const contactColumn = [
    {
      label: '',
      field: '',
      checkbox: true,
    },
    {
      label: 'Lead Name',
      field: 'leadName',
      link: true,
    },
    {
      label: 'Account Reference Number',
      field: 'referenceNum',
      link: true,
    },
    {
      label: 'Contact',
      field: 'contactUser',
      //avatar: true,
    },
    {
      label: 'Status',
      field: 'status',
      badge: true,
    },
    {
      label: 'Assigned User',
      field: 'assignedUser',
      avatar: true,
    },
  ];
  const breadcrumbs = [
    {
      name: 'Home',
      href: '/dashboard',
    },
    {
      name: 'Leads',
      active: true,
    },
  ];
  const [alertData, setAlertData] = useState({
    open: false,
    type: AlertStatus.Success,
    message: '',
  });
  const [leadsList, setLeadsList] = useState([]);
  const [leadId, setLeadId] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [breadcrumbList] = useState(breadcrumbs);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entityData, setEntityData] = useState([]);
  const [showPrimaryContactModal, setShowPrimaryContactModal] = useState(false);
  const [primaryContactOptions, setPrimaryContactOptions] = useState([]);
  const alertClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  useEffect(() => {
    dispatch(resetSalesReducer());
    fetchLeads();
  }, []);

  const fetchLeads = async (currentPage = 1, search = '', activeCard = 0) => {
    setTableLoader(true);
    const queryParam = search ? `&query=${encodeURIComponent(search)}` : '';
    try {
      const response = await api.get(`${salesLeadsEndpoint}?pageNumber=${currentPage}&pageSize=${itemsPerPage}${queryParam}`);
      response.data.salesLeads = response.data.salesLeads?.map((item) => ({
        ...item,
        referenceNum: item?.account?.referenceNumber,
        tenantUser: item?.account?.name,
        contactUser: `${item?.contact?.firstName} ${item?.contact?.lastName}`,
        assignedUser: `${item?.assignedToUser?.firstName} ${item?.assignedToUser?.lastName}`,
      }));
      const leadsList = response.data?.salesLeads;
      setEntityData(response.data);
      setLeadsList(leadsList);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setTableLoader(false);
  };

  const handleRefreshData = () => {
    fetchLeads();
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(true);
  };

  const handleEditEntity = (item, header) => {
    switch (header?.field) {
      case 'leadName':
        setLeadId(item?.leadId);
        navigate(`/leads/${item?.leadId}`);
        break;
      case 'referenceNum':
        navigate(`/accounts/${item?.account?.tenantId}`);
        break;
    }
    // setShowPrimaryContactModal(true);
  };

  const handleSearch = (searchText) => {
    setSearch(searchText);
    fetchLeads(1, searchText);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchLeads(page);
  };

  const handleDeleteConfirmYes = () => {
    deleteContacts();
  };

  const deleteContacts = async () => {
    const selectedItems = leadsList.filter((item) => item.checked)?.map((adj) => adj.contactId);
    setDeleteDialog(false);
    try {
      await api.delete(salesLeadsEndpoint, {
        data: selectedItems,
      });
      setAlertData({
        ...alertData,
        open: true,
        type: AlertStatus.Success,
        message: Messages.primaryContactDelete,
      });
      fetchLeads();
      // fetchAggregateData();
    } catch (error) {
      console.error(Messages.genericError, error);
    }
  };

  const handleDeleteConfirmNo = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDeleteDialog(false);
  };

  const toggleSelectedCheckbox = (checkedItem) => {
    setLeadsList(getCheckboxResult(leadsList, checkedItem, 'leadId'));
  };

  const addLead = () => {
    navigate('/leads/newLead');
  };

  return (
    <>
      <div className="d-flex flex-grow-1 flex-column p-4">
        <div className="d-flex justify-between">
          <Grid container className="d-flex flex-column">
            <CustomBreadCrumb breadcrumbList={breadcrumbList} />
            <Typography className="mt-2" variant="h5" gutterBottom>
              Leads
            </Typography>
          </Grid>
        </div>
        <div className="d-flex">
          <Grid container>
            <Grid item xs={10}>
              <CustomTableActions
                disableDelete={!leadsList.filter((cus) => cus.checked)?.length}
                handleRefresh={handleRefreshData}
                handleDelete={handleDeleteConfirm}
                handleAddEntity={addLead}
              ></CustomTableActions>
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { borderRadius: '7px' },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTable tableColumn={contactColumn} loading={tableLoader} tableData={leadsList} toggleSelectedCheckbox={toggleSelectedCheckbox} handleEditEntity={handleEditEntity} />
        <CustomPagination totalCount={entityData?.totalCount} totalPages={entityData?.totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        {/* Confirm Delete Box */}
        <ConfirmDialog showModal={deleteDialog} handleConfirmYes={handleDeleteConfirmYes} handleConfirmNo={handleDeleteConfirmNo} />
        {/* Toaster Message */}
        <CustomAlert type={alertData?.type} message={alertData.message} open={alertData?.open} onClose={alertClose} />
      </div>
    </>
  );
};

export default LeadsLanding;
